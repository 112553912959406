import axios from "axios";
import jwtDecode from "jwt-decode";
import { refreshAuthorization } from "../api/Auth";
import { APP_ROUTES } from "../routes";
import store from "../store";
import { setUserSession } from "../store/modules/auth/action";

export const formatedUsernameForType = (username, isUsernameTypeEmail) => {
  return isUsernameTypeEmail ? username : `+${username}`;
};
export const getSocialSigninRedirectURI = () => {
  return `${window.location.origin}${APP_ROUTES.SOCIAL_SIGNIN.path}`;
};
export const extractUserSessionFromPayload = (payload) => {
  const userSession = payload.signInUserSession;
  const idToken = userSession.idToken.jwtToken;
  const accessToken = userSession.accessToken.jwtToken;
  const refreshToken = userSession.refreshToken.token;
  return { idToken, accessToken, refreshToken };
};
export const getConfigWithLatestTokens = async (
  config,
  oldIdToken,
  oldAccessToken
) => {
  if (oldIdToken && oldAccessToken) {
    if (!isTokenValid(oldIdToken) || !isTokenValid(oldAccessToken)) {
      const response = await refreshAuthorization().catch((err) => {
        console.log(err);
      });
      if (response) {
        const userSession = extractUserSessionFromPayload({
          signInUserSession: response,
        });
        const { idToken, accessToken } = userSession;
        store.dispatch(setUserSession(userSession));
        config.headers.common.Authorization = idToken;
        config.headers.common.AccessToken = accessToken;
      }
    }
  }
  return config;
};
export const isTokenValid = (token) => {
  const now = Math.round(new Date().getTime() / 1000);
  const decodedToken = jwtDecode(token);
  const isValid = decodedToken && now < decodedToken.exp;
  return isValid;
};

export const firstLetterToUpperCase = (str) => {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
};

export const parseUserResponse = (res, isSocialSigin) => {
  const userData = res?.data?.data?.response;
  let buyer_ProfileId = null;
  let seller_ProfileId = null;

  let selected_WorkspaceProfileID = null;
  let isSocialValue = false;
  let account_Role = "buyer";
  let workspaceList = [];

  // userData?.account[0]?.profiles?.forEach((profile) => {
  //   if (profile.profile_type === "buyer") {
  //     buyer_ProfileId = profile.id;
  //   } else {
  //     seller_ProfileId = profile.id;
  //   }
  // });

  userData?.account[0]?.profiles.forEach((profile) => {
    if (profile.profile_type === "buyer") {
      buyer_ProfileId = profile.id;
    } else {
      seller_ProfileId = profile.id;
    }
  });
  workspaceList = userData?.workspaces;

  const state = {
    email: userData.email,
    firstName: userData.first_name,
    lastName: userData.last_name,
    password: userData.password,
    phoneNumber: userData.phone_number,
    userId: userData.id,
    imageUri: userData.image_uri,
    accountId: userData.account[0].id,

    isSocial: isSocialSigin,

    // user Account Role
    accountRole: account_Role,

    // these will remain constant
    buyerProfileId: buyer_ProfileId,
    sellerProfileId: seller_ProfileId,

    //
    joinedWorkspacesList: workspaceList,

    // this will change with workspace switch
    buyerProfileWorkspaceId: 0,
    sellerProfileWorkspaceId: 0,

    // used by Amir bhai
    workspaceProfileId: selected_WorkspaceProfileID,

    selectedWorkspaceId: 0,
    isMarketPlaceOwner: true,
    workspaceCategoryId: 0,
    isDefaultWorkspace: false, // for switch profile check
    selectedWorkspaceImagUrl: null,
    selectedWorkspaceName: "",

    twilioToken: null, // for twilio token
  };

  return state;
};

export const getSelectedWorkspaceProfileId = (userSession) => {
  if (userSession.accountRole === "buyer") {
    return userSession.buyerProfileWorkspaceId;
  } else {
    return userSession.sellerProfileWorkspaceId;
  }
};
/**
 * if isSeller = false, that means buyer and incase if isSeller = true then the user is Seller
 * @param {*} checked
 */
export const switchProfileType = (isSeller) => {
  if (isSeller) {
  } else {
  }
};

export const CheckPassword = (inputtxt) => {
  const decimal =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,256}$/;
  if (inputtxt.match(decimal)) {
    return true;
  } else {
    return false;
  }
};

export async function uploadFileUsingPreSignedURL(
  pre_signed_url,
  file,
  customProgressLogic
) {
  try {
    const res = await uploadS3(pre_signed_url, file, (percent) => {
      customProgressLogic(percent);
    });
    return res;
  } catch (ex) {
    throw ex;
  }
}

function uploadS3(url, file, progressCallback) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr);
        } else {
          reject(xhr);
        }
      }
    };
    if (progressCallback) {
      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const percentComplete = (e.loaded / file.size) * 100;
          progressCallback(percentComplete);
        }
      };
    }
    const state = store.getState();
    let email = state?.userReducer?.userProfile?.email;
    xhr.open("PUT", url);
    // xhr.setRequestHeader("x-amz-tagging", `email=${email}`);
    xhr.send(file);
  });
}
export const getRemainingTimeToUpload = (startTime, uploadProgressEvent) => {
  const timeElapsed = new Date() - startTime;
  const uploadSpeed = uploadProgressEvent.loaded / (timeElapsed / 1000);

  const timeRemaining =
    (uploadProgressEvent.total - uploadProgressEvent.loaded) / uploadSpeed;
  return timeRemaining;
};
export const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
  const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const checkNameCharactersLimit = (text, limit) => {
  if (text == "") {
    return;
  }
  let result = "";
  if (text.length > limit) {
    result = text.substring(0, limit + 1) + "...";
  } else {
    result = text;
  }

  return result;
};

export const capitalizeFirstCharacter = (str) => {
  return str?.charAt(0).toUpperCase() + str.slice(1);
};
export const lowerCasedColumns = (mixedColumns) => {
  return mixedColumns.map((column) => {
    return String(column).toLowerCase();
  });
};

export const generateRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const twilioNumbersList = () => {
  const arr = [];

  return arr;
};

export const randomize = (arr) => {
  let data = [...arr];
  let chosenItems = [];
  function getRandomValue() {
    if (data.length === 0) {
      data = chosenItems;
      chosenItems = [];
    }
    const index = Math.floor(Math.random() * data.length);
    const choice = data.splice(index, 1)[0];

    chosenItems.push(choice);
    return choice;
  }

  return {
    randomItem: getRandomValue,
  };
};

export const matchingColumnInColumnsOrDataDictionary = (
  sourceColumn,
  fileColumns,
  dataDictionary
) => {
  const lowerCasedSourceColumn = String(sourceColumn).toLowerCase();
  let matchingColumnName = "";
  fileColumns.forEach((fileColumn) => {
    if (String(fileColumn).toLowerCase() === lowerCasedSourceColumn) {
      //Mapped directly with file columns
      matchingColumnName = fileColumn;
    }
  });
  if (!matchingColumnName) {
    //Matching column not found in file
    dataDictionary.forEach((dataDictionaryObj) => {
      if (dataDictionaryObj.key.toLowerCase() === lowerCasedSourceColumn) {
        //Mapping is available
        fileColumns.forEach((fileColumn) => {
          if (
            fileColumn.toLowerCase() === dataDictionaryObj.value.toLowerCase()
          ) {
            matchingColumnName = fileColumn;
          }
        });
      }
    });
  }
  return matchingColumnName;
};
export const getDataDictionaryColumnsFromMapping = (
  fileColumns,
  dataDictionary,
  columnMapping
) => {
  const dataDictionaryMapping = [];
  for (const mappingColumn in columnMapping) {
    const matchingColumn = matchingColumnInColumnsOrDataDictionary(
      mappingColumn,
      fileColumns,
      dataDictionary
    );
    if (matchingColumn !== columnMapping[mappingColumn]) {
      dataDictionaryMapping.push({
        [mappingColumn]: columnMapping[mappingColumn],
      });
    }
  }
  return dataDictionaryMapping;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

//creating function to load ip address from the API
export const getIPAddress = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  // console.log("IPAddress", res?.data?.IPv4);
};

export const getCurrentDomainName = () => {
  let result = "palisadeknowledge";
  let currentDomain = window.location.host;
  if (currentDomain === "app.privhomes.com") {
    result = "privhomes";
  } else if (currentDomain === "app.fidelityamericanhomes.com") {
    result = "fidelityamericanhomes";
  } else if (currentDomain === "app.palisade.ai") {
    result = "palisadeknowledge";
  } else if (currentDomain === "app.progressiveamericanproperties.com") {
    result = "progressiveamericanproperties";
  } else if (currentDomain === "app.prudentialhomesofamerica.com") {
    result = "prudentialhomesofamerica";
  } else if (currentDomain === "app.myaihomevalueestimator.com") {
    result = "myaihomevalueestimator";
  } else if (currentDomain === "app.homeoffercalculator.com") {
    result = "homeoffercalculator";
  } else if (currentDomain === "app.americanbestagents.com") {
    result = "americanbestagents";
  } else if (currentDomain === "app.alyson.ai") {
    result = "alyson";
  } else {
    // For development purpose
    result = "alyson";
  }

  return result;
};

export const changeFavivon = () => {
  const link = document.querySelector('link[rel="icon"]');
  let fav_icon = "favicon_palisade.ico";
  let currentDomain = window.location.host;
  if (currentDomain === "app.privhomes.com") {
    fav_icon = "favicon_privhomes.ico";
  } else if (currentDomain === "app.fidelityamericanhomes.com") {
    fav_icon = "favicon_palisade.ico";
  } else if (currentDomain === "app.palisade.ai") {
    fav_icon = "favicon_palisade.ico";
  } else if (currentDomain === "app.progressiveamericanproperties.com") {
    fav_icon = "favicon_palisade.ico";
  } else if (currentDomain === "app.prudentialhomesofamerica.com") {
    fav_icon = "favicon_palisade.ico";
  } else if (currentDomain === "app.myaihomevalueestimator.com") {
    fav_icon = "favicon_homevalueestimator.ico";
  } else if (currentDomain === "app.homeoffercalculator.com") {
    fav_icon = "favicon_homeoffercalculator.ico";
  } else if (currentDomain === "app.americanbestagents.com") {
    fav_icon = "favicon_americanbestagents.ico";
  } else if (currentDomain === "app.alyson.ai") {
    fav_icon = "favicon_alyson.ico";
  } else if (currentDomain === "localhost:3000") {
    // For development purpose
    fav_icon = "favicon_alyson.ico";
  }

  if (link) {
    link.setAttribute("href", fav_icon);
  }
};

export const isMobile = () => {
  return window.innerWidth < 650 ? true : false;
};

export const convertUtcToReadableDateFormat = (timeString) => {
  // Regular expression to match the date components
  const dateRegex =
    /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})\.(\d+)? UTC$/;

  // Match the timeString against the regex
  const match = timeString.match(dateRegex);

  if (!match) {
    throw new Error("Invalid date format");
  }

  // Extract the date and time components from the match groups
  const year = match[1];
  const month = match[2];
  const day = match[3];
  const hours = match[4];
  const minutes = match[5];
  const seconds = match[6];
  const milliseconds = match[7] || 0;

  // Create a new Date object using the extracted components
  const date = new Date(
    Date.UTC(year, month - 1, day, hours, minutes, seconds, milliseconds)
  );

  // Format the date as yyyy-mm-dd
  const formattedDate = `${year}-${String(month).padStart(2, "0")}-${String(
    day
  ).padStart(2, "0")}`;

  return formattedDate;
};

// Function to truncate text to 25 characters and add ellipsis if needed
export const truncateText = (charLimit, text) => {
  return (text != null && text.length) > charLimit
    ? text.substring(0, charLimit) + "..."
    : text;
};
