import React from "react";
import { Tabs } from "antd";
import "./index.scss";
import PlacesSearch from "../PlacesSearch";
import CompaniesSearch from "../CompaniesSearch";
import EventsSearch from "../EventsSearch";
import PeopleSearch from "../PeopleSearch";
import ProfileSearch from "../ProfileSearch";
import Overview from "../Overview";

const { TabPane } = Tabs;
const Explorer = () => {
  return (
    <Tabs defaultActiveKey="1" className="profile-explorer__tabs">
      <TabPane tab="Overview" key="1">
        <Overview />
      </TabPane>
      <TabPane tab="People" key="2">
        <PeopleSearch />
      </TabPane>
      <TabPane tab="Places" key="3">
        <PlacesSearch />
      </TabPane>
      <TabPane tab="Companies" key="4">
        <CompaniesSearch />
      </TabPane>
      <TabPane tab="Events" key="5">
        <EventsSearch />
      </TabPane>
    </Tabs>
  );
};

export default Explorer;
