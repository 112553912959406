import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { trackEventCall } from "analytics";
import {
  Button,
  Image,
  Input,
  message,
  Modal,
  Spin,
  Typography,
  Upload,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  deleteUserAccount,
  getProfilePresignedUri,
  updateUserProfile,
  updateWorkProfile,
} from "api/userManagement";
import { getUserWorkspaceByProfileIds } from "api/Workspaces";
import CustomAvatar from "commons/CustomAvatar";
import VerifyPhonePopup from "components/VerifyPhonePopup";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { IconCameraAddPhoto, IconEdit } from "images";
import { useEffect, useState } from "react";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthState, setUserSession } from "store/modules/auth/action";
import { clearFileOnboardingState } from "store/modules/fileOnboard/action";
import { isMobile, uploadFileUsingPreSignedURL } from "utils";
import "./index.scss";

function Profile() {
  const [loading, setLoading] = useState(false);
  const [workLoading, setWorkLoading] = useState(false);
  const [workLoading1, setWorkLoading1] = useState(false);
  const [workLoading2, setWorkLoading2] = useState(false);
  const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);
  const authReducer = useSelector((state) => state.authReducer);
  const { firstName, lastName, email, phoneNumber } = authReducer.userSession;
  const [userFullName, setUserFullName] = useState(`${firstName} ${lastName}`);
  const [userPhone, setUserPhone] = useState(`${phoneNumber}`);
  const dispatch = useDispatch();
  const userSession = authReducer.userSession;
  const [preSignedInfo, setPreSignedInfo] = useState({});
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [workPosition, setWorkPosition] = useState(null);
  const [workDescription, setWorkDescription] = useState(null);
  const [workCalendlyAccount, setWorkCalendlyAccount] = useState(null);
  const [workImage1Url, setWorkImage1Url] = useState(null);
  const [workImage2Url, setWorkImage2Url] = useState(null);
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [showWorkSaveChanges, setShowWorkSaveChanges] = useState(false);
  const [createVerifyPhoneVisible, setCreateVerifyPhoneVisible] =
    useState(false);
  const [userWorkPosition, setUserWorkPosition] = useState("");
  const [userWorkDescription, setUserWorkDescription] = useState("");
  const [userWorkCalendlyAccount, setUserWorkCalendlyAccount] = useState("");
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const { confirm } = Modal;

  const onProgress = () => {};

  const onNameChage = (e) => {
    setUserFullName(e.target.value);
    setShowSaveChanges(true);
  };
  const onPhoneChage = (e) => {
    setUserPhone(e.target.value);
    setShowSaveChanges(true);
  };
  const onDescriptionChage = (e) => {
    setUserWorkDescription(e.target.value);
    setShowWorkSaveChanges(true);
  };

  const onPositionChange = (e) => {
    setUserWorkPosition(e.target.value);
    setShowWorkSaveChanges(true);
  };

  const onCalendlyAccountUrlChange = (e) => {
    setUserWorkCalendlyAccount(e.target.value);
    setShowWorkSaveChanges(true);
  };

  useEffect(() => {
    setUserProfileUrl(userSession?.imageUri ? userSession?.imageUri : null);
  }, []);
  // useEffect(() => {
  //   setWorkProfileData();
  // }, [userSession.joinedWorkspacesList]);

  useEffect(() => {
    // payload
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        // console.log("Profile screen", res);
        res?.data?.data?.forEach((el) => {
          if (
            el.workspace_id != null &&
            parseInt(el.workspace_id) ===
              parseInt(userSession.selectedWorkspaceId)
          ) {
            el?.workspace_profiles?.forEach((account) => {
              if (account.account_role === "seller") {
                setWorkPosition(account.work_position);
                setWorkDescription(account.work_description);
                setWorkCalendlyAccount(account.work_calendly_url);
                setWorkImage1Url(account.work_image1);
                setWorkImage2Url(account.work_image2);
                setUserWorkPosition(account.work_position);
                setUserWorkDescription(account.work_description);
                setUserWorkCalendlyAccount(account.work_calendly_url);
              }
            });
          }
        });
        // let updatedUserSession = { ...userSession };
        // updatedUserSession.joinedWorkspacesList = res?.data?.data;
        // dispatch(setUserSession(updatedUserSession));
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      });
  }, []);

  const setWorkProfileData = () => {
    userSession?.joinedWorkspacesList?.forEach((ws) => {
      if (parseInt(ws?.id) === parseInt(userSession.selectedWorkspaceId)) {
        ws?.workspace_profiles?.forEach((account) => {
          if (account.account_role === "seller") {
            setWorkPosition(account.work_position);
            setWorkDescription(account.work_description);
            setWorkImage1Url(account.work_image1);
            setWorkImage2Url(account.work_image2);
            setUserWorkPosition(account.work_position);
            setUserWorkDescription(account.work_description);
          }
        });
      }
    });
  };

  const onChange = ({ file }) => {
    setLoading(true);
    const payloadForProfileUri = {
      userId: userSession.userId,
      imageName: "profile_image.png",
    };
    let uploadedImagePath = null;
    getProfilePresignedUri(payloadForProfileUri)
      .then((res) => {
        setPreSignedInfo(res?.data?.data);
        const uploadUrl = res?.data?.data?.url;
        const bucket = res?.data?.data?.bucket;
        const key = res?.data?.data?.key;
        uploadedImagePath =
          "https://" +
          bucket +
          ".s3.us-west-2.amazonaws.com" +
          "/" +
          key +
          `?abc=${Math.random()}`;
        uploadFileUsingPreSignedURL(uploadUrl, file, (progress) => {
          onProgress({
            percent: Math.ceil(progress),
          });
        })
          .then((uploadRes) => {
            setUserProfileUrl(uploadedImagePath);
            userSession.imageUri = uploadedImagePath;
            dispatch(setUserSession(userSession));
            emitCustomEvent("profile-img-changed", uploadedImagePath);
            message.success("Image uploaded successfully!");
            const payloadForProfileImage = {
              userId: userSession.userId,
              data: {
                image_uri: uploadedImagePath,
              },
            };
            updateUserProfileData(payloadForProfileImage);
            setLoading(false);
          })
          .catch((uploadErr) => {
            console.log(uploadErr);
            setLoading(false);
          })
          .finally(() => {
            // setLoading(false);
          });
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {});
  };

  const onWorkFileChange = ({ file }) => {
    setWorkLoading1(true);
    const payloadForProfileUri = {
      userId: userSession.userId,
      imageName: "work_image_1.png",
      workspaceId: userSession.selectedWorkspaceId,
    };
    let uploadedImagePath = null;
    getProfilePresignedUri(payloadForProfileUri)
      .then((res) => {
        setPreSignedInfo(res.data.data);
        const uploadUrl = res.data.data.url;
        const bucket = res.data.data.bucket;
        const key = res.data.data.key;
        uploadedImagePath =
          "https://" +
          bucket +
          ".s3.us-west-2.amazonaws.com" +
          "/" +
          key +
          `?abc=${Math.random()}`;
        uploadFileUsingPreSignedURL(uploadUrl, file, (progress) => {
          onProgress({
            percent: Math.ceil(progress),
          });
        })
          .then((uploadRes) => {
            setWorkImage1Url(uploadedImagePath);
            // userSession.imageUri = uploadedImagePath;
            // dispatch(setUserSession(userSession));
            // emitCustomEvent("profile-img-changed", uploadedImagePath);
            message.success("Work image uploaded successfully!");
            const payloadForWorkProfileImage = {
              workspaceId: userSession.selectedWorkspaceId,
              profileId: userSession.sellerProfileId,
              data: {
                work_image1: uploadedImagePath,
              },
            };
            updateWorkProfileData(payloadForWorkProfileImage);
            setWorkLoading1(false);
          })
          .catch((uploadErr) => {
            console.log(uploadErr);
            setWorkLoading1(false);
          })
          .finally(() => {
            // setLoading(false);
          });
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {});
  };

  const onWorkFileChange1 = ({ file }) => {
    setWorkLoading2(true);
    const payloadForProfileUri = {
      userId: userSession.userId,
      imageName: "work_image_2.png",
      workspaceId: userSession.selectedWorkspaceId,
    };
    let uploadedImagePath = null;
    getProfilePresignedUri(payloadForProfileUri)
      .then((res) => {
        setPreSignedInfo(res.data.data);
        const uploadUrl = res.data.data.url;
        const bucket = res.data.data.bucket;
        const key = res.data.data.key;
        uploadedImagePath =
          "https://" +
          bucket +
          ".s3.us-west-2.amazonaws.com" +
          "/" +
          key +
          `?abc=${Math.random()}`;
        uploadFileUsingPreSignedURL(uploadUrl, file, (progress) => {
          onProgress({
            percent: Math.ceil(progress),
          });
        })
          .then((uploadRes) => {
            setWorkImage2Url(uploadedImagePath);
            // userSession.imageUri = uploadedImagePath;
            // dispatch(setUserSession(userSession));
            // emitCustomEvent("profile-img-changed", uploadedImagePath);
            message.success("Work image uploaded successfully!");
            const payloadForWorkProfileImage = {
              workspaceId: userSession.selectedWorkspaceId,
              profileId: userSession.sellerProfileId,
              data: {
                work_image2: uploadedImagePath,
              },
            };
            updateWorkProfileData(payloadForWorkProfileImage);
            setWorkLoading2(false);
          })
          .catch((uploadErr) => {
            console.log(uploadErr);
            setWorkLoading2(false);
          })
          .finally(() => {
            // setLoading(false);
          });
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {});
  };

  const updateUserProfileData = (profileState) => {
    updateUserProfile(profileState)
      .then((res) => {
        setShowSaveChanges(false);
        userSession.firstName = userFullName;
        userSession.lastName = "";
        userSession.phoneNumber = userPhone;
        dispatch(setUserSession(userSession));
        emitCustomEvent("first-name-changed", userFullName);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateWorkProfileData = (profileState) => {
    updateWorkProfile(profileState)
      .then((res) => {
        message.success("Work profile updated successfully!");
        setShowWorkSaveChanges(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setWorkLoading(false);
      });
  };

  const handleSaveChanges = () => {
    setLoading(true);
    const payloadForProfileImage = {
      userId: userSession.userId,
      data: {
        first_name: userFullName,
        phone_number: userPhone,
      },
    };
    updateUserProfileData(payloadForProfileImage);
  };
  const handleCancelChanges = () => {
    setUserFullName(userSession.firstName + " " + userSession.lastName);
    setUserPhone(userSession.phoneNumber);
    setShowSaveChanges(false);
  };

  const handleWorkSaveChanges = () => {
    setWorkLoading(true);
    const payloadForProfileImage = {
      workspaceId: userSession.selectedWorkspaceId,
      profileId: userSession.sellerProfileId,
      data: {
        work_position: userWorkPosition,
        work_description: userWorkDescription,
        work_calendly_url: userWorkCalendlyAccount,
      },
    };
    updateWorkProfileData(payloadForProfileImage);
  };

  const handleCancelWorkChanges = () => {
    setUserWorkPosition(workPosition);
    setUserWorkDescription(workDescription);
    setShowWorkSaveChanges(false);
  };
  const handleEditProfileInfo = () => {
    setShowSaveChanges(true);
  };

  const handleEditWorkProfileInfo = () => {
    setShowWorkSaveChanges(true);
  };

  const onVerifyClick = () => {
    if (createVerifyPhoneVisible === false) {
      setCreateVerifyPhoneVisible(true);
    }
  };

  useCustomEventListener("user-phone-updated", (data) => {
    setUserPhone(data);
  });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div>Upload</div>
    </div>
  );

  const logoutUser = () => {
    const eventData = {
      email: userSession.email,
      userId: userSession.userId,
    };
    trackEventCall(ANALYTICS_EVENT_NAMES.LOGOUT, eventData);
    dispatch(clearFileOnboardingState(null));
    dispatch(clearAuthState(null));
  };

  const deleteAccount = () => {
    confirm({
      className: "profilePage",
      title: "Do you want to delete your account?",
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      content: "By deleting your account, all of your data will be wiped out.",
      onOk() {
        apiCallForDeleteAccount();
      },
      onCancel() {},
    });
  };

  const apiCallForDeleteAccount = () => {
    setDeleteAccountLoading(true);
    const payload = {
      email: userSession.email,
      isSocial: true,
      phoneNumber: userSession.phoneNumber,
    };

    deleteUserAccount(payload)
      .then((res) => {
        console.log("Account deleted", res);
        message.success("Account deleted successfully!");
        setDeleteAccountLoading(false);
        logoutUser();
      })
      .catch((err) => {
        console.log("Account delete error", err);
        setDeleteAccountLoading(false);
      });
  };

  return (
    <Spin spinning={deleteAccountLoading}>
      {isMobileView === true ? (
        <div className="profilePage">
          <div className="profilePage__headerMobile">
            <div className="profilePage__headerDiv">
              <Typography.Text className="profilePage__headerText">
                Personal Information
              </Typography.Text>
              <Spin spinning={loading}>
                <ImgCrop>
                  <Upload
                    accept=".png, .jpg, .jpeg"
                    showUploadList={false}
                    customRequest={onChange}
                  >
                    <div className="profilePage__headerImgDiv">
                      <CustomAvatar
                        className="profilePage__headerImg"
                        imageUri={userProfileUrl}
                        name={userSession.firstName}
                      />
                      <Image
                        className="profilePage__cameraIcon"
                        src={IconCameraAddPhoto}
                        preview={false}
                      ></Image>
                    </div>
                  </Upload>
                </ImgCrop>
              </Spin>
            </div>
            <div className="profilePage__headerNameInfoDiv">
              <Typography.Text className="profilePage__normalTextBoldFirst">
                Full Name
              </Typography.Text>
              <Input
                className="profilePage__inputField"
                value={userFullName}
                bordered={false}
                onChange={onNameChage}
              ></Input>
              <Typography.Text className="profilePage__normalTextBold">
                Email
              </Typography.Text>
              <Typography.Text className="profilePage__normalText">
                {email}
              </Typography.Text>
              <Typography.Text className="profilePage__normalTextBold">
                Person ID
              </Typography.Text>
              <Typography.Text className="profilePage__normalText">
                {userSession.userId}
              </Typography.Text>
            </div>
            <div className="profilePage__ctoDiv">
              <div className="profilePage__verifyPhoneDiv">
                <div className="profilePage__phoneTitleValueDiv">
                  <Typography.Text className="profilePage__normalTextBoldFirst">
                    Phone Number
                  </Typography.Text>
                  <Input
                    className="profilePage__inputField"
                    value={userPhone}
                    bordered={false}
                    disabled={true}
                    onChange={onPhoneChage}
                  ></Input>
                </div>
                <div className="profilePage__verifyTextDiv">
                  <Typography.Text
                    className="profilePage__verifyText"
                    onClick={onVerifyClick}
                  >
                    Change Phone Number
                  </Typography.Text>
                </div>
              </div>

              <div className="profilePage__ctoParentDiv">
                {showSaveChanges ? (
                  <div className="profilePage__buttonsDiv">
                    <Button
                      className="profilePage__cancelButton"
                      type="primary"
                      onClick={handleCancelChanges}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={loading}
                      className="profilePage__saveChangesButton"
                      type="primary"
                      htmlType="submit"
                      onClick={handleSaveChanges}
                    >
                      Save Changes
                    </Button>
                  </div>
                ) : (
                  <Image
                    className="profilePage__iconEdit"
                    preview={false}
                    src={IconEdit}
                    onClick={handleEditProfileInfo}
                  ></Image>
                )}
              </div>
            </div>
          </div>
          {userSession.accountRole === "seller" ? (
            <div className="profilePage__footerMobile">
              <div className="profilePage__headerDiv">
                <Typography.Text className="profilePage__headerText">
                  Work Profile
                </Typography.Text>
              </div>
              <div className="profilePage__footerInfoDiv">
                <Typography.Text className="profilePage__normalTextBoldFirst">
                  Position
                </Typography.Text>
                <Input
                  className="profilePage__inputFieldWithMargin"
                  value={userWorkPosition}
                  defaultValue={workPosition}
                  bordered={false}
                  onChange={onPositionChange}
                ></Input>
                <Typography.Text className="profilePage__normalTextBold">
                  Description
                </Typography.Text>
                <Input.TextArea
                  className="profilePage__inputFieldDesc"
                  bordered={false}
                  maxLength={180}
                  defaultValue={workDescription}
                  value={userWorkDescription}
                  onChange={onDescriptionChage}
                  placeholder="Add work description here"
                  autoSize={{
                    minRows: 2,
                    maxRows: 2,
                  }}
                ></Input.TextArea>

                <Typography.Text className="profilePage__normalTextBoldFirst">
                  Calendly Account Url
                </Typography.Text>
                <Input
                  className="profilePage__inputFieldWithMargin"
                  value={userWorkCalendlyAccount}
                  defaultValue={workCalendlyAccount}
                  bordered={false}
                  onChange={onCalendlyAccountUrlChange}
                ></Input>
                <div className="profilePage__sellerImgsDiv">
                  <Typography.Text className="profilePage__normalTextWorkLogos">
                    Upload work logos:{" "}
                  </Typography.Text>
                  <Spin spinning={workLoading1}>
                    <ImgCrop>
                      <Upload
                        listType="picture-card"
                        accept=".png, .jpg, .jpeg"
                        showUploadList={false}
                        customRequest={onWorkFileChange}
                      >
                        <Image
                          className="profilePage__workImg"
                          placeholder={true}
                          src={workImage1Url}
                          preview={false}
                        />
                      </Upload>
                    </ImgCrop>
                  </Spin>
                  <Spin spinning={workLoading2}>
                    <ImgCrop>
                      <Upload
                        listType="picture-card"
                        accept=".png, .jpg, .jpeg"
                        showUploadList={false}
                        customRequest={onWorkFileChange1}
                      >
                        <Image
                          className="profilePage__workImg"
                          placeholder={true}
                          src={workImage2Url}
                          preview={false}
                        />
                      </Upload>
                    </ImgCrop>
                  </Spin>
                </div>
                <div className="profilePage__workProfileEditDiv">
                  {showWorkSaveChanges ? (
                    <div className="profilePage__buttonsDiv">
                      <Button
                        className="profilePage__cancelButton"
                        type="primary"
                        onClick={handleCancelWorkChanges}
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={workLoading}
                        className="profilePage__saveChangesButton"
                        type="primary"
                        htmlType="submit"
                        onClick={handleWorkSaveChanges}
                      >
                        Save Changes
                      </Button>
                    </div>
                  ) : (
                    <Image
                      className="profilePage__iconEdit"
                      preview={false}
                      src={IconEdit}
                      onClick={handleEditWorkProfileInfo}
                    ></Image>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <Button
            className={
              userSession.accountRole === "seller"
                ? "profilePage__deleteAccountButtonSellerMobile"
                : "profilePage__deleteAccountButtonBuyerMobile"
            }
            type="danger"
            htmlType="submit"
            onClick={deleteAccount}
          >
            Delete Account
          </Button>
        </div>
      ) : (
        <div className="profilePage">
          <div className="profilePage__header">
            <div className="profilePage__headerDiv">
              <Typography.Text className="profilePage__headerText">
                Personal Information
              </Typography.Text>
              <Spin spinning={loading}>
                <ImgCrop>
                  <Upload
                    accept=".png, .jpg, .jpeg"
                    showUploadList={false}
                    customRequest={onChange}
                  >
                    <div className="profilePage__headerImgDiv">
                      <CustomAvatar
                        className="profilePage__headerImg"
                        imageUri={userProfileUrl}
                        name={userSession.firstName}
                      />
                      <Image
                        className="profilePage__cameraIcon"
                        src={IconCameraAddPhoto}
                        preview={false}
                      ></Image>
                    </div>
                  </Upload>
                </ImgCrop>
              </Spin>
            </div>
            <div className="profilePage__headerNameInfoDiv">
              <Typography.Text className="profilePage__normalTextBoldFirst">
                Full Name
              </Typography.Text>
              <Input
                className="profilePage__inputField"
                value={userFullName}
                bordered={false}
                onChange={onNameChage}
              ></Input>
              <Typography.Text className="profilePage__normalTextBold">
                Email
              </Typography.Text>
              <Typography.Text className="profilePage__normalText">
                {email}
              </Typography.Text>
              <Typography.Text className="profilePage__normalTextBold">
                Person ID
              </Typography.Text>
              <Typography.Text className="profilePage__normalText">
                {userSession.userId}
              </Typography.Text>
            </div>
            <div className="profilePage__ctoDiv">
              <div className="profilePage__verifyPhoneDiv">
                <div className="profilePage__phoneTitleValueDiv">
                  <Typography.Text className="profilePage__normalTextBoldFirst">
                    Phone Number
                  </Typography.Text>
                  <Input
                    className="profilePage__inputField"
                    value={userPhone}
                    bordered={false}
                    disabled={true}
                    onChange={onPhoneChage}
                  ></Input>
                </div>
                <div className="profilePage__verifyTextDiv">
                  <Typography.Text
                    className="profilePage__verifyText"
                    onClick={onVerifyClick}
                  >
                    Change Phone Number
                  </Typography.Text>
                </div>
              </div>

              <div className="profilePage__ctoParentDiv">
                {showSaveChanges ? (
                  <div className="profilePage__buttonsDiv">
                    <Button
                      className="profilePage__cancelButton"
                      type="primary"
                      onClick={handleCancelChanges}
                    >
                      Cancel
                    </Button>
                    <Button
                      loading={loading}
                      className="profilePage__saveChangesButton"
                      type="primary"
                      htmlType="submit"
                      onClick={handleSaveChanges}
                    >
                      Save Changes
                    </Button>
                  </div>
                ) : (
                  <Image
                    className="profilePage__iconEdit"
                    preview={false}
                    src={IconEdit}
                    onClick={handleEditProfileInfo}
                  ></Image>
                )}
              </div>
            </div>
          </div>
          {userSession.accountRole === "seller" ? (
            <div className="profilePage__footer">
              <div className="profilePage__headerDiv">
                <Typography.Text className="profilePage__headerText">
                  Work Profile
                </Typography.Text>
              </div>
              <div className="profilePage__footerInfoDiv">
                <Typography.Text className="profilePage__normalTextBoldFirst">
                  Position
                </Typography.Text>
                <Input
                  className="profilePage__inputFieldWithMargin"
                  value={userWorkPosition}
                  defaultValue={workPosition}
                  bordered={false}
                  onChange={onPositionChange}
                ></Input>
                <Typography.Text className="profilePage__normalTextBold">
                  Description
                </Typography.Text>
                <Input.TextArea
                  className="profilePage__inputFieldDesc"
                  bordered={false}
                  maxLength={90}
                  defaultValue={workDescription}
                  value={userWorkDescription}
                  onChange={onDescriptionChage}
                  placeholder="Add work description here"
                  autoSize={{
                    minRows: 2,
                    maxRows: 2,
                  }}
                ></Input.TextArea>
                <Typography.Text className="profilePage__normalTextBoldFirst">
                  Calendly Account Url
                </Typography.Text>
                <Input
                  className="profilePage__inputFieldWithMargin"
                  value={userWorkCalendlyAccount}
                  defaultValue={workCalendlyAccount}
                  bordered={false}
                  onChange={onCalendlyAccountUrlChange}
                ></Input>
                <div className="profilePage__sellerImgsDiv">
                  <Typography.Text className="profilePage__normalTextWorkLogos">
                    Upload work logos:{" "}
                  </Typography.Text>
                  <Spin spinning={workLoading1}>
                    <ImgCrop>
                      <Upload
                        listType="picture-card"
                        accept=".png, .jpg, .jpeg"
                        showUploadList={false}
                        customRequest={onWorkFileChange}
                      >
                        <Image
                          className="profilePage__workImg"
                          placeholder={true}
                          src={workImage1Url}
                          preview={false}
                        />
                      </Upload>
                    </ImgCrop>
                  </Spin>
                  <Spin spinning={workLoading2}>
                    <ImgCrop>
                      <Upload
                        listType="picture-card"
                        accept=".png, .jpg, .jpeg"
                        showUploadList={false}
                        customRequest={onWorkFileChange1}
                      >
                        <Image
                          className="profilePage__workImg"
                          placeholder={true}
                          src={workImage2Url}
                          preview={false}
                        />
                      </Upload>
                    </ImgCrop>
                  </Spin>
                </div>
                <div className="profilePage__workProfileEditDiv">
                  {showWorkSaveChanges ? (
                    <div className="profilePage__buttonsDiv">
                      <Button
                        className="profilePage__cancelButton"
                        type="primary"
                        onClick={handleCancelWorkChanges}
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={workLoading}
                        className="profilePage__saveChangesButton"
                        type="primary"
                        htmlType="submit"
                        onClick={handleWorkSaveChanges}
                      >
                        Save Changes
                      </Button>
                    </div>
                  ) : (
                    <Image
                      className="profilePage__iconEdit"
                      preview={false}
                      src={IconEdit}
                      onClick={handleEditWorkProfileInfo}
                    ></Image>
                  )}
                </div>
              </div>
            </div>
          ) : null}
          <Button
            className={
              userSession.accountRole === "seller"
                ? "profilePage__deleteAccountButtonSeller"
                : "profilePage__deleteAccountButtonBuyer"
            }
            type="danger"
            htmlType="submit"
            onClick={deleteAccount}
          >
            Delete Account
          </Button>
        </div>
      )}
      {createVerifyPhoneVisible && (
        <VerifyPhonePopup
          visible={createVerifyPhoneVisible}
          onCancel={() => setCreateVerifyPhoneVisible(false)}
          currentPhoneNumber={userPhone}
        ></VerifyPhonePopup>
      )}
    </Spin>
  );
}

export default Profile;
