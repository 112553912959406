import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Image, message, Spin, Alert } from "antd";
import "./index.scss";
import { createProfile, createDestination } from "api/Connectors";
import { getDestinationRequiredFileds } from "api/Connectors";
import Flex from "screens/Chat/Flex";
import { IconChevronLeft } from "images";
import { getSelectedWorkspaceProfileId } from "utils";

function DestinationProcessingFields() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sourceId, sourceType } = useParams();
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const [processingRequiredFields, setProcessingRequiredFields] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [spinning, setSpinning] = useState(false);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const [componentFormError, setComponentFormError] = useState({});
  const [domain, setDomain] = useState("");
  const [clientID, setClientID] = useState("");
  const [selectedApiVersion, setSelectedApiVersion] = useState("");
  const [apiVersions, setApiVersions] = useState([]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleComponentFormChange = useCallback(
    (e, index) => {
      let data = [...processingRequiredFields];
      data[index][e.target.name] = e.target.value;
      if (e.target.name === "instanceUrl") {
        setDomain(e.target.value);
      }
      if (e.target.name === "clientId") {
        setClientID(e.target.value);
      }
      if (e.target.name === "apiVersion") {
        setSelectedApiVersion(e.target.value);
      }
    },

    [processingRequiredFields]
  );
  const validate = () => {
    const errors = {};
    processingRequiredFields.forEach((field) => {
      if (field.required && !field[field.name]) {
        errors[
          field.name
        ] = `Please fill out the ${field.heading.toLowerCase()} field!`;
      }
    });
    return errors;
  };

  const onSubmit = async () => {
    const formErrors = validate(processingRequiredFields);
    setComponentFormError(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const requestBody = {};
      processingRequiredFields.forEach((field) => {
        if (field[field.name]) {
          requestBody[field.name] = field[field.name];
        }
      });
      if (sourceId) {
        requestBody.connectorId = parseInt(sourceId);
      }
      requestBody.profileWorkspaceId =
        getSelectedWorkspaceProfileId(userSession);
      requestBody.isDestination = true;
      setSpinning(true);
      console.log("payload", requestBody)
      try {
        const res = await createDestination(requestBody);
        message.success(res?.data?.message);
        navigate(`/dashboard/datasets/destinations`);
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setSpinning(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDestinationRequiredFileds(sourceId, { "isDestination": true });
        setIsLoader(false)
        setProcessingRequiredFields(response?.data?.data[0]?.required_fields[0])

      } catch (error) {
        setIsLoader(false)
        // Handle the error, e.g., show a notification or log it
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      {/* <div className="Fixed">
        <div className="fixedBar">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="Title"> Connection Setup</span>
        </div>
      </div> */}
      {spinning || isLoader ? (
        <div className="loading-container">
          {spinning ? (
            "Please wait, we're setting up your connection"
          ) : (
            <Spin />
          )}
        </div>) : (<div className="ProcessingFields">
          <h1 className="ProcessingFields__mainHeading"></h1>

          <form className="ProcessingFields__wrapper" onSubmit={onSubmit}>
            <>
              {processingRequiredFields.map((field, index) => (
                <div className="ProcessingFields__div" key={index}>
                  <h6 className="ProcessingFields__heading">{field.heading}</h6>
                  {field.heading === "API Version" ||
                    field.heading === "App Version" ? (
                    <select
                      className="ProcessingFields__input"
                      name={field.name}
                      value={field.value}
                      onChange={(e) => handleComponentFormChange(e, index)}
                    >
                      <option value="">Choose an API version</option>
                      {apiVersions.map((version) => (
                        <option key={version} value={version}>
                          {version}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <Input
                      className="ProcessingFields__input"
                      name={field.name}
                      value={field.value}
                      onChange={(e) => handleComponentFormChange(e, index)}
                      placeholder={field.placeholder}
                    />
                  )}
                  <p className="ProcessingFields__para">{field.description}</p>
                  {componentFormError[field.name] && (
                    <div className="ProcessingFields__error">
                      <p>{componentFormError[field.name]}</p>
                    </div>
                  )}
                </div>
              ))}
            </>
          </form>

          <div className="ProcessingFields__buttonsDiv">
            <Button
              className="ProcessingFields__button"
              type="primary"
              onClick={
                onSubmit
              }
            >
              {"Submit"
              }
            </Button>
          </div>
        </div>)
      }
    </>
  );
}

export default DestinationProcessingFields;
