import React, { useState } from "react";
import "./index.scss";
import { Image, Input, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import RandomIcon from "../../../images/Datasets-1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Postgres, IconChevronLeft, IconSalesforce } from "images";

function PostgresCatalog() {
  const navigate = useNavigate();

  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/sources/setup/${"postgres"}/${id}`);
  };

  const handleBackClick = () => {
    navigate(-1); // Go back one step
  };
  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };
  return (
    <div className="PostgresCatalog">
      <div className="PostgresCatalog__Fixed">
        {/* <div className="PostgresCatalog__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="PostgresCatalog__title"
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}
            onClick={handleConnectionClick}>
            Connections</span>
          <span className="PostgresCatalog__slash">/</span>
          <span className="PostgresCatalog__title" onClick={handleBackClick}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}>
            Catalog
          </span>
          <span className="PostgresCatalog__slash">/</span>
          <span className="PostgresCatalog__mainTitle">Postgres-Retl</span>
        </div> */}
        <div className="PostgresCatalog__sourceText">
          <div>
            <Image
              src={Postgres}
              preview={false}
              style={{ marginRight: "10px" }}
            />
            <span style={{ marginLeft: "15px" }}>Postgres</span>
          </div>
        </div>
        <div className="PostgresCatalog__bar">
          <div>
            <Button
              onClick={() => navigateToFields()}
              className="PostgresCatalog__button"
            >
              Add source
              <ArrowRightOutlined className="PostgresCatalog__btnImage" />
            </Button>
          </div>
        </div>

        <div className="PostgresCatalog__newSource">
          <Button className="PostgresCatalog__newText">Source</Button>
        </div>
        <div className="PostgresCatalog__exploreBar">
          <div className="PostgresCatalog__exploreText">
            Connect Postgres to setup Reverse ETL
          </div>
        </div>
        <div className="PostgresCatalog__bottomBar"></div>
      </div>
      <div className="PostgresCatalog__mainContainer">
        <div className="PostgresCatalog__flexDiv">
          <div className="PostgresCatalog__container1">
            <h6 className="PostgresCatalog__cardHeading1">
              Compatible destination types
            </h6>
            <div className="PostgresCatalog__textFlex">
              {/* <div className="PostgresCatalog__cardIcon">
                <MenuOutlined />
              </div> */}
              {/* <div className="PostgresCatalog__cardText">Storage</div> */}
            </div>
            <p className="PostgresCatalog__rawText">
            {/* <a className="PostgresCatalog__anchorTag">Learn more</a> about the */}
              Learn more about the
              destinations supported for Reverse ETL. If you don't see your
              destination listed in the Reverse ETL catalog, use the Palisade
              Connections destination to send data from your Reverse ETL
              warehouse to other destinations listed in the catalog.
              {/* <a className="PostgresCatalog__anchorTag">Learn more</a> */}
            </p>
          </div>
          <div className="PostgresCatalog__container2">
            <div className="PostgresCatalog__containFlex">
              <div className="PostgresCatalog__line"></div>
              <div className="PostgresCatalog__justDiv">
                <h6 className="PostgresCatalog__cardHeading2">
                  Set up requirements
                </h6>
                <ul>
                  <li>Postgres Host</li>
                  <li>Postgres Port</li>
                  <li>Postgres Database</li>
                  <li>Postgres Username + Password</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="PostgresCatalog__flexDiv1">
          <div className="PostgresCatalog__container3">
            <h6 className="PostgresCatalog__overviewHeading1">Overview</h6>
            <p className="PostgresCatalog__overviewText">
              Use Postgres as a Warehouse Source for Reverse ETL
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostgresCatalog;
