import { Button, Form, Image, List, message, Select } from "antd";
import { saveDataDictionary, updateFileMetadata } from "api/FileOnboard";
import { isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getDataDictionaryColumnsFromMapping,
  getSelectedWorkspaceProfileId,
  matchingColumnInColumnsOrDataDictionary,
} from "utils";
import { ENTITY_TYPES, ENTITY_TYPE_COLUMNS } from "../../../../../constants";
import ArrowDarkIcon from "../../../../../images/arrow-dark.svg";
import ArrowLightIcon from "../../../../../images/arrow-light.svg";
import IconDownArrow from "../../../../../images/filter-chevron-dropdown.svg";
import "./index.scss";

const { Option } = Select;

/*
- File onboarding flow component used to render Column mapping step
- onSave will be invoked when all of the requried fields are filled
- Error handling and redux state is hanlded within the scope of this component
*/
function ColumnMapping({ onSave }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const currentProcessingFile = useSelector(
    (state) => state.fileOnboardReducer
  ).currentProcessingFile;
  const userSession = useSelector((state) => state.authReducer).userSession;

  const [dataSource] = useState(() => {
    const systemRequiredFields = [];
    const sourceRequiredFields = [];

    currentProcessingFile.matchedSource.schema.columns.forEach((column) => {
      const systemRequiredColumns = ENTITY_TYPE_COLUMNS[
        currentProcessingFile.matchedSource.entity_type_id
      ].map((entityColumns) => entityColumns.column_name);

      if (systemRequiredColumns.includes(column)) {
        systemRequiredFields.push(column);
      } else {
        sourceRequiredFields.push(column);
      }
    });
    return {
      systemRequiredFields,
      sourceRequiredFields,
    };
  });

  const processFile = (mappingValues) => {
    const updateFileMetadataPayload = {
      fileName: currentProcessingFile.filename,
      file_id: currentProcessingFile.file_id,
      tags: currentProcessingFile.tags,
      extracted_from: currentProcessingFile.extractedFrom,
      provider_id: currentProcessingFile.matchedSource.id,
      account_id: userSession.accountId,
      user_id: userSession.userId,
      profile_workspace_id: getSelectedWorkspaceProfileId(userSession),
      entity_type_id: currentProcessingFile.matchedSource.entity_type_id,
      mapping: mappingValues,
    };
    if (
      String(currentProcessingFile.matchedSource.entity_type_id) ===
      String(ENTITY_TYPES.EVENTS)
    ) {
      updateFileMetadataPayload.campaign = currentProcessingFile.campaign_id;
      updateFileMetadataPayload.event_type =
        currentProcessingFile.event_action_id;
      updateFileMetadataPayload.dataset_id = currentProcessingFile.dataset_id;
      updateFileMetadataPayload.creative_id = currentProcessingFile.creative_id;
      updateFileMetadataPayload.subject_id = currentProcessingFile.subject_id;
    } else {
      updateFileMetadataPayload.accurate_to_dt = moment(
        currentProcessingFile.accurateToDate
      )
        .utc()
        .format();
    }
    const promises = [updateFileMetadata(updateFileMetadataPayload)];
    const dataDictionaryMapping = getDataDictionaryColumnsFromMapping(
      currentProcessingFile.schema.columns,
      currentProcessingFile.dataDictionary,
      mappingValues
    );
    if (!isEmpty(dataDictionaryMapping)) {
      const updatedDictionary = {};
      dataDictionaryMapping.forEach((mapping) => {
        for (const key in mapping) {
          updatedDictionary[key.replace(/'/g, "''")] = mapping[key].replace(
            /'/g,
            "''"
          );
        }
      });
      const dataDictionaryPayload = {
        mapping: [updatedDictionary],
      };
      promises.push(saveDataDictionary(dataDictionaryPayload));
    }
    setLoading(true);
    Promise.all(promises)
      .then(() => {
        onSave();
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onFinish = (values) => {
    const emptyValues = Object.values(values).filter((value) => {
      return isEmpty(value);
    });
    if (!isEmpty(emptyValues)) {
      setError("Your file upload must contain all the required fields.");
    } else {
      processFile(values);
    }
  };

  const getInitialValues = () => {
    const autoMatchedInitialValues = {};
    currentProcessingFile.matchedSource.schema.columns.forEach(
      (sourceColumn) => {
        const matchingColumn = matchingColumnInColumnsOrDataDictionary(
          sourceColumn,
          currentProcessingFile.schema.columns,
          currentProcessingFile.dataDictionary
        );
        if (matchingColumn) {
          autoMatchedInitialValues[sourceColumn] = matchingColumn;
        }
      }
    );
    const userMatchedFields = currentProcessingFile?.matchedFields || {};
    const initialValues = { ...autoMatchedInitialValues, ...userMatchedFields };
    return initialValues;
  };

  const getHeader = (leftTitle, rightTitle) => {
    return (
      <div className="columnMapping__row columnMapping__row--header">
        <p className="columnMapping__row__left">{leftTitle}</p>
        <Image src={ArrowDarkIcon} preview={false} />
        <div className="columnMapping__row__right">
          <span className="columnMapping__row__right__label">{rightTitle}</span>
        </div>
      </div>
    );
  };
  return (
    <div className="columnMapping">
      <p className="columnMapping__title">Map your columns</p>
      {getHeader("System Required Fields", "Your Fields")}
      <Form
        onFinish={onFinish}
        onFieldsChange={() => {
          setError("");
        }}
        initialValues={getInitialValues()}
      >
        <List
          className="columnMapping__list"
          dataSource={[
            ...dataSource.systemRequiredFields,
            ...dataSource.sourceRequiredFields,
          ]}
          itemLayout="vertical"
          renderItem={(item, index) => {
            return (
              <>
                {index === dataSource.systemRequiredFields.length && (
                  <>
                    <div className="columnMapping__row__innerHeader"></div>
                    {getHeader("Source Required Fields", "Your Fields")}
                  </>
                )}
                <div key={index} className="columnMapping__row">
                  <p className="columnMapping__row__left">{item}</p>
                  <Image src={ArrowLightIcon} preview={false} />
                  <div className="columnMapping__row__right">
                    <Form.Item name={`${item}`} required>
                      <Select
                        placeholder="Select column"
                        showSearch
                        filterOption="children"
                        suffixIcon={
                          <Image src={IconDownArrow} preview={false} />
                        }
                      >
                        {currentProcessingFile.schema.columns.map(
                          (column, index) => {
                            return (
                              <Option value={column} key={index}>
                                {column}
                              </Option>
                            );
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            );
          }}
        />

        <div className="columnMapping__footer">
          <span className="columnMapping__footer__error">{error}</span>
          <Button
            loading={loading}
            htmlType="submit"
            className="columnMapping__footer__saveButton"
          >
            Finish
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default ColumnMapping;
