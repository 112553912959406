import { SEGMENT_APP_NAME } from "../constants";

export const trackPageCall = (pagePath = "", pageData = {}) => {
  // window.analytics.page(pagePath, SEGMENT_APP_NAME, pageData);
  window.analytics.page(pagePath, pageData);
};
export const trackEventCall = (eventName = "", eventData = {}) => {
  window.analytics.track(eventName, eventData);
  // FS.event(eventName, eventData);
};
export const trackIdentifyCall = (userId = "", userData = {}) => {
  window.analytics.identify(userId, userData);
};
