import { combineReducers } from "redux";
import authReducer from "./modules/auth/reducer";
import fileOnboardReducer from "./modules/fileOnboard/reducer";
import connectorReducer from "./modules/connector/reducer";
import stepsReducer from "./modules/datasets/reducer";

const rootReducer = combineReducers({
  authReducer,
  fileOnboardReducer,
  connectorReducer,
  stepsReducer,
});
export default rootReducer;
