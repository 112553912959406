import { ExclamationCircleOutlined } from "@ant-design/icons";
import { trackEventCall } from "analytics";
import { Image, message, Modal, Typography } from "antd";
import { getProposalDetailsById } from "api/Playbooks";
import { ANALYTICS_EVENT_NAMES } from "constants";
import "emoji-mart/css/emoji-mart.css";
import { ChatBg, IconCallMe, IconChevronLeft, IconClockTimer } from "images";
import { useEffect, useRef, useState } from "react";
import { emitCustomEvent } from "react-custom-events";
import { useSelector } from "react-redux";
import { checkNameCharactersLimit, isMobile } from "utils";
import Avatar from "../Avatar";
import CallMeNow from "../CallMeNow";
import {
  sendMessageFromSeller,
  updateTwilioConvAttributes,
} from "../ChatController";
import InputToolbar from "../ChatInput";
import Message from "../Message";
import ScheduleCall from "../ScheduleCall";
import "./index.scss";

const ChatUI = ({
  conversation,
  ourAppUsers,
  rightNavChat = false,
  isFromProposal = false,
  onBackClicked,
  playbookDetails,
  chatQuestionOptions,
}) => {
  const [messages, setMessages] = useState([]);
  const [loadingError, setLoadingError] = useState(false);
  const [participantsUserData, setParticipantsUserData] = useState({});
  const [participantsList, setParticipantsList] = useState([]);
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const messagesEndRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [quizList, setQuizList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showInputField, setShowInputField] = useState(true);
  const [selectPlaybookDetails, setSelectedPlaybookDetails] =
    useState(playbookDetails);

  const externalLinkSegmentParams = authReducer.externalLinkSegmentParams;

  const { confirm } = Modal;

  const makePhoneCallConfirmPopup = () => {
    confirm({
      className: "Connectting to Seller",
      title:
        "Just wait for few seconds, we are connecting you with the seller...",
      icon: <ExclamationCircleOutlined />,
      // okText: "Ok",

      onOk() {
        //makePhoneCall();
        // window.open("tel: +14153670390");
        window.location = "tel: +14153670390";
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    // console.log("playbookDetails is undefined");
    // console.log("Conversation is : ", conversation);
    if (conversation === null) {
      return;
    }
    const inputString = conversation?.channelState?.uniqueName;
    const parts = inputString.split("-");
    const pbId = parts[parts.length - 1];
    if (pbId === undefined) {
      return;
    }
    // console.log("playbookId : ", pbId);
    const payload = {
      playbook_id: pbId,
      profile_workspace_id: userSession.buyerProfileWorkspaceId,
    };
    getProposalDetailsById(payload)
      .then((res) => {
        // console.log("playbookDetails", res);
        setSelectedPlaybookDetails(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log("playbookDetails Error", err);
        message.error(err?.response?.data?.message);
      });
  }, [conversation]);

  useEffect(() => {
    // console.log("chatQuestionOptions", chatQuestionOptions);
    if (chatQuestionOptions?.length === 0) {
      updateConvAttributesForQuiz();
      return;
    }
    setQuizList(chatQuestionOptions);
    setCurrentQuestion(chatQuestionOptions);
  }, []);

  useEffect(() => {
    if (externalLinkSegmentParams != null) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PLATFORM_EVENT,
        externalLinkSegmentParams
      );
      // dispatch(setExternalLinkSegmentParams(null));
    }

    if (isMobileView === true) {
      emitCustomEvent("hideHeader_footer_forMobileChat", true);
    }
    loadMessages();

    return () => {
      setMessages([]);
      setLoadingError(false);
      setParticipantsUserData({});
      setParticipantsList([]);
      conversation?.off("messageAdded", messageAdded);
    };
  }, [conversation !== null && conversation]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInputField(conversation?.channelState?.attributes?.is_quiz_shown);
    }, 2000);
  }, [conversation !== null && conversation]);

  const loadMessages = () => {
    setLoadingError(true);
    conversation?.setAllMessagesRead();
    //console.log("conversation", conversation?.getMessages());
    conversation
      ?.getMessages()
      .then((messagePaginator) => {
        // console.log("messagePaginator", messagePaginator?.items?.length);
        setMessages(messagePaginator.items?.reverse());
        // scrollToBottom();
        setLoadingError(false);
      })
      .catch((err) => {
        setLoadingError(false);
      });
    conversation?.on("messageAdded", messageAdded);
    setParticipantsList([]);
    conversation?.getParticipants()?.then((participants) => {
      setParticipantsList([...new Set(participants)]);
      participants?.forEach((p) => {
        p?.getUser()?.then((u) => {
          setParticipantsUserData({
            ...participantsUserData,
            [p.sid]: u,
          });
        });
      });
    });
  };
  // console.log("playbookDetails", playbookDetails);
  const messageAdded = (message) => {
    // console.log("message", message);
    conversation?.updateLastReadMessageIndex(message?.state?.index);
    // props.conversation.advanceLastReadMessageIndex(message?.state?.index);
    setMessages((oldMessages) => {
      const messagesIds = [];
      const allMessages = [message, ...oldMessages];
      const updatedMessages = [];
      allMessages.forEach((message) => {
        if (!messagesIds.includes(message.state.sid)) {
          updatedMessages.push(message);
          messagesIds.push(message.state.sid);
        }
      });
      return updatedMessages;
    });
  };

  const sendMessage = (text) => {
    if (text.length > 0) {
      const attributes = {
        id: userSession.userId,
        phone_number: userSession.phoneNumber,
        first_name: userSession.firstName,
        last_name: userSession.lastName,
        image_uri: userSession.imageUri,
      };
      conversation?.sendMessage(text, attributes);
      logSegmentEvents();
    }
  };

  const logSegmentEvents = () => {
    let fromUser = userSession.firstName + " " + userSession.lastName;
    const attributes = {
      userId: userSession.userId,
      phoneNumber: userSession.phoneNumber,
      userName: fromUser,
      playbookName: selectPlaybookDetails?.name,
    };

    const mergedJson = {
      ...attributes,
      ...externalLinkSegmentParams,
    };

    trackEventCall(ANALYTICS_EVENT_NAMES.COMMUNICATION_INITIATED, mergedJson);
  };

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const parseChatName = (chatName) => {
    if (chatName === undefined) {
      chatName = "No Name";
    }
    let result = chatName;
    result = checkNameCharactersLimit(chatName, 60);
    return result;
  };

  const parseChatH2Name = (name) => {
    if (name === undefined) {
      name = "No Name";
    }
    let result = name;
    result = checkNameCharactersLimit(name, 45);
    return result;
  };

  // console.log("conversation", conversation);
  const selectedQuestionsWithOptions = (option) => {
    // if (currentQuestionIndex + 1 === quizList.length) {
    sendMessage(option.content);
    // if (option.id === 0) {
    //   sendMessage(option.buyertext);
    // } else if (option.id === 1) {
    //   // makePhoneCallConfirmPopup();
    //   sendMessage(option.buyertext);
    // } else if (option.id === 2) {
    // }
    // Last question,
    updateConvAttributesForQuiz();
    // }
    // } else {
    //   sendMessage(option.content);
    //   if (option.id === 0) {
    //     let sendSmsRes = sendMessageFromSeller(
    //       conversation?.sid,
    //       selectPlaybookDetails,
    //       option?.sellertext
    //     );
    //     if (sendSmsRes !== null) {
    //       sendSmsRes
    //         .then((res) => {
    //           // console.log("message sending successful from seller", res);
    //           setCurrentQuestion(quizList[currentQuestionIndex + 1]);
    //           setCurrentQuestionIndex(currentQuestionIndex + 1);
    //         })
    //         .catch((err) => {
    //           console.log("message sending from seller failed", err);
    //         });
    //     }
    //   } else {
    //     setShowInputField(true);
    //     const attr = {
    //       seller_firstname: selectPlaybookDetails?.seller?.first_name,
    //       seller_lastname: selectPlaybookDetails?.seller?.last_name,
    //       buyer_firstname: userSession.firstName,
    //       buyer_lastname: userSession.lastName,
    //       is_quiz_shown: true,
    //     };
    //     updateTwilioConvAttributes(conversation?.sid, attr);
    //   }

    //   // setCurrentQuestion(quizList[currentQuestionIndex + 1]);
    //   // setCurrentQuestionIndex(currentQuestionIndex + 1);
    // }
    scrollToBottom();
  };
  const updateConvAttributesForQuiz = () => {
    setShowInputField(true);
    const attr = {
      seller_firstname: selectPlaybookDetails?.seller?.first_name,
      seller_lastname: selectPlaybookDetails?.seller?.last_name,
      buyer_firstname: userSession.firstName,
      buyer_lastname: userSession.lastName,
      is_quiz_shown: true,
    };
    updateTwilioConvAttributes(conversation?.sid, attr);
  };
  // console.log("conversation", conversation);
  return (
    <>
      {isMobileView === true ? (
        <div className="chatUI" style={{ backgroundImage: `url(${ChatBg})` }}>
          {isMobileView === true && (
            <div className="chatUI__chatHeaderMobileDiv">
              {isFromProposal ? (
                <div className="chatUI__chatHeaderMobileProposalNew">
                  <div className="chatUI__headerElementsContainerProposalNew">
                    <Image
                      rootClassName="chatUI__backArrowMobileNew"
                      preview={false}
                      src={IconChevronLeft}
                      onClick={onBackClicked}
                    ></Image>
                    <div className="chatUI__chatUserIconDiv">
                      <Avatar
                        url={
                          conversation?.channelState?.attributes
                            ?.group_image_url
                        }
                        currentConversation={conversation}
                        refresh={Math.random()}
                      />
                    </div>

                    <div className="chatUI__headElementsDivProposalNew">
                      <Typography.Text className="chatUI__headerNameTextProposalNew">
                        {parseChatName(conversation?.friendlyName) || "No Name"}
                      </Typography.Text>
                      <div className="chatUI__replyRateTimeDivNew">
                        <Image
                          rootClassName="chatUI__iconClockTimer"
                          preview={false}
                          src={IconClockTimer}
                        ></Image>
                        <Typography.Text className="chatUI__replyRateTextBlue">
                          {"1.5 hours"}
                        </Typography.Text>
                      </div>
                    </div>
                    <div className="chatUI__meetingAndCallDiv">
                      <ScheduleCall
                        sellerInfoObject={selectPlaybookDetails}
                      ></ScheduleCall>
                      <a>
                        <img
                          className="chatUI__iconCallMe"
                          src={IconCallMe}
                          //alt={`Call:  ${selectPlaybookDetails?.seller?.phone_number}`}
                          onClick={makePhoneCallConfirmPopup}
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="chatUI__chatHeaderMobile">
                  <div className="chatUI__headerElementsContainer">
                    <Image
                      rootClassName="chatUI__backArrowMobile"
                      preview={false}
                      src={IconChevronLeft}
                      onClick={onBackClicked}
                    ></Image>
                    <Typography.Text className="chatUI__headerNameText">
                      {parseChatName(conversation?.friendlyName) || "No Name"}
                    </Typography.Text>
                    <Avatar
                      url={
                        conversation?.channelState?.attributes?.group_image_url
                      }
                      currentConversation={conversation}
                      refresh={Math.random()}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {/* {isFromProposal && (
            <div className="chatUI__scheduleCallDivProposal">
              <ScheduleCall sellerInfoObject={selectPlaybookDetails}></ScheduleCall>
              <CallMeNow playbookDetails={selectPlaybookDetails}></CallMeNow>
            </div>
          )} */}
          <div
            className={
              isFromProposal
                ? "chatUI__parentDivMobileProposal"
                : "chatUI__parentDivMobile"
            }
          >
            <div
              className={
                isFromProposal
                  ? "chatUI__messageContainerMobileProposal"
                  : "chatUI__messageContainerMobile"
              }
            >
              {messages?.length > 0 ? (
                <div className="chatUI__messageDivMobile">
                  {messages?.map((message, index) => (
                    <Message
                      id={index}
                      key={index}
                      fromProposal={isFromProposal}
                      msgIndex={messages?.length}
                      currentMsgIndex={index}
                      message={message}
                      prevMessage={messages[index + 1]}
                      author={
                        participantsUserData[message?.state?.participantSid]
                      }
                      users={ourAppUsers}
                      currentConversation={conversation}
                      conversationParticipants={participantsList}
                      rightNavChat={rightNavChat}
                      playbookDetails={selectPlaybookDetails}
                    />
                  ))}
                </div>
              ) : (
                <div
                  className={
                    rightNavChat === true
                      ? "chatUI__noMessageDivRightNav"
                      : "chatUI__noMessageDiv"
                  }
                >
                  This is the very beginning of your chat.
                  <br /> Say hi to everyone
                </div>
              )}
            </div>
            <div className="chatUI__scrollDiv" ref={messagesEndRef} />
            {/* <div className="chatUI__scheduleCallDiv">
              <ScheduleCall></ScheduleCall>{" "}
              <CallMeNow
                phoneNumber={selectPlaybookDetails?.seller?.phone_number}
              ></CallMeNow>
            </div> */}
            {!showInputField && (
              <div className="chatUI__chatQuizDivMobile">
                {currentQuestion?.map((option, index) => {
                  return (
                    <div
                      className="chatUI__quizOptionDiv"
                      key={index}
                      onClick={() => {
                        selectedQuestionsWithOptions(option);
                      }}
                    >
                      <Typography.Text className="chatUI__quizOptionText">
                        {option.content}
                      </Typography.Text>
                    </div>
                  );
                })}
              </div>
            )}
            {showInputField && (
              <div className="chatUI__chatInputDivMobile">
                <InputToolbar
                  showAttachment={false}
                  onSend={sendMessage}
                  activeConversation={conversation}
                  appUsers={ourAppUsers}
                  rightNavChat={rightNavChat}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="chatUI" style={{ backgroundImage: `url(${ChatBg})` }}>
          <div className="chatUI__parentDiv">
            <div
              className={
                rightNavChat === true
                  ? "chatUI__messageContainerDesktopProposal"
                  : "chatUI__messageContainer"
              }
            >
              {messages?.length > 0 ? (
                <div className="chatUI__messageDiv hide-scroll-bar">
                  {messages?.map((message, index) => (
                    <Message
                      id={index}
                      key={index}
                      fromProposal={isFromProposal}
                      msgIndex={messages?.length}
                      currentMsgIndex={index}
                      message={message}
                      prevMessage={messages[index + 1]}
                      author={
                        participantsUserData[message?.state?.participantSid]
                      }
                      users={ourAppUsers}
                      currentConversation={conversation}
                      conversationParticipants={participantsList}
                      rightNavChat={rightNavChat}
                      playbookDetails={selectPlaybookDetails}
                    />
                  ))}
                </div>
              ) : (
                <div
                  className={
                    rightNavChat === true
                      ? "chatUI__noMessageDivRightNav"
                      : "chatUI__noMessageDiv"
                  }
                >
                  This is the very beginning of your chat.
                  <br /> Say hi to everyone
                </div>
              )}
            </div>
            {rightNavChat ? (
              <div className="chatUI__chatBottomOptionsParentDiv">
                {showInputField ? (
                  <div className="chatUI__chatBottomOptionsRightNavDiv">
                    <div className="chatUI__scrollDiv" ref={messagesEndRef} />
                    <div
                      className={
                        rightNavChat === true
                          ? "chatUI__scheduleCallDivDesktopProposal"
                          : "chatUI__scheduleCallDiv"
                      }
                    >
                      <ScheduleCall
                        sellerInfoObject={selectPlaybookDetails}
                      ></ScheduleCall>
                      <CallMeNow
                        playbookDetails={selectPlaybookDetails}
                      ></CallMeNow>
                    </div>
                    <div
                      className={
                        rightNavChat === true
                          ? "chatUI__chatInputDivRightNav"
                          : "chatUI__chatInputDiv"
                      }
                    >
                      <InputToolbar
                        showAttachment={false}
                        onSend={sendMessage}
                        activeConversation={conversation}
                        appUsers={ourAppUsers}
                        rightNavChat={rightNavChat}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="chatUI__chatQuizDivRightNavChat">
                    {currentQuestion?.map((option, index) => {
                      return (
                        <div
                          className="chatUI__quizOptionDiv"
                          key={index}
                          onClick={() => {
                            selectedQuestionsWithOptions(option);
                          }}
                        >
                          <Typography.Text className="chatUI__quizOptionText">
                            {option.content}
                          </Typography.Text>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div className="chatUI__chatBottomOptionsParentDiv">
                {showInputField ? (
                  <div className="chatUI__chatBottomOptionsDiv">
                    <div className="chatUI__scrollDiv" ref={messagesEndRef} />
                    <div
                      className={
                        rightNavChat === true
                          ? "chatUI__scheduleCallDivDesktopProposal"
                          : "chatUI__scheduleCallDiv"
                      }
                    >
                      <ScheduleCall
                        sellerInfoObject={selectPlaybookDetails}
                      ></ScheduleCall>
                      <CallMeNow
                        playbookDetails={selectPlaybookDetails}
                      ></CallMeNow>
                    </div>
                    <div
                      className={
                        rightNavChat === true
                          ? "chatUI__chatInputDivRightNav"
                          : "chatUI__chatInputDiv"
                      }
                    >
                      <InputToolbar
                        showAttachment={false}
                        onSend={sendMessage}
                        activeConversation={conversation}
                        appUsers={ourAppUsers}
                        rightNavChat={rightNavChat}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="chatUI__chatQuizDivDesktop">
                    {currentQuestion?.map((option, index) => {
                      return (
                        <div
                          className="chatUI__quizOptionDiv"
                          key={index}
                          onClick={() => {
                            selectedQuestionsWithOptions(option);
                          }}
                        >
                          <Typography.Text className="chatUI__quizOptionText">
                            {option.content}
                          </Typography.Text>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ChatUI;
