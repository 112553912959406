import React, { useState, useEffect } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Spin, Table, Tooltip, message } from "antd";
import { useParams } from "react-router-dom";
import { getProfileEmails, getProfileNumbers, getProfileIPs, getProfileAgents, getProfileLocations } from "api/Explorer";
import "./index.scss";

const UnifiedProfile = () => {
  const { revId } = useParams();
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [loadingPhoneNumbers, setLoadingPhoneNumbers] = useState(false);
  const [loadingIPs, setLoadingIPs] = useState(false);
  const [loadingAgents, setLoadingAgents] = useState(false);
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [emailsData, setEmailsData] = useState([]);
  const [locationsData, setLocationsData] = useState([]);
  const [phoneNumbersData, setPhoneNumbersData] = useState([]);
  const [ipsData, setIPsData] = useState([]);
  const [agentsData, setAgentsData] = useState([]);

  function formatDate(date) {
    return new Date(date).toLocaleDateString("en-US");
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        message.success('Copied to clipboard');
      })
      .catch((err) => {
        message.error('Failed to copy');
        console.error('Failed to copy: ', err);
      });
  };

  const fetchProfileEmails = async () => {
    setLoadingEmails(true); // Show loader when API call starts

    try {
      const res = await getProfileEmails(revId);

      const results = [
        {
          associatedEmails: res?.data?.data?.list_of_associated_emails || [],
          lastEmail: res?.data?.data?.last_email || '',
          updated: res?.data?.data?.last_activity_date || ''
        }
      ];
      setEmailsData(results);

    } catch (err) {
      console.error("Error fetching search results:", err.message);
    } finally {
      setLoadingEmails(false); // Hide loader when API call completes
    }
  };

  const fetchProfileLocations = async () => {
    setLoadingLocations(true); // Show loader when API call starts

    try {
      const res = await getProfileLocations(revId);

      const results = [
        {
          associatedAddresses: res?.data?.data?.list_of_associated_addresses || [],
          lastAddress: res?.data?.data?.last_address || '',
          updated: res?.data?.data?.last_activity_date || ''
        }
      ];
      setLocationsData(results);

    } catch (err) {
      console.error("Error fetching search results:", err.message);
    } finally {
      setLoadingLocations(false); // Hide loader when API call completes
    }
  };

  const fetchProfileNumbers = async () => {
    setLoadingPhoneNumbers(true); // Show loader when API call starts

    try {
      const res = await getProfileNumbers(revId);

      const results = [
        {
          associatedNumbers: res?.data?.data?.list_of_associated_phone_numbers || [],
          lastNumber: res?.data?.data?.last_phone || '',
          updated: res?.data?.data?.last_activity_date || ''
        }
      ];
      setPhoneNumbersData(results);

    } catch (err) {
      console.error("Error fetching search results:", err.message);
    } finally {
      setLoadingPhoneNumbers(false); // Hide loader when API call completes
    }
  };

  const fetchProfileAgents = async () => {
    setLoadingAgents(true); // Show loader when API call starts

    try {
      const res = await getProfileAgents(revId);

      const results = res?.data?.data?.map((item) => ({
        userAgents: item?.list_of_associated_user_agents,
        deviceId: item?.device_type,
        browserId: item?.browser_type,
        lastActivity: item?.last_activity_date,
        visits: item?.total_visits,
      }));
      setAgentsData(results);

    } catch (err) {
      console.error("Error fetching search results:", err.message);
    } finally {
      setLoadingAgents(false); // Hide loader when API call completes
    }
  };


  const fetchProfileIPs = async () => {
    setLoadingIPs(true); // Show loader when API call starts

    try {
      const res = await getProfileIPs(revId);
      if (res?.data?.data?.length > 0) {
        const results = res?.data?.data?.map((item) => ({
          associatedIPs: item?.list_of_associated_ip_addresses,
          state: item?.ip_state,
          city: item?.ip_city,
          addressType: item?.ip_address_type,
          updated: item?.last_activity_date,
          visits: item?.total_visits,
        }));
        setIPsData(results);
      }
    } catch (err) {
      console.error("Error fetching search results:", err.message);
    } finally {
      setLoadingIPs(false); // Hide loader when API call completes
    }
  };

  useEffect(() => {
    if (revId) {
      fetchProfileEmails();
      fetchProfileNumbers();
      fetchProfileIPs();
      fetchProfileAgents();
      fetchProfileLocations();
    }
  }, [revId]);


  const ipColumnsConfig = [
    {
      title: "IPs",
      dataIndex: "associatedIPs",
      key: "associatedIPs",
      width: 200,
    },
    {
      title: "LAST SEEN",
      dataIndex: "updated",
      key: "updated",
      width: 150,
      render: (text) => <span>{text == '' ? "" : formatDate(text)}</span>,
    },
    {
      title: "IP STATE",
      dataIndex: "state",
      key: "state",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "IP CITY",
      dataIndex: "city",
      key: "city",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "IP ADDRESS TYPE",
      dataIndex: "addressType",
      key: "addressType",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "# OF VISITS",
      dataIndex: "visits",
      key: "visits",
      width: 120,
      render: (text) => <span>{text}</span>,
    },
  ];


  const agentColumnsConfig = [
    {
      title: "USER AGENTS",
      dataIndex: "userAgents",
      key: "userAgents",
      width: 200,
    },
    {
      title: "LAST SEEN",
      dataIndex: "lastActivity",
      key: "lastActivity",
      width: 150,
      render: (text) => <span>{text == '' ? "" : formatDate(text)}</span>,
    },
    {
      title: "BROWSER TYPE",
      dataIndex: "browserId",
      key: "browserId",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "DEVICE TYPE",
      dataIndex: "deviceId",
      key: "deviceId",
      width: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "# OF VISITS",
      dataIndex: "visits",
      key: "visits",
      width: 120,
      render: (text) => <span>{text}</span>,
    },
  ];

  const createUploadColumns = (config) => {
    const columns = config.map((columnConfig) => ({
      title: columnConfig.title,
      dataIndex: columnConfig.dataIndex,
      key: columnConfig.key,
      render:
        columnConfig.render ||
        ((text) => (
          <div className="unified-profile__icon-row">
            <span>{text}</span>
          </div>
        )),
    }));
    return columns;
  };

  const emailsColumns = [
    {
      title: "EMAILS",
      dataIndex: "associatedEmails",
      key: "associatedEmails",
      render: (emails, record, index) => (
        <div className="email-table__emails">
          {/* Display title once for the list of associated emails */}
          {index === 0 && emailsData?.length > 0 && (
            <div className="email-table__emails-list-title">
              LIST OF ASSOCIATED EMAILS
            </div>
          )}
          {/* Render each email in the associated emails list */}
          {emails?.map((email, emailIndex) => (
            <div key={emailIndex} className="email-table__email-item">
              {email}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "LAST EMAIL",
      dataIndex: "lastEmail",
      key: "lastEmail",
      render: (lastEmail) => (
        <div className="email-table__last-email">
          {lastEmail || ''}
          {lastEmail && (
            <Tooltip title="Copy email">
              <CopyOutlined
                className="email-table__copy-icon"
                onClick={() => handleCopy(lastEmail)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "UPDATED",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => (
        <div className="email-table__updated">
          {updated == '' ? "" : formatDate(updated)}
        </div>
      ),
    },
  ];



  const phonesColumns = [
    {
      title: "PHONE NUMBERS",
      dataIndex: "associatedNumbers",
      key: "associatedNumbers",
      render: (numbers, record, index) => (
        <div className="email-table__emails">
          {index === 0 && phoneNumbersData?.length > 0 && (
            <div className="email-table__emails-list-title">
              LIST OF ASSOCIATED NUMBERS
            </div>
          )}
          {numbers?.map((number, numberIndex) => (
            <div key={numberIndex} className="email-table__email-item">
              {number}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "LAST PHONE NUMBER",
      dataIndex: "lastNumber",
      key: "lastNumber",
      render: (lastNumber) => (
        <div className="email-table__last-emails">
          {lastNumber || ''}
          {lastNumber && (
            <Tooltip title="Copy number">
              <CopyOutlined
                className="email-table__copy-icon"
                onClick={() => handleCopy(lastNumber)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "UPDATED",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => (
        <div className="email-table__updated">
          {formatDate(updated)}
        </div>
      ),
    },
  ];


  const locationsColumns = [
    {
      title: "LOCATIONS",
      dataIndex: "associatedAddresses",
      key: "associatedAddresses",
      render: (locations, record, index) => (
        <div className="email-table__emails">
          {/* Display title once for the list of associated emails */}
          {index === 0 && (
            <div className="email-table__emails-list-title">
              LIST OF ASSOCIATED LOCATIONS
            </div>
          )}
          {/* Render each email in the associated emails list */}
          {locations?.map((locations, emailIndex) => (
            <div key={emailIndex} className="email-table__email-item">
              {locations}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: "LAST LOCATION",
      dataIndex: "lastAddress",
      key: "lastAddress",
      render: (lastAddress) => (
        <div className="email-table__last-email">
          {lastAddress || ''}
          {lastAddress && (
            <Tooltip title="Copy email">
              <CopyOutlined
                className="email-table__copy-icon"
                onClick={() => handleCopy(lastAddress)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "UPDATED",
      dataIndex: "updated",
      key: "updated",
      render: (updated) => (
        <div className="email-table__updated">
          {formatDate(updated)}
        </div>
      ),
    },
  ];


  return (
    <div className="unified-profile">
      <Spin spinning={loadingEmails}>
        <Table
          className="unified-profile__table"
          columns={emailsColumns}
          dataSource={emailsData[0]?.associatedEmails?.length > 0 ? emailsData : []}
          pagination={false}
          tableLayout="fixed"
        />
      </Spin>
      <Spin spinning={loadingPhoneNumbers}>
        <Table
          className="unified-profile__table"
          columns={phonesColumns}
          dataSource={phoneNumbersData[0]?.associatedNumbers?.length > 0 ? phoneNumbersData : []}
          pagination={false}
          tableLayout="fixed"
        />
      </Spin>
      <Spin spinning={loadingLocations}>
        <Table
          className="unified-profile__table"
          columns={locationsColumns}
          dataSource={locationsData[0]?.associatedAddresses?.length > 0 ? locationsData : []}
          pagination={false}
          tableLayout="fixed"
        />
      </Spin>
      <Spin spinning={loadingIPs}>
        <Table
          className="unified-profile__table"
          columns={createUploadColumns(ipColumnsConfig)}
          dataSource={ipsData}
          pagination={false}
          tableLayout="fixed"
        />
      </Spin>
      <Spin spinning={loadingAgents}>
        <Table
          className="unified-profile__table"
          columns={createUploadColumns(agentColumnsConfig)}
          dataSource={agentsData}
          pagination={false}
          tableLayout="fixed"
        />
      </Spin>
    </div>
  );
};

export default UnifiedProfile;
