import { DatePicker, Form, Image } from "antd";
import { DEFAULT_DATE_FORMAT } from "constants";
import "./index.scss";
import IconCalendar from "../../../../../../images/calendar.svg";

function FilterDatePicker({
  name = "",
  onChange,
  placeholder = "Enter date here...",
}) {
  return (
    <Form.Item name={name} className="filterDatePicker">
      <DatePicker
        placeholder={placeholder}
        format={DEFAULT_DATE_FORMAT}
        allowClear={false}
        suffixIcon={<Image src={IconCalendar} preview={false} />}
        onChange={onChange}
      />
    </Form.Item>
  );
}
export default FilterDatePicker;
