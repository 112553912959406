import {
  SET_CONNECTOR_OVERVIEW_LIST,
  SET_REQUIRED_FIELDS,
  SET_LOADING,
  SET_CATALOGS_LIST,
  SET_MODEL_DETAILS
} from "./action";

const initialState = {
  connectorOverviewList: [],
  requiredFields: [],
  loading: false,
};

const connectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_CONNECTOR_OVERVIEW_LIST:
      return {
        ...state,
        connectorOverviewList: action.payload,
      };
    case SET_REQUIRED_FIELDS:
      return {
        ...state,
        requiredFields: action.payload,
      };
    case SET_CATALOGS_LIST:
      return {
        ...state,
        catalogsList: action.payload,
      };
    case SET_MODEL_DETAILS:
      return {
        ...state,
        modelDetails: action.payload,
      };

    default:
      return state;
  }
};

export default connectorReducer;
