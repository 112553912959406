import "./index.scss";
import { useNavigate } from "react-router-dom";
import { MarkDoneEmpty, MarkedGreen } from "images";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Image, List, Radio, Card } from "antd";
import { setFormData, setStepCompletion } from "store/modules/datasets/action";
import { useState } from "react";

function AddExclustionSelectSource() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const steps = useSelector((state) => state.stepsReducer);
  const sourcesList = steps.sources.newSources;
  const getSelectedSource = steps && steps.steps[3.1].data.selectSource; // value selected in step 3.1
  const [selectedSource, setSelectedSource] = useState(getSelectedSource);
  
  const handleInputChange = (subStep, subStepKey, value) => {
    setSelectedSource(value);
    dispatch(setFormData(subStep, subStepKey, value));
    dispatch(setStepCompletion(subStep, true));
    navigate(-1);
  };

  const CustomPanelHeader = ({ mainHeading, subHeading }) => (
    <Card className="selectSource__customPanelHeader">
      <div className="selectSource__customPanelHeader-header">
        <Image
          src={MarkDoneEmpty}
          preview={false}
          className="selectSource__customPanelHeader-image"
        />
        <div className="selectSource__customPanelHeader-headingContainer">
          <div className="selectSource__customPanelHeader-mainHeading">
            {mainHeading}
          </div>
          <div className="selectSource__customPanelHeader-subHeading">
            {subHeading}
          </div>
        </div>
      </div>
    </Card>
  ); 

  const { Panel } = Collapse;
  
  return (
    <div className="selectSource">
      <Collapse defaultActiveKey={["1"]} collapsible="disabled">
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="Select Your Source"
              subHeading="No source selected"
            />
          }
          key="1"
        >
          <List
            dataSource={sourcesList}
            renderItem={(item) => (
              <List.Item
                onClick={() => {
                  handleInputChange(
                    3.1,
                    "selectSource",
                    item.event_entity_type
                  );
                }}
                className="selectSource__list-item"
              >
                <Card className="selectSource__card">
                  <div className="selectSource__card-content">
                    <Radio 
                    className="selectSource__radio"
                    checked={selectedSource === item.event_entity_type} />
                    <Image
                      src={item.image}
                      width={30}
                      height={30}
                      className="selectSource__image"
                    />
                    <div
                      className={`selectSource__text-container ${item.event_entity_type
                        ? ""
                        : "selectSource__text-container--centered"
                        }`}
                    >
                      <p className="selectSource__primary-text">{item.name}</p>
                      {item.event_entity_type && (
                        <p className="selectSource__secondary-text">
                          {item.event_entity_type}
                        </p>
                      )}
                    </div>
                  </div>
                </Card>
              </List.Item>
            )}
          />
        </Panel>
      </Collapse>
    </div>
  );
}
export default AddExclustionSelectSource;
