import { keyBy } from "lodash";

export const SET_STEP_COMPLETION = "SET_STEP_COMPLETION";
export const SET_DATA = "SET_DATA";
export const SET_SOURCES = "SET_SOURCES";
export const SET_DESTINATIONS = "SET_DESTINATIONS";
export const CLEAR_DATASET_STATE = "CLEAR_DATASET_STATE";
export const SET_ACTIVE_KEY = "SET_ACTIVE_KEY";

export const setStepCompletion = (step, isCompleted) => ({
  type: SET_STEP_COMPLETION,
  payload: { step, isCompleted },
});

export const setFormData = (step, subStep, value) => ({
  type: SET_DATA,
  payload: { step, subStep, value },
});

export const setNewSources = (newSources) => ({
  type: SET_SOURCES,
  payload: { newSources },
});
export const setNewDestinations = (newDestinations) => ({
  type: SET_DESTINATIONS,
  payload: { newDestinations },
});

export const clearDatasetState = () => ({
  type: CLEAR_DATASET_STATE,
});

export const setActiveKey = (activeKey) => ({
  type: SET_ACTIVE_KEY,
  payload: {activeKey}, 
})
