import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Image, Modal } from "antd";
import ScreenRecording from "components/ScreenRecording";
import { IconCallMe } from "images";
import { useReactMediaRecorder } from "react-media-recorder";
import "./index.scss";

function CallMeNow({ playbookDetails }) {
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ video: true });

  const { confirm } = Modal;

  const makePhoneCallConfirmPopup = () => {
    confirm({
      className: "Connectting to Seller",
      title:
        "Just wait for few seconds, we are connecting you with the seller...",
      icon: <ExclamationCircleOutlined />,
      // okText: "Ok",

      onOk() {
        //makePhoneCall();
        // window.open("tel: +14153670390");
        window.location = "tel: +14153670390";
      },
      onCancel() {},
    });
  };

  const callMeNowClicked = () => {};
  // console.log("playbookDetails", playbookDetails);
  return (
    <div className="callMeNow">
      <div className="callMeNow__callMeDiv">
        <Image
          rootClassName="callMeNow__iconCallMe"
          preview={false}
          src={IconCallMe}
        ></Image>
        <a
          className="callMeNow__callMeText"
          // href={"tel:" + playbookDetails?.seller?.phone_number}
          onClick={makePhoneCallConfirmPopup}
        >
          Call me now
        </a>
      </div>
      {/* <ScreenRecording
        screen={true}
        audio={true}
        video={false}
        downloadRecordingPath="Screen_Recording_Demo"
        downloadRecordingType="mp4"
        emailToSupport="zaman@revcloud.com"
      ></ScreenRecording> */}
    </div>
  );
}

export default CallMeNow;
