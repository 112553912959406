import { useState } from "react";
import { List, Radio, Card, Image, Spin, Button} from "antd";
import { useNavigate } from "react-router-dom";
import "./index.scss"; // Ensure this imports the CSS with updated class names

function DesktopSelectSource({
  handleSourceSelection,
  handleSourceValue,
  sources,
  sourcesLoader,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();

  const handleOptionChange = (e) => {
    const newValue = e.target.value;
    setSelectedOption(newValue);
    handleSourceSelection(newValue);
    handleSourceValue(newValue?.event_entity_type); // Pass the selected value to the parent component
  };

  const handleRedirect = () => {
    navigate("/dashboard/datasets/sources/catalogList");
  };

  return (
    <div className="desktop-select-source">
      {" "}
      {/* Apply updated parent class */}
      {sourcesLoader ? (
        <div className="loading-container">
          {" "}
          {/* Apply updated spinner container class */}
          <Spin />
        </div>
      ) : (
        <List
          dataSource={sources}
          locale={{
            emptyText: (<>
              <div>
                No sources found. Only onboarded sources will be displayed here.
                </div>
                <div>
                <Button
                  type="primary"
                  onClick={handleRedirect}
                  className={"redirectButton"}
                >
                   Add Source
                </Button>
              </div>
              </>
            ),
          }}
// Customize the empty text here
          renderItem={(item) => (
            <List.Item style={{ padding: 0 }}>
              <Card
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  marginTop: "12px",
                  borderRadius: "16px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  minHeight: "40px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Radio
                    className="custom-radio" // Ensure this class matches the CSS
                    value={item} // Use item as the value
                    checked={selectedOption === item} // Check if the current item is selected
                    onChange={handleOptionChange}
                  />
                  <Image
                    src={item?.image}
                    width={30}
                    height={30}
                    style={{ marginLeft: "4px" }}
                    preview={false}
                  />
                    <div style={{ marginLeft: "28px", display: "flex", flexDirection: "column", justifyContent: item.event_entity_type ? "flex-start" : "center", height: "100%" }}>
                    <p style={{ margin: 0, fontFamily: "Lato, sans-serif", fontWeight: "501", fontSize: "10.6pt", color: "#000000" }}>
                      {item.name}
                    </p>
                    {item.event_entity_type && (
                      <p style={{ margin: 0, fontFamily: "Lato, sans-serif", fontWeight: "300", fontSize: "9.8pt", color: "#000000" }}>
                        {item.event_entity_type}
                      </p>
                    )}
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
        />
      )}
    </div>
  );
}

export default DesktopSelectSource;
