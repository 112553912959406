import { Button } from "antd";
import "./index.scss";

function FilterFieldsContainer({ label = "", onClear, children }) {
  return (
    <div className="filterFieldsContainer">
      <div className="filterFieldsContainer__heading">
        <span className="filterFieldsContainer__heading__label">{label}</span>
        <span
          onClick={onClear}
          className="filterFieldsContainer__heading__clear"
        >
          Clear
        </span>
      </div>
      <div className="filterFieldsContainer__fieldsContainer">{children}</div>
    </div>
  );
}
export default FilterFieldsContainer;
