import { Marketplace } from "components/AppLeftNav/MarketPlace";
import "./index.scss";

function AppRightNavMobile() {
  return (
    <div className="appRightNavMobile">
      <div className="appRightNavMobile__marketplace">
        <Marketplace />
      </div>
    </div>
  );
}

export default AppRightNavMobile;
