import { trackEventCall, trackPageCall } from "analytics";
import {
  Avatar,
  Button,
  Carousel,
  Divider,
  Empty,
  message,
  Rate,
  Spin,
  List,
  Image
} from "antd";
import { getPlaybookDetailsById, playbookActions, buyerPlaybooksUsingFilters, } from "api/Playbooks";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import Flex from "screens/Chat/Flex";
import { setExternalUserInfo } from "store/modules/auth/action";
import "./index.scss";
import { ANALYTICS_EVENT_NAMES } from "constants";
import MobilePackageDetails from "./mPackageDetails";
import SinglePlaybookDetails from "../NewPlaybooks/SinglePlaybook/singleplaybookDetails";
import { PlaybookDummyImage } from "images";


function MobilePlaybookDetails({ singlePlaybookDetail }) {
  const contentStyle = {
    width: '100%', // Adjust the width as needed
    textAlign: 'center',
    color: "#fff",
    background: "#C0C0C0",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  const { playbookId } = useParams();
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const isB2B = userSession?.selectedWorkspaceName == "Palisade Knowledge";
  const [pbDetails, setPBDetails] = useState({});
  const [spinning, setSpinning] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [recommendedPlaybooks, setRecommendedPlaybooks] = useState([]);
  const [creatingConversation, setCreatingConversation] = useState(false);
  var groupChatImage = "";
  const navigate = useNavigate();
  const externalUserInfo = authReducer.externalUserInfo;
  const dispatch = useDispatch();
  const { selectedWorkspaceId, buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  useEffect(() => {
    const pageCallData = {
      marketplaceId: userSession.selectedWorkspaceId,
    };
    trackPageCall("Playbook Details", pageCallData);
  }, []);

  const requestProposalBeta = (packageid = null, details) => {
    const mPlaybookDetails = details ? details : pbDetails;

    const payload = {
      profile_workspace_id: userSession.buyerProfileWorkspaceId,
      playbook_id: playbookId,
      action: PLAYBOOK_ACTIONS.REQUEST_PROPOSAL,
      price: "99",
      selected_package_id: packageid,
    };

    const packages = mPlaybookDetails?.packages;
    packages?.forEach((pkg) => {
      if (pkg?.id === parseInt(packageid)) {
        setCreatingConversation(true);
        const segmentEventData = {
          marketplaceId: mPlaybookDetails?.workspace_id,
          playbookname: mPlaybookDetails?.name,
          sellername:
            mPlaybookDetails?.seller?.first_name +
            " " +
            mPlaybookDetails?.seller?.last_name,
          selleremail: mPlaybookDetails?.seller?.email,
          playbook_id: playbookId,
          revenue: parseInt(pkg?.price),
          currency: "USD",
          planType: pkg?.plan_type,
          planId: pkg?.id,
        };
        // console.log("segmentEventData", segmentEventData);
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED,
          segmentEventData
        );
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
          segmentEventData
        );

        playbookActions(payload)
          .then((res) => {
            trackEventCall(
              ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
              segmentEventData
            );
            trackEventCall(
              ANALYTICS_EVENT_NAMES.PROPOSAL_SENT,
              segmentEventData
            );
            trackEventCall(
              ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
              segmentEventData
            );
            message.success(res?.data?.message);

            createProposalChat(mPlaybookDetails);
          })
          .catch((err) => {
            message.error(err.response.data.message);
          })
          .finally(() => {
            setCreatingConversation(false);
          });
      }
    });
    // }
    // dispatch(setExternalUserInfo(null));
  };
  const requestProposal = (details) => {
    // console.log("requestProposal");
    const mPlaybookDetails = pbDetails;

    const payload = {
      profile_workspace_id: userSession.buyerProfileWorkspaceId,
      playbook_id: playbookId,
      action: PLAYBOOK_ACTIONS.REQUEST_PROPOSAL,
      price: "99",
      selected_package_id: "",
    };
    const segmentEventData = {
      marketplaceId: userSession.selectedWorkspaceId,
      playbookname: mPlaybookDetails?.name,
      sellername:
        mPlaybookDetails?.seller?.first_name +
        " " +
        mPlaybookDetails?.seller?.last_name,
      selleremail: mPlaybookDetails?.seller?.email,
      playbook_id: playbookId,
      revenue: "99",
      currency: "USD",
    };
    trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED, segmentEventData);
    trackEventCall(
      ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
      segmentEventData
    );
    playbookActions(payload)
      .then((res) => {
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
          segmentEventData
        );
        trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, segmentEventData);
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
          segmentEventData
        );
        message.success(res?.data?.message);

        createProposalChat(mPlaybookDetails);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setCreatingConversation(false);
      });
  };

  const createProposalChat = (mPlaybookDetails) => {
    navigate(`/dashboard/proposalDetails/${mPlaybookDetails?.id}`);
  };

  useEffect(() => {
    setSpinning(true);

    const payload = {
      playbook_id: playbookId,
      profile_workspace_id: 10,
    };

    getPlaybookDetailsById(payload)
      .then((res) => {
        setPBDetails(res?.data?.data[0]);
        getSearchResults(res?.data?.data[0].playbook_category_id);
        let localImageList = [];
        if (res?.data?.data[0]?.image1_url) {
          //let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image1_url);
          //setImagesList(localImageList);
        }

        if (res?.data?.data[0]?.image2_url) {
          //let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image2_url);
          setImagesList(localImageList);
        }
        if (res?.data?.data[0]?.image3_url) {
          //let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image3_url);
          //setImagesList(localImageList);
        }
        if (res?.data?.data[0]?.image4_url) {
          //let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image4_url);
          //setImagesList(localImageList);
        }
        setImagesList(localImageList);
        if (externalUserInfo != null) {
          // console.log("Before method triggering");
          // processPackageDetails(res?.data?.data[0]);
          requestProposalBeta(externalUserInfo?.packageId, res?.data?.data[0]);
          dispatch(setExternalUserInfo(null));
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setSpinning(false);
      });
  }, [playbookId]);

  const getSearchResults = (playbookCategoryId) => {
    let min = 0;
    let max = 999999999;

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      workspace_id: selectedWorkspaceId,
      playbook_category_id: playbookCategoryId,
      profile_type: "buyer",
      query: "",
      min_budget: min,
      max_budget: max,
      tags: "",
      owners: "",
      created_before: "",
      rating: "",
      action: "",
    };

    buyerPlaybooksUsingFilters(payload)
      .then((res) => {
        setRecommendedPlaybooks(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {});
  };

  const mPlaybookDetails = pbDetails;
  return (
    <div className="mPlaybookDetails">
      {spinning ? (
        <Flex
          flex={1}
          justify="center"
          align="center"
          style={{ width: "100%", height: 500 }}
          direction="column"
        >
          <Spin size="default" />
          <div> Loading...</div>
        </Flex>
      ) : !isEmpty(mPlaybookDetails) ? (
        <div>
          <div className="mPlaybookDetails__centerAlign mPlaybookDetails__image">
            <CustomAvatar
              name={mPlaybookDetails?.seller?.first_name}
              imageUri={mPlaybookDetails?.seller?.image_uri}
            />
          </div>

          <span className="mPlaybookDetails__centerAlign mPlaybookDetails__playbookTitle">
            {mPlaybookDetails?.name}
          </span>
          <span className="mPlaybookDetails__centerAlign mPlaybookDetails__welcomeText ">
            Hi, {" " + userSession.firstName + " " + userSession.lastName}
            <br />
            My name is
            {" " +
              mPlaybookDetails?.seller?.first_name +
              " " +
              mPlaybookDetails?.seller?.last_name}
            <br />
            Let me tell you about my playbook.
          </span>

          <div className="mPlaybookDetails__centerAlign">
            <Button
              className="mPlaybookDetails__positiveBtn"
              onClick={requestProposal}
              loading={creatingConversation}
              style={{
                display:
                  mPlaybookDetails?.seller?.seller_id === userSession.userId ||
                  mPlaybookDetails?.proposal_requested === true
                    ? "none"
                    : "visible",
              }}
            >
              Continue
            </Button>
          </div>

          <div
            className="mPlaybookDetails__centerAlign"
            style={{
              display:
                mPlaybookDetails?.image1_url === "" &&
                mPlaybookDetails?.image2_url === "" &&
                mPlaybookDetails?.image3_url === "" &&
                mPlaybookDetails?.image4_url
                  ? "none"
                  : "visible",
            }}
          >
            <Carousel style={{ width: "100%" }} autoplay>
              {imagesList.map((imageUrl, index) => {
                return (
                  <div>
                    <Image
                    className="mPlaybookDetails__movingImg"
                      alt="Image of playbook"
                      style={contentStyle}
                      src={ imageUrl !== "" ? imageUrl : PlaybookDummyImage}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="DivCon">
            <p className="mPlaybookDetails__aboutHeading">Highlights</p>

            <div style={{ flexDirection: "row" }}>
              <span style={{ fontSize: 18 }}>{"\u2022"}</span>
              <span className="mPlaybookDetails__highLights__text">
                {mPlaybookDetails?.highlight_1}
              </span>
            </div>

            <div style={{ flexDirection: "row" }}>
              <span style={{ fontSize: 18 }}>{"\u2022"}</span>
              <span className="mPlaybookDetails__highLights__text">
                {mPlaybookDetails?.highlight_2}
              </span>
            </div>

            <div style={{ flexDirection: "row" }}>
              <span style={{ fontSize: 18 }}>{"\u2022"}</span>
              <span className="mPlaybookDetails__highLights__text">
                {mPlaybookDetails?.highlight_3}
              </span>
            </div>

            <div style={{ flexDirection: "row" }}>
              <span style={{ fontSize: 18 }}>{"\u2022"}</span>
              <span className="mPlaybookDetails__highLights__text">
                {mPlaybookDetails?.highlight_4}
              </span>
            </div>

            <p className="mPlaybookDetails__aboutHeading">About</p>
            <p className="mPlaybookDetails__description">
              {mPlaybookDetails?.description}
            </p>
          </div>
          {!isB2B && (
          <p className="mPlaybookDetails__reviewsInfo">
            <Rate disabled defaultValue={1} count={1} />{" "}
            {mPlaybookDetails?.rating_avg !== null
              ? mPlaybookDetails?.rating_avg
              : 0}
            {"  "}
            <span className="mPlaybookDetails__reviewsInfo__count">
              (
              {mPlaybookDetails?.rating_avg !== null
                ? parseInt(parseInt(mPlaybookDetails?.rating_avg) * 1.7)
                : 0}
              K)
            </span>{" "}
          </p>
          )}

          <Divider className="mPlaybookDetails__divider" />

          <div className="mPlaybookDetails__languageCategory">
            <p className="mPlaybookDetails__languageCategory__heading">
              Language
            </p>
            <p className="mPlaybookDetails__languageCategory__heading">
              Category
            </p>
          </div>

          <div className="mPlaybookDetails__languageCategory">
            <p className="mPlaybookDetails__languageCategory__value">
              {mPlaybookDetails?.language}
            </p>
            <p className="mPlaybookDetails__languageCategory__value">
              {mPlaybookDetails?.playbook_category_name}
            </p>
          </div>
          {/* {isB2B ? (<Spin spinning={creatingConversation}>
            <p className="mPlaybookDetails__comparePackage"> Compare Package</p>
            {mPlaybookDetails?.packages && (
              <div>
                <MobilePackageDetails
                  packageDetails={mPlaybookDetails?.packages}
                />
              </div>
            )}
            </Spin>):(null)} */}
            
          <p className="mPlaybookDetails__recommendedHeading">
              Recommendation For You
            </p>
            <p className="mPlaybookDetails__recommend">
              {mPlaybookDetails?.playbook_category_name}
            </p>
            <div className="mPlaybookDetails__divList">
              <List
                dataSource={recommendedPlaybooks}
                className="mPlaybookDetails__list"
                renderItem={(item, index) => {
                  return (
                    <div className="mPlaybookDetails__datas">
                      <SinglePlaybookDetails
                        className="mPlaybookDetails__single"
                        singlePlaybookDetail={item}
                      />
                    </div>
                  );
                }}
              />
            </div>
        </div>
      ) : (
        <Empty />
      )}
    </div>
  );
}

export default MobilePlaybookDetails;

function CustomAvatar({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="mPlaybookDetails__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="mPlaybookDetails__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
