import {
  Avatar,
  Button,
  Form,
  Image,
  Input,
  List,
  message,
  Popconfirm,
  Select,
  Typography,
} from "antd";
import {
  getUserInvitesListById,
  inviteUserToWorkspace,
  updateUserInvite,
} from "api/userManagement";
import { Close, IconUser, ProfilePlaceholder } from "images";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isMobile, validateEmail } from "utils";
import "./index.scss";

function UserManagement() {
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const authReducer = useSelector((state) => state.authReducer);
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const userSession = authReducer.userSession;
  const [emailEntered, setEmailEntered] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [freeInvitesCount, setFreeInvitesCount] = useState(5);
  const [isMobileView, setIsMobileView] = useState(isMobile());

  useEffect(() => {
    setUserProfileUrl(
      userSession?.imageUri ? userSession?.imageUri : ProfilePlaceholder
    );
  }, []);

  useEffect(() => {
    const payloadForInvtesList = {
      userId: userSession.userId,
    };

    getUserInvitesListById(payloadForInvtesList)
      .then((res) => {
        // console.log(res);
        setData(res?.data?.data?.response);
        if (res?.data?.data?.response?.length <= 5) {
          setFreeInvitesCount(5 - res?.data?.data?.response?.length);
        } else {
          setFreeInvitesCount(0);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          message.error(err.response.data.message);
        } else {
          message.error(err.message);
        }
      });
  }, []);

  function handleChange(value) {
    setSelectedRole(value);
  }

  const featuresData = [
    {
      name: "Share your datasets",
    },
    {
      name: "Share your playbooks & projects",
    },
    {
      name: "Review a proposal together",
    },
    {
      name: "Chat",
    },
  ];

  const handleInvites = (values) => {
    const { emailaddress, selectrole } = values;
    if (freeInvitesCount <= 0) {
      message.error("You have consumed your free invites quota.");
      return;
    }
    if (emailEntered != "" && selectedRole != "") {
      setLoading(true);
      const payloadForInvite = {
        workspace_name: "Customer Success",
        workspace_id: userSession.selectedWorkspaceId,
        user_id: userSession.userId,
        guest_email: emailEntered,
        role: selectedRole,
        name: userSession.firstName + " " + userSession.lastName,
      };

      inviteUserToWorkspace(payloadForInvite)
        .then((res) => {
          // console.log(res);
          setData([
            ...data,
            {
              guest_email: emailEntered,
              role: selectedRole,
              status: "pending",
              id: res?.data?.data[0]?.id,
            },
          ]);
          form.resetFields();
          setEmailEntered("");
          setSelectedRole("");
          setFreeInvitesCount(freeInvitesCount - 1);
          message.success("Invitation sent successfully");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.message) {
            message.error(err.response.data.message);
          } else {
            message.error(err?.message);
          }
        });
    } else {
      message.error("Please enter the required fields");
    }
  };

  const inputOnChange = (e) => {
    if (validateEmail(e.target.value)) {
      setEmailEntered(e.target.value);
    } else {
      setEmailEntered("");
    }
  };

  const confirm = (id) => {
    // console.log(id);
    setLoading(true);
    const payload = {
      invite_id: id,
      status: "Cancelled",
    };
    updateUserInvite(payload)
      .then((res) => {
        // console.log(res);
        message.success(res?.data?.message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.message) {
          message.error(err.response.data.message);
        } else {
          message.error(err.message);
        }
      });
  };

  return (
    <>
      {isMobileView === true ? (
        <div className="userManagement">
          <div className="userManagement__mainContainerMobile">
            <div className="userManagement__headerMobile">
              <div className="userManagement__headerDivMobileContainer">
                <div className="userManagement__headerDivMobile">
                  <Typography.Text className="userManagement__headerText">
                    Add friends, family and coworkers to your marketplace.
                  </Typography.Text>
                  <div className="userManagement__headerRightDiv">
                    <Avatar
                      className="userManagement_headerImg"
                      src={userProfileUrl}
                    />
                  </div>
                </div>
                <div className="userManagement__headerFreeInvitesDivMobile">
                  <Typography.Text className="userManagement__headerTextBold12Mobile">
                    Free Invites:{freeInvitesCount}
                  </Typography.Text>
                  <Typography.Text className="userManagement__headerTextRegular10Mobile">
                    Expires in 29 day(s)
                  </Typography.Text>
                </div>
              </div>

              <div className="userManagement__inputFieldsContainerMobile">
                <Form
                  form={form}
                  layout="vertical"
                  className="userManagement__inputFormMobile"
                  onFinish={handleInvites}
                >
                  <Form.Item
                    className="userManagement__inputFormItemMobile"
                    name="emaiaddress"
                    label="Email Address"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please input correct email addresses.",
                      },
                      {
                        type: "email",
                        message: "Please input correct email addresses.",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      disabled={loading}
                      autoComplete="new-password"
                      placeholder="abc@xyz.com"
                      className="userManagement__inputFieldMobile"
                      type={"email"}
                      onChange={inputOnChange}
                    />
                  </Form.Item>
                  <Form.Item name="selectrole" label=" ">
                    <Select
                      disabled={loading}
                      className="userManagement__selectRoleMobile"
                      defaultValue="Role"
                      onChange={handleChange}
                    >
                      {/* <Option value="role">Role</Option> */}
                      <Option value="Owner">Owner</Option>
                      <Option value="Member">Member</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label=" ">
                    <div className="userManagement__inviteBtnContainer">
                      <Button
                        loading={loading}
                        className={
                          emailEntered != "" && selectedRole != ""
                            ? "userManagement__inviteButtonEnabledMobile"
                            : "userManagement__inviteButtonMobile"
                        }
                        type="primary"
                        htmlType="submit"
                      >
                        Invite
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className="userManagement__footerContainerMobile">
              <div className="userManagement__footerMobile">
                <div className="userManagement__workspaceMembersParentDivLeft">
                  <div className="userManagement__workspaceMembersTitlesDiv">
                    <Typography.Text className="userManagement__headerTextMedium10">
                      Marketplace members
                    </Typography.Text>
                    <Typography.Text className="userManagement__headerTextRegular">
                      Added team members will appear here...
                    </Typography.Text>
                  </div>
                  <List
                    className="userManagement__invitesList  hide-scroll-bar"
                    split
                    locale={{
                      emptyText:
                        "It's quite in here. Why not invite some family members?",
                    }}
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                      <List.Item className="userManagement__listItemMobile">
                        <div className="userManagement__listIconNamesDiv">
                          <Image
                            className="userManagement__listItemIcon"
                            preview={false}
                            src={IconUser}
                          ></Image>
                          <div className="userManagement__listItemTitlesDiv">
                            <Typography.Text className="userManagement__headerTextListItem">
                              {item.guest_email}
                            </Typography.Text>
                            <Typography.Text className="userManagement__headerTextBoldFeaturesList">
                              {item.role}
                            </Typography.Text>
                          </div>
                        </div>
                        <div className="userManagement__listItemInviteStatusDiv">
                          <Typography.Text
                            className={
                              item.status === "pending"
                                ? "userManagement__headerTextRegularNormal"
                                : "userManagement__headerTextRegularNormalOtherStatus"
                            }
                          >
                            {item.status}
                          </Typography.Text>
                          {item.status === "pending" && (
                            <Popconfirm
                              id={item.id}
                              placement="topRight"
                              title={
                                "Are you sure that you want to cancel this invite?"
                              }
                              onConfirm={() => {
                                confirm(item.id);
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Image
                                disabled={loading}
                                className="userManagement__listItemIconClose"
                                preview={false}
                                src={Close}
                              ></Image>
                            </Popconfirm>
                          )}

                          {/* <Image
                    className="userManagement__listItemIconEdit"
                    preview={false}
                    src={IconEdit}
                  ></Image> */}
                        </div>
                      </List.Item>
                    )}
                  ></List>
                </div>
              </div>
              <div className="userManagement__workspaceMembersParentDivRightMobile">
                <Typography.Text className="userManagement__headerTextMedium">
                  What will you gain?
                </Typography.Text>
                <Typography.Text className="userManagement__headerTextRegularMarginTop18">
                  By inviting friends/family/coworkers, you will be able to:
                </Typography.Text>
                <List
                  className="userManagement__featuresListMobile"
                  split
                  itemLayout="horizontal"
                  dataSource={featuresData}
                  renderItem={(item) => (
                    <List.Item className="userManagement__featuresListItem">
                      <span className="userManagement__featuresListItemIcon"></span>
                      <Typography.Text className="userManagement__headerTextBoldFeaturesList">
                        {item.name}
                      </Typography.Text>
                    </List.Item>
                  )}
                ></List>
                <Button
                  className="userManagement__textButtonMobile"
                  type="text"
                >
                  {" "}
                  Learn More...
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="userManagement  hide-scroll-bar">
          <div className="userManagement__mainContainer">
            <div className="userManagement__header">
              <div className="userManagement__headerDiv">
                <Typography.Text className="userManagement__headerText">
                  Add friends, family and coworkers to your marketplace.
                </Typography.Text>
                <div className="userManagement__headerRightDiv">
                  <div className="userManagement__headerFreeInvitesDiv">
                    <Typography.Text className="userManagement__headerTextBold12">
                      Free Invites:{freeInvitesCount}
                    </Typography.Text>
                    <Typography.Text className="userManagement__headerTextRegular12">
                      Expires in 29 day(s)
                    </Typography.Text>
                  </div>

                  <Avatar
                    className="userManagement_headerImg"
                    src={userProfileUrl}
                  />
                </div>
              </div>
              <div className="userManagement__inputFieldsContainer">
                <Form
                  form={form}
                  layout="vertical"
                  className="userManagement__inputForm"
                  onFinish={handleInvites}
                >
                  <Form.Item
                    className="userManagement__inputFormItem"
                    name="emaiaddress"
                    label="Email Address"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please input correct email addresses.",
                      },
                      {
                        type: "email",
                        message: "Please input correct email addresses.",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      disabled={loading}
                      autoComplete="new-password"
                      placeholder="abc@xyz.com"
                      className="userManagement__inputField"
                      type={"email"}
                      onChange={inputOnChange}
                    />
                  </Form.Item>
                  <Form.Item name="selectrole" label=" ">
                    <Select
                      disabled={loading}
                      className="userManagement__selectRole"
                      defaultValue="Role"
                      onChange={handleChange}
                    >
                      {/* <Option value="role">Role</Option> */}
                      <Option value="Owner">Owner</Option>
                      <Option value="Member">Member</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label=" ">
                    <Button
                      loading={loading}
                      className={
                        emailEntered != "" && selectedRole != ""
                          ? "userManagement__inviteButtonEnabled"
                          : "userManagement__inviteButton"
                      }
                      type="primary"
                      htmlType="submit"
                    >
                      Invite
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className="userManagement__footer">
              <div className="userManagement__workspaceMembersParentDivLeft">
                <div className="userManagement__workspaceMembersTitlesDiv">
                  <Typography.Text className="userManagement__headerTextMedium10">
                    Marketplace members
                  </Typography.Text>
                  <Typography.Text className="userManagement__headerTextRegular">
                    Added team members will appear here...
                  </Typography.Text>
                </div>
                <List
                  className="userManagement__invitesList  hide-scroll-bar"
                  split
                  locale={{
                    emptyText:
                      "It's quite in here. Why not invite some family members?",
                  }}
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item className="userManagement__listItem">
                      <div className="userManagement__listIconNamesDiv">
                        <Image
                          className="userManagement__listItemIcon"
                          preview={false}
                          src={IconUser}
                        ></Image>
                        <div className="userManagement__listItemTitlesDiv">
                          <Typography.Text className="userManagement__headerTextListItem">
                            {item.guest_email}
                          </Typography.Text>
                          <Typography.Text className="userManagement__headerTextBoldFeaturesList">
                            {item.role}
                          </Typography.Text>
                        </div>
                      </div>
                      <div className="userManagement__listItemInviteStatusDiv">
                        <Typography.Text
                          className={
                            item.status === "pending"
                              ? "userManagement__headerTextRegularNormal"
                              : "userManagement__headerTextRegularNormalOtherStatus"
                          }
                        >
                          {item.status}
                        </Typography.Text>
                        {item.status === "pending" && (
                          <Popconfirm
                            id={item.id}
                            placement="topRight"
                            title={
                              "Are you sure that you want to cancel this invite?"
                            }
                            onConfirm={() => {
                              confirm(item.id);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Image
                              disabled={loading}
                              className="userManagement__listItemIconClose"
                              preview={false}
                              src={Close}
                            ></Image>
                          </Popconfirm>
                        )}

                        {/* <Image
                    className="userManagement__listItemIconEdit"
                    preview={false}
                    src={IconEdit}
                  ></Image> */}
                      </div>
                    </List.Item>
                  )}
                ></List>
              </div>
              <div className="userManagement__workspaceMembersParentDivRight">
                <Typography.Text className="userManagement__headerTextMedium">
                  What will you gain?
                </Typography.Text>
                <Typography.Text className="userManagement__headerTextRegularMarginTop18">
                  By inviting friends/family/coworkers, you will be able to:
                </Typography.Text>
                <List
                  className="userManagement__featuresList"
                  split
                  itemLayout="horizontal"
                  dataSource={featuresData}
                  renderItem={(item) => (
                    <List.Item className="userManagement__featuresListItem">
                      <span className="userManagement__featuresListItemIcon"></span>
                      <Typography.Text className="userManagement__headerTextBoldFeaturesList">
                        {item.name}
                      </Typography.Text>
                    </List.Item>
                  )}
                ></List>
                <Button className="userManagement__textButton" type="text">
                  {" "}
                  Learn More...
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserManagement;
