import { Button, Card, Image } from "antd";
import React from "react";
import "./index.scss";
import { AlysonGreetingMessage } from "images";

function AlysonGreetingCard({ onDismiss }) {
  return (
    <div className="Alysongreetings">
      <div className="Alysongreetingscardtop">
        <div className="Greetingsimage">
          <Image src={AlysonGreetingMessage}></Image>
        </div>
        <div>
          <h1 className="Greetingsheading">
            {" "}
            Let's get started <br /> creating your dataset
          </h1>
        </div>
      </div>
      <div className="Alysongreetingcardbottom">
        <div>
          <h5 className="Greetingspara">
            First, we will define people, places or <br /> companies, then we
            will connect your <br /> dataset to the destinations
          </h5>
        </div>
        <div className="Buttondiv">
          <Button onClick={onDismiss} className="greetingsdismissbutton">
            Dismiss
          </Button>
        </div>
      </div>
    </div>
  );
}
export default AlysonGreetingCard;
