import "./index.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MarkDoneEmpty, MarkedGreen } from "images";
import { Card, Collapse, Drawer, Image } from "antd";
import CustomSyncRecurrence from "./CustomSyncRecurrence";
import { useDispatch, useSelector } from "react-redux";
import { setFormData, setStepCompletion } from "store/modules/datasets/action";

const { Panel } = Collapse;

function BuildList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authReducer = useSelector((state) => state.authReducer);
  const marketPlaceId = authReducer.userSession.selectedWorkspaceId;

  const steps = useSelector((state) => state.stepsReducer);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleInputChange = (subStep, subStepKey, value) => {
    const isStepCompleted = true;
    dispatch(setStepCompletion(2, isStepCompleted));
    dispatch(setFormData(subStep, subStepKey, value));
    dispatch(setStepCompletion(subStep, true));
  };
  const CustomPanelHeader = ({ mainHeading }) => (
    <Card className="buildList__customPanelHeader">
      <div className="buildList__customPanelHeader-header">
        <Image
          src={steps && steps.steps[2]?.completed ? MarkedGreen : MarkDoneEmpty}
          preview={false}
          className="buildList__customPanelHeader-image"
        />
        <div>
          <p className="buildList__customPanelHeader-mainHeading">
            {mainHeading}
          </p>
        </div>
      </div>
    </Card>
  );
  const handleSelectSourceClicked = () => {
    navigate(`/dashboard/datasets/datasets/build-list/select-source`);
  };
  const handlePasteQueryClicked = () => {
    navigate(`/dashboard/datasets/datasets/build-list/paste-query`);
  };
  const handleWhenToRunQueryClicked = () => {
    setDrawerVisible(true);
  };
  const CustomCardContent = ({ mainHeading = "", onClick, number }) => (
    <Card className="buildList__customCardContent" onClick={onClick}>
      <div className="buildList__customCardContent-header">
        <Image
          src={
            steps && steps.steps[number]?.completed
              ? MarkedGreen
              : MarkDoneEmpty
          }
          preview={false}
          className="buildList__customCardContent-image"
        />
        <p className="buildList__customCardContent-mainHeading">
          {mainHeading}
        </p>
      </div>
    </Card>
  );

  return (
    <div className="BuildList">
      <Collapse defaultActiveKey={["1"]} collapsible="disabled">
        <Panel
          header={
            <CustomPanelHeader mainHeading="Who should be included in this list?" />
          }
          key="1"
        >
          <CustomCardContent
            mainHeading="Select your Source"
            onClick={handleSelectSourceClicked}
            number={2.1}
          />
          <CustomCardContent
            mainHeading="Paste the query"
            onClick={handlePasteQueryClicked}
            number={2.2}
          />
          <CustomCardContent
            mainHeading="When would you like this query to run"
            onClick={handleWhenToRunQueryClicked}
            number={2.3}
          />
          <Drawer
            placement="bottom"
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
            height="90%"
            className="customdrawer"
          >
            <CustomSyncRecurrence
              onDoneClicked={() => {
                handleInputChange(2.3, "customSyncRecurrence", "done");
                setDrawerVisible(false);
                navigate(-1);
              }}
            />
          </Drawer>
        </Panel>
      </Collapse>
    </div>
  );
}

export default BuildList;
