import { Form, Slider } from "antd";
import {
  buyerPlaybooksUsingFilters,
  getBuyerPlaybookFilterValues,
  getCategoryDetailsByCategoryId,
  getGroupedPlaybooks,
} from "api/Playbooks";
import Searchbar from "components/Datasets/Commons/Searchbar";
import SideFilters from "components/Datasets/Commons/SideFilters";
import FilterDatePicker from "components/Datasets/Commons/SideFilters/Fields/FilterDatePicker";
import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "components/Datasets/Commons/SideFilters/Fields/FilterDropdown";
import FilterFieldsContainer from "components/Datasets/Commons/SideFilters/Fields/FilterFieldsContainer";
import FilterRatingDropdown from "components/Datasets/Commons/SideFilters/Fields/FilterRatingDropdown";
import FilterSingleSelectionDropdown from "components/Datasets/Commons/SideFilters/Fields/FilterSingleSelectionDropdown";
import {
  DEFAULT_DATE_FORMAT,
  PLAYBOOK_ACTIONS,
  SHOULD_SEARCH_ON_PAGE_LOAD,
} from "constants";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { useParams } from "react-router-dom";
// import SingleCategoryPlaybooks from "./SingleCategoryPlaybooks";
import CategoriesPreview from "./CategoriesPreview";
import { SearchOutlined } from "@ant-design/icons";
import { trackEventCall, trackPageCall } from "analytics";
import { Divider, Input, List, message } from "antd";
import { getPlaybookCategories } from "api/Playbooks";
import { getUserWorkspaceByProfileIds, joinWorkspace } from "api/Workspaces";
import classNames from "classnames";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { isEmpty } from "lodash";
import { Navigate, useNavigate } from "react-router-dom";
import {
  setExternalInviteInfo,
  setExternalLinkSegmentParams,
  setExternalUserInfo,
  setUserSession,
} from "store/modules/auth/action";
import "./index.scss";
import { isMobile } from "utils";
import { Button } from "antd";

function PlaybookCategoriesPreview() {
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchedFilters, setSearchedFilters] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [form] = Form.useForm();

  const playbookCategoryId = 5;

  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const { selectedWorkspaceId, buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;

  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const dispatch = useDispatch();

  const [categoriesList, setCategoriesList] = useState([]);
  const [finalCategoriesList, setFinalCategoriesList] =
    useState(categoriesList);
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();
  const shouldHighlightField = !isEmpty(searchedText) || isFocused;

  const externalLinkSegmentParams = authReducer.externalLinkSegmentParams;
  const [isMobileView, setIsMobileView] = useState(isMobile());

  const domainAndUserInfo = authReducer.domainAndUserInfo;
  const externalUserInfo = authReducer.externalUserInfo;
  const externalInviteInfo = authReducer.externalInviteInfo;

  //   const [current, setCurrent] = useState("desktop");

  //   const handleMobileClick = (event) => {
  //     setCurrent("mobile");
  //   };
  //   const handleDesktopClick = (event) => {
  //     setCurrent("desktop");
  //   };

  useEffect(() => {
    if (isMobileView) {
      callGetUserWorkspaceByProfileIdsAPI();
    }
  }, []);

  const callGetUserWorkspaceByProfileIdsAPI = () => {
    // payload
    // adding commit
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let externalWorkspaceId = domainAndUserInfo?.marketplaceId;
        if (externalInviteInfo != null) {
          externalWorkspaceId = externalInviteInfo.marketplaceId;
        } else if (externalUserInfo != null) {
          externalWorkspaceId = externalUserInfo.marketplaceId;
        }

        let marketplacesList = res?.data?.data;

        if (marketplacesList != null && marketplacesList.length > 0) {
          let defaultMarketplaceId = domainAndUserInfo?.marketplaceId;

          if (parseInt(userSession.selectedWorkspaceId) > 0) {
            defaultMarketplaceId = userSession.selectedWorkspaceId;
          }
          let isMarketPlacePresent = false;
          for (let i = 0; i < marketplacesList.length; i++) {
            let el = marketplacesList[i];
            if (
              el.workspace_id != null &&
              el.workspace_id === parseInt(defaultMarketplaceId)
            ) {
              userSession.selectedWorkspaceImagUrl = el.image_uri;
              userSession.selectedWorkspaceName = el.name;
              userSession.workspaceCategoryId = el.workspace_category_id;
              userSession.selectedWorkspaceId = el.workspace_id;
              isMarketPlacePresent = true;
              if (el?.workspace_profiles.length > 1) {
                el?.workspace_profiles.forEach((workspaceProfile) => {
                  if (workspaceProfile.account_role === "buyer") {
                    userSession.buyerProfileWorkspaceId =
                      workspaceProfile.profile_workspace_id;
                  } else {
                    userSession.sellerProfileWorkspaceId =
                      workspaceProfile.profile_workspace_id;
                  }
                });
              }
              break;
            } else {
              isMarketPlacePresent = false;
            }
          }
          dispatch(setUserSession(userSession));

          if (
            !isMarketPlacePresent &&
            externalWorkspaceId !== 1 &&
            (externalUserInfo?.playbookId === null ||
              externalUserInfo?.playbookId === undefined)
          ) {
            callNewJoinWorkspaceAPI(externalWorkspaceId);
            if (externalUserInfo != null) {
              dispatch(setExternalUserInfo(null));
            }
          }
        } else {
          if (
            externalWorkspaceId > 0 &&
            (externalUserInfo?.playbookId === null ||
              externalUserInfo?.playbookId === undefined)
          ) {
            callNewJoinWorkspaceAPI(externalWorkspaceId);

            dispatch(setExternalUserInfo(null));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const callUpdateUserWorkspaceByProfileIdsAPI = () => {
    // payload
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let marketplacesList = res?.data?.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (externalLinkSegmentParams != null) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PLATFORM_EVENT,
        externalLinkSegmentParams
      );
      // dispatch(setExternalLinkSegmentParams(null));
    }
    if (externalUserInfo != null) {
      const pageCallData = {
        marketplaceId: externalUserInfo.marketplaceId,
      };
      const mergedJson = {
        ...pageCallData,
        ...externalLinkSegmentParams,
      };
      trackPageCall("Playbook Categories", mergedJson);
    } else {
      const pageCallData = {
        marketplaceId: userSession.selectedWorkspaceId,
      };
      const mergedJson = {
        ...pageCallData,
        ...externalLinkSegmentParams,
      };
      trackPageCall("Playbook Categories", mergedJson);
    }
    setLoading(true);
    getWorkspacesByProfiles();
  }, []);

  const getWorkspacesByProfiles = () => {
    if (externalInviteInfo != null || externalUserInfo != null) {
      const payload = {
        profileids:
          userSession.buyerProfileId + "," + userSession.sellerProfileId,
      };
      getUserWorkspaceByProfileIds(payload)
        .then((res) => {
          let externalWorkspaceId = 1;
          if (externalInviteInfo != null) {
            externalWorkspaceId = externalInviteInfo.marketplaceId;
          } else if (externalUserInfo != null) {
            externalWorkspaceId = externalUserInfo.marketplaceId;
          }

          let workspaceIdsList = [];
          res?.data?.data?.forEach((el) => {
            if (el.workspace_id != null) {
              workspaceIdsList.push(el.workspace_id);
            }
          });
          if (workspaceIdsList.includes(parseInt(externalWorkspaceId))) {
            userSession.selectedWorkspaceId = externalWorkspaceId;
            res?.data?.data.forEach((el) => {
              if (
                el.workspace_id != null &&
                el.workspace_id === parseInt(externalWorkspaceId)
              ) {
                userSession.workspaceCategoryId = el.workspace_category_id;
                userSession.selectedWorkspaceId = el.workspace_id;
              }
            });
            dispatch(setUserSession(userSession));
            if (externalInviteInfo != null) {
              dispatch(setExternalInviteInfo(null));
            }
            if (externalUserInfo != null) {
              const playbookId = externalUserInfo.playbookId;
              userSession?.joinedWorkspacesList?.forEach((ws) => {
                if (
                  parseInt(ws?.id) === parseInt(externalUserInfo.marketplaceId)
                ) {
                  ws?.workspace_profiles?.forEach((account) => {
                    if (account.account_role === "buyer") {
                      userSession.buyerProfileWorkspaceId = account.id;
                    }
                    if (account.account_role === "seller") {
                      userSession.sellerProfileWorkspaceId = account.id;
                    }
                  });
                }
              });
              dispatch(setUserSession(userSession));
              // return <Navigate to={`/dashboard/playbookDetails/${playbookId}`} />;

              if (
                externalUserInfo?.playbookId !== null &&
                externalUserInfo?.playbookId !== undefined
              ) {
                navigate(`/dashboard/proposalDetails/${playbookId}`);
              } else {
                dispatch(setExternalUserInfo(null));
              }
            }
            setLoading(false);
          } else {
            callJoinWorkspaceAPI();
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error(err?.response?.data?.message);
        });
    }
    // else if (externalUserInfo != null) {
    //   // console.log("Website routing");
    //   callJoinWorkspaceAPI();
    //   // const playbookId = externalUserInfo.playbookId;
    //   // dispatch(setExternalUserInfo(null));
    //   // navigate(`/dashboard/playbookDetails/${playbookId}`);
    // }
  };

  const callNewJoinWorkspaceAPI = (marketplaceId) => {
    const payload = {
      workspace_id: marketplaceId, // workspace to join
      buyer_profile_id: userSession.buyerProfileId, // current buyer profile id
      seller_profile_id: userSession.sellerProfileId, // current seller profile id
    };
    joinWorkspace(payload)
      .then((res) => {
        let updatedUserSession = { ...userSession };
        updatedUserSession.selectedWorkspaceId = parseInt(marketplaceId);

        updatedUserSession.buyerProfileWorkspaceId =
          res?.data?.data?.buyer_profile_workspace_id;
        updatedUserSession.sellerProfileWorkspaceId =
          res?.data?.data?.seller_profile_workspace_id;
        updatedUserSession.isDefaultWorkspace = false;

        updatedUserSession.workspaceCategoryId =
          res?.data?.data?.workspace_info?.workspace_category_id;
        userSession.selectedWorkspaceName =
          res?.data?.data?.workspace_info?.name;

        dispatch(setUserSession(updatedUserSession));
        // setMyWorkplaces([...myWorkplaces, res?.data?.data]);
        // callUpdateUserWorkspaceByProfileIdsAPI();
      })
      .catch((err) => {});
  };

  const callJoinWorkspaceAPI = () => {
    console.log("Called this methode");
    let externalWorkspaceId = "1";
    if (externalInviteInfo != null) {
      externalWorkspaceId = externalInviteInfo.marketplaceId;
    } else if (externalUserInfo != null) {
      externalWorkspaceId = externalUserInfo.marketplaceId;
    }
    if (externalWorkspaceId === 1) {
      return;
    }

    const payload = {
      workspace_id: externalWorkspaceId, // workspace to join
      buyer_profile_id: userSession.buyerProfileId, // current buyer profile id
      seller_profile_id: userSession.sellerProfileId, // current seller profile id
    };
    joinWorkspace(payload)
      .then((res) => {
        let updatedUserSession = { ...userSession };
        updatedUserSession.selectedWorkspaceId = parseInt(payload.workspace_id);
        updatedUserSession.buyerProfileWorkspaceId =
          res?.data?.data?.buyer_profile_workspace_id;
        updatedUserSession.sellerProfileWorkspaceId =
          res?.data?.data?.seller_profile_workspace_id;
        updatedUserSession.isDefaultWorkspace = false;

        updatedUserSession.workspaceCategoryId =
          res?.data?.data?.workspace_info?.workspace_category_id;

        dispatch(setUserSession(updatedUserSession));

        // const externalInviteLocal = externalInviteInfo;
        if (externalInviteInfo != null) {
          dispatch(setExternalInviteInfo(null));
        }

        // if (externalInviteLocal === null) {
        //   const playbookId = externalUserInfo.playbookId;
        //   dispatch(setExternalUserInfo(null));
        //   navigate(`/dashboard/playbookDetails/${playbookId}`);
        // }

        //TODO: no need of this as we already setting our updated ids from server response
        if (externalUserInfo != null) {
          if (
            externalUserInfo.playbookId !== null &&
            externalUserInfo.playbookId !== undefined
          ) {
            const playbookId = externalUserInfo.playbookId;
            navigate(`/dashboard/proposalDetails/${playbookId}`);
          } else {
            dispatch(setExternalUserInfo(null));
          }
        }
      })
      .catch((err) => {
        console.log(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getFilters();
  }, [selectedWorkspaceId]);

  useEffect(() => {
    if (shouldFetchResults.current) {
      getSearchResults();
    }
  }, [searchedText, searchedFilters, selectedWorkspaceId]);

  const getFilters = () => {
    const payload = {
      playbook_category_id: playbookCategoryId,
      profile_workspace_id: buyerProfileWorkspaceId,
      workspace_id: selectedWorkspaceId,
      profile_type: "buyer",
    };

    getBuyerPlaybookFilterValues(payload)
      .then((res) => {
        const filters = res.data.data;
        setFilterOptions({
          ...filters,
        });
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const listDropdownData = [
    PLAYBOOK_ACTIONS.REVIEWING,
    PLAYBOOK_ACTIONS.HIGH_PRIORITY,
    PLAYBOOK_ACTIONS.LOW_PRIORITY,
    PLAYBOOK_ACTIONS.MEDIUM_PRIORITY,
    PLAYBOOK_ACTIONS.NOT_INTRESTED,
  ];

  const onApplyFilters = () => {
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    setSearchedFilters(form.getFieldsValue());
  };

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });

    let updatedBudgetLimits = filters["budget"]?.split(",");
    let min = 0;
    let max = 999999;

    if (updatedBudgetLimits?.length > 1) {
      min = parseInt(updatedBudgetLimits[0]);
      max = parseInt(updatedBudgetLimits[1]);
    }

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      workspace_id: selectedWorkspaceId,
      profile_type: "buyer",
      query: searchedText,
      min_budget: min,
      max_budget: max,
      tags: filters["tags"],
      owners: filters["owners"],
      created_before: filters["createdBefore"],
      rating: filters["rating"],
      action: filters["list"],
    };

    setLoading(true);
    getGroupedPlaybooks(payload)
      .then((res) => {
        console.log("here ");
        setSearchResults(res?.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tipFormatter = (value) => `$${value}`;

  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
  };
  const filterFields = [
    <FilterFieldsContainer
      label="Budget"
      onClear={() => {
        onResetFields(["budget"]);
      }}
    >
      <Form.Item name="budget">
        <Slider
          range={{ draggableTrack: true }}
          min={0}
          max={parseInt(filterOptions.maxBudget)}
          defaultValue={[0, parseInt(filterOptions.maxBudget)]}
          tipFormatter={tipFormatter}
          onAfterChange={onApplyFilters}
        />
      </Form.Item>
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Owners"
      onClear={() => {
        onResetFields(["owners"]);
      }}
    >
      <FilterDropdown
        name="owners"
        placeholder="Enter Owners here…"
        options={filterOptions.sellers}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Tags"
      onClear={() => {
        onResetFields(["tags"]);
      }}
    >
      <FilterDropdown
        name="tags"
        placeholder="Enter tags here…"
        options={filterOptions.tags}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="List"
      onClear={() => {
        onResetFields(["list"]);
      }}
    >
      <FilterSingleSelectionDropdown
        name="list"
        placeholder="Enter list here…"
        options={listDropdownData}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Created before"
      onClear={() => {
        onResetFields(["createdBefore"]);
      }}
    >
      <FilterDatePicker name="createdBefore" onChange={onApplyFilters} />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Rating"
      onClear={() => {
        onResetFields(["rating"]);
      }}
    >
      <FilterRatingDropdown
        name="rating"
        placeholder="Enter rating here…"
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
  ];

  return (
    <>
      <div className="PlaybookCategoriesPreview">
        <div className="PlaybookCategoriesPreview__searchContainer">
          <Searchbar
            placeholder="Search playbooks"
            filtersVisible={showFilters}
            onFiltersVisibilityChange={setShowFilters}
            onSubmitSearchText={(searchedText) => {
              shouldFetchResults.current = true;
              setIsBeginingSearch(false);
              setSearchedText(searchedText);
            }}
            filtersApplied={
              !isEmpty(
                Object.values(searchedFilters).filter((value) => {
                  return !isEmpty(value);
                })
              )
            }
          />
        </div>

        <div className="PlaybookCategoriesPreview__filterAndResults">
          <>
            {showFilters && (
              <div className="PlaybookCategoriesPreview__filterAndResults__filters">
                <Form form={form}>
                  <SideFilters
                    filterFields={filterFields}
                    onClose={() => {
                      setShowFilters(false);
                    }}
                    onReset={() => {
                      form.resetFields();
                      shouldFetchResults.current = true;
                      setIsBeginingSearch(false);
                      setSearchedFilters({});
                    }}
                  />
                </Form>
              </div>
            )}
            {!isBeginingSearch && (
              <div className="PlaybookCategoriesPreview__Margin">
                <CategoriesPreview />
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
}

export default PlaybookCategoriesPreview;

// <div className="PlaybookCategoriesPreviewViewbutton">
// <Button
//   className="PlaybookCategoriesPreviewViewbutton__DesktopButton"
//   onClick={handleDesktopClick}
// >
//   Desktop
// </Button>
// <Button
//   className="PlaybookCategoriesPreviewViewbutton__MobileButton"
//   onClick={handleMobileClick}
// >
//   Mobile
// </Button>
// <Button
//   className={
//     current === "desktop"
//       ? "PlaybookCategoriesPreview__Create"
//       : "PlaybookCategoriesPreviewMobile__Create"
//   }
// >
//   Create
// </Button>
// </div>
