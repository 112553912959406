import { Button, Form, Image, List, message, Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentSourceInfo } from "store/modules/fileOnboard/action";
import { matchingColumnInColumnsOrDataDictionary } from "utils";
import {
  ENTITY_TYPES,
  ENTITY_TYPE_COLUMNS,
} from "../../../../../../../../constants";
import ArrowDarkIcon from "../../../../../../../../images/arrow-dark.svg";
import ArrowLightIcon from "../../../../../../../../images/arrow-light.svg";
import IconDownArrow from "../../../../../../../../images/filter-chevron-dropdown.svg";
import IconTooltip from "../../../../../../../../images/tooltip.svg";
import "./index.scss";
const { Option } = Select;

/*
- File onboarding flow component used to render System Fields step
- onContinue will be invoked when all of the requried fields are filled
- Error handling and redux dispatch is hanlded within the scope of this component
*/
function SystemFields({ onContinue }) {
  const [error, setError] = useState("");
  const currentSourceInfo = useSelector(
    (state) => state.fileOnboardReducer
  ).currentSourceInfo;
  const currentProcessingFile = useSelector(
    (state) => state.fileOnboardReducer
  ).currentProcessingFile;
  const dispatch = useDispatch();

  const getErrorText = () => {
    switch (currentSourceInfo.entity_type) {
      case ENTITY_TYPES.COMPANY:
        return "Your file upload must contain company name and company website";
      case ENTITY_TYPES.PEOPLE:
        return "Your file upload must contain name plus at least one unique identifier (phone, email or address)";
      case ENTITY_TYPES.PLACES:
        return "Your file upload must contain address line 1, state, city and zip code";
      case ENTITY_TYPES.EVENTS:
        return "Your file upload must contain email and timestamp ";
      default:
        return "";
    }
  };
  const onFinish = (values) => {
    const updatedValues = { ...values };
    let optionalValues = {};
    let optionalFieldsError = false;
    if (currentSourceInfo.entity_type === ENTITY_TYPES.PEOPLE) {
      const {
        first_name,
        last_name,
        name,
        phone_number,
        email,
        address,
        city,
        state,
        zip,
      } = updatedValues;

      delete updatedValues.first_name;
      delete updatedValues.last_name;
      delete updatedValues.name;
      delete updatedValues.phone_number;
      delete updatedValues.email;
      delete updatedValues.city;
      delete updatedValues.state;
      delete updatedValues.zip;
      delete updatedValues.address;

      const isFilledAnyAddressField =
        !isEmpty(address) || !isEmpty(city) || !isEmpty(state) || !isEmpty(zip);
      const areFilledAllAddressFields =
        !isEmpty(address) && !isEmpty(city) && !isEmpty(state) && !isEmpty(zip);

      if (!isEmpty(first_name)) {
        optionalValues.first_name = first_name;
      }
      if (!isEmpty(last_name)) {
        optionalValues.last_name = last_name;
      }
      if (!isEmpty(name)) {
        optionalValues.name = name;
      }
      if (!isEmpty(phone_number)) {
        optionalValues.phone_number = phone_number;
      }
      if (!isEmpty(email)) {
        optionalValues.email = email;
      }
      if (areFilledAllAddressFields) {
        optionalValues.address = address;
        optionalValues.city = city;
        optionalValues.state = state;
        optionalValues.zip = zip;
      }
      const isNameProvided =
        (!isEmpty(first_name) && !isEmpty(last_name)) || !isEmpty(name);
      const isOptionalFieldProvided =
        !isEmpty(email) || !isEmpty(phone_number) || areFilledAllAddressFields;

      optionalFieldsError =
        !isNameProvided ||
        !isOptionalFieldProvided ||
        (isFilledAnyAddressField && !areFilledAllAddressFields);
    }

    const emptyValues = Object.values(updatedValues).filter((value) => {
      return !value;
    });
    if (optionalFieldsError || !isEmpty(emptyValues)) {
      setError(getErrorText());
    } else {
      dispatch(
        setCurrentSourceInfo({
          ...currentSourceInfo,
          systemFields: { ...updatedValues, ...optionalValues },
        })
      );
      onContinue();
    }
  };

  const systemRequiredColumns =
    ENTITY_TYPE_COLUMNS[currentSourceInfo.entity_type];
  const getInitialValues = () => {
    const autoMatchedInitialValues = {};
    systemRequiredColumns.forEach((sourceColumn) => {
      const matchingColumn = matchingColumnInColumnsOrDataDictionary(
        sourceColumn.column_name,
        currentProcessingFile.schema.columns,
        currentProcessingFile.dataDictionary
      );
      if (matchingColumn) {
        autoMatchedInitialValues[sourceColumn.column_name] = matchingColumn;
      }
    });
    const initialValues =
      currentSourceInfo?.systemFields || autoMatchedInitialValues;
    return initialValues;
  };
  const getHeadingText = () => {
    switch (currentSourceInfo.entity_type) {
      case ENTITY_TYPES.COMPANY:
        return "Identify system required fields for companies";
      case ENTITY_TYPES.PEOPLE:
        return "Identify system required fields for people";
      case ENTITY_TYPES.PLACES:
        return "Identify system required fields for places";
      case ENTITY_TYPES.EVENTS:
        return "Identify system required fields for Email";
      default:
        return null;
    }
  };
  const getOptionalFieldHeader = (index) => {
    switch (currentSourceInfo.entity_type) {
      case ENTITY_TYPES.COMPANY:
        return null;
      case ENTITY_TYPES.PEOPLE:
        if (index === 1) {
          return (
            <p className="systemFields__list__optionalFieldHeader">
              Or map Full Name
            </p>
          );
        } else if (index === 2) {
          return (
            <p className="systemFields__list__optionalFieldHeader">
              Map Phone or Email or Address Fields
            </p>
          );
        } else {
          return null;
        }
      case ENTITY_TYPES.PLACES:
        return null;
      default:
        return null;
    }
  };
  const getColumnsName = (item) => {
    return (
      <div className="systemFields__row__left__columnWithTooltip">
        <span className="systemFields__row__left__columnWithTooltip__columnName">
          {item.column_name}
        </span>
        {item.tooltip && (
          <Tooltip title={item.tooltip}>
            <Image src={IconTooltip} preview={false} />
          </Tooltip>
        )}
      </div>
    );
  };
  const getDropdownOptions = (item) => {
    return currentProcessingFile.schema.columns.map((column, index) => {
      return (
        <Option value={column} key={index}>
          {column}
        </Option>
      );
    });
  };

  return (
    <div className="systemFields">
      <p className="systemFields__heading">{getHeadingText()}</p>
      <p className="systemFields__subHeading">
        System required fields allow us to match your data and link your data to
        other data sources with a common entity ID. We cannot upload any data
        that does not meet the minimum requirements to generate an entity ID. If
        you choose address fields, a new source will also be created in the
        Place knowledge graph.
      </p>
      <div className="systemFields__row systemFields__row--header">
        <p className="systemFields__row__left">System Required Fields</p>
        <Image src={ArrowDarkIcon} preview={false} />
        <div className="systemFields__row__right">
          <span className="systemFields__row__right__label">Your Fields</span>
        </div>
      </div>

      <Form
        onFinish={onFinish}
        onFieldsChange={() => {
          setError("");
        }}
        initialValues={getInitialValues()}
      >
        <List
          className="systemFields__list"
          dataSource={systemRequiredColumns}
          itemLayout="vertical"
          renderItem={(item, index) => {
            return (
              <>
                <div key={index} className="systemFields__row">
                  {getColumnsName(item)}
                  <Image src={ArrowLightIcon} preview={false} />
                  <div className="systemFields__row__right">
                    <Form.Item name={`${item.column_name}`}>
                      <Select
                        placeholder="Select column"
                        showSearch
                        filterOption="children"
                        allowClear
                        suffixIcon={
                          <Image src={IconDownArrow} preview={false} />
                        }
                      >
                        {getDropdownOptions(item)}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {getOptionalFieldHeader(index)}
              </>
            );
          }}
        />

        <div className="systemFields__footer">
          <span className="systemFields__footer__error">{error}</span>
          <Button
            htmlType="submit"
            className="systemFields__footer__continueButton"
          >
            Continue
          </Button>
        </div>
      </Form>
    </div>
  );
}
export default SystemFields;
