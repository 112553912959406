import React, { useState } from "react";
import { Tabs } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { EyeOutlined} from "@ant-design/icons";
// import classNames from "classnames";
import Facebook from "./Facebook";
import LinkedIn from "./LinkedIn";
import Google from "./Google";
import ThankYouPage from "./ThankYouPage";
import PlaybookCategoriesPreview from "./PlaybookCategoriesPreview";
import PlaybookDetailsPreview from "./PlaybookDetailsPreview";
import "./index.scss";
import { isEmpty } from "lodash";
import {Avatar} from "antd";
import { useCustomEventListener } from "react-custom-events";

const { TabPane } = Tabs;

function PreviewPlaybookAds() {

    const userSession = useSelector((state) => state.authReducer).userSession;
    const [userProfileUrl, setUserProfileUrl] = useState(null);
    const [userFullName, setUserFullName] = useState(
        `${userSession.firstName} ${userSession.lastName}`
      );



      useCustomEventListener("profile-img-changed", (data) => {
        setUserProfileUrl(data);
      });
      useCustomEventListener("first-name-changed", (data) => {
        setUserFullName(data);
      });

      useEffect(() => {
        setUserProfileUrl(userSession?.imageUri);
      }, []);

  return (
    <>
      <div className="tabsContainer">
      <Tabs type="card">
          <TabPane tab="Facebook" key="facebook">
            <Facebook />
          </TabPane>
          <TabPane tab="LinkedIn" key="linkedin">
            <LinkedIn />
          </TabPane>
          <TabPane tab="Google" key="google">
            <Google />
          </TabPane>
          <TabPane tab="Thank You Page" key="thankyoupage">
            <ThankYouPage />
          </TabPane>
          <TabPane tab="Playbook Details" key="playbookdetails">
            <PlaybookDetailsPreview />
          </TabPane>
          <TabPane tab="Playbook Categories" key="playbookcategories">
            <PlaybookCategoriesPreview />
          </TabPane>
          </Tabs>
          <div className="tabsContainer__View">
          <EyeOutlined /> 
          </div>
          <div className="tabsContainer__Avatar">
          <CustomAvatar name={userFullName} imageUri={userProfileUrl} />
          </div>
      </div>
    </>
  );
}

export default PreviewPlaybookAds;

function CustomAvatar({ name, imageUri }) {
    const arrayOfColors = {
      A: "#AB0CFF",
      B: "#3283FF",
      C: "#85660C",
      D: "#782AB7",
      E: "#565656",
      F: "#EA1E29",
      G: "#3DB24B",
      H: "#F8E2A1",
      I: "#E2E2E2",
      J: "#1CBF4E",
      K: "#C5441C",
      L: "#DC9FFB",
      M: "#D90ED7",
      N: "#325A9B",
      O: "#FFAF16",
      P: "#F9A19F",
      Q: "#91AD1C",
      R: "#1C8356",
      S: "#48C1A7",
      T: "#2ED9FF",
      U: "#B10CA1",
      V: "#C174A7",
      W: "#EA3FBA",
      X: "#AE0065",
      Y: "#E8D325",
      Z: "#F80086",
    };
  
    let finalAvatar = null;
    if (
      imageUri !== null &&
      imageUri !== undefined &&
      imageUri !== "" &&
      !isEmpty(imageUri)
    ) {
      finalAvatar = (
        <Avatar
          className="tabsContainer__customAvatar__avatar"
          src={imageUri}
        />
      );
    } else {
      finalAvatar = (
        <Avatar
          className="tabsContainer__customAvatar__avatar"
          style={{
            backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
            paddingTop: 8,
          }}
        >
          {name?.charAt(0).toUpperCase() + ""}
        </Avatar>
      );
    }
  
    return <div className="customAvatar">{finalAvatar}</div>;
  }
