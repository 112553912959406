import API from "..";

// playbooks
export const getPlaybookCategories = (payload) => {
  return API.get("/playbooks/getPlaybookCategories", payload);
};

export const getCategoryDetailsByCategoryId = (payload) => {
  return API.get("/playbooks/getCategoryDetailsByCategoryId", payload);
};

export const getGroupedPlaybooks = (payload) => {
  return API.post("/playbooks/groupedSearch", null, payload);
};

export const getPresignedUrl = (payload) => {
  return API.get("/playbooks/PresignedUrlForPlaybooks", payload);
};

// create a playbook
export const createAPlaybook = (payload) => {
  return API.post("/playbooks/createPlaybook", payload);
};

//edit a playbook
export const editPlaybook = (payload, playbookId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return API.patch(`/playbooks/editPlaybook/${playbookId}`, payload, { headers });
};

// playbook filters
export const getBuyerPlaybookFilterValues = (payload) => {
  return API.get("/playbooks/getPlaybookFilterValues", payload);
};
export const getSellerPlaybookFilterValues = (payload) => {
  return API.get("/playbooks/getPlaybookFilterValues", payload);
};

// playbooks using Filters
export const buyerPlaybooksUsingFilters = (payload) => {
  return API.post("/playbooks/search", null, payload);
};
export const sellerPlaybooksUsingFilters = (payload) => {
  return API.post("/playbooks/search", null, payload);
};

// playbook Actions
export const playbookActions = (payload) => {
  return API.post("/playbooks/updatePlaybookActions", null, payload);
};

// playbook details
export const getPlaybookDetailsById = (payload) => {
  return API.get("/playbooks/getPlaybookDetailsById", payload);
};

// proposal details
export const getProposalDetailsById = (payload) => {
  return API.get("/playbooks/getProposalDetailsById", payload);
};

// proposals
export const getProposalFilterValues = (payload) => {
  return API.get("/playbooks/getProposalFilterValues", payload);
};

export const proposalUsingFilters = (payload) => {
  return API.post("/playbooks/proposal/search", null, payload);
};

export const updateProposalDetails = (payload) => {
  return API.post("/playbooks/updateProposalDetailsById", null, payload);
};

// Playbooks Media Upload
export const getPresignedUrlForImage = (payload) => {
  return API.post("/playbooks/s3PresignedUrlForPlaybooks", payload);
};
