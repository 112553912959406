import "./index.scss";
import { List, Input, Image, InputNumber, Form } from "antd";
import { useState } from "react";
import { TickOn, TickOff } from "images";

const { TextArea } = Input;

function EditPackage({
  onPackagePriceChanged,
  onPackageDescriptionChanged,
  servicesSelectionData,
  onServiceNameChanged,
  changeServiceSelection,
}) {
const [columnsData, setColumnsData] = useState([]);
  const [packageSelection, setPackageSelection] = useState(1);

  return (
    <div className="EditPackage">
      <p className="EditPackage__heading">Add Package</p>
      <div className="EditPackage__mainContainer">
        <div className="EditPackage__mainContainer__labelColumn">
          <p className="EditPackage__mainContainer__labelColumn__head">
            Package
          </p>
          <p className="EditPackage__mainContainer__labelColumn__subHeading">
            Add pricing details and offerings your playbook will offer in
            package.
          </p>
        </div>
        <div
          className={
            packageSelection === 1
              ? "EditPackage__mainContainer__selectedValueColumn"
              : "EditPackage__mainContainer__unSelectedValueColumn"
          }
          onClick={() => setPackageSelection(1)}
        >
          <p className="EditPackage__mainContainer__heading">Basic</p>
          <Form.Item
            name="basicPrice"
            required
            rules={[
              {
                required: true,
                message: "Enter price",
              },
            ]}
          >
            <div className="EditPackage__mainContainer__priceContainer">
              <InputNumber
                addonBefore="$"
                className="inputAddOnAfter"
                placeholder="Enter Price"
                min={0}
                onChange={onPackagePriceChanged("basic")}
              />
            </div>
          </Form.Item>

          <Form.Item
            name="basicDescription"
            required
            rules={[
              {
                required: true,
                message: "Enter description",
              },
            ]}
          >
            <TextArea
              style={{
                "min-height": 100,
                padding: 8,
                "margin-top": 8,
                "margin-bottom": 8,
              }}
              placeholder="Add details of your package here"
              rows={4}
              onChange={onPackageDescriptionChanged("basic")}
            />
          </Form.Item>
        </div>

        <div
          className={
            packageSelection === 2
              ? "EditPackage__mainContainer__selectedValueColumn"
              : "EditPackage__mainContainer__unSelectedValueColumn"
          }
          onClick={() => setPackageSelection(2)}
        >
          <p className="EditPackage__mainContainer__heading">Standard</p>

          <Form.Item
            name="standardPrice"
            required
            rules={[
              {
                required: true,
                message: "Enter price",
              },
            ]}
          >
            <div className="EditPackage__mainContainer__priceContainer">
              <InputNumber
                addonBefore="$"
                className="inputAddOnAfter"
                placeholder="Enter Price"
                min={0}
                onChange={onPackagePriceChanged("standard")}
              />
            </div>
          </Form.Item>

          <Form.Item
            name="standardDescription"
            required
            rules={[
              {
                required: true,
                message: "Enter description",
              },
            ]}
          >
            <TextArea
              style={{
                "min-height": 100,
                padding: 8,
                "margin-top": 8,
                "margin-bottom": 8,
              }}
              placeholder="Add details of your package here"
              rows={4}
              onChange={onPackageDescriptionChanged("standard")}
            />
          </Form.Item>
        </div>

        <div
          className={
            packageSelection === 3
              ? "EditPackage__mainContainer__selectedValueColumn"
              : "EditPackage__mainContainer__unSelectedValueColumn"
          }
          onClick={() => setPackageSelection(3)}
        >
          <p className="EditPackage__mainContainer__heading">Premium</p>

          <Form.Item
            name="premiumPrice"
            required
            rules={[
              {
                required: true,
                message: "Enter price",
              },
            ]}
          >
            <div className="EditPackage__mainContainer__priceContainer">
              <InputNumber
                addonBefore="$"
                className="inputAddOnAfter"
                placeholder="Enter Price"
                min={0}
                onChange={onPackagePriceChanged("premium")}
              />
            </div>
          </Form.Item>

          <Form.Item
            name="premiumDescription"
            required
            rules={[
              {
                required: true,
                message: "Enter description",
              },
            ]}
          >
            <TextArea
              style={{
                "min-height": 100,
                padding: 8,
                "margin-top": 8,
                "margin-bottom": 8,
              }}
              placeholder="Add details of your package here"
              rows={4}
              onChange={onPackageDescriptionChanged("premium")}
            />
          </Form.Item>
        </div>

        <List
          dataSource={servicesSelectionData}
          itemLayout="vertical"
          rowKey={"key"}
          className="EditPackage__list"
          renderItem={(item, index) => {
            return (
              <div
                className={
                  index % 2 === 0
                    ? "EditPackage__list__oddRow"
                    : "EditPackage__list__evenRow"
                }
              >
                <div className="EditPackage__list__row">
                  <div className="EditPackage__list__row__rowDivision">
                    <Form.Item
                      name={"serviceName" + index}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Enter service name",
                        },
                      ]}
                    >
                      <Input
                        name="service_name"
                        placeholder="Service name"
                        onChange={onServiceNameChanged(index)}
                      />
                    </Form.Item>
                  </div>
                  <div className="EditPackage__list__row__rowDivision">
                    <Image
                      className="EditPackage__list__row__rowDivision__tick"
                      src={item.b_isSelected ? TickOn : TickOff}
                      preview={false}
                      onClick={() =>
                        changeServiceSelection(
                          !item.b_isSelected,
                          "basic",
                          index
                        )
                      }
                    />
                  </div>
                  <div className="EditPackage__list__row__rowDivision">
                    <Image
                      className="EditPackage__list__row__rowDivision__tick"
                      src={item.s_isSelected ? TickOn : TickOff}
                      preview={false}
                      onClick={() =>
                        changeServiceSelection(
                          !item.s_isSelected,
                          "standard",
                          index
                        )
                      }
                    />
                  </div>
                  <div className="EditPackage__list__row__rowDivision">
                    <Image
                      className="EditPackage__list__row__rowDivision__tick"
                      src={item.p_isSelected ? TickOn : TickOff}
                      preview={false}
                      onClick={() =>
                        changeServiceSelection(
                          !item.p_isSelected,
                          "premium",
                          index
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
export default EditPackage;
