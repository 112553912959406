import "./index.scss";
import { MoreOutlined, CopyOutlined } from "@ant-design/icons";

import { Card, message, Popover, Avatar, Row, Col, Image } from "antd";
import { PlaybookRequested } from "images";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Rate, Divider } from "antd";
import { trackEventCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { PlaybookDummyImage } from "images";

import { playbookActions } from "api/Playbooks";

function SinglePlaybook({ singlePlaybookDetail }) {
  let data = singlePlaybookDetail;
 
  const navigate = useNavigate();

  const { buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const userSession = useSelector((state) => state.authReducer).userSession;
  const isB2B = userSession?.selectedWorkspaceName == "Palisade Knowledge";
  const isPrivHome  = userSession?.selectedWorkspaceName == "PrivHomes";

  const handleVisibleChange = (newVisible) => {
    setActionVisibilty(newVisible);
  };
  const [actionVisibilty, setActionVisibilty] = useState(false);

  const onReadMoreClicked = (playbookId) => {
    navigate(`/dashboard/playbookDetails/${playbookId}`);
  };
  const createProposalChat = (playbookDetails) => {
    navigate(`/dashboard/proposalDetails/${playbookDetails?.id}`);
  };

  const callActionsAPI = (actionText) => {
    setActionVisibilty(false);

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      playbook_id: data.id,
      action: actionText,
      price: data.min_price,
      selected_package_id: 0,
    };
    const segmentEventData = {
      playbookname: data?.name,
      sellername: data?.seller?.first_name + " " + data?.seller?.last_name,
      selleremail: data?.seller?.email,
      playbook_id: data?.id,
      price: data?.min_price,
      marketplaceId: data?.workspace_id,
    };
    if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED,
        segmentEventData
      );
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
        segmentEventData
      );
    }
    console.log(payload);

    playbookActions(payload)
      .then((res) => {
        if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
            segmentEventData
          );
          trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, segmentEventData);
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
            segmentEventData
          );
          createProposalChat(data);
        }
        message.success(res?.data?.message);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {});
  };

  const actionDropDownContent = (
    <div className="popOverContainer">
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.REQUEST_PROPOSAL);
        }}
        style={{
          display:
          data?.user_id == userSession?.userId
              ? "none"
              : "visible",
        }}
      >
        {PLAYBOOK_ACTIONS.REQUEST_PROPOSAL}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.REVIEWING);
        }}
      >
        {PLAYBOOK_ACTIONS.REVIEWING}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.HIGH_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.HIGH_PRIORITY}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.MEDIUM_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.MEDIUM_PRIORITY}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.LOW_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.LOW_PRIORITY}
      </p>

      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.NOT_INTRESTED);
        }}
      >
        {PLAYBOOK_ACTIONS.NOT_INTRESTED}
      </p>
    </div>
  );

  return (
    <div className="singlePlaybookDetails">
      <Card
        className="singlePlaybookDetails__Card"
        hoverable
        // onClick={() => onReadMoreClicked(data.id)}
      >
        <Image
          onClick={() => onReadMoreClicked(data.id)}
          className="singlePlaybookDetails__IMG1"
          alt="Image of playbook"
          preview={false}
          src={
            data?.thumbnail === null 
              ? (data?.image1_url && data?.image1_url !== '') 
                ? data?.image1_url
                : PlaybookDummyImage
              : data?.thumbnail
          }
        />

        <Row
          className="MobileSingleProposal__Row-2"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={19}>
            <div className="singlePlaybookDetails__appHomeButton">
              <CustomAvatarSinglePlaybook
                imageUri={data?.seller?.image_uri}
                name={data?.seller?.first_name}
              />
              <div className="singlePlaybookDetails__appHomeHeadContainer">
                <div
                  className="singlePlaybookDetails__appHomeMainHead"
                  onClick={() => onReadMoreClicked(data.id)}
                >
                  {data?.seller?.first_name + " " + data?.seller?.last_name}{" "}
                </div>
                <div
                  onClick={() => onReadMoreClicked(data.id)}
                  className="singlePlaybookDetails__appHomeSubHead"
                >
                  {data?.seller?.work_position}
                </div>
              </div>
            </div>
          </Col>

          <Col span={5}>
            <Popover
              placement="bottom"
              overlayClassName="actionsPopover"
              content={actionDropDownContent}
              trigger="click"
              visible={actionVisibilty}
              onVisibleChange={handleVisibleChange}
              className="singlePlaybookDetails__appHomeButton"
            >
              <MoreOutlined className="singlePlaybookDetails__appDotsIcon" />
            </Popover>
          </Col>
        </Row>
        <h3
          onClick={() => onReadMoreClicked(data.id)}
          className="singlePlaybookDetails__H1"
        >
          {data?.name}
        </h3>
        <div title={data?.short_description}>
        <p className="singlePlaybookDetails__P1">{data?.short_description}</p>
        </div>

        {!isB2B ? <p className="singlePlaybookDetails__proposalDetails__reviewsInfo">
          <Rate disabled defaultValue={1} count={1} />
          {"  "}
          {singlePlaybookDetail?.rating_avg !== null
            ? singlePlaybookDetail?.rating_avg
            : 0}
          {"  "}
          <span className="singlePlaybookDetails__proposalDetails__reviewsInfo__count">
            (
            {singlePlaybookDetail?.rating_avg !== null
              ? parseInt(parseInt(singlePlaybookDetail?.rating_avg) * 1.7)
              : 0}
            K)
          </span>{" "}
        </p>: <p className="singlePlaybookDetails__proposalDetails__reviewsInfo">
          {/* <Rate disabled defaultValue={1} count={1} />
          {"  "}
          {singlePlaybookDetail?.rating_avg !== null
            ? singlePlaybookDetail?.rating_avg
            : 0}
          {"  "} */}
          <span className="singlePlaybookDetails__proposalDetails__reviewsInfo__count">
            {/* (
            {singlePlaybookDetail?.rating_avg !== null
              ? parseInt(parseInt(singlePlaybookDetail?.rating_avg) * 1.7)
              : 0}
            K) */}
          </span>{" "}
        </p>}

        <Divider className="singlePlaybookDetails__divider" />

        <div className="singlePlaybookDetails__P2">
          {data?.isProposal ? (
            <div className="singlePlaybookDetails__P2__Read">
              <img src={PlaybookRequested} /> Requested
            </div>
          ) : (
            <p className="singlePlaybookDetails__P2__Low">
              {/* {isB2B ? <div className="singlePlaybookDetails__P2__Low__Para">STARTING FROM</div> : null}
              {isPrivHome ? <div className="singlePlaybookDetails__P2__Low__Para"></div>: "$" +parseInt(data?.min_price) } */}
              <div className="singlePlaybookDetails__P2__Low__Para"
               onClick={() => onReadMoreClicked(data.id)}
               >Learn More</div>
            </p>
          )}
        </div>
      </Card>
    </div>
  );
}

export default SinglePlaybook;

function CustomAvatarSinglePlaybook({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="singlePlaybookDetails__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="singlePlaybookDetails__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
