import { Client as ConversationsClient } from "@twilio/conversations";
import { trackEventCall, trackPageCall } from "analytics";
import {
  Avatar,
  Button,
  Carousel,
  Divider,
  Empty,
  Image,
  message,
  Rate,
  Spin,
  Typography,
} from "antd";
import { getProposalDetailsById, playbookActions } from "api/Playbooks";
import { getTwilioToken } from "api/TwilioChat";
import { getWorkspaceUsersList } from "api/userManagement";
import CustomAvatar from "commons/CustomAvatar";
import ChatRightNav from "components/ChatRightNav";
import { PLAYBOOK_ACTIONS } from "constants";
import { Close, IconProposalChat, RightNavChatIcon } from "images";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { emitCustomEvent } from "react-custom-events";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addTwilioChatParticipants,
  addTwilioSMSParticipants,
  createTwilioConversation,
  sendMessageFromSeller,
  updateTwilioConvAttributes,
} from "screens/Chat/ChatController";
import ChatUI from "screens/Chat/ChatUI";
import Flex from "screens/Chat/Flex";
import { setExternalUserInfo, setUserSession } from "store/modules/auth/action";
import { isMobile } from "utils";
import {
  ANALYTICS_EVENT_NAMES,
  DEEPLINK_IDENTIFIERS,
} from "../../../constants";
import MobileProposalDetails from "../mPropsalDetails";
import "./index.scss";
import PackageDetails from "./PackageDetails";
import { PlaybookDummyImage } from "images";
import { getMarketplaceMessages } from "api/Workspaces";

export const ProposalDetailPage = () => {
  const [isMobileView, setIsMobileView] = useState(isMobile());

  useEffect(() => {
    function handleResize() {
      setIsMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobileView === true ? (
    <MobileProposalDetails />
  ) : (
    <ProposalDetails />
  );
};

function ProposalDetails() {
  const contentStyle = {
    height: "520px",
    width: "520px",
    color: "#fff",
    textAlign: "center",
    background: "#C0C0C0",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  const { playbookId } = useParams();
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const isB2B = userSession?.selectedWorkspaceName == "Palisade Knowledge";
  const [pbDetails, setPBDetails] = useState({});
  const [spinning, setSpinning] = useState(true);
  const [imagesList, setImagesList] = useState([]);
  const [creatingConversation, setCreatingConversation] = useState(false);
  const navigate = useNavigate();
  const externalUserInfo = authReducer.externalUserInfo;
  const dispatch = useDispatch();
  const [twilioToken, setTwilioToken] = useState(null);
  const [proposalConversation, setProposalConversation] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [appUsers, setAppUsers] = useState({});
  const twilio = useRef(null);
  const [isRighNavChatOpen, setIsRightNavChatOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [selectedPkgId, setSelectedPkgId] = useState(0);
  const [currentChatChannelSid, setCurrentChatChannelSid] = useState(null);
  var selectedPackageIdVar = null;
  const externalLinkSegmentParams = authReducer.externalLinkSegmentParams;
  const [proposalMessagesData, setProposalMessagesData] = useState({});
  var proposalMsgData = null;

  useEffect(() => {
    getMarketplaceMessages(userSession.selectedWorkspaceId)
      .then((res) => {
        setProposalMessagesData(res?.data?.data);
        proposalMsgData = res?.data?.data;
      })
      .catch((err) => {
        console.log("messages api error: ", err);
      });
  }, []);

  useEffect(() => {
    const payloadForUsersList = {
      account_id: "1", //userSession.userId,
    };
    getWorkspaceUsersList(payloadForUsersList)
      .then((res) => {
        const usersMap = {};
        res.data.data.response.forEach((user) => {
          usersMap[user.phone_number] = user;
        });
        setAppUsers(usersMap);
      })
      .catch((error) => {
        message.error(error.response.data);
      });
  }, []);

  useEffect(() => {
    setSpinning(true);

    const payload = {
      playbook_id: playbookId,
      profile_workspace_id: userSession.buyerProfileWorkspaceId,
    };

    getProposalDetailsById(payload)
      .then((res) => {
        // console.log("proposalDetails desktop", res?.data?.data[0]);
        setPBDetails(res?.data?.data[0]);
        selectedPackageIdVar = parseInt(
          res?.data?.data[0]?.selected_package_id
        );
        setSelectedPkgId(parseInt(res?.data?.data[0]?.selected_package_id));
        let localImageList = [];
        if (res?.data?.data[0]?.image1_url) {
          // let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image1_url);
          // setImagesList(localImageList);
        }

        if (res?.data?.data[0]?.image2_url) {
          // let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image2_url);
          // setImagesList(localImageList);
        }
        if (res?.data?.data[0]?.image3_url) {
          // let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image3_url);
          // setImagesList(localImageList);
        }
        if (res?.data?.data[0]?.image4_url) {
          // let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image4_url);
          // setImagesList(localImageList);
        }
        setImagesList(localImageList);

        if (
          externalUserInfo != null &&
          externalUserInfo?.from !== DEEPLINK_IDENTIFIERS.PROPOSAL_CHAT
        ) {
          selectedPackageIdVar = parseInt(externalUserInfo?.packageId);
          setSelectedPkgId(parseInt(externalUserInfo?.packageId));
          // console.log("Before method triggering");
          // processPackageDetails(res?.data?.data[0]);
          requestProposalBeta(externalUserInfo?.packageId, res?.data?.data[0]);
          // dispatch(setExternalUserInfo(null));
        }
        setIsRightNavChatOpen(true);
        // Check if conversation already exists, otherwise create a new one
        checkConversation(res?.data?.data[0]);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setSpinning(false);
      });
  }, [proposalMessagesData]);

  const checkConversation = (proposalDetails) => {
    // if (userSession.twilioToken !== null) {
    //   setTwilioToken(userSession.twilioToken);
    //   initTwilio(userSession.twilioToken, proposalDetails);
    // } else {
    setCreatingConversation(true);
    const payloadForTwilioToken = {
      userId: userSession.phoneNumber + "-" + userSession.userId,
    };

    getTwilioToken(payloadForTwilioToken)
      .then((res) => {
        setTwilioToken(res?.data?.data?.token);
        userSession.twilioToken = res?.data?.data?.token;
        dispatch(setUserSession(userSession));
        initTwilio(res?.data?.data?.token, proposalDetails);
      })
      .catch((error) => {
        message?.error(error?.response?.data);
        setCreatingConversation(false);
      });
    // }
  };

  const initTwilio = async (token, proposalDetails) => {
    if (twilio.current || !userSession?.userId) return;

    if (!token) {
      // auth.signOut(); PeopleComponent should be turned into a functional component so we can use Firebase hooks
      // this.props.router.replace("/login");
      setCreatingConversation(false);
      return;
    }

    try {
      twilio.current = new ConversationsClient(token);
    } catch (err) {
      setCreatingConversation(false);
      if (err?.message?.includes("Access Token expired")) {
        // firebase.auth().signOut();TODO:ref
      }
      return;
    }
    const client = twilio.current;

    client?.on("connectionStateChanged", (state) => {
      if (state === "connecting") {
      }

      if (state === "connected") {
        // console.log("pbDetails", proposalDetails);
      }

      if (state === "disconnecting") {
      }

      if (state === "disconnected") {
      }

      if (state === "denied") {
      }
    });

    //Conversation name
    let convName =
      // proposalDetails?.seller?.first_name +
      // " chat with " +
      // userSession?.firstName +
      // " RE: " +
      proposalDetails?.name;
    // console.log("proposalDetails", proposalDetails);
    const uniqueName =
      convName + "_" + userSession.userId + "-" + proposalDetails?.id;

    client?.on("conversationJoined", (thisConversation) => {
      // console.log(
      //   "proposalDetails desktop conversationJoined",
      //   thisConversation
      // );
      if (!conversations.find((c) => c.sid === thisConversation.sid)) {
        setConversations([...conversations, thisConversation]);
        // console.log("conversation UniqueName = ", uniqueName);
        client
          ?.getConversationByUniqueName(uniqueName)
          .then((conversation) => {
            if (
              conversation?.sid === thisConversation?.sid &&
              conversation?.channelState?.uniqueName === uniqueName
            ) {
              // console.log("conversation", thisConversation);
              setProposalConversation(thisConversation);
              setCurrentChatChannelSid(thisConversation?.sid);
              setCreatingConversation(false);
              // setIsRightNavChatOpen(true);
              if (externalUserInfo != null) {
                dispatch(setExternalUserInfo(null));
                window.history.replaceState({}, document.title);
              }
            }
          })
          .catch((err) => {
            console.log("conversation fetch error", err);
            createProposalChat(proposalDetails);
          });
      }
    });

    client
      ?.getSubscribedConversations()
      .then((res) => {
        // console.log("Subscried conversations", res);
        const lastestConversations = res?.items;
        if (lastestConversations.length === 0) {
          createProposalChat(proposalDetails);
        }
      })
      .catch((err) => {
        setCreatingConversation(false);
        console.log("No subscribed conversation", err);
      });
  };

  useEffect(() => {
    const pageCallData = {
      marketplaceId: userSession.selectedWorkspaceId,
    };
    const mergedJson = {
      ...pageCallData,
      ...externalLinkSegmentParams,
    };
    trackPageCall("Proposal Details", mergedJson);
  }, []);

  const requestProposalBeta = (packageid = null, details) => {
    const playbookDetails = details ? details : pbDetails;
    const payload = {
      profile_workspace_id: userSession.buyerProfileWorkspaceId,
      playbook_id: playbookId,
      action: PLAYBOOK_ACTIONS.REQUEST_PROPOSAL,
      price: "99",
      selected_package_id: packageid,
    };

    const packages = playbookDetails?.packages;
    packages?.forEach((pkg) => {
      if (pkg?.id === parseInt(packageid)) {
        // setCreatingConversation(true);
        const segmentEventData = {
          marketplaceId: playbookDetails?.workspace_id,
          playbookname: playbookDetails?.name,
          sellername:
            playbookDetails?.seller?.first_name +
            " " +
            playbookDetails?.seller?.last_name,
          selleremail: playbookDetails?.seller?.email,
          playbook_id: playbookId,
          revenue: parseInt(pkg?.price),
          currency: "USD",
          planType: pkg?.plan_type,
          planId: pkg?.id,
        };
        const mergedJson = {
          ...segmentEventData,
          ...externalLinkSegmentParams,
        };
        // console.log("segmentEventData", segmentEventData);
        trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED, mergedJson);
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
          mergedJson
        );
        playbookActions(payload)
          .then((res) => {
            trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED, mergedJson);
            trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, mergedJson);
            trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED, mergedJson);
            message.success(res?.data?.message);

            //createProposalChat(playbookDetails);
          })
          .catch((err) => {
            message.error(err.response.data.message);
          })
          .finally(() => {});
      }
    });
    // }
    // dispatch(setExternalUserInfo(null));
  };

  const createProposalChat = (playbookDetails) => {
    // Conversation name
    let convName =
      // playbookDetails?.seller?.first_name +
      // " chat with " +
      // userSession?.firstName +
      // " RE: " +
      playbookDetails?.name;

    var buyerFirstName = userSession.firstName;
    var stringArray = buyerFirstName.split(/(\s+)/);
    buyerFirstName = stringArray.length > 1 ? stringArray[0] : buyerFirstName;

    let uniqueName =
      convName + "_" + userSession.userId + "-" + playbookDetails?.id;
    // Participants list
    const sellerObj = playbookDetails?.seller;
    let sellerUser = {
      email: sellerObj?.email,
      first_name: sellerObj?.first_name,
      last_name: sellerObj?.last_name,
      phone_number: sellerObj?.phone_number,
      id: sellerObj?.seller_id,
      image_uri: sellerObj?.image_uri,
    };
    let currentUser = {
      email: userSession.email,
      first_name: userSession.firstName,
      last_name: userSession.lastName,
      phone_number: userSession.phoneNumber,
      id: userSession.userId,
      image_uri: userSession.imageUri,
    };
    let userArray = [];
    userArray.push(sellerUser);
    userArray.push(currentUser);

    // Default message
    // const proposalLink = `https://${window.location.host}/dashboard/proposalDetails/${playbookDetails?.id}`;
    // const sellerMessage = `Hi ${userSession.firstName}, You have requested a proposal for ${playbookDetails?.name} from ${playbookDetails?.seller?.first_name}.   Here is a link to the playbook details page ${proposalLink}.  ${playbookDetails?.seller?.first_name} will get back to you shortly but please tell us how we can help.   You can request more information, pricing details, a custom proposal or request a call or schedule a meeting.`;

    let originalTest = proposalMsgData?.default_messages?.question[0]; //`Hi ${buyerFirstName}, this is ${playbookDetails?.seller?.first_name} ${playbookDetails?.seller?.last_name}. Thank you for requesting an offer on your home.`; //`Hi ${userSession.firstName}, I can help you with pricing details, a custom proposal, request a call or schedule a meeting.`;
    const msgString = originalTest
      .replace("{buyer_name}", buyerFirstName)
      .replace("{seller_fname}", playbookDetails?.seller?.first_name);
    if (selectedPackageIdVar !== null) {
      const packages = playbookDetails?.packages;
      let planType = "basic";
      packages?.forEach((pkg) => {
        if (pkg?.id === parseInt(selectedPackageIdVar)) {
          planType = pkg?.plan_type;
          selectedPackageIdVar = null;
        }
      });

      // msgString = replacedText; //`Hi ${buyerFirstName}, this is ${playbookDetails?.seller?.first_name} ${playbookDetails?.seller?.last_name}. Thank you for requesting an offer on your home.`; //`Hi ${userSession.firstName}, It looks like you’re interested in ${planType} plan for ${playbookDetails?.name}. Let me know how I can help you.`;
    }

    const sellerMessage = msgString; //`Hi ${userSession.firstName}, I can help you with pricing details, a custom proposal, request a call or schedule a meeting.`;
    // const sellerMessage1 = `Before we get started, we would like to know a few things. Are you a US citizen?`; //`You just requested an offer on your home. I can help you. Can you confirm your address?`; //`I can provide pricing details, a custom proposal, give you a call, or time that works for you.`;

    let response = createTwilioConversation(convName, uniqueName);

    if (response !== null) {
      response
        .then((res) => {
          // console.log("Response", res);

          let channelSid = res?.data?.sid;
          setCurrentChatChannelSid(res?.data?.sid);
          // let groupChatImage = playbookDetails?.seller?.image_uri;
          const attr = {
            // group_image_url: groupChatImage,
            seller_firstname: sellerObj?.first_name,
            seller_lastname: sellerObj?.last_name,
            buyer_firstname: userSession.firstName,
            buyer_lastname: userSession.lastName,
          };
          updateTwilioConvAttributes(channelSid, attr);
          let addParticopantResponse = addTwilioChatParticipants(
            channelSid,
            userArray,
            userSession.selectedWorkspaceId
          );
          if (addParticopantResponse !== null) {
            addParticopantResponse
              .then((res) => {
                if (res === true) {
                  //Add SMS Participants
                  let addSMSParticipantResponse = addTwilioSMSParticipants(
                    channelSid,
                    userArray
                  );
                  if (addSMSParticipantResponse !== null) {
                    addSMSParticipantResponse
                      .then((res) => {
                        if (res === true) {
                          setCreatingConversation(false);
                          // setIsRightNavChatOpen(true);
                          if (externalUserInfo != null) {
                            dispatch(setExternalUserInfo(null));
                            window.history.replaceState({}, document.title);
                          }
                          logSegmentEvents(playbookDetails);
                          const timer = setTimeout(() => {
                            let sendSmsRes = sendMessageFromSeller(
                              channelSid,
                              playbookDetails,
                              sellerMessage
                            );
                            if (sendSmsRes !== null) {
                              sendSmsRes
                                .then((res) => {
                                  // console.log("Send first sms successful", res);
                                  // const timer1 = setTimeout(() => {
                                  //   let sendSmsRes1 = sendMessageFromSeller(
                                  //     channelSid,
                                  //     playbookDetails,
                                  //     sellerMessage1
                                  //   );
                                  //   if (sendSmsRes1 !== null) {
                                  //     sendSmsRes1
                                  //       .then((res) => {
                                  //         // console.log("Send first sms successful", res);
                                  //       })
                                  //       .catch((err) => {
                                  //         setCreatingConversation(false);
                                  //         console.log("Send sms error", err);
                                  //       });
                                  //   }
                                  // }, 2000);
                                  // clearTimeout(timer1);
                                })
                                .catch((err) => {
                                  setCreatingConversation(false);
                                  console.log("Send first sms error", err);
                                });
                            }
                          }, 2000);
                          // clearTimeout(timer);
                          // }
                        }
                      })
                      .catch((err) => {
                        setCreatingConversation(false);
                        console.log("sms errr", err);
                      });
                  }
                }
              })
              .catch((err) => {
                setCreatingConversation(false);
                console.log("errr", err);
              });
          }
        })
        .catch((error) => {
          setCreatingConversation(false);
          if (error?.response?.data?.message) {
            message.error(error?.response?.data?.message);
          } else {
            message.error(error?.message);
          }
        });
    } else {
      setCreatingConversation(false);
    }
  };

  const logSegmentEvents = (playbookDetails) => {
    let sName =
      playbookDetails?.seller?.first_name +
      " " +
      playbookDetails?.seller?.last_name;
    const eventData = {
      sellername: sName,
      selleremail: playbookDetails?.seller?.email,
      sellerphone: playbookDetails?.seller?.phone_number,
      buyername: userSession.firstName + " " + userSession.lastName,
      buyerphone: userSession.phoneNumber,
      buyeremail: userSession.email,
    };
    const segmentEventData = {
      sellername: sName,
      selleremail: playbookDetails?.seller?.email,
      sellerphone: playbookDetails?.seller?.phone_number,
      buyername: userSession.firstName + " " + userSession.lastName,
      buyerphone: userSession.phoneNumber,
      buyeremail: userSession.email,
      price: playbookDetails?.min_price,
    };
    const mergedJson1 = {
      ...eventData,
      ...externalLinkSegmentParams,
    };
    const mergedJson2 = {
      ...segmentEventData,
      ...externalLinkSegmentParams,
    };
    trackEventCall(ANALYTICS_EVENT_NAMES.CHAT_CREATED, mergedJson1);
    trackEventCall(ANALYTICS_EVENT_NAMES.MEETING_SCHEDULED, mergedJson1);
    trackEventCall(ANALYTICS_EVENT_NAMES.REVENUE_GENERATED, mergedJson2);
  };

  const handleChatIconClick = () => {
    setIsRightNavChatOpen(true);
  };

  const handleCloseRightNavChat = () => {
    setIsRightNavChatOpen(false);
  };

  const onPackageSelectionChanged = (pkgId) => {
    let msgString = `Hi ${userSession.firstName}, I can help you with pricing details, a custom proposal, request a call or schedule a meeting.`;
    if (pkgId !== null) {
      const packages = playbookDetails?.packages;
      let planType = "basic";
      packages?.forEach((pkg) => {
        if (pkg?.id === parseInt(pkgId)) {
          planType = pkg?.plan_type;
        }
      });

      msgString = `Hi ${userSession.firstName}, It looks like you’ve updated your package plan to ${planType} for ${playbookDetails?.name}. Let me know how I can help you.`;
    }
    const sellerMessage = msgString;
    let sendSmsRes = sendMessageFromSeller(
      currentChatChannelSid,
      playbookDetails,
      sellerMessage
    );
    if (sendSmsRes !== null) {
      sendSmsRes
        .then((res) => {
          // console.log("Send first sms successful", res);
        })
        .catch((err) => {
          setCreatingConversation(false);
          console.log("Send sms error", err);
        });
    }
  };

  const playbookDetails = pbDetails;

  const onMobileBackClicked = () => {
    emitCustomEvent("hideHeader_footer_forMobileChat", false);
    setIsRightNavChatOpen(false);
  };

  return (
    <>
      {isMobileView === true && isRighNavChatOpen === true ? (
        <div className="proposalDetails">
          {isRighNavChatOpen === true ? (
            <div className="proposalDetails__proposalChatMobileDiv">
              {creatingConversation ? (
                <div className="proposalDetails__rightdivProposalDetailsLoadingChatMobile">
                  <Flex
                    flex={1}
                    justify="center"
                    align="center"
                    style={{ width: "100%", height: 500 }}
                    direction="column"
                  >
                    <Spin size="default" />
                    <div> Loading Conversation...</div>
                  </Flex>
                </div>
              ) : (
                <ChatUI
                  isFromProposal={true}
                  rightNavChat={false}
                  conversation={proposalConversation}
                  ourAppUsers={appUsers}
                  onBackClicked={onMobileBackClicked}
                ></ChatUI>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="proposalDetails">
          <div className="proposalDetails__leftdivProposalDetails">
            {spinning ? (
              <Flex
                flex={1}
                justify="center"
                align="center"
                style={{ width: "100%", height: 500 }}
                direction="column"
              >
                <Spin size="default" />
                <div> Loading...</div>
              </Flex>
            ) : !isEmpty(playbookDetails) ? (
              <div className="proposalDetails__leftdivContainer">
                <div className="proposalDetails__headerDiv">
                  <Typography.Text className="proposalDetails__headerText">
                    Proposal Details
                  </Typography.Text>
                </div>
                <div className="proposalDetails__contentContainer">
                  <div className="proposalDetails__centerAlign proposalDetails__image">
                    <CustomAvatarProposalDetails
                      name={playbookDetails?.seller?.first_name}
                      imageUri={playbookDetails?.seller?.image_uri}
                    />
                  </div>

                  <span className="proposalDetails__centerAlign proposalDetails__playbookTitle">
                    {playbookDetails?.name}
                  </span>
                  <span className="proposalDetails__centerAlign proposalDetails__welcomeText ">
                    Hi,{" "}
                    {" " + userSession.firstName + " " + userSession.lastName}
                    <br />
                    My name is
                    {" " +
                      playbookDetails?.seller?.first_name +
                      " " +
                      playbookDetails?.seller?.last_name}
                    <br />
                    Let me tell you about my team and our proposal.
                  </span>
                  <div className="proposalDetails__centerAlign">
                    <Button
                      className="proposalDetails__positiveBtn"
                      // onClick={requestProposal}
                      loading={creatingConversation}
                      style={{
                        display:
                          playbookDetails?.seller?.seller_id ===
                            userSession.userId ||
                          playbookDetails?.proposal_requested === true
                            ? "none"
                            : "visible",
                      }}
                    >
                      Continue
                    </Button>
                  </div>
                  <div
                    className="proposalDetails__centerAlign"
                    style={{
                      display:
                        playbookDetails?.image1_url === "" &&
                        playbookDetails?.image2_url === "" &&
                        playbookDetails?.image3_url === ""
                          ? "none"
                          : "visible",
                    }}
                  >
                    <Carousel style={{ width: "100%" }} autoplay>
                      {imagesList.map((imageUrl, index) => {
                        return (
                          <div>
                            <Image
                              alt="Image of playbook"
                              style={contentStyle}
                              src={
                                imageUrl !== "" ? imageUrl : PlaybookDummyImage
                              }
                              preview={false}
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>

                  <div className="proposalDetails__belowCarouselContent">
                    <p className="proposalDetails__aboutHeading">Highlights</p>

                    <div style={{ flexDirection: "row" }}>
                      <span style={{ fontSize: 18 }}>{"\u2022"}</span>
                      <span className="proposalDetails__highLights__text">
                        {playbookDetails?.highlight_1}
                      </span>
                    </div>

                    <div style={{ flexDirection: "row" }}>
                      <span style={{ fontSize: 18 }}>{"\u2022"}</span>
                      <span className="proposalDetails__highLights__text">
                        {playbookDetails?.highlight_2}
                      </span>
                    </div>
                    <div style={{ flexDirection: "row" }}>
                      <span style={{ fontSize: 18 }}>{"\u2022"}</span>
                      <span className="proposalDetails__highLights__text">
                        {playbookDetails?.highlight_3}
                      </span>
                    </div>

                    <div style={{ flexDirection: "row" }}>
                      <span style={{ fontSize: 18 }}>{"\u2022"}</span>
                      <span className="proposalDetails__highLights__text">
                        {playbookDetails?.highlight_4}
                      </span>
                    </div>
                    <p className="proposalDetails__aboutHeading">About</p>
                    <p className="proposalDetails__description">
                      {playbookDetails?.description}
                    </p>
                    {!isB2B ? ( <p className="proposalDetails__reviewsInfo">
                      <Rate disabled defaultValue={1} count={1} />{" "}
                      {playbookDetails?.rating_avg !== null
                        ? playbookDetails?.rating_avg
                        : 0}
                      {"  "}
                      <span className="proposalDetails__reviewsInfo__count">
                        (
                        {playbookDetails?.rating_avg !== null
                          ? parseInt(
                              parseInt(playbookDetails?.rating_avg) * 1.7
                            )
                          : 0}
                        K)
                      </span>{" "}
                    </p>): null}
                    <Divider className="proposalDetails__divider" />
                    <div className="proposalDetails__languageCategory">
                      <p className="proposalDetails__languageCategory__heading">
                        Language
                      </p>
                      <p className="proposalDetails__languageCategory__heading">
                        Category
                      </p>
                    </div>

                    <div className="proposalDetails__languageCategory">
                      <p className="proposalDetails__languageCategory__value">
                        {playbookDetails?.language}
                      </p>
                      <p className="proposalDetails__languageCategory__value">
                        {playbookDetails?.playbook_category_name}
                      </p>
                    </div>
                    {/* {isB2B ? (<Spin spinning={creatingConversation}>
                      <p className="proposalDetails__comparePackage">
                        {" "}
                        Compare Package
                      </p>
                      {playbookDetails?.packages && (
                        <PackageDetails
                          packageDetails={playbookDetails?.packages}
                          selectedPackageId={selectedPkgId}
                          proposalId={playbookDetails?.proposal_id}
                          callbackPkgSelectionChanged={
                            onPackageSelectionChanged
                          }
                        />
                      )}
                    </Spin>) : null} */}
                  </div>
                </div>
              </div>
            ) : (
              <Empty />
            )}
            {isMobileView === true && (
              <div className="proposalDetails__iconProposalChatMobile">
                <Image
                  preview={false}
                  src={IconProposalChat}
                  onClick={handleChatIconClick}
                ></Image>
              </div>
            )}
          </div>
          {!isMobileView && (
            <div>
              {isRighNavChatOpen === true ? (
                <div className="proposalDetails__rightdivProposalDetailsParent">
                  <div className="proposalDetails__userImageInfoHeaderDiv">
                    <div className="proposalDetails__userImgRigtChatNav">
                      <CustomAvatar
                        imageUri={pbDetails?.seller?.image_uri}
                        name={pbDetails?.seller?.first_name}
                        preview={false}
                      ></CustomAvatar>
                    </div>

                    <div className="proposalDetails__nameDescDiv">
                      <Typography.Text className="proposalDetails__textSellerName">
                        {pbDetails?.seller?.first_name +
                          " " +
                          pbDetails?.seller?.last_name}
                      </Typography.Text>
                      <Typography.Text className="proposalDetails__textSellerJobDesc">
                        {`${pbDetails?.seller?.first_name}'s position and role in two lines`}
                      </Typography.Text>
                    </div>
                    <Image
                      className="proposalDetails__closeChatBtn"
                      src={Close}
                      preview={false}
                      onClick={handleCloseRightNavChat}
                    ></Image>
                  </div>

                  <div className="proposalDetails__rightdivProposalDetails">
                    {creatingConversation ? (
                      <div className="proposalDetails__rightdivProposalDetailsLoadingChat">
                        <Flex
                          flex={1}
                          justify="center"
                          align="center"
                          style={{ width: "100%", height: 500 }}
                          direction="column"
                        >
                          <Spin size="default" />
                          <div> Loading Conversation...</div>
                        </Flex>
                      </div>
                    ) : (
                      <ChatRightNav
                        currentConversation={proposalConversation}
                        ourAppUsers={appUsers}
                        proposalDetailsObj={pbDetails}
                        chatQuestionOptions={
                          proposalMessagesData?.default_messages?.options
                        }
                        // closeRightNavChatClicked={handleCloseRightNavChat}
                      ></ChatRightNav>
                    )}
                  </div>
                </div>
              ) : (
                <div className="proposalDetails__rightdivProposalDetailsClosed">
                  <Image
                    preview={false}
                    src={RightNavChatIcon}
                    className="proposalDetails__rightNavChatIcon"
                    onClick={handleChatIconClick}
                  ></Image>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default ProposalDetailPage;

function CustomAvatarProposalDetails({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="playbookDetails__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="playbookDetails__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
