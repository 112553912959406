import { Avatar } from "antd";
import { isEmpty } from "lodash";
import { useState } from "react";
import { isMobile } from "utils";
import "./index.scss";

function CustomAvatar({ name, imageUri }) {
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className={
          isMobileView === true
            ? "customAvatar__avatarMobile"
            : "customAvatar__avatar"
        }
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className={
          isMobileView === true
            ? "customAvatar__avatarMobile"
            : "customAvatar__avatar"
        }
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}

export default CustomAvatar;
