import { Form, Image, Select } from "antd";
import IconDownArrow from "../../../../../../images/filter-chevron-dropdown.svg";
import IconLink from "../../../../../../images/link.svg";
import IconId from "../../../../../../images/revcloud-id.svg";
import IconSize from "../../../../../../images/size.svg";
import IconCountry from "../../../../../../images/country.svg";
import IconAddress from "../../../../../../images/address.svg";
import IconEmail from "../../../../../../images/email.svg";
import IconPhone from "../../../../../../images/phone.svg";
import IconState from "../../../../../../images/state.svg";
import IconCity from "../../../../../../images/city.svg";
import IconZip from "../../../../../../images/zip.svg";

import "./index.scss";

export const FILTER_DROPDOWN_SUFFIX = {
  DOWN_ARROW: IconDownArrow,
  LINK: IconLink,
  ID: IconId,
  SIZE: IconSize,
  COUNTRY: IconCountry,
  ADDRESS: IconAddress,
  EMAIL: IconEmail,
  PHONE: IconPhone,
  STATE: IconState,
  CITY: IconCity,
  ZIP: IconZip,
};

function FilterDropdown({
  placeholder = "",
  name = "",
  selectMode = "multiple",
  suffixIcon = FILTER_DROPDOWN_SUFFIX.DOWN_ARROW,
  options = [],
  onChange,
}) {
  return (
    <Form.Item name={name} className="filterDropdown">
      <Select
        showSearch
        showArrow
        placeholder={placeholder}
        optionFilterProp="children"
        mode={selectMode}
        maxTagCount={1}
        maxTagTextLength={14}
        suffixIcon={<Image src={suffixIcon} preview={false} />}
        getPopupContainer={(trigger) => trigger.parentNode}
        listHeight={140}
        onChange={onChange}
      >
        {options.map((option, index) => {
          return (
            <Select.Option key={index} value={option?.name || option}>
              {option?.name || option}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}
export default FilterDropdown;
