import "./index.scss";
import { Col, Row } from "antd";
import { Image, Avatar } from "antd";
import { PlaybookRequested } from "images";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Rate, Divider } from "antd";
import { isEmpty } from "lodash";
import { PlaybookDummyImage } from "images";

function MobilePreviewCard({ singlePlaybookDetail }) {
  let data = singlePlaybookDetail;
  const navigate = useNavigate();

  return (
    <div className="MobilePreviewCard">
      <div className="MobilePreviewCard__Row" hoverable>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="MobilePreviewCard__Col" span={9}>
            <Image
              className="MobilePreviewCard__IMG1"
              preview={false}
              alt="Image of playbook"
              src={PlaybookDummyImage}
            />
          </Col>
          <Col className="MobilePreviewCard__Col" span={15}>
            <h3 className="MobilePreviewCard__H1">Cash Offer For Your Home</h3>
            <p className="MobilePreviewCard__P1">
              We offer to buy your home and we want to give you a price that
              you'll be happy with.
            </p>

            <p className="MobilePreviewCard__proposalDetails__reviewsInfo">
              <Rate disabled defaultValue={1} count={1} />
              <span className="MobilePreviewCard__proposalDetails__reviewsInfo__count">
                23
              </span>
            </p>
          </Col>
        </Row>

        <Row
          className="MobilePreviewCard__Row-2"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={14}>
            <div className="MobilePreviewCard__appHomeButton">
              <CustomAvatarSinglePlaybook />
              <div className="MobilePreviewCard__appHomeHeadContainer">
                <div className="MobilePreviewCard__appHomeMainHead">
                  Kimbell Belmont
                </div>
                <div className="MobilePreviewCard__appHomeSubHead">
                  Real Estate CEO
                </div>
              </div>
            </div>
          </Col>

          <Col span={10}>
            <div className="MobilePreviewCard__P2">
              {data?.isProposal ? (
                <p className="MobilePreviewCard__P2__Read">
                  <img src={PlaybookRequested} style={{ marginRight: "4px" }} />{" "}
                  Requested
                </p>
              ) : (
                <p className="MobilePreviewCard__P2__Low">
                  <p className="MobilePreviewCard__P2__Low__As">AS LOW AS</p>
                  <div className="MobilePreviewCard__P2__Low__Price">$300</div>
                </p>
              )}
            </div>
          </Col>
        </Row>

        <Divider className="MobilePreviewCard__divider" />
      </div>
    </div>
  );
}

export default MobilePreviewCard;

function CustomAvatarSinglePlaybook({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="MobilePreviewCard__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="MobilePreviewCard__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
