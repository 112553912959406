import React, { useState } from "react";
import { Image, Spin, Table } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import {
  IconPeopleOV,
  IconPlacesOV,
  IconCompaniesOV,
  IconEventsOV,
  IconIdentitesGeneratedPast,
  IconSourcesGeneratedPast,
  IconDataOnboardedPast,
} from "images";
import { setExternalUserInfo, setUserSession } from "store/modules/auth/action";
import { getUserWorkspaceByProfileIds, joinWorkspace } from "api/Workspaces";
import { getPast30DaysRecords, getExplorerIdentitiesCount } from "api/Datasets";

function Overview() {
  useEffect(() => {});

  const dispatch = useDispatch();
  const [connectionsLoading, setConnectionsLoading] = useState(false);
  const [csvLoading, setCsvLoading] = useState(false);
  const [connectionDetails, setConnectionDetails] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const userSession = useSelector((state) => state.authReducer.userSession);
  const authReducer = useSelector((state) => state.authReducer);
  const domainAndUserInfo = authReducer.domainAndUserInfo;
  const externalUserInfo = authReducer.externalUserInfo;
  const externalInviteInfo = authReducer.externalInviteInfo;

  const OWNER = "owner";
  const FOUNDER = "founder";

  useEffect(() => {
    fetchConnectionDetails();
    fetchCsvData();
    callGetUserWorkspaceByProfileIdsAPI();
  }, []);

  useEffect(() => {
    callUpdateUserWorkspaceByProfileIdsAPI();
  }, [userSession]);

  const callJoinWorkspaceAPI = (marketplaceId) => {
    if (marketplaceId !== undefined) {
      const payload = {
        workspace_id: marketplaceId,
        buyer_profile_id: userSession.buyerProfileId,
        seller_profile_id: userSession.sellerProfileId,
      };
      joinWorkspace(payload)
        .then((res) => {
          let updatedUserSession = { ...userSession };
          updatedUserSession.selectedWorkspaceId = parseInt(marketplaceId);
          updatedUserSession.buyerProfileWorkspaceId =
            res?.data?.data?.buyer_profile_workspace_id;
          updatedUserSession.sellerProfileWorkspaceId =
            res?.data?.data?.seller_profile_workspace_id;
          updatedUserSession.isDefaultWorkspace = false;
          updatedUserSession.workspaceCategoryId =
            res?.data?.data?.workspace_info?.workspace_category_id;
          userSession.selectedWorkspaceName =
            res?.data?.data?.workspace_info?.name;
          dispatch(setUserSession(updatedUserSession));
        })
        .catch((err) => {});
    }
  };

  const callGetUserWorkspaceByProfileIdsAPI = () => {
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let externalWorkspaceId = domainAndUserInfo?.marketplaceId;
        if (externalInviteInfo != null) {
          externalWorkspaceId = externalInviteInfo.marketplaceId;
        } else if (externalUserInfo != null) {
          externalWorkspaceId = externalUserInfo.marketplaceId;
        }

        let marketplacesList = res?.data?.data;
        // setMyWorkplaces(marketplacesList);

        if (marketplacesList != null && marketplacesList.length > 0) {
          let defaultMarketplaceId = domainAndUserInfo?.marketplaceId;

          if (parseInt(userSession.selectedWorkspaceId) > 0) {
            defaultMarketplaceId = userSession.selectedWorkspaceId;
          }
          let isMarketPlacePresent = false;
          for (let i = 0; i < marketplacesList.length; i++) {
            let el = marketplacesList[i];
            if (
              el.workspace_id != null &&
              el.workspace_id === parseInt(externalWorkspaceId)
            ) {
              userSession.isDataMenuAvialable =
                el?.key === "B2B" ? true : false;
              userSession.selectedWorkspaceImagUrl = el.image_uri;
              userSession.selectedWorkspaceName = el.name;
              userSession.workspaceCategoryId = el.workspace_category_id;
              userSession.selectedWorkspaceId = el.workspace_id;
              isMarketPlacePresent = true;
              if (el?.workspace_profiles.length > 1) {
                el?.workspace_profiles.forEach((workspaceProfile) => {
                  if (
                    workspaceProfile.workspace_role === OWNER ||
                    workspaceProfile.workspace_role === FOUNDER
                  ) {
                    userSession.isMarketPlaceOwner = true;
                  } else {
                    userSession.isMarketPlaceOwner = false;
                  }
                  if (workspaceProfile.account_role === "buyer") {
                    userSession.buyerProfileWorkspaceId =
                      workspaceProfile.profile_workspace_id;
                  } else {
                    userSession.sellerProfileWorkspaceId =
                      workspaceProfile.profile_workspace_id;
                  }
                });
              }
              break;
            } else {
              isMarketPlacePresent = false;
            }
          }
          dispatch(setUserSession(userSession));

          if (
            !isMarketPlacePresent &&
            externalWorkspaceId !== 1 &&
            (externalUserInfo?.playbookId === null ||
              externalUserInfo?.playbookId === undefined)
          ) {
            callJoinWorkspaceAPI(externalWorkspaceId);
            if (externalUserInfo != null) {
              dispatch(setExternalUserInfo(null));
            }
          }
        } else {
          if (
            externalWorkspaceId > 0 &&
            (externalUserInfo?.playbookId === null ||
              externalUserInfo?.playbookId === undefined)
          ) {
            callJoinWorkspaceAPI(externalWorkspaceId);
            dispatch(setExternalUserInfo(null));
          }
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const callUpdateUserWorkspaceByProfileIdsAPI = () => {
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let marketplacesList = res?.data?.data;
        // setMyWorkplaces(marketplacesList);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const fetchConnectionDetails = async () => {
    setConnectionsLoading(true);
    try {
      const res = await getExplorerIdentitiesCount();

      const { totalPeople, totalPlace, totalCompany, totalEvents } =
        res.data.data;
      setConnectionDetails([
        {
          people: formatNumber(Number(totalPeople)),
          places: formatNumber(Number(totalPlace)),
          companies: formatNumber(Number(totalCompany)),
          events: formatNumber(Number(totalEvents)),
        },
      ]);
    } catch (error) {
      console.error("Failed to fetch connection details:", error);
    } finally {
      setConnectionsLoading(false);
    }
  };

  const fetchCsvData = async () => {
    setCsvLoading(true);
    try {
      const res = await getPast30DaysRecords();
      const {
        dataOnboardedPast30Days,
        identitiesGeneratedPast30Days,
        sourcesOnboardedPast30Days,
      } = res.data.data;
      setCsvData([
        {
          key: "1",
          data_onboarded: formatNumber(Number(dataOnboardedPast30Days)),
          identites_generated: formatNumber(
            Number(identitiesGeneratedPast30Days)
          ),
          source_generated: formatNumber(Number(sourcesOnboardedPast30Days)),
        },
      ]);
    } catch (error) {
      console.error("Failed to fetch CSV data:", error);
    } finally {
      setCsvLoading(false);
    }
  };

  const connectionColumns = [
    {
      title: "PEOPLE",
      dataIndex: "people",
      key: "people",
      render: (text) => (
        <div className="overview__icon-row">
          <Image
            src={IconPeopleOV}
            className="overview__icon"
            preview={false}
          />
          <span>{text || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "PLACES",
      dataIndex: "places",
      key: "places",
      render: (text) => (
        <div className="overview__icon-row">
          <Image
            src={IconPlacesOV}
            className="overview__icon"
            preview={false}
          />
          <span>{text || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "COMPANIES",
      dataIndex: "companies",
      key: "companies",
      render: (text) => (
        <div className="overview__icon-row">
          <Image
            src={IconCompaniesOV}
            className="overview__icon"
            preview={false}
          />
          <span>{text || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "EVENTS",
      dataIndex: "events",
      key: "events",
      render: (text) => (
        <div className="overview__icon-row">
          <Image
            src={IconEventsOV}
            className="overview__icon"
            preview={false}
          />
          <span>{text || "N/A"}</span>
        </div>
      ),
    },
  ];

  const dataColumns = [
    {
      title: "DATA ONBOARDED (PAST 30 DAYS)",
      dataIndex: "data_onboarded",
      key: "data_onboarded",
      render: (text) => (
        <div className="overview__icon-row">
          <Image
            src={IconDataOnboardedPast}
            className="overview__icon"
            preview={false}
          />
          <span>{text || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "IDENTITIES GENERATED (PAST 30 DAYS)",
      dataIndex: "identites_generated",
      key: "identites_generated",
      render: (text) => (
        <div className="overview__icon-row">
          <Image
            src={IconIdentitesGeneratedPast}
            className="overview__icon"
            preview={false}
          />
          <span>{text || "N/A"}</span>
        </div>
      ),
    },
    {
      title: "SOURCES GENERATED (PAST 30 DAYS)",
      dataIndex: "source_generated",
      key: "source_generated",
      render: (text) => (
        <div className="overview__icon-row">
          <Image
            src={IconSourcesGeneratedPast}
            className="overview__icon"
            preview={false}
          />
          <span>{text}</span>
        </div>
      ),
    },
  ];

  return (
    <div className="overview">
      <>
        <Table
          className="overview__table overview__table--connections"
          columns={connectionColumns}
          dataSource={connectionDetails}
          pagination={false}
          loading={connectionsLoading}
        />
        <Table
          className="overview__table"
          columns={dataColumns}
          dataSource={csvData}
          pagination={false}
          loading={csvLoading}
        />
      </>
    </div>
  );
}

const formatNumber = (value) => {
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(1)}B`; // Billions
  } else if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(1)}M`; // Millions
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(1)}K`; // Thousands
  } else if (value < 1_000) {
    return value;
  }
  return value.toString(); // Less than 1,000
};

export default Overview;
