import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  Card,
  Collapse,
  Drawer,
  Image,
  Button,
  notification,
  Menu,
  Spin,
} from "antd";
import { MarkedGreen, MarkDoneEmpty } from "images"; // Assuming you have a green tick image
import SelectEntityType from "../../SelectEntityType";
import AlysonGreetingCard from "../../Components/AlysonGreetingCard";
import { useNavigate } from "react-router-dom";
import {
  HistoryOutlined,
  DownOutlined,
  UpOutlined,
  FileSyncOutlined,
  MessageOutlined,
  RocketOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import DesktopBuildList from "../../DesktopBuildList";
import DesktopAddExclusive from "../../DesktopAddExclusive";
import DesktopPasteQuery from "../../DesktopBuildList/DesktopPasteQuery";
import DesktopPreviewYourList from "../../DesktopPreviewYourList";
import DesktopDatasetCreation from "../../DesktopDatasetCreation";
import { testQuery } from "api/Datasets";
import DesktopTransformData from "../../DesktopTransformData";
const { Panel } = Collapse;

const DesktopSteps = ({
  sources,
  entities,
  handleBuildQueryValue,
  handleSourceSelectedValue,
  handleExclusiveQueryValue,
  handleExclusiveSelectedValue,
  handleEntityValue,
  handleTransformQueryValue,
  handleTransformSelectedDestination,
  handleCreateDataSet,
  handleDestinationValue,
  destinations,
  entityLoader,
  sourcesLoader,
  destinationsLoader,
  previewLink,
  tempId,
  listId,
  entityValue,
  profileWorkspaceId,
}) => {
  const navigate = useNavigate();
  const [activePanel, setActivePanel] = useState("1");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [showAll, setShowAll] = useState(true);
  const [showGreetingCard, setShowGreetingCard] = useState(false);
  const [listSelection, setListSelection] = useState(false);
  const [exclusiveSelection, setExclusiveSelection] = useState(false);
  const [transformSelection, setTransformSelection] = useState(false);
  const [datasetSelection, setDatasetSelection] = useState(false);
  const [previewSelection, setPreviewSelection] = useState(false);
  const [current, setCurrent] = useState("dataset");
  const [selectedEntity, setSelectedEntity] = useState(false);

  const MenuItem = [
    // {
    //   label: "Archived",
    //   key: "archived",
    //   icon: <FileSyncOutlined />,
    //   disabled: true,
    // },
    // {
    //   label: "Messages & Notifications",
    //   key: "messages",
    //   icon: <MessageOutlined />,
    //   disabled: true,
    // },
    {
      label: "Create New Audience",
      key: "dataset",
      icon: <RocketOutlined style={{ fontSize: "17px" }} />,
    },
    // {
    //   label: "New Project",
    //   key: "SubMenu",
    //   icon: <PlusOutlined />,
    //   disabled: true,
    // },
  ];

  const onClick = (e) => {
    console.log("click ", e);
    setCurrent(e.key);
  };

  useEffect(() => {
    const isGreetingDismissed = localStorage.getItem("greetingDismissed");
    if (!isGreetingDismissed) {
      setShowGreetingCard(true);
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem("greetingDismissed", "true");
    setShowGreetingCard(false);
  };

  const handleListSelection = (val) => {
    setListSelection(val);
  };

  const handleExclusiveSelection = (val) => {
    setExclusiveSelection(val);
  };

  const handleTransformSelection = (val) => {
    setTransformSelection(val);
  };

  const handleDatasetSelection = (val) => {
    setDatasetSelection(val);
  };
  const handlePreviewSelection = (val) => {
    setPreviewSelection(val);
  };

  useEffect(() => {
    if (datasetSelection) {
      setActivePanel(null);
      notification.success({
        message: "Audience Creation",
        description: "Finalizing the audience. Please wait.",
        placement: "topRight",
        duration: 2,
      });
    }
  }, [datasetSelection]);

  useEffect(() => {
    if (previewSelection) {
      setActivePanel("7");
      notification.success({
        message: "Link Ready",
        description: "View your Google Sheets link.",
        placement: "topRight",
        duration: 2,
      });
    }
  }, [previewSelection]);

  const handleAll = () => {
    setShowAll(!showAll);
  };

  const handleCardClick = () => {
    setDrawerVisible(true);
  };

  const handleStepTwo = () => {
    setActivePanel("3");
    // if (listSelection) {
    // setActivePanel("3");
    notification.success({
      message: "List Building Complete",
      description: "Moving to Step 3.",
      placement: "topRight",
      duration: 2,
    });
    // }
  };

  const handleStepThree = () => {
    setActivePanel("5");
    // if (exclusiveSelection) {
    // setActivePanel("5");
    notification.success({
      message: "Exclusion Complete",
      description: "Moving to Step 5.",
      placement: "topRight",
      duration: 2,
    });
    // }
  };

  const handleStepFive = () => {
    setActivePanel("6");
    // if (transformSelection) {
    //   setActivePanel("6");
    notification.success({
      message: "Transformation Complete",
      description: "Moving to Step 6.",
      placement: "topRight",
      duration: 2,
    });
    // }
  };

  const handlePanelChange = (panelKey) => {
    console.log("panel", panelKey);
    if (panelKey === "6" && !transformSelection) {
      notification.warning({
        message: "Complete Step 5",
        description: "Finish Step 5 before proceeding to Step 6.",
        placement: "topRight",
        duration: 2,
      });
      return;
    }

    if (
      panelKey === "7" &&
      !(listSelection && transformSelection && selectedEntity)
    ) {
      notification.warning({
        message: "Complete All Tasks",
        description: "Finish all tasks before moving to Step 7.",
        placement: "topRight",
        duration: 2,
      });
      return;
    }

    if (panelKey === "5" && !(listSelection && selectedEntity)) {
      notification.warning({
        message: "Complete Previous Steps",
        description: "Ensure Steps 1 and 2 are done before Step 5.",
        placement: "topRight",
        duration: 2,
      });
      return;
    }

    if (panelKey === "3" && !listSelection) {
      notification.warning({
        message: "Complete Step 2",
        description: "Finish Step 2 before moving to Step 3.",
        placement: "topRight",
        duration: 2,
      });
      return;
    }

    if (panelKey === "2" && !selectedEntity) {
      notification.warning({
        message: "Complete Step 1",
        description: "Finish Step 1 before moving to Step 2.",
        placement: "topRight",
        duration: 2,
      });
      return;
    }

    setActivePanel(activePanel === panelKey ? null : panelKey);
  };

  const handleSelectEntity = (entity) => {
    handleEntityValue(entity);
    setSelectedEntity(entity);
    setActivePanel("2");
    notification.success({
      message: `Entity Selected.`,
      description: "Moving to Step 2",
      placement: "topRight",
      duration: 2,
    });
  };

  const CustomPanelHeader = ({
    number,
    mainHeading,
    subHeading,
    panelKey,
    isActive,
    isSelected,
    profileWorkspaceId,
  }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: isSelected
          ? "#51b150"
          : isActive
          ? "#394399"
          : "#FFFFFF",
        padding: "8px",
        borderRadius: "16px",
        boxShadow:
          activePanel === panelKey ? "none" : "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          backgroundColor: isSelected
            ? "green"
            : isActive
            ? "#3A6793"
            : "#3872CD",
          color: "#FFFFFF",
          marginRight: "8px",
        }}
      >
        {number}
      </div>
      <div>
        <div
          style={{
            fontSize: "15px",
            color: isSelected || isActive ? "#FFFFFF" : "#394399",
            fontWeight: "bold",
          }}
        >
          {mainHeading}
        </div>
        <div
          style={{
            fontSize: "13px",
            color: isSelected || isActive ? "#FFFFFF" : "#394399",
          }}
        >
          {subHeading}
        </div>
      </div>
    </div>
  );

  const CustomCardContent = ({
    mainHeading = "",
    subHeading = "",
    onClick,
    showButtons = false,
  }) => (
    <Card
      style={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
        padding: "16px", // Increase padding for larger content area
        marginTop: "16px",
        width: "90%", // Adjust width as needed
        marginLeft: "auto",
        marginRight: "auto",
      }}
      onClick={onClick}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "2px" }}
      >
        {/* <Image
          src={selectedEntity !== null && activePanel == 1  ? MarkedGreen : MarkDoneEmpty}
          preview={false}
          style={{ marginRight: "8px", width: "20px", height: "20px" }}
        /> */}
        <p
          style={{
            flex: 1,
            fontFamily: "Lato",
            fontWeight: "bold",
            fontSize: "11.4pt",
            textAlign: "left",
            margin: 0,
            color: "#000000",
          }}
        >
          {mainHeading}
        </p>
      </div>
      <div style={{ marginTop: "4px", marginLeft: "0px" }}>
        <p
          style={{
            fontFamily: "Lato",
            fontWeight: "normal",
            fontSize: "10.4pt",
            textAlign: "left",
            margin: 0,
            color: "#000000",
          }}
        >
          {subHeading}
        </p>
        <>
          {showButtons && (
            <div className="custom-card-buttons">
              {entityLoader ? (
                <div className="center-spin-container">
                  <Spin />
                </div>
              ) : Object.keys(entities).length === 0 ? (
                <div className="no-data-message">
                  <p>
                    No entities found. You need to onboard the data first. Only
                    onboarded entities will be displayed here.
                  </p>
                </div>
              ) : (
                Object.keys(entities).map((key) => (
                  <Button
                    key={key}
                    className="custom-button"
                    style={{
                      backgroundColor:
                        selectedEntity === key ? "#0000003f" : "#f2f2f2",
                      color: "#000000",
                      margin: "4px",
                    }}
                    onClick={() => handleSelectEntity(key)}
                  >
                    {key}
                  </Button>
                ))
              )}
            </div>
          )}
        </>
      </div>
    </Card>
  );

  return (
    <div className="desktop_steps">
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={MenuItem}
        className="custom-menu"
      />
      {/* {showGreetingCard &&  <div className={`AlysonGreetingCard ${showGreetingCard ? 'show' : ''}`}>
          <AlysonGreetingCard onDismiss={handleDismiss} />
        </div>} */}

      <div className="desktop_steps__scrollable">
        {/* <Button
          icon={<HistoryOutlined />}
          className="desktop_steps__allBtn"
          onClick={handleAll}
        >
          All
          {showAll ? <DownOutlined /> : <UpOutlined />}
        </Button> */}
        {showAll ? (
          <Collapse
            accordion
            expandIconPosition="end"
            onChange={handlePanelChange}
            activeKey={activePanel} // Set active key to control which panel is open
            className="desktop_steps"
          >
            <Panel
              header={
                <CustomPanelHeader
                  number={1}
                  mainHeading="Select Your Entity Type"
                  subHeading="1 task"
                  panelKey="1"
                  isActive={activePanel === "1"}
                  isSelected={selectedEntity} // Highlight if an entity is selected
                />
              }
              key="1"
            >
              <CustomCardContent
                mainHeading="Set your entity type"
                subHeading="Entities in audience: People, Places, Events or Companies"
                onClick={handleCardClick}
                showButtons={true} // Show buttons for step 1
              />
            </Panel>
            <Panel
              header={
                <CustomPanelHeader
                  number={2}
                  mainHeading="Build Your List"
                  subHeading="Multiple tasks"
                  panelKey="2"
                  isActive={activePanel === "2"}
                  isSelected={listSelection}
                />
              }
              key="2"
            >
              <CustomCardContent
                mainHeading="Who should be included in the list?"
                subHeading="Define your list"
                // onClick={handleBuildListClicked}
              />
              <DesktopBuildList
                handleListSelection={handleListSelection}
                sources={sources}
                handleBuildQueryValue={handleBuildQueryValue}
                handleSourceSelectedValue={handleSourceSelectedValue}
                sourcesLoader={sourcesLoader}
                handleStepTwo={handleStepTwo}
                entityValue={entityValue}
                profileWorkspaceId={profileWorkspaceId}
              />
            </Panel>
            <Panel
              header={
                <CustomPanelHeader
                  number={3}
                  mainHeading="Add Exclusions"
                  subHeading="Multiple tasks"
                  panelKey="3"
                  isActive={activePanel === "3"}
                  isSelected={exclusiveSelection}
                />
              }
              key="3"
            >
              <CustomCardContent
                mainHeading="Build your list of People"
                subHeading="Define your exclusion segments"
              />
              <DesktopAddExclusive
                handleExclusiveSelection={handleExclusiveSelection}
                sources={sources}
                handleExclusiveQueryValue={handleExclusiveQueryValue}
                handleExclusiveSelectedValue={handleExclusiveSelectedValue}
                sourcesLoader={sourcesLoader}
                handleStepThree={handleStepThree}
                entityValue={entityValue}
                profileWorkspaceId={profileWorkspaceId}
              />
            </Panel>
            <Panel
              header={
                <CustomPanelHeader
                  number={4}
                  mainHeading="Join Columns"
                  subHeading="Optional"
                  panelKey="4"
                  isActive={activePanel === "4"}
                />
              }
              key="4"
              collapsible="disabled"
            >
              <CustomCardContent
                mainHeading="Select the source"
                subHeading="Match source with list of fields to your audience fields"
              />
            </Panel>
            <Panel
              header={
                <CustomPanelHeader
                  number={5}
                  mainHeading="Transform Your Data"
                  subHeading="Multiple tasks"
                  panelKey="5"
                  isActive={activePanel === "5"}
                  isSelected={transformSelection}
                />
              }
              key="5"
            >
              <CustomCardContent
                mainHeading="Paste SQL to transform data"
                subHeading="Add the SQL traits"
              />
              <DesktopTransformData
                handleTransformSelection={handleTransformSelection}
                sources={sources}
                handleTransformQueryValue={handleTransformQueryValue}
                handleTransformSelectedDestination={
                  handleTransformSelectedDestination
                }
                sourcesLoader={sourcesLoader}
                handleStepFive={handleStepFive}
                entityValue={entityValue}
                profileWorkspaceId={profileWorkspaceId}
              />
            </Panel>
            <Panel
              header={
                <CustomPanelHeader
                  number={6}
                  mainHeading="Preview Your List"
                  subHeading="Optional"
                  panelKey="6"
                  isActive={activePanel === "6"}
                  isSelected={previewSelection}
                />
              }
              key="6"
            >
              <CustomCardContent
                mainHeading="Preview your list in a Google sheet"
                subHeading="View and approve list"
              />
              <DesktopPreviewYourList
                previewData={previewLink}
                tempId={tempId}
                listId={listId}
                handlePreviewSelection={handlePreviewSelection}
              />
            </Panel>
            <Panel
              header={
                <CustomPanelHeader
                  number={7}
                  mainHeading="Select Your Destinations"
                  subHeading="Multiple tasks"
                  panelKey="7"
                  isActive={activePanel === "7"}
                  isSelected={datasetSelection}
                />
              }
              key="7"
            >
              <CustomCardContent mainHeading="Choose destination & finalize audience" />
              <DesktopDatasetCreation
                handleCreateDataSet={handleCreateDataSet}
                handleDestinationValue={handleDestinationValue}
                destinations={destinations}
                handleDatasetSelection={handleDatasetSelection}
                destinationsLoader={destinationsLoader}
              />
            </Panel>
          </Collapse>
        ) : null}
      </div>
      {/* <Drawer
        placement="bottom"
        closable={true}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        height="50%"
        className="custom-drawer"
      >
        <SelectEntityType />
      </Drawer> */}
    </div>
  );
};

export default DesktopSteps;
