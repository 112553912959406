import API from "..";

export const createWorkspace = (payload) => {
  return API.post("/workspace/createWorkspace", payload);
};

export const getAllApplicationWorkspaces = (payload) => {
  return API.get("/workspace/getAllApplicationWorkspaces", payload);
};

export const getAllMyAllWorkspaces = () => {
  return API.get("/workspace/getCreatorWorkspaces");
};

export const getUserWorkspaceByProfileIds = (payload) => {
  return API.get("/workspace/getUserWorkspacesByProfiles", payload);
};


export const editWorkspaceNav = (payload) => {
  return API.put("/workspace/editWorkspaceNav", payload);
};

export const joinWorkspace = (payload) => {
  return API.post("/workspace/joinWorkspace", payload);
};

export const getWorkspaceCategories = (payload) => {
  return API.get("/workspace/getWorkspaceCategories", payload);
};

export const getMarketplaceDetailsById = (marketplaceId) => {
  return API.get(`/workspace/getWorkspaceById/${marketplaceId}`);
};

export const editWorkspace = (payload, marketplaceId) => {
  return API.put(`/workspace/${marketplaceId}`, payload);
};

export const getPreSignedUrlForWorkspaceImage = (payload) => {
  return API.get("/workspace/getPreSignedUrlForWorkspaceImage", payload);
};

export const getAllMarketplaces = (payload) => {
  return API.get("/workspace/external/getMarketplacesInfo", payload);
};

export const getMarketplaceMessages = (marketplaceId) => {
  return API.get(`/workspace/getMarketplaceMessages/${marketplaceId}`);
};
