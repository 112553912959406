import { Divider } from "antd";
import { AppHomeButton } from "components/AppLeftNav/HomeButton";
import { MenuList } from "components/AppLeftNav/MenuList";
import "./index.scss";

function AppLeftNavMobile() {
  return (
    <div className="appLeftNavMobile">
      <AppHomeButton />
      {/* <Divider className="appLeftNavMobile__dividerBorder" /> */}
      <MenuList></MenuList>
    </div>
  );
}

export default AppLeftNavMobile;
