import { useState, useEffect } from "react";
import { Collapse, Card, Drawer, Image, notification, Button } from "antd";
import DesktopSelectSource from "./DesktopSelectSource";
import DesktopPasteQuery from "./DesktopPasteQuery";
import { MarkDoneEmpty, MarkedGreen } from "images";
import DesktopCustomSyncRecurrence from "./DesktopCustomSyncRecurrence";
import { testQuery } from "api/Datasets";

const { Panel } = Collapse;

function DesktopAddExclusive({
  handleExclusiveSelection,
  sources,
  handleExclusiveQueryValue,
  handleExclusiveSelectedValue,
  sourcesLoader,
  handleStepThree,
  entityValue,
  profileWorkspaceId
}) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [sourceSelected, setSourceSelected] = useState(false);
  const [loader, setLoader] = useState(false);
  const [timeSelected, setTimeSelected] = useState(false);
  const [querySelected, setQuerySelected] = useState(false);
  const [queryValue, setQueryValue] = useState("");
  const [sourceValue, setSourceValue] = useState("");
  const [activeKey, setActiveKey] = useState([""]);
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    const allSelectedNow = sourceSelected && querySelected;
    setAllSelected(allSelectedNow);
    handleExclusiveSelection(allSelectedNow);
  }, [sourceSelected, querySelected]);

  const handleSourceSelection = (val) => {
    setSourceSelected(val);
    if (val) {
      setActiveKey((prevKeys) =>
        prevKeys.filter((key) => key !== "1").concat("2")
      );
    }
  };

  const handleTestQuery = async () => {
    if (!sourceSelected) {
      notification.warning({
        message: "Source Selection Required",
        description: "Please select a source before running the query.",
        placement: "topRight",
      });
      return;
    }

    notification.info({
      message: "Query Validation",
      description: "Your query is being validated. Please hold on.",
      placement: "topRight",
      duration: 2, // Adjust duration as needed
    });

    setLoader(true);
    try {
      const response = await testQuery({
        query: queryValue,
        entityType: sourceValue,
        eventType: entityValue,
        profileWorkspaceId: profileWorkspaceId
      });
      setLoader(false);
      setQuerySelected(true);
      notification.success({
        message: "Query Validated",
        description: "Your query has been successfully validated.",
        placement: "topRight",
      });
      handleExclusiveQueryValue(queryValue);
      handleStepThree();
      setActiveKey(activeKey.filter((key) => key !== "2"));
    } catch (error) {
      console.error(error);
      setLoader(false);
      notification.error({
        message: "Query Validation Failed",
        description: "There was an issue validating your query. Please try again.",
        placement: "topRight",
      });
    }
  };

  const handleQueryValue = (val) => {
    setQueryValue(val);
  };

  const handleSourceValue = (val) => {
    setSourceValue(val);
    handleExclusiveSelectedValue(val);
  };

  const handletimeSelection = (val) => {
    setTimeSelected(val);
    if (val) {
      setActiveKey((prevKeys) => prevKeys.filter((key) => key !== "3"));
    }
  };


  const CustomPanelHeader = ({ mainHeading, isSelected }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        color: "#000000",
        padding: "8px",
        borderRadius: "16px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Image
        src={isSelected ? MarkedGreen : MarkDoneEmpty}
        preview={false}
        style={{ marginRight: "8px", width: "20px", height: "20px" }}
      />
      <p
        style={{
          flex: 1,
          fontFamily: "Lato",
          fontWeight: "bold",
          fontSize: "15px",
          textAlign: "left",
          margin: 0,
        }}
      >
        {mainHeading}
      </p>
    </div>
  );

  const handlePanelChange = (key) => {
    if (key.includes("2") && !sourceSelected) {
      notification.warning({
        message: "Source Selection Required",
        description: "Please select a source before accessing the query panel.",
      });
    } else {
      setActiveKey(key);
    }
  };

  return (
    <div
      className="desktop_buildList"
      style={{ backgroundColor: "#eff2fc", padding: "20px" }}
    >
      <Collapse
        activeKey={activeKey}
        onChange={handlePanelChange}
        expandIconPosition="end"
        style={{ backgroundColor: "#eff2fc" }}
      >
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="Select your source"
              isSelected={sourceSelected}
            />
          }
          style={{ backgroundColor: "#eff2fc" }}
          key="1"
        >
          <DesktopSelectSource
            handleSourceSelection={handleSourceSelection}
            handleSourceValue={handleSourceValue}
            sources={sources}
            sourcesLoader={sourcesLoader}
          />
        </Panel>
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="Paste the query"
              isSelected={querySelected}
            />
          }
          style={{ backgroundColor: "#eff2fc" }}
          key="2"
        >
          <DesktopPasteQuery
            handleTestQuery={handleTestQuery}
            handleQueryValue={handleQueryValue}
            loader={loader}
          />
        </Panel>
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="When would you like this query to run?"
              isSelected={timeSelected}
            />
          }
          style={{ backgroundColor: "#eff2fc" }}
          key="3"
          collapsible="disabled"
        >
          <DesktopCustomSyncRecurrence
            handletimeSelection={handletimeSelection}
          />
        </Panel>
      </Collapse>
      {/* <Button
        style={{
          margin: '16px 0 0 0',
          display: 'inline-flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#3872cd',
          width: '100px',
          color: '#f0f5fb',
          fontSize: '11pt',
          fontFamily: '"Lato", sans-serif',
          fontWeight: 'bold',
          borderRadius: '15px',
          marginTop: '-1px',
          float: 'right',
          border: 'none',
          height: '30px',
        }}
        onClick={handleSkip}
      >
        Skip
      </Button> */}

      <Drawer
        placement="bottom"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        height="90%"
        className="customdrawer"
      />
    </div>
  );
}

export default DesktopAddExclusive;
