import { ANALYTICS_EVENT_NAMES, DEEPLINK_IDENTIFIERS } from "../../constants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { setExternalUserInfo, setUserSession } from "store/modules/auth/action";
import { getUserInfoByMarketPlace } from "api/userManagement";
import { trackEventCall, trackIdentifyCall } from "analytics";
import { parseUserResponse } from "utils";
import { message } from "antd";
import { getTwilioToken } from "api/TwilioChat";

function DeeplinkExternalSellerListing() {
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const { marketplaceid, userid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let redirectedFrom = "";
  // console.log(
  //   `MarketplaceId: ${marketplaceid} categoryId: ${categoryid} playbookid: ${playbookid} packageId: ${packageid} userid ${userid}`
  // );
  if (window.location.href.includes("external/requestProposal")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.REQUEST_PROPOSAL;
  } else if (window.location.href.includes("external/playbook")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.PLAYBOOK;
  } else if (window.location.href.includes("external/joinMarketplace")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.JOIN_MARKETPLACE;
  } else if (window.location.href.includes("external/proposalChat")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.PROPOSAL_CHAT;
  } else if (window.location.href.includes("external/seller-listing")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.SELLER_LISTING;
  }

  const redirectToMyPlaybooks = () => {
    setTimeout(() => {
      navigate(`/dashboard/myPlaybooks`);
    }, 10);
  };

  const setWorkspaceProfileIds = (state, marketplacesList) => {
    if (marketplacesList != null && marketplacesList.length > 0) {
      for (let i = 0; i < marketplacesList.length; i++) {
        let el = marketplacesList[i];
        state.workspaceCategoryId = el?.workspace_category_id;
        state.selectedWorkspaceImagUrl = el?.image_uri;
        state.selectedWorkspaceName = el?.name;
        if (el.id != null && el.id === parseInt(marketplaceid)) {
          if (el?.workspace_profiles.length > 1) {
            el?.workspace_profiles.forEach((workspaceProfile) => {
              if (workspaceProfile.account_role === "buyer") {
                state.buyerProfileWorkspaceId = workspaceProfile.id;
              } else {
                state.sellerProfileWorkspaceId = workspaceProfile.id;
              }
            });
          }
        }
      }
      state.selectedWorkspaceId = marketplaceid;
      dispatch(setUserSession(state));
      redirectToMyPlaybooks();
    } else {
      console.log("Marketplaces list is empty");
    }
  };

  const getUserInfo = () => {
    const payload = {
      user_id: userid,
      marketplace_id: marketplaceid,
    };
    getUserInfoByMarketPlace(payload)
      .then((response) => {
        // console.log("res", response);
        if (response.status === 200) {
          const eventData = {
            email: response?.data?.data?.response.email,
          };
          trackEventCall(ANALYTICS_EVENT_NAMES.SIGNIN, eventData);
          let state = parseUserResponse(response, false);
          // console.log("state", state);
          const segmentIdentityData = {
            email: state?.email,
            firstname: state?.firstName,
            lastname: state?.lastName,
            phonenumber: state?.phoneNumber,
          };

          trackIdentifyCall(state?.userId, segmentIdentityData);
          // window.OpenReplay.setUserID(state?.email);
          dispatch(setUserSession(state));
          // setTimeout(() => {
          setWorkspaceProfileIds(
            state,
            response?.data?.data?.response?.workspaces
          );
          // }, 10);
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      });
  };

  useEffect(() => {
    dispatch(
      setExternalUserInfo({
        marketplaceId: marketplaceid,
        from: redirectedFrom,
        userId: userid,
      })
    );
    //navigate(APP_ROUTES.LANDING.path);
    //redirectToProposalChat(playbookid);
    getUserInfo();
  }, []);

  return <div>Fetching User Information...</div>;
}

export default DeeplinkExternalSellerListing;
