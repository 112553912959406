import "./index.scss";
import { MoreOutlined, CopyOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Image, message, Popover, Avatar } from "antd";
import { PlaybookRequested } from "images";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Rate, Divider } from "antd";
import { trackEventCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { PlaybookDummyImage } from "images";

import { playbookActions } from "api/Playbooks";

function MobileSinglePlaybook({ singlePlaybookDetail }) {
  let data = singlePlaybookDetail;
  const navigate = useNavigate();

  const { buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const userSession = useSelector((state) => state.authReducer).userSession;
  const isB2B = userSession?.selectedWorkspaceName == "Palisade Knowledge";

  // useEffect(() => {
  //   // Check if the marketplace has changed
  //   window.location.reload();
  // }, []);

  const handleVisibleChange = (newVisible) => {
    setActionVisibilty(newVisible);
  };
  const [actionVisibilty, setActionVisibilty] = useState(false);

  const onReadMoreClicked = (playbookId) => {
    navigate(`/dashboard/playbookDetails/${playbookId}`);
  };
  const createProposalChat = (playbookDetails) => {
    navigate(`/dashboard/proposalDetails/${playbookDetails?.id}`);
  };

  const callActionsAPI = (actionText) => {
    setActionVisibilty(false);

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      playbook_id: data.id,
      action: actionText,
      price: data.min_price,
      selected_package_id: 0,
    };
    const segmentEventData = {
      playbookname: data?.name,
      sellername: data?.seller?.first_name + " " + data?.seller?.last_name,
      selleremail: data?.seller?.email,
      playbook_id: data?.id,
      price: data?.min_price,
      marketplaceId: data?.workspace_id,
    };
    if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED,
        segmentEventData
      );
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
        segmentEventData
      );
    }
    console.log(payload);

    playbookActions(payload)
      .then((res) => {
        if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
            segmentEventData
          );
          trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, segmentEventData);
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
            segmentEventData
          );
          createProposalChat(data);
        }
        message.success(res?.data?.message);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {});
  };

  const actionDropDownContent = (
    <div className="popOverContainer">
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.REQUEST_PROPOSAL);
        }}
        style={{
          display:
          data?.user_id == userSession?.userId
              ? "none"
              : "visible",
        }}
      >
        {PLAYBOOK_ACTIONS.REQUEST_PROPOSAL}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.REVIEWING);
        }}
      >
        {PLAYBOOK_ACTIONS.REVIEWING}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.HIGH_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.HIGH_PRIORITY}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.MEDIUM_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.MEDIUM_PRIORITY}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.LOW_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.LOW_PRIORITY}
      </p>

      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.NOT_INTRESTED);
        }}
      >
        {PLAYBOOK_ACTIONS.NOT_INTRESTED}
      </p>
    </div>
  );

  return (
    <div className="MobileSinglePlaybook">
      <div className="MobileSinglePlaybook__Row" hoverable>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="MobileSinglePlaybook__Col" span={9}>
            <Image
              onClick={() => onReadMoreClicked(data.id)}
              className="MobileSinglePlaybook__IMG1"
              preview={false}
              alt="Image of playbook"
              src={
                data?.thumbnail === null 
                  ? (data?.image1_url && data?.image1_url !== '') 
                    ? data?.image1_url
                    : PlaybookDummyImage
                  : data?.thumbnail
              }
            />
          </Col>
          <Col className="MobileSinglePlaybook__Col" span={15}>
            <h3
              onClick={() => onReadMoreClicked(data.id)}
              className="MobileSinglePlaybook__H1"
            >
              {data?.name}
            </h3>
            <p className="MobileSinglePlaybook__P1">
              {data?.description}
            </p>
              {!isB2B && ( <p className="MobileSinglePlaybook__proposalDetails__reviewsInfo">
                <Rate disabled defaultValue={1} count={1} />
                {"  "}
                {singlePlaybookDetail?.rating_avg !== null
                  ? singlePlaybookDetail?.rating_avg
                  : 0}
                {"  "}
                <span className="MobileSinglePlaybook__proposalDetails__reviewsInfo__count">
                  (
                  {singlePlaybookDetail?.rating_avg !== null
                    ? parseInt(parseFloat(singlePlaybookDetail?.rating_avg) * 1.7)
                    : 0}
                  K)
                </span>{" "}
              </p>)}
           
          </Col>
        </Row>

        <Row
          className="MobileSinglePlaybook__Row-2"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={14}>
            <div className="MobileSinglePlaybook__appHomeButton">
              <CustomAvatarSinglePlaybook
                imageUri={data?.seller?.image_uri}
                name={data?.seller?.first_name}
              />
              <div className="MobileSinglePlaybook__appHomeHeadContainer">
                <div
                  className="MobileSinglePlaybook__appHomeMainHead"
                  onClick={() => onReadMoreClicked(data.id)}
                >
                  {data?.seller?.first_name + " " + data?.seller?.last_name}
                </div>
                <div
                  onClick={() => onReadMoreClicked(data.id)}
                  className="MobileSinglePlaybook__appHomeSubHead"
                >
                  {data?.seller?.work_position}
                </div>
              </div>
            </div>
          </Col>

          <Col span={10}>
            <div className="MobileSinglePlaybook__P2">
              {data?.isProposal ? (
                <p className="MobileSinglePlaybook__P2__Read">
                  <img src={PlaybookRequested} style={{ marginRight: "4px" }} />{" "}
                  Requested
                </p>
              ) : (
                <p className="MobileSinglePlaybook__P2__Low">
                  {/* {isB2B ? <p className="MobileSinglePlaybook__P2__Low__As">STARTING FROM</p>:null} */}
                  
                  <div className={"MobileSinglePlaybook__P2__Low__B2BPrice"}
                  onClick={() => onReadMoreClicked(data.id)}>
                    {/* {userSession.selectedWorkspaceName === "PrivHomes" ? null : "$" +parseInt(data?.min_price)} */}
                    {"Learn More"}
                    <p>
                      <Popover
                        placement="bottom"
                        overlayClassName="actionsPopover"
                        content={actionDropDownContent}
                        trigger="click"
                        visible={actionVisibilty}
                        onVisibleChange={handleVisibleChange}
                        className={isB2B ? "MobileSinglePlaybook__P2__Low__B2BPrice__appdot":"MobileSinglePlaybook__P2__Low__B2CPrice__appdot"}
                      >
                        <MoreOutlined className="MobileSinglePlaybook__P2__Low__Price__appDotsIcon" />
                      </Popover>
                    </p>
                  </div>
                </p>
              )}
            </div>
          </Col>
        </Row>

        <Divider className="MobileSinglePlaybook__divider" />
      </div>
    </div>
  );
}

export default MobileSinglePlaybook;

function CustomAvatarSinglePlaybook({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="MobileSinglePlaybook__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="MobileSinglePlaybook__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}

// {data?.isProposal ? (
//   <p className="MobileSinglePlaybook__P2__Read">
//     Playbook Requested
//   </p>
// ) : (
//   <p className="MobileSinglePlaybook__P2__Low">
//     AS LOW AS{" "}
//     <div className="MobileSinglePlaybook__Dollar">
//       {" "}
//       USD{parseInt(data?.min_price)}{" "}
//     </div>
//   </p>
// )}
