import { Image, Steps } from "antd";
import { useState } from "react";
import BackIcon from "../../../../../../../images/chevron-left.svg";
import AdditionalRequiredFields from "./AdditionalRequiredFields";
import "./index.scss";
import SourceName from "./SourceName";
import SystemFields from "./SystemFields";

const { Step } = Steps;

/*
- File onboarding flow component used to render Create Source steps
- onCreate will be invoked when all of the requried create source steps are done
- onCancel will be invoked when user cancels the create source step
*/
function CreateSourceSteps({ onCancel, onCreate }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsDefinitions] = useState([
    {
      title: "Source Name",
      content: (
        <SourceName
          onContinue={() => {
            setCurrentStep(1);
          }}
        />
      ),
    },
    {
      title: "System Fields",
      content: (
        <SystemFields
          onContinue={() => {
            setCurrentStep(2);
          }}
        />
      ),
    },
    {
      title: "Additional Required Fields",
      content: (
        <AdditionalRequiredFields
          onContinue={() => {
            onCreate();
          }}
        />
      ),
    },
  ]);
  return (
    <div className="createSourceSteps">
      <div className="createSourceSteps__header">
        <div className="createSourceSteps__header__leftContent">
          <Image
            className="createSourceSteps__header__leftContent__backBtn"
            src={BackIcon}
            preview={false}
            onClick={() => {
              if (currentStep === 0) {
                onCancel();
              } else {
                setCurrentStep((pre) => pre - 1);
              }
            }}
          />
        </div>
        <div className="createSourceSteps__header__middleContent">
          <Steps current={currentStep}>
            {stepsDefinitions.map((item) => {
              return (
                <Step
                  className="createSourceSteps__steps__step"
                  key={item.title}
                  title={item.title}
                />
              );
            })}
          </Steps>
        </div>
        <div className="createSourceSteps__header__rightContent"></div>
      </div>

      <div className="createSourceSteps__content">
        {stepsDefinitions[currentStep].content}
      </div>
    </div>
  );
}
export default CreateSourceSteps;
