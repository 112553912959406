import { InfoCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Form, Input, message, Typography } from "antd";
import { ProfilePlaceholder } from "images";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CheckPassword, isMobile } from "utils";
import { changePassword } from "../../../api/Auth";
import "./index.scss";

function ChangePassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [isMobileView, setIsMobileView] = useState(isMobile());

  useEffect(() => {
    setUserProfileUrl(
      userSession?.imageUri ? userSession?.imageUri : ProfilePlaceholder
    );
  }, []);

  const handleChangePassword = (values) => {
    const { oldpassword, newpassword, confirmnewpassword } = values;
    if (CheckPassword(newpassword) && CheckPassword(confirmnewpassword)) {
      setLoading(true);
      if (newpassword === confirmnewpassword) {
        changePassword(oldpassword, newpassword)
          .then((res) => {
            message.success("Password changed successfully");
            form.resetFields();
          })
          .catch((err) => {
            message.error(err.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
        message.error("Passwords does not match");
      }
    } else {
      message.error(
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      );
    }
  };

  return (
    <div className="changePassword">
      {isMobileView === true ? (
        <div className="changePassword__headerMobile">
          <div className="changePassword__headerDiv">
            <Typography.Text className="changePassword__headerText">
              Change Password
            </Typography.Text>
            <Avatar className="userManagement_headerImg" src={userProfileUrl} />
          </div>
          <div className="changePassword__inputFieldsContainer">
            <Form form={form} layout="vertical" onFinish={handleChangePassword}>
              <Form.Item
                name="oldpassword"
                label="Old Password"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your old password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Type your old password"
                  className="changePassword__inputField"
                  autoComplete="new-password"
                  type={"password"}
                />
              </Form.Item>
              <Form.Item
                name="newpassword"
                label="New Password"
                required
                tooltip={{
                  title:
                    "Use 8 or more characters with a mix of letters, numbers & symbols",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input your new password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Type your new password"
                  className="changePassword__inputField"
                  autoComplete="new-password"
                  type={"password"}
                />
              </Form.Item>
              <Form.Item
                name="confirmnewpassword"
                label="Confirm New Password"
                required
                tooltip={{
                  title:
                    "Use 8 or more characters with a mix of letters, numbers & symbols",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input your confirm password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Retype your new password"
                  className="changePassword__inputField"
                  autoComplete="new-password"
                  type={"password"}
                />
              </Form.Item>
              <div className="changePassword__buttonsDivMobile">
                <Button
                  loading={loading}
                  className="changePassword__saveChangesButtonMobile"
                  type="primary"
                  htmlType="submit"
                >
                  Save Changes
                </Button>
                <Button
                  className="changePassword__cancelButtonMobile"
                  type="primary"
                  onClick={(e) => {
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      ) : (
        <div className="changePassword__header">
          <div className="changePassword__headerDiv">
            <Typography.Text className="changePassword__headerText">
              Change Password
            </Typography.Text>
            <Avatar className="userManagement_headerImg" src={userProfileUrl} />
          </div>
          <div className="changePassword__inputFieldsContainer">
            <Form form={form} layout="vertical" onFinish={handleChangePassword}>
              <Form.Item
                name="oldpassword"
                label="Old Password"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your old password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Type your old password"
                  className="changePassword__inputField"
                  autoComplete="new-password"
                  type={"password"}
                />
              </Form.Item>
              <Form.Item
                name="newpassword"
                label="New Password"
                required
                tooltip={{
                  title:
                    "Use 8 or more characters with a mix of letters, numbers & symbols",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input your new password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Type your new password"
                  className="changePassword__inputField"
                  autoComplete="new-password"
                  type={"password"}
                />
              </Form.Item>
              <Form.Item
                name="confirmnewpassword"
                label="Confirm New Password"
                required
                tooltip={{
                  title:
                    "Use 8 or more characters with a mix of letters, numbers & symbols",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input your confirm password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Retype your new password"
                  className="changePassword__inputField"
                  autoComplete="new-password"
                  type={"password"}
                />
              </Form.Item>
              <div className="changePassword__buttonsDiv">
                <Button
                  className="changePassword__cancelButton"
                  type="primary"
                  onClick={(e) => {
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  className="changePassword__saveChangesButton"
                  type="primary"
                  htmlType="submit"
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangePassword;
