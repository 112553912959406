import React from "react";
import "./index.scss";
import { BobDitty } from "images";
import { Rate, Avatar } from "antd";

function CreatorDetailsMobile({ creatorDetails, userRole, marketingStatement, rating }) {
  return (
    <div className="creatorDetailsMobile">
      <div className="creatorDetailsMobile__rowDivision">
        <div className="creatorDetailsMobile__rowDivision__leftSide">
          <span className="creatorDetailsMobile__rowDivision__leftSide__row1">
            {creatorDetails.first_name + " " + creatorDetails.last_name}
          </span>
          <span className="creatorDetailsMobile__rowDivision__leftSide__row2">
            {userRole + ""}
          </span>
          <span className="creatorDetailsMobile__rowDivision__leftSide__row3">
            <Rate disabled defaultValue={1} count={1} className="creatorDetailsMobile__rowDivision__leftSide__row3__star"/> {rating!==null ? rating.toFixed(1):4.8}{"  "}
            <span className="creatorDetailsMobile__rowDivision__leftSide__row3__count">
              ({rating!==null ? (rating * 1.7).toFixed(2): (1.4)}K)
            </span>
          </span>

          <span className="creatorDetailsMobile__rowDivision__leftSide__row4">
            {marketingStatement + ""}
          </span>
        </div>
        <div className="creatorDetailsMobile__rowDivision__middle">
          <Avatar className="creatorDetailsMobile__rowDivision__middle__avatar" src={creatorDetails?.image_uri}>
            {creatorDetails.first_name.charAt(0) + ""}
          </Avatar>
        </div>
      </div>
    </div>
  );
}

export default CreatorDetailsMobile;
