import { InfoCircleOutlined } from "@ant-design/icons";
import { message, Button, Form, Input, Space, Typography, Image } from "antd";
import { resetPassword } from "api/userManagement";
import { UserOnBoardingFooter } from "components/UserOnBoardingFooter";
import { Close } from "images";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { CheckPassword } from "utils";
import { forgotPassword, forgotPasswordSubmit } from "../../api/Auth";
import AppLogo from "../../components/AppLogo";
import "./index.scss";

function ResetPassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleResetPassword = (values) => {
    setShowErrorMessage(false);
    const { confirmationCode, newpassword } = values;
    if (CheckPassword(newpassword)) {
      setLoading(true);
      forgotPasswordSubmit(state.email, confirmationCode, newpassword)
        .then((res) => {
          message.success("Password changed successfully.");
          navigate(APP_ROUTES.SIGNIN.path);
        })
        .catch((err) => {
          setErrorMessage(err.message);
          setShowErrorMessage(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setShowErrorMessage(true);
      setErrorMessage(
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      );
    }
  };

  const resendPasswordCode = () => {
    setShowErrorMessage(false);
    forgotPassword(state.email)
      .then((res) => {
        message.success("Code sent to your email for password reset");
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setShowErrorMessage(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onErrorCloseClick = () => {
    setShowErrorMessage(false);
  };

  return (
    <>
      <div className="resetPassword__container">
        <div className="resetPassword__header">
          <div>
            <Typography.Text className="resetPassword__textDonthaveAccount">
              Don't have an account?
            </Typography.Text>
            <Link to={APP_ROUTES.SIGNUP.path}>
              <Button type="primary" className="resetPassword__button">
                Sign Up
              </Button>
            </Link>
          </div>
        </div>
        <div className="resetPassword">
          <Space
            direction="vertical"
            size={0}
            className="resetPassword__signinFields"
          >
            <div className="resetPassword__headerLogo">
              <AppLogo className="resetPassword__logo" />
            </div>

            <Typography.Text className="resetPassword__headerTitle">
              Reset Password
            </Typography.Text>
            {showErrorMessage && (
              <div className="signinPage__errorMessageContainer">
                <Image
                  className="signinPage__errorContainerCrossImage"
                  src={Close}
                  onClick={onErrorCloseClick}
                  preview={false}
                ></Image>
                <Typography.Text
                  className="signinPage__textErrorMessage"
                  type="danger"
                >
                  {errorMessage}
                </Typography.Text>
              </div>
            )}
            <Form
              layout="vertical"
              autoComplete="off"
              onFinish={handleResetPassword}
            >
              <Form.Item
                name="confirmationCode"
                label="Code"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input the code sent to your username.",
                  },
                ]}
              >
                <Input
                  placeholder="Enter 6 digit code"
                  className="resetPassword__inputField"
                ></Input>
              </Form.Item>
              <Typography.Text
                className="resetPassword__textResendCode"
                onClick={resendPasswordCode}
              >
                Resend Code
              </Typography.Text>
              <Form.Item
                name="newpassword"
                label="New Password"
                required
                tooltip={{
                  title:
                    "Use 8 or more characters with a mix of letters, numbers & symbols",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please input your new password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  disabled={loading}
                  placeholder="Enter New Password"
                  className="signinPage__inputField"
                  autoComplete="new-password"
                />
              </Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className="resetPassword__continueButton"
              >
                Reset Password
              </Button>
            </Form>
          </Space>
        </div>

        <UserOnBoardingFooter />
      </div>
    </>
  );
}
export default ResetPassword;
