import React, { useState, useEffect } from "react";
import { Table, Input, Spin } from "antd";
import { getProfiles } from "api/Explorer";
import "./index.scss";
import { SearchOutlined } from "@ant-design/icons";
import LetterAvatar from "react-letter-avatar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const { Search } = Input;

const columns = [
  {
    title: "USER",
    dataIndex: "user",
    key: "user",
    render: (text, record) => (
      <div className="profile-explorer__user">
        <LetterAvatar
          name={record?.user === "" ? "A" : record?.user}
          size={25}
          radius={25}
        />
        <span style={{ marginLeft: "6px" }}>{text}</span>
      </div>
    ),
  },
  {
    title: "UNIVERSAL ID",
    dataIndex: "universalId",
    key: "universalId",
  },
  {
    title: "FIRST ACTIVITY",
    dataIndex: "firstActivity",
    key: "firstActivity",
  },
  {
    title: "LAST ACTIVITY",
    dataIndex: "lastActivity",
    key: "lastActivity",
  },
];

const ProfileSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { buyerProfileWorkspaceId, userId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  function formatDate(date) {
    return new Date(date).toLocaleDateString("en-US");
  }

  // Initial API call to fetch all profiles on mount
  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const res = await getProfiles(userId, buyerProfileWorkspaceId);
      const results = res?.data?.data?.map((el) => ({
        user: `${el.user || ""}`.trim(),
        universalId: el.universal_id || "N/A",
        firstActivity: formatDate(el.first_activity),
        lastActivity: formatDate(el.last_activity),
      }));
      setProfiles(results || []);
      setFilteredData(results || []); // Initially set filtered data to all profiles
    } catch (err) {
      console.error("Error fetching profiles:", err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch profiles only once on component mount
  useEffect(() => {
    fetchProfiles();
  }, []);

  // Update filtered data when searchTerm changes
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = profiles.filter((item) =>
      item.user.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setFilteredData(filtered);
  }, [profiles, searchTerm]);

  const handleRowClick = (record) => {
    const personName = record?.user;
    const revId = record?.universalId;
    navigate(`/dashboard/datasets/explorer/${personName}/${revId}`);
  };

  return (
    <div className="profile-explorer">
      <div className="profile-explorer__header">
        <h2>Profile Explorer</h2>
        <Input
          className="profile-explorer__header__input"
          type="search"
          placeholder="Search people"
          prefix={<SearchOutlined style={{ color: "grey" }} />}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
        />
      </div>
      {/* Show loading spinner while data is being fetched */}
      <Spin spinning={loading} size="medium">
        <Table
          columns={columns}
          dataSource={filteredData} // Use filtered data
          pagination={false}
          className="profile-explorer__content"
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
              onMouseEnter: (e) => {
                e.currentTarget.style.cursor = "pointer";
              },
              onMouseLeave: (e) => {
                e.currentTarget.style.cursor = "default";
              },
            };
          }}
        />
      </Spin>
    </div>
  );
};

export default ProfileSearch;
