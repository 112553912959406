import React, { useState } from "react";
import "./index.scss";
import { Image, Input, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import RandomIcon from "../../../images/Datasets-1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft, IconSalesforce } from "images";

function SalesForceDestinationDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(
      `/dashboard/destination/setup/${"salesforce-marketing-cloud"}/${id}`
    );
  };

  const handleBackClick = () => {
    navigate(-1); // Go back one step
  };

  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };
  
  return (
    <div className="SalesforceDestinationDetails">
      <div className="SalesforceDestinationDetails__Fixed">
        {/* <div className="SalesforceDestinationDetails__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="SalesforceDestinationDetails__title"
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}
            onClick={handleConnectionClick}>
            Connections</span>
          <span className="SalesforceDestinationDetails__slash">/</span>
          <span className="SalesforceDestinationDetails__title" onClick={handleBackClick}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}>
            Catalog
          </span>
          <span className="SalesforceDestinationDetails__slash">/</span>
          <span className="SalesforceDestinationDetails__mainTitle">
            Salesforce-Marketing-Cloud (Actions)
          </span>
        </div> */}
        <div className="SalesforceDestinationDetails__sourceText">
          <div>
            <Image
              src={IconSalesforce}
              preview={false}
              style={{ marginRight: "10px" }}
            />
            <span style={{ marginLeft: "15px" }}>
              Salesforce Marketing Cloud (Actions)
            </span>
          </div>
        </div>
        <div className="SalesforceDestinationDetails__bar">
          <div>
            <Button
              onClick={() => navigateToFields(id)}
              className="SalesforceDestinationDetails__button"
            >
              Add Destination
              <ArrowRightOutlined className="SalesforceDestinationDetails__btnImage" />
            </Button>
          </div>
        </div>

        <div className="SalesforceDestinationDetails__newSource">
          <Button className="SalesforceDestinationDetails__newText">
            Destination
          </Button>
        </div>
        <div className="SalesforceDestinationDetails__exploreBar">
          <div className="SalesforceDestinationDetails__exploreText">
            Salesforce is a leader in on-demand customer relationship management
          </div>
        </div>
        <div className="SalesforceDestinationDetails__bottomBar"></div>
      </div>
      <div className="SalesforceDestinationDetails__mainContainer">
        <div className="SalesforceDestinationDetails__flexDiv">
          <div className="SalesforceDestinationDetails__container1">
            <h6 className="SalesforceDestinationDetails__cardHeading1">
              Compatible destination types
            </h6>
            <div className="SalesforceDestinationDetails__textFlex">
              <div className="SalesforceDestinationDetails__cardIcon">
                <MenuOutlined />
              </div>
              <div className="SalesforceDestinationDetails__cardText">
                Storage
              </div>
            </div>
            <p className="SalesforceDestinationDetails__rawText">
              Destinations for storing raw data.
              {/* <a className="SalesforceDestinationDetails__anchorTag">
                Learn more
              </a> */}
            </p>
          </div>
          <div className="SalesforceDestinationDetails__container2">
            <div className="SalesforceDestinationDetails__containFlex">
              <div className="SalesforceDestinationDetails__line"></div>
              <div className="SalesforceDestinationDetails__justDiv">
                <h6 className="SalesforceDestinationDetails__cardHeading2">
                  Set up requirements
                </h6>
                <p className="SalesforceDestinationDetails__setText1">
                  * Username and password for an account in your salesforce org{" "}
                </p>
                <p className="SalesforceDestinationDetails__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="SalesforceDestinationDetails__flexDiv1">
          <div className="SalesforceDestinationDetails__container3">
            <h6 className="SalesforceDestinationDetails__overviewHeading1">
              Overview
            </h6>
            <p className="SalesforceDestinationDetails__overviewText">
              When you enter your Salesforce credentials and hit save, we will
              sync Salesforce standard objects {"("}and their corresponding
              properties{")"}
              to any databases you have turned on. Salesforce data will reflect
              the state of the object at the time of sync. We will run an update
              ~3 hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesForceDestinationDetails;
