import { SearchOutlined } from "@ant-design/icons";
import { Image, Input, Spin } from "antd";
import { IconChevronLeft } from "images";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Flex from "screens/Chat/Flex";
import { getListOfDestinationsCatalogs } from "api/Connectors";
import "./index.scss";

function DestinationCatalogList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [filteredNewSources, setFilteredNewSources] = useState([]);
  const [catalogsList, setCatalogList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  // const isLoader = useSelector((state) => state?.connectorReducer?.loading);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getListOfDestinationsCatalogs({
          isDestination: true,
        });
        setIsLoader(false);
        setCatalogList(response?.data?.data);
      } catch (error) {
        setIsLoader(false);
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const [mainHeading, setMainHeading] = useState({
    MianNewSources: [
      {
        mainid: 1,
        mainHeading: "Most Recent Destinations",
        mainDescription1:
          "Explore recent additions to destinations catalog by popular demand.",
        mainDescription2: "",
      },
    ],
  });

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    const filterData = (data, stateSetter) => {
      const filtered = data.filter((item) =>
        item.type.toLowerCase().includes(inputValue)
      );
      stateSetter(filtered);
    };

    filterData(catalogsList, setFilteredNewSources);
  };

  const gotoDetails = (name, id) => {
    const subRoute = name.toLowerCase().split(" ").join("");
    navigate(`/dashboard/datasets/destination/${subRoute}/${id}`);
  };
  const handleBackClick = () => {
    // Navigate back to the previous page
    navigate(-1); // Go back one step
  };

  return (
    <>
      <div className="DestinationCatalogList">
        {/* <div className="DestinationCatalogList__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span
            className="DestinationCatalogList__title"
            style={{ cursor: "pointer" }}
            onClick={handleBackClick}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            Connections
          </span>
          <span className="DestinationCatalogList__slash">/</span>
          <span className="DestinationCatalogList__mainTitle">Catalog</span>
        </div>

        <div className="DestinationCatalogList__sourceText">
          <span></span>
        </div> */}

        <div className="DestinationCatalogList__bar">
          <div className="DestinationCatalogList__tagLink">
            {/*<a className="DestinationCatalogList__link">Catalog documentation</a>*/}
          </div>
          <Input
            className="DestinationCatalogList__input"
            type="search"
            onChange={handleSearch}
            value={searchInput}
            placeholder="Search an integration..."
            prefix={<SearchOutlined style={{ color: "grey" }} />}
          />
        </div>

        {/* ------------- New Source Portion ----------------- */}
        <div className="DestinationCatalogList__MainDiv">
          {mainHeading.MianNewSources.map((item, index) => {
            return (
              <>
                <div key={item.mainid} className="DestinationCatalogList__newSource">
                  <span className="DestinationCatalogList__newText">
                    {item.mainHeading}
                  </span>
                </div>
                <div className="DestinationCatalogList__exploreBar">
                  <div className="DestinationCatalogList__exploreText">
                    {item.mainDescription1}
                  </div>
                  <a className="DestinationCatalogList__exploreLink">
                    {item.mainDescription2}
                  </a>
                </div>
              </>
            );
          })}
        </div>
        {isLoader ? (
          <div className="loading-container">
          <Spin />
        </div>
        ) : (
          <div className="DestinationCatalogList__allFlexBox">
            {searchInput
              ? filteredNewSources.map((item, index) => {
                  return (
                    <>
                      <div
                        key={item.id}
                        className="DestinationCatalogList__singleFlexBox"
                        onClick={() => gotoDetails(item.type, item?.id)}
                      >
                        {/* <div onClick={() => gotoDetails(item.type, item?.id)}> */}
                        <div>
                          <Image src={item.icon_url} preview={false} />
                        </div>
                        <div className="DestinationCatalogList__listText">
                          <div className="DestinationCatalogList__listText__text1">
                            {item.type}
                          </div>
                          <div className="DestinationCatalogList__listText__text2">
                            {item.source_category}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              : catalogsList
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((item, index) => {
                    return (
                      <>
                        <div
                          key={item.id}
                          className="DestinationCatalogList__singleFlexBox highlightedOnHover"
                          onClick={() => gotoDetails(item.type, item?.id)}
                        >
                          <div>
                            {/* <div onClick={() => gotoDetails(item.type, item?.id)}> */}
                            <Image
                              className="DestinationCatalogList__image"
                              src={item.icon_url}
                              preview={false}
                            />
                          </div>
                          <div className="DestinationCatalogList__listText">
                            <div className="DestinationCatalogList__listText__text1">
                              {item.type}
                            </div>
                            <div className="DestinationCatalogList__listText__text2">
                              {item.source_category}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
          </div>
        )}
      </div>
    </>
  );
}

export default DestinationCatalogList;
