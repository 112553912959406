import {
  AutoComplete,
  Button,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  message,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { getEventMetaData, getEventRawMetaData, getMetaDataGoogleSheet } from "api/FileOnboard";
import { ENTITY_EVENT_ID } from "constants";
import { ENTITY_TYPES } from "constants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import IconDownArrow from "../../../../../images/filter-chevron-dropdown.svg";
import { useDispatch, useSelector } from "react-redux";
import IconCalendar from "../../../../../images/calendar.svg";
import IconTooltip from "../../../../../images/tooltip.svg";
import { setCurrentProcessingFile } from "../../../../../store/modules/fileOnboard/action";
import "./index.scss";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

/*
- File onboarding flow component used to render Add MetaData step
- onClickAddMapping will be invoked when all of the requried fields are filled
- Error handling and redux dispatch is hanlded within the scope of this component
- FieldLabelWithTooltip is a small component to render the field labels with their tooltips
*/
function FieldLabelWithTooltip({ label = "", tooltipTitle = "" }) {
  return (
    <div className="fieldLabelWithTooltip">
      <span className="fieldLabelWithTooltip__label">{label}</span>
      <Tooltip title={tooltipTitle}>
        <Image src={IconTooltip} preview={false} />
      </Tooltip>
    </div>
  );
}
function AddMetadata({ onClickAddMapping }) {
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState("");
  const [error, setError] = useState("");
  const [keyValueTags, setKeyValueTags] = useState([]);
  const [googleSheetUrl, setGooglSheetUrl] = useState("https://www.google.com/");
  const [creativeOptions, setCreativeOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [datasetsOptions, setDatasetsOptions] = useState([]);
  const [tagValues, setTagValues] = useState({});
  const currentProcessingFile = useSelector(
    (state) => state.fileOnboardReducer
  ).currentProcessingFile;
  const dispatch = useDispatch();
  const { userId, accountId } = useSelector(
    (state) => state.authReducer
  ).userSession;


  useEffect(()=>{
    getMetaDataGoogleSheet().then((res) => {
      setGooglSheetUrl(res.data.data.sheetUrl);
    }).catch((err) => {
      if (err?.response?.data?.message) {
        message.error(err.response.data.message);
      } else {
        message.error(err?.message);
      }
    });

  }, []);


  useEffect(() => {
    if (
      String(currentProcessingFile.matchedSource.entity_type_id) ===
      String(ENTITY_TYPES.EVENTS)
    ) {
      getEventRawMetaData({ entity_event_id: ENTITY_EVENT_ID.EMAIL })
        .then((res) => {
          setKeyValueTags(res.data.data);
        })

        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err.response.data.message);
          } else {
            message.error(err?.message);
          }
        });
      getEventMetaData({ user_id: userId, account_id: accountId })
        .then((res) => {
          const metaData = res.data.data.response;
          setCreativeOptions(metaData?.creatives || []);
          setSubjectOptions(metaData?.subjects || []);
          setDatasetsOptions(metaData?.datasets || []);
        })

        .catch((err) => {
          if (err?.response?.data?.message) {
            message.error(err.response.data.message);
          } else {
            message.error(err?.message);
          }
        });
    }
  }, [currentProcessingFile.matchedSource.entity_type_id]);

  const onFinish = (values) => {
    if (
      String(currentProcessingFile.matchedSource.entity_type_id) ===
      String(ENTITY_TYPES.EVENTS)
    ) {
      const {
        campaign_id,
        event_action_id,
        dataset_id,
        creative_id,
        subject_id,
      } = values;
      if (
        !isEmpty(campaign_id?.trim()) &&
        !isEmpty(event_action_id) &&
        !isEmpty(dataset_id?.trim()) &&
        !isEmpty(creative_id?.trim()) &&
        !isEmpty(subject_id?.trim())
      ) {
        const tagsWithValues = [];
        for (const key in tagValues) {
          const value = tagValues[key];
          if (value) {
            tagsWithValues.push(`${key}:${value}`);
          }
        }
        dispatch(
          setCurrentProcessingFile({
            ...currentProcessingFile,
            ...values,
            tags: tagsWithValues,
          })
        );
        onClickAddMapping();
      } else {
        setError("Please enter the required data in their relevant fields.");
      }
    } else {
      const { filename, accurateToDate } = values;
      if (!isEmpty(filename?.trim()) && !isEmpty(accurateToDate)) {
        dispatch(
          setCurrentProcessingFile({
            ...currentProcessingFile,
            ...values,
            tags,
          })
        );
        onClickAddMapping();
      } else {
        setError("Please enter the required data in their relevant fields.");
      }
    }
  };

  const onEnterTag = () => {
    const tagValue = currentTag.trim();
    if (tagValue) {
      setTags((pre) => {
        return [...new Set([...pre, tagValue])];
      });
      setCurrentTag("");
    }
  };

  return (
    <div className="addMetadata">
      <p className="addMetadata__heading">Add File Metadata</p>
      <p className="addMetadata__subHeading">
        The date when we learn a fact helps us when we merge data to create your
        knowledge graph. If we learned something yesterday, that will be more
        accurate than something we learned years ago. Adding the date helps us
        manage conflicts. Tags help you search across data. If you tag a file
        based on a project, you can search across all of your data using this
        unique tag.
      </p>
      <Form
        onFinish={onFinish}
        onFieldsChange={() => {
          setError("");
        }}
        initialValues={{
          ...currentProcessingFile.matchedSource,
          ...currentProcessingFile,
        }}
      >
        {String(currentProcessingFile.matchedSource.entity_type_id) !==
          String(ENTITY_TYPES.EVENTS) && (
          <>
            <Form.Item
              label={
                <FieldLabelWithTooltip
                  label="File name"
                  tooltipTitle="Input a name for your file."
                />
              }
              name="filename"
              colon={false}
              required
            >
              <Input placeholder="Enter file name..." />
            </Form.Item>
            <Form.Item
              label={
                <FieldLabelWithTooltip
                  label="Accurate to date"
                  tooltipTitle={`When we merge data about a person, company or a place, we look at this date to determine what data is the most accurate. For example, if two uploads have a "job title", but one is one day old and another is a year old, we will use the one day old "job title" since it’s more recent.`}
                />
              }
              name="accurateToDate"
              required
              colon={false}
            >
              <DatePicker
                placeholder="yyyy-mm-dd"
                allowClear={false}
                suffixIcon={<Image src={IconCalendar} preview={false} />}
              />
            </Form.Item>
            <Divider className="addMetadata__divider" />
            <div className="addMetadata__tags">
              <span className="addMetadata__tags__para">Tags</span>
              <Input
                placeholder="Add custom tags"
                value={currentTag}
                onChange={(e) => {
                  setCurrentTag(e.target.value);
                }}
                onPressEnter={(e) => {
                  e.preventDefault();
                  onEnterTag();
                }}
                onBlur={onEnterTag}
              />
              <div className="addMetadata__tags__customTags">
                <div className="addMetadata__tags__customTags__tags">
                  {tags.map((tag, index) => {
                    return (
                      <Tag
                        closable
                        onClose={(e) => {
                          e.preventDefault();
                          const updatedTagsList = [...tags];
                          updatedTagsList.splice(index, 1);
                          setTags(updatedTagsList);
                        }}
                        key={index}
                      >
                        {tag}
                      </Tag>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}

        {String(currentProcessingFile.matchedSource.entity_type_id) ===
          String(ENTITY_TYPES.EVENTS) && (
          <>
            <Form.Item
              label={
                <FieldLabelWithTooltip
                  label="Add Your Metadata"
                  tooltipTitle="Add your data to following sheet to proceed with next steps"
                />
              }
              name="add_your_data"
              colon={false}
              required
            >
              <>
                  <span style={{ marginLeft: 8 }}>Redirect to <a href={googleSheetUrl} target="_blank">Google Sheet</a></span>
              </>
            </Form.Item>
            <Form.Item
              label={
                <FieldLabelWithTooltip
                  label="Campaign ID"
                  tooltipTitle="It displays the campaign id associated with the selected source."
                />
              }
              name="campaign_id"
              colon={false}
              required
            >
              <AutoComplete
                style={{
                  width: 200,
                }}
                options={[]}
                showSearch={false}
                showArrow={false}
                placeholder="Enter Campain ID"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                suffixIcon={
                  <SearchOutlined className="addMetadata__searchIcon" />
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <FieldLabelWithTooltip
                  label="Event Type"
                  tooltipTitle="It displays the event type associated with the selected source."
                />
              }
              name="event_action_id"
              required
              colon={false}
            >
              <AutoComplete
                style={{
                  width: 200,
                }}
                options={[]}
                showSearch={false}
                showArrow={false}
                placeholder="Enter Event Type"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                suffixIcon={
                  <SearchOutlined className="addMetadata__searchIcon" />
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <FieldLabelWithTooltip
                  label="Audience"
                  tooltipTitle={`Input the Audience.`}
                />
              }
              name="dataset_id"
              required
              colon={false}
            >
              <Select
                placeholder="Select column"
                showSearch
                filterOption="children"
                allowClear={false}
                suffixIcon={<Image src={IconDownArrow} preview={false} />}
                style={{
                  width: 200,
                }}
              >
                {datasetsOptions.map((option, index) => {
                  return (
                    <Option value={String(option.id)} key={index}>
                      {option.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <FieldLabelWithTooltip
                  label="Creative"
                  tooltipTitle={`Input the creative value.`}
                />
              }
              name="creative_id"
              required
              colon={false}
            >
              <Select
                placeholder="Select column"
                showSearch
                filterOption="children"
                allowClear={false}
                suffixIcon={<Image src={IconDownArrow} preview={false} />}
                style={{
                  width: 200,
                }}
              >
                {creativeOptions.map((option, index) => {
                  return (
                    <Option value={String(option.id)} key={index}>
                      {option.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <FieldLabelWithTooltip
                  label="Subject"
                  tooltipTitle={`Input the Subject.`}
                />
              }
              name="subject_id"
              required
              colon={false}
            >
              <Select
                placeholder="Select column"
                showSearch
                filterOption="children"
                allowClear={false}
                suffixIcon={<Image src={IconDownArrow} preview={false} />}
                style={{
                  width: 200,
                }}
              >
                {subjectOptions.map((option, index) => {
                  return (
                    <Option value={String(option.id)} key={index}>
                      {option.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Divider className="addMetadata__divider" />
            <div className="addMetadata__tags">
              <span className="addMetadata__tags__para">Tags</span>
              <div className="addMetadata__tags__row">
                <span className="addMetadata__tags__row__headerLeftContent">
                  Keys
                </span>
                <span className="addMetadata__tags__row__headerRightContent">
                  Values
                </span>
              </div>
              <div className="addMetadata__tags__container">
                {keyValueTags.map((keyValueTag) => {
                  return (
                    <div className="addMetadata__tags__row">
                      <span className="addMetadata__tags__row__tagsLeftContent">
                        <span className="addMetadata__tags__row__tagsLeftContent__key">
                          {keyValueTag.name}
                        </span>
                        <span className="addMetadata__tags__row__tagsLeftContent__colon">
                          <span>:</span>
                        </span>
                      </span>
                      <Input
                        style={{
                          width: 200,
                        }}
                        onChange={(e) => {
                          setTagValues((pre) => {
                            return {
                              ...pre,
                              [keyValueTag.name]: e.target.value,
                            };
                          });
                        }}
                        placeholder="Enter tag value"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}

        <Divider className="addMetadata__divider" />

        <div className="addMetadata__footer">
          <span className="addMetadata__footer__error">{error}</span>
          <Button
            type="primary"
            htmlType="submit"
            className="addMetadata__footer__continueBtn"
          >
            Continue
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AddMetadata;
