import { trackEventCall, trackPageCall } from "analytics";
import {
  Avatar,
  Button,
  Carousel,
  Divider,
  Empty,
  message,
  List,
  Rate,
  Spin,
  Tooltip,
  Image,
} from "antd";
import {
  getPlaybookDetailsById,
  playbookActions,
  buyerPlaybooksUsingFilters,
} from "api/Playbooks";
import { capitalizeFirstCharacter } from "utils";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import Flex from "screens/Chat/Flex";
import { setExternalUserInfo } from "store/modules/auth/action";
import { ANALYTICS_EVENT_NAMES } from "../../../constants";
import PackageDetails from "./PackageDetails";
import { isMobile } from "utils";
import { EditPlaybookIcon } from "images";
// import SinglePlaybook from "../NewPlaybooks/SinglePlaybook";
import SinglePlaybookDetails from "../NewPlaybooks/SinglePlaybook/singleplaybookDetails";
import { PlaybookDummyImage } from "images";

import "./index.scss";
import MobilePlaybookDetails from "../mPlaybookDetails";

export const PlaybooksDetailPage = () => {
  const [isMobileView, setIsMobileView] = useState(isMobile());

  useEffect(() => {
    function handleResize() {
      setIsMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobileView === true ? (
    <MobilePlaybookDetails />
  ) : (
    <PlaybookDetails />
  );
};

function PlaybookDetails() {
  const contentStyle = {
    height: "520px",
    width: "520px",
    color: "#fff",
    textAlign: "center",
    background: "#C0C0C0",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    objectFit: "contain",
  };

  const { playbookId } = useParams();
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const isB2B = userSession?.selectedWorkspaceName == "Palisade Knowledge";
  const [pbDetails, setPBDetails] = useState({});
  const [spinning, setSpinning] = useState(true);
  const [recommendedPlaybooks, setRecommendedPlaybooks] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [showCreatePlaybookTooltip, setShowCreatePlaybookTooltip] =
    useState(false);
  const [isMobileView, setIsMobileView] = useState(isMobile());

  const { selectedWorkspaceId, buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  const navigate = useNavigate();
  const externalUserInfo = authReducer.externalUserInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    function handleResize() {
      setIsMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const pageCallData = {
      marketplaceId: userSession.selectedWorkspaceId,
    };
    trackPageCall("Playbook Details", pageCallData);
  }, []);

  const requestProposalBeta = (packageid = null, details) => {
    const playbookDetails = details ? details : pbDetails;

    const payload = {
      profile_workspace_id: userSession.buyerProfileWorkspaceId,
      playbook_id: playbookId,
      action: PLAYBOOK_ACTIONS.REQUEST_PROPOSAL,
      price: "99",
      selected_package_id: packageid,
    };

    const packages = playbookDetails?.packages;
    packages?.forEach((pkg) => {
      if (pkg?.id === parseInt(packageid)) {
        setCreatingConversation(true);
        const segmentEventData = {
          marketplaceId: playbookDetails?.workspace_id,
          playbookname: playbookDetails?.name,
          sellername:
            playbookDetails?.seller?.first_name +
            " " +
            playbookDetails?.seller?.last_name,
          selleremail: playbookDetails?.seller?.email,
          playbook_id: playbookId,
          revenue: parseInt(pkg?.price),
          currency: "USD",
          planType: pkg?.plan_type,
          planId: pkg?.id,
        };
        // console.log("segmentEventData", segmentEventData);
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED,
          segmentEventData
        );
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
          segmentEventData
        );

        playbookActions(payload)
          .then((res) => {
            trackEventCall(
              ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
              segmentEventData
            );
            trackEventCall(
              ANALYTICS_EVENT_NAMES.PROPOSAL_SENT,
              segmentEventData
            );
            trackEventCall(
              ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
              segmentEventData
            );
            message.success(res?.data?.message);

            createProposalChat(playbookDetails);
          })
          .catch((err) => {
            message.error(err.response.data.message);
          })
          .finally(() => {
            setCreatingConversation(false);
          });
      }
    });
    // }
    // dispatch(setExternalUserInfo(null));
  };
  const requestProposal = (details) => {
    // console.log("requestProposal");
    const playbookDetails = pbDetails;

    const payload = {
      profile_workspace_id: userSession.buyerProfileWorkspaceId,
      playbook_id: playbookId,
      action: PLAYBOOK_ACTIONS.REQUEST_PROPOSAL,
      price: "99",
      selected_package_id: "",
    };
    const segmentEventData = {
      marketplaceId: userSession.selectedWorkspaceId,
      playbookname: playbookDetails?.name,
      sellername:
        playbookDetails?.seller?.first_name +
        " " +
        playbookDetails?.seller?.last_name,
      selleremail: playbookDetails?.seller?.email,
      playbook_id: playbookId,
      revenue: "99",
      currency: "USD",
    };
    trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED, segmentEventData);
    trackEventCall(
      ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
      segmentEventData
    );
    playbookActions(payload)
      .then((res) => {
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
          segmentEventData
        );
        trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, segmentEventData);
        trackEventCall(
          ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
          segmentEventData
        );
        message.success(res?.data?.message);

        createProposalChat(playbookDetails);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setCreatingConversation(false);
      });
  };

  const createProposalChat = (playbookDetails) => {
    navigate(`/dashboard/proposalDetails/${playbookDetails?.id}`);
  };

  useEffect(() => {
    setSpinning(true);

    const payload = {
      playbook_id: playbookId,
      profile_workspace_id: 10,
    };

    getPlaybookDetailsById(payload)
      .then((res) => {
        setPBDetails(res?.data?.data[0]);
        getSearchResults(res?.data?.data[0].playbook_category_id);
        let localImageList = [];
        if (res?.data?.data[0]?.image1_url) {
          // let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image1_url);
          //setImagesList(localImageList);
        }

        if (res?.data?.data[0]?.image2_url) {
          //let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image2_url);
          //setImagesList(localImageList);
        }
        if (res?.data?.data[0]?.image3_url) {
          //let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image3_url);
          //setImagesList(localImageList);
        }
        if (res?.data?.data[0]?.image4_url) {
          //let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image4_url);
          //setImagesList(localImageList);
        }
        setImagesList(localImageList);
        if (externalUserInfo != null) {
          // console.log("Before method triggering");
          // processPackageDetails(res?.data?.data[0]);
          requestProposalBeta(externalUserInfo?.packageId, res?.data?.data[0]);
          dispatch(setExternalUserInfo(null));
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setSpinning(false);
      });
  }, [playbookId]);

  const getSearchResults = (playbookCategoryId) => {
    let min = 0;
    let max = 999999999;

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      workspace_id: selectedWorkspaceId,
      playbook_category_id: playbookCategoryId,
      profile_type: "buyer",
      query: "",
      min_budget: min,
      max_budget: max,
      tags: "",
      owners: "",
      created_before: "",
      rating: "",
      action: "",
    };

    buyerPlaybooksUsingFilters(payload)
      .then((res) => {
        setRecommendedPlaybooks(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {});
  };
  const playbookDetails = pbDetails;
  return (
    <>
      <div className="playbookDetails">
        <div className="playbookDetails__searchContainer__addIcon">
        {userSession?.accountRole != "buyer" && (
        <Link className="menu-list__link" to={`/dashboard/editPlaybookDetails/${playbookId}`}>
          <Tooltip title="Edit Playbook">
            <Image src={EditPlaybookIcon} preview={false} />
          </Tooltip>
          </Link>
          )}
        </div>
        {spinning ? (
          <Flex
            flex={1}
            justify="center"
            align="center"
            style={{ width: "100%", height: 500 }}
            direction="column"
          >
            <Spin size="default" />
            <div> Loading...</div>
          </Flex>
        ) : !isEmpty(playbookDetails) ? (
          <div>
            <div className="playbookDetails__centerAlign playbookDetails__image">
              <CustomAvatar
                name={playbookDetails?.seller?.first_name}
                imageUri={playbookDetails?.seller?.image_uri}
              />
            </div>

            <span className="playbookDetails__centerAlign playbookDetails__playbookTitle">
              {playbookDetails?.name}
            </span>
            <span className="playbookDetails__centerAlign playbookDetails__welcomeText ">
              Hi, {" " + userSession.firstName + " " + userSession.lastName}
              <br />
              My name is
              {" " +
                playbookDetails?.seller?.first_name +
                " " +
                playbookDetails?.seller?.last_name}
              <br />
              Let me tell you about my playbook.
            </span>

            <div className="playbookDetails__centerAlign">
              <Button
                className="playbookDetails__positiveBtn"
                onClick={requestProposal}
                loading={creatingConversation}
                style={{
                  display:
                    playbookDetails?.seller?.seller_id === userSession.userId ||
                    playbookDetails?.proposal_requested === true
                      ? "none"
                      : "visible",
                }}
              >
                Continue
              </Button>
            </div>

            <div
              className="playbookDetails__centerAlign"
              style={{
                display:
                  playbookDetails?.image1_url === "" &&
                  playbookDetails?.image2_url === "" &&
                  playbookDetails?.image3_url === "" &&
                  playbookDetails?.image4_url
                    ? "none"
                    : "visible",
              }}
            >
              <Carousel style={{ width: "100%" }} autoplay>
                {imagesList.map((imageUrl, index) => {
                  return (
                    <div>
                      <Image
                        alt="Image of playbook"
                        style={contentStyle}
                        preview={false}
                        src={imageUrl !== "" ? imageUrl : PlaybookDummyImage}
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <p className="playbookDetails__aboutHeading">Highlights</p>

            <div style={{ flexDirection: "row" }}>
              <span style={{ fontSize: 18 }}>{"\u2022"}</span>
              <span className="playbookDetails__highLights__text">
                {playbookDetails?.highlight_1}
              </span>
            </div>

            <div style={{ flexDirection: "row" }}>
              <span style={{ fontSize: 18 }}>{"\u2022"}</span>
              <span className="playbookDetails__highLights__text">
                {playbookDetails?.highlight_2}
              </span>
            </div>

            <div style={{ flexDirection: "row" }}>
              <span style={{ fontSize: 18 }}>{"\u2022"}</span>
              <span className="playbookDetails__highLights__text">
                {playbookDetails?.highlight_3}
              </span>
            </div>

            <div style={{ flexDirection: "row" }}>
              <span style={{ fontSize: 18 }}>{"\u2022"}</span>
              <span className="playbookDetails__highLights__text">
                {playbookDetails?.highlight_4}
              </span>
            </div>

            <p className="playbookDetails__aboutHeading">About</p>
            <p className="playbookDetails__description">
              {playbookDetails?.description}
            </p>

            {!isB2B && (
            <p className="playbookDetails__reviewsInfo">
            <Rate disabled defaultValue={1} count={1} />{" "}
            {playbookDetails?.rating_avg !== null
            ? playbookDetails?.rating_avg
            : 0}
            {"  "}
            <span className="playbookDetails__reviewsInfo__count">
            (
            {playbookDetails?.rating_avg !== null
              ? parseInt(parseInt(playbookDetails?.rating_avg) * 1.7)
              : 0}
            K)
            </span>{" "}
             </p>
             )}

            <Divider className="playbookDetails__divider" />

            <div className="playbookDetails__languageCategory">
              <p className="playbookDetails__languageCategory__heading">
                Language
              </p>
              <p className="playbookDetails__languageCategory__heading">
                Category
              </p>
            </div>

            <div className="playbookDetails__languageCategory">
              <p className="playbookDetails__languageCategory__value">
                {playbookDetails?.language}
              </p>
              <p className="playbookDetails__languageCategory__value">
                {playbookDetails?.playbook_category_name}
              </p>
            </div>

            <p className="playbookDetails__tagsHeading">Tags</p>
            <div className="playbookDetails__tagsdescription">
              {playbookDetails?.secondary_tag?.map((item) => (
                <span className="playbookDetails__tagsdescription__tagsAndActions__left__eachPill">
                  {capitalizeFirstCharacter(item)}
                </span>
              ))}
            </div>
            {isB2B ? (null
            // <Spin spinning={creatingConversation}>
            //   <p className="playbookDetails__comparePackage">
            //     {" "}
            //     Compare Package
            //   </p>
            //   {playbookDetails?.packages && (
            //     <PackageDetails
            //       packageDetails={playbookDetails?.packages}
            //       requestBtnClicked={requestProposalBeta}
            //       sellerId={playbookDetails?.seller?.seller_id}
            //     />
            //   )}
            // </Spin>
            ):(null)}
            <p className="playbookDetails__recommendedHeading">
              Recommendation For You
            </p>
            <p className="playbookDetails__recommend">
              {playbookDetails?.playbook_category_name}
            </p>
            <div className="playbookDetails__divList">
              <List
                dataSource={recommendedPlaybooks}
                className="playbookDetails__list"
                renderItem={(item, index) => {
                  return (
                    <div className="playbookDetails__datas">
                      <SinglePlaybookDetails
                        className="playbookDetails__single"
                        singlePlaybookDetail={item}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
}
export default PlaybookDetails;

function CustomAvatar({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="playbookDetails__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="playbookDetails__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
