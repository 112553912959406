import React, { useState, useEffect } from "react";
import { Input, Button, Switch, message, Tabs, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { deleteModel, editSource } from "api/Connectors";
import { getModelDetails } from "store/modules/connector/action";
import { saveQuery } from "api/Connectors";
import "./index.scss";

function ModelSettings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modelId, modelName } =
    useParams();
  const modelDetails = useSelector(
    (state) => state?.connectorReducer?.modelDetails
  );
  const [isSwitchOn, setSwitchOn] = useState(true);
  const [status, setStatus] = useState();
  const [model, setModel] = useState('');

  const handleSwitchChange = () => {
    const newState = !isSwitchOn; // Determine the new state
    setSwitchOn(newState);
    setStatus(newState ? 'Active' : 'Inactive'); // Set status based on the new state
  };
  const handleNameChange = (event) => {
    setModel(event.target.value);
  };

  useEffect(() => {
    dispatch(getModelDetails(parseInt(modelId)));
  }, [dispatch, modelId]);

  useEffect(() => {
    if (modelDetails?.primary_key !== undefined) {
      setModel(modelDetails?.model_name)
      setSwitchOn(modelDetails?.status === "Active" ? true : false);
    }
  }, [modelDetails?.primary_key]);

  const handleEditModel = () => {
    if (!model.trim()) {
      message.error("Model name is empty. Cannot save changes.");
      return;
    }

    // setLoading(true);
    const queryData = {
      "model_name": model,
      "query": modelDetails?.query,
      "primary_key": modelDetails?.primary_key,
      "status": status
    };
    saveQuery(modelId, queryData)
      .then(res => {
        message.success(res?.data?.message)
      })
      .catch(error => {
        message.success(error?.data?.message)
      });
  };

  const handleDeleteModel = () => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this model?",
      okText: "Yes",
      cancelText: "No",
      okButtonProps: {
        // Customize the props of the "Yes" button
        type: "primary", // Set the color of the button
        danger: true, // Indicate that this is a dangerous action
      },
      cancelButtonProps: {
        // Customize the props of the "No" button
        type: "default", // Set the color of the button
      },
      onOk() {
        deleteModel(parseInt(modelId))
          .then((res) => {
            navigate(`/dashboard/datasets/sources`);
            message.success(res?.data?.message);
          })
          .catch((err) => {
            message.error(
              err?.response?.data?.message || "Error deleting source"
            );
          });
      },
      onCancel() {
        // Do nothing if user cancels deletion
      },
    });
  };

  const items = [
    { key: "1", label: "Overview" },
    { key: "2", label: "Query Builder" },
    { key: "3", label: "Settings" },
  ];

  const onChange = (activeKey) => {
    if (activeKey === "1") {
      navigate(
        `/dashboard/datasets/model/overview/${modelId}/${modelName}`
      );
    }
    if (activeKey === "2") {
      navigate(
        `/dashboard/model/querybuilder/${modelId}/${modelName}`
      );
    }
  };

  return (
    <div className="ModelSettings">
      <div className="ModelSettings__headerText">
        <Tabs defaultActiveKey="3" items={items}
          onChange={onChange}
        />
      </div>
      <div className="ModelSettings__form">
        <h1 className="ModelSettings__topHeading">Settings</h1>
        <div className="ModelSettings__field">
          <h6 className="ModelSettings__heading">Model Name *</h6>
          <Input
            className={`ModelSettings__input ${model.trim() === "" ? "empty-input" : ""
              }`}
            value={model}
            onChange={handleNameChange}
          />
        </div>

        <div className="ModelSettings__field">
          <div>
            <h6 className="ModelSettings__heading">Enable Model</h6>
            <p className="ModelSettings__para">
              Enabling this model allows it to send data to your Reverse ETL destination
            </p>
            <Switch
              checked={isSwitchOn}
              onChange={handleSwitchChange}
              style={{ backgroundColor: isSwitchOn ? "#1c74d4" : "#ccc" }}
            />
          </div>
        </div>

      </div>

      <div className="ModelSettings__buttonsDiv">
        <Button
          className="ModelSettings__saveButton"
          type="primary"
          onClick={handleEditModel}
        >
          Save Changes
        </Button>
        <Button
          danger
          className="ModelSettings__deleteButton"
          onClick={handleDeleteModel}
          icon={
            <DeleteOutlined style={{ fontSize: "20px", marginLeft: "-5px" }} />
          }
        >
          Delete Model
        </Button>
      </div>
    </div>
  );
}

export default ModelSettings;
