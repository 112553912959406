import { call, put, takeLatest, select } from "redux-saga/effects";
import {
  getListOfFlows,
  getSaleforceRequiredFileds,
  getListOfCatalogs,
  getModelsList
} from "./../../../api/Connectors";
import {
  setLoading,
  GET_CONNECTOR_OVERVIEW_LIST,
  setConnectorOverviewList,
  GET_REQUIRED_FIELDS,
  setRequiredFields,
  GET_CATALOGS_LIST,
  setCatalogsList,
  GET_MODEL_DETAILS,
  setModelDetails,
} from "./action";

export function* connectorSaga() {
  yield takeLatest(GET_CONNECTOR_OVERVIEW_LIST, handleConnectorOverviewList);
  yield takeLatest(GET_REQUIRED_FIELDS, handleRequiredFields);
  yield takeLatest(GET_CATALOGS_LIST, handleCatalogsList);
  yield takeLatest(GET_MODEL_DETAILS, handleModelDetails);
}

function* handleConnectorOverviewList(action) {
  try {
    if (
      Number(
        yield select(
          (state) =>
            state?.connectorReducer?.connectorOverviewList[0]?.connection_id
        )
      ) == Number(action.payload)
    ) {
      const response = yield call(getListOfFlows, action.payload);
      yield put(setConnectorOverviewList(response?.data?.data));
    } else {
      yield put(setLoading(true));
      const response = yield call(getListOfFlows, action.payload);
      yield put(setConnectorOverviewList(response?.data?.data));
    }
  } finally {
    yield put(setLoading(false));
  }
}

function* handleRequiredFields(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(getSaleforceRequiredFileds, action.payload);
    yield put(setRequiredFields(response?.data?.data?.[0]?.required_fields[0]));
  } finally {
    // Dispatch action to set loading state to false regardless of success or failure
    yield put(setLoading(false));
  }
}

function* handleCatalogsList(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(getListOfCatalogs);
    yield put(setCatalogsList(response?.data?.data));
  } finally {
    // Dispatch action to set loading state to false regardless of success or failure
    yield put(setLoading(false));
  }
}

function* handleModelDetails(action) {
  try {
    yield put(setLoading(true));
    const response = yield call(getModelsList, action.payload);
    yield put(setModelDetails(response?.data?.data[0]));
  } finally {
    // Dispatch action to set loading state to false regardless of success or failure
    yield put(setLoading(false));
  }
}
