import { Typography } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Linkify from "react-linkify";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { isMobile } from "utils";
import CallMe from "../CallMe";
import Day from "../Day";
import ScheduleMeeting from "../ScheduleMeeting";
import "./index.scss";

dayjs.extend(utc);

const Message = ({
  fromProposal = false,
  msgIndex = -1,
  currentMsgIndex = -1,
  message,
  author,
  prevMessage,
  users,
  currentConversation,
  conversationParticipants,
  rightNavChat = false,
  playbookDetails,
}) => {
  // console.log("message", message);
  const type = message?.state?.type;
  const images = message?.state?.media;
  const createdAt = "";
  const text = message?.state?.body;
  const mesgAuthor = message?.state?.author;
  var userId = "";
  if (mesgAuthor.includes("-")) {
    const myArray = mesgAuthor.split("-");
    userId = myArray[0];
  } else {
    userId = mesgAuthor;
  }
  var userFirstName = message?.state?.attributes?.first_name;
  var userLastName = message?.state?.attributes?.last_name;
  var userImageUri = message?.state?.attributes?.image_uri;
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [imagePreviewPhotoIndex, setImagePreviewPhotoIndex] = useState(0);
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const isMe = userSession.phoneNumber === userId;
  const [onCallMeClicked, setOnCallMeClicked] = useState(false);
  const [isMobileView, setIsMobileView] = useState(isMobile());
  var senderName = "";
  if (author?.state?.attributes?.fullName != null) {
    senderName = author?.state?.attributes?.fullName;
  } else {
    if (userId.includes("+")) {
      conversationParticipants.forEach((p) => {
        if (
          p?.state?.attributes != null &&
          p?.state?.attributes?.phone_number === userId
        ) {
          senderName =
            p?.state?.attributes?.first_name +
            " " +
            p?.state?.attributes?.last_name;
          userImageUri = p?.state?.attributes?.image_uri;
        }
      });
      // senderName = userId;
    } else if (users[userId]?.first_name === "undefined") {
      senderName = users[userId]?.phone_number;
    } else {
      senderName = userFirstName + " " + userLastName;
    }
  }

  const renderImage = () => {
    return (
      images && (
        <div onClick={() => setImagePreviewOpen(true)}>
          <img src={images[0]} className="messageBox__image" />
        </div>
      )
    );
  };

  const renderTime = () => {
    let date = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(message?.state?.timestamp);

    return (
      <>
        {isMobileView === true ? (
          <Typography.Text
            className={classNames(
              "messageBox__timeMobile",
              isMe && "messageBox__isMeTimeMobile"
            )}
          >
            {date}
          </Typography.Text>
        ) : (
          <Typography.Text
            className={classNames(
              "messageBox__time",
              isMe && "messageBox__isMeTime"
            )}
          >
            {date}
          </Typography.Text>
        )}
      </>
    );
  };

  const renderSenderCallMe = () => {
    return (
      <div className="messageBox__isMeWrapper">
        <div className="messageBox__callMeDiv">
          <a
            className="messageBox__callMeText"
            // href={"tel:" + playbookDetails?.seller?.phone_number}
          >
            Call me
          </a>
        </div>
      </div>
    );
  };

  const renderPhoneInputField = () => {
    return (
      <div className="messageBox__isMeWrapper">
        <div className="messageBox__phoneInputDiv">
          <PhoneInput
            className="messageBox__inputField"
            country={"us"}
            prefix="+ "
          />
        </div>
      </div>
    );
  };

  const callMeClicked = () => {
    setOnCallMeClicked(true);
  };

  // console.log("msgIndex", msgIndex);
  // console.log("fromProposal", fromProposal);

  return (
    <>
      {isMobileView === true ? (
        <div className="messageBox__container">
          <Day
            currentMessageCreatedAt={message?.state?.timestamp}
            prevMessageCreatedAt={prevMessage?.state?.timestamp}
          />
          {!isMe && (
            <div className="messageBox__senderContainer">
              {/* <CustomAvatar
            name={senderName}
            imageUri={userImageUri}
            // size={30}
            // url={author?.state?.attributes?.avatar}
            // onClick={() => {}}
          /> */}
              {/* <Typography.Text
            className={
              rightNavChat === true
                ? "messageBox__textSenderNameRightNavChat"
                : "messageBox__textSenderName"
            }
          >
            {senderName}
          </Typography.Text> */}
            </div>
          )}
          <div
            className={classNames(
              "messageBox__wrapper",
              isMe && "messageBox__isMeWrapper"
            )}
          >
            {type === "text" || type === "sms" ? (
              <div
                className={classNames(
                  "messageBox__bubbleMobile",
                  isMe && "messageBox__bubbleMeMobile"
                )}
              >
                <Linkify>
                  <Typography.Text
                    className={
                      rightNavChat === true
                        ? isMe
                          ? "messageBox__textMessageIsMeRightNavChat"
                          : "messageBox__textMessageRightNavChat"
                        : isMe
                        ? "messageBox__textMessageIsMeMobile"
                        : "messageBox__textMessageMobile"
                    }
                  >
                    {text}
                  </Typography.Text>
                </Linkify>
                {renderTime()}
              </div>
            ) : (
              renderImage()
            )}
          </div>

          {/* {fromProposal && msgIndex - currentMsgIndex === 2 ? (
            <ScheduleMeeting
              sellerInfoObject={playbookDetails}
            ></ScheduleMeeting>
          ) : null}
          {fromProposal && msgIndex - currentMsgIndex === 2 ? (
            <CallMe
              playbookDetails={playbookDetails}
              onCallMeClicked={callMeClicked}
            ></CallMe>
          ) : null}

          {onCallMeClicked && renderSenderCallMe()} */}
          {/* {onCallMeClicked && renderPhoneInputField()} */}

          {/* {renderTime()} */}

          {imagePreviewOpen && images ? (
            <Lightbox
              mainSrc={images[imagePreviewPhotoIndex]}
              nextSrc={images[(imagePreviewPhotoIndex + 1) % images.length]}
              prevSrc={
                images[
                  (imagePreviewPhotoIndex + images.length - 1) % images.length
                ]
              }
              onCloseRequest={() => setImagePreviewOpen(false)}
              onMovePrevRequest={() =>
                setImagePreviewPhotoIndex(
                  (imagePreviewPhotoIndex + images.length - 1) % images.length
                )
              }
              onMoveNextRequest={() =>
                setImagePreviewPhotoIndex(
                  (imagePreviewPhotoIndex + 1) % images.length
                )
              }
            />
          ) : null}
        </div>
      ) : (
        <div className="messageBox__container">
          <Day
            currentMessageCreatedAt={message?.state?.timestamp}
            prevMessageCreatedAt={prevMessage?.state?.timestamp}
          />
          {!isMe && (
            <div className="messageBox__senderContainer">
              {/* <CustomAvatar
            name={senderName}
            imageUri={userImageUri}
            // size={30}
            // url={author?.state?.attributes?.avatar}
            // onClick={() => {}}
          /> */}
              {/* <Typography.Text
            className={
              rightNavChat === true
                ? "messageBox__textSenderNameRightNavChat"
                : "messageBox__textSenderName"
            }
          >
            {senderName}
          </Typography.Text> */}
            </div>
          )}
          <div
            className={classNames(
              "messageBox__wrapper",
              isMe && "messageBox__isMeWrapper"
            )}
          >
            {type === "text" || type === "sms" ? (
              <div
                className={classNames(
                  "messageBox__bubble",
                  isMe && "messageBox__bubbleMe"
                )}
              >
                <Linkify>
                  <Typography.Text
                    className={
                      rightNavChat === true
                        ? isMe
                          ? "messageBox__textMessageIsMeRightNavChat"
                          : "messageBox__textMessageRightNavChat"
                        : isMe
                        ? "messageBox__textMessageIsMe"
                        : "messageBox__textMessage"
                    }
                  >
                    {text}
                  </Typography.Text>
                </Linkify>
                {renderTime()}
              </div>
            ) : (
              renderImage()
            )}
          </div>

          {fromProposal && msgIndex - currentMsgIndex === 2 ? (
            <ScheduleMeeting
              sellerInfoObject={playbookDetails}
            ></ScheduleMeeting>
          ) : null}
          {fromProposal && msgIndex - currentMsgIndex === 2 ? (
            <CallMe
              playbookDetails={playbookDetails}
              onCallMeClicked={callMeClicked}
            ></CallMe>
          ) : null}

          {onCallMeClicked && renderSenderCallMe()}
          {/* {onCallMeClicked && renderPhoneInputField()} */}

          {/* {renderTime()} */}

          {imagePreviewOpen && images ? (
            <Lightbox
              mainSrc={images[imagePreviewPhotoIndex]}
              nextSrc={images[(imagePreviewPhotoIndex + 1) % images.length]}
              prevSrc={
                images[
                  (imagePreviewPhotoIndex + images.length - 1) % images.length
                ]
              }
              onCloseRequest={() => setImagePreviewOpen(false)}
              onMovePrevRequest={() =>
                setImagePreviewPhotoIndex(
                  (imagePreviewPhotoIndex + images.length - 1) % images.length
                )
              }
              onMoveNextRequest={() =>
                setImagePreviewPhotoIndex(
                  (imagePreviewPhotoIndex + 1) % images.length
                )
              }
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default Message;
