import { Tooltip } from "antd";
import CustomAvatar from "commons/CustomAvatar";
import { ProfilePlaceholder } from "images";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./index.scss";

const Avatar = ({
  url,
  isOnline,
  size = 34,
  onClick,
  currentConversation,
  refresh,
}) => {
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const [isUserOnline, setIsUserOnline] = useState(false);
  const [conversationImage, setConversationImage] = useState(null);
  const [tooltipTitle, setTooltipTitle] = useState("");

  useEffect(() => {
    checkUserOnline(currentConversation);

    // return () => {
    //   setIsUserOnline(false);
    // };
  }, [refresh, currentConversation]);

  const checkUserOnline = (conversation) => {
    setToolTipTitle(conversation);
    showReleventImage(conversation);
    conversation?.getParticipants()?.then((participants) => {
      if (
        participants.length === 2 ||
        participants.length === 3 ||
        participants.length === 4
      ) {
        participants?.forEach((p) => {
          if (p?.type === "chat") {
            p?.getUser()?.then((u) => {
              var userId = "";
              if (u?.state?.identity.includes("_")) {
                const myArray = u?.state?.identity.split("_");
                userId = myArray[0];
              } else {
                userId = u?.state?.identity;
              }
              if (userId != userSession.phoneNumber) {
                setIsUserOnline(u?.state?.online === true);
              }
            });
          }
        });
      }
    });
  };
  const showReleventImage = (conv) => {
    if (conv?.channelState?.attributes?.group_image_url !== undefined) {
      setConversationImage(conv?.channelState?.attributes?.group_image_url);
    } else if (conv?.channelState?.attributes?.group_image_url == undefined) {
      conv?.getParticipants()?.then((participants) => {
        if (participants?.length >= 2) {
          for (let i = 0; i < participants?.length; i++) {
            let p = participants[i];
            if (p?.state?.attributes?.id !== userSession.userId) {
              setConversationImage(p?.state?.attributes?.image_uri);
              break;
            }
          }
        }
      });
    }
  };

  const setToolTipTitle = (conv) => {
    if (
      conv?.channelState?.attributes?.seller_firstname !== undefined &&
      conv?.channelState?.attributes?.buyer_firstname !== undefined
    ) {
      setTooltipTitle(
        conv?.channelState?.attributes?.seller_firstname +
          " & " +
          conv?.channelState?.attributes?.buyer_firstname
      );
    }
  };
  return (
    <Tooltip placement="topRight" title={tooltipTitle}>
      <div className="avatar__container" onClick={onClick}>
        <CustomAvatar
          className="avatar__img"
          name={currentConversation?.friendlyName}
          imageUri={conversationImage}
          // src={url || ProfilePlaceholder}
          // style={{ width: size, height: size }}
        />
        {isUserOnline ? (
          <div className="avatar__onlineBadge" />
        ) : (
          <div className="avatar__offlineBadge" />
        )}
      </div>
    </Tooltip>
  );
};

export default Avatar;
