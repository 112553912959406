import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Image, Modal } from "antd";
import { IconCallMe } from "images";
import "./index.scss";

function CallMe({ playbookDetails, onCallMeClicked }) {
  const { confirm } = Modal;

  const makePhoneCallConfirmPopup = () => {
    confirm({
      className: "Connectting to Seller",
      title:
        "Just wait for few seconds, we are connecting you with the seller...",
      icon: <ExclamationCircleOutlined />,
      // okText: "Ok",

      onOk() {
        //makePhoneCall();
        // window.open("tel: +14153670390");
        window.location = "tel: +14153670390";
        onCallMeClicked();
      },
      onCancel() {},
    });
  };

  const makePhoneCall = () => {
    return (
      <a
        className="callMe__callMeText"
        href={"tel: +14153670390"}
        // href={"tel:" + playbookDetails?.seller?.phone_number}
        // onClick={onCallMeClicked}
      >
        Call me
      </a>
    );
  };

  return (
    <div className="callMe">
      <div className="callMe__callMeDiv">
        {/* <Image
          rootClassName="callMe__iconCallMe"
          preview={false}
          src={IconCallMe}
        ></Image> */}
        <a
          className="callMe__callMeText"
          // href={"tel: +14153670390"}
          // href={"tel:" + playbookDetails?.seller?.phone_number}
          onClick={makePhoneCallConfirmPopup}
        >
          Call me
        </a>
      </div>
    </div>
  );
}

export default CallMe;
