import React, { useState } from "react";
import "./index.scss";
import { Image, Input, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft, Facebookads } from "images";
import ConnectFacebookAds from "./Create/Screen1";

function FacebookAdsCatalog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/sources/setup/${"facebookads"}/${id}`);
  };
  const handleBackClick = () => {
    // Navigate back to the previous page
    navigate(-1); // Go back one step
  };

  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };

  return (
    <div className="FacebookAdsCatalog">
      <div className="FacebookAdsCatalog__Fixed">
        {/* <div className="FacebookAdsCatalog__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span
            className="FacebookAdsCatalog__title"
            onMouseEnter={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.textDecoration = "none")
            }
            style={{ cursor: "pointer" }}
            onClick={handleConnectionClick}
          >
            Connections
          </span>
          <span className="FacebookAdsCatalog__slash">/</span>
          <span
            className="FacebookAdsCatalog__title"
            style={{ cursor: "pointer" }}
            onClick={handleBackClick}
            onMouseEnter={(e) =>
              (e.currentTarget.style.textDecoration = "underline")
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.textDecoration = "none")
            }
          >
            Catalog
          </span>
          <span className="FacebookAdsCatalog__slash">/</span>
          <span className="FacebookAdsCatalog__mainTitle">Facebook Ads</span>
        </div> */}
        <div className="FacebookAdsCatalog__sourceText">
          <div>
            <Image
              src={Facebookads}
              preview={false}
              style={{ width: "50px", height: "50px", marginRight: "10px" }}
            />
              <span style={{ marginLeft: "15px" }}>Facebook Ads</span>
          </div>
        </div>
        <div className="FacebookAdsCatalog__bar">
          <div>
            <Button
              onClick={() => navigateToFields()}
              className="FacebookAdsCatalog__button"
            >
              Add source
              <ArrowRightOutlined className="FacebookAdsCatalog__btnImage" />
            </Button>
          </div>
        </div>

        <div className="FacebookAdsCatalog__newSource">
          <Button className="FacebookAdsCatalog__newText">Source</Button>
        </div>
        <div className="FacebookAdsCatalog__exploreBar">
          <div className="FacebookAdsCatalog__exploreText">
            Facebook Ads can help you reach the more than two billion people who
            use Facebook
          </div>
        </div>
        <div className="FacebookAdsCatalog__bottomBar"></div>
      </div>
      <div className="FacebookAdsCatalog__mainContainer">
        <div className="FacebookAdsCatalog__flexDiv">
          <div className="FacebookAdsCatalog__container1">
            <h6 className="FacebookAdsCatalog__cardHeading1">
              Compatible destination types
            </h6>
            <div className="FacebookAdsCatalog__textFlex">
              <div className="FacebookAdsCatalog__cardIcon">
                <MenuOutlined />
              </div>
              <div className="FacebookAdsCatalog__cardText">Storage</div>
            </div>
            <p className="FacebookAdsCatalog__rawText">
              Destinations for storing raw data.
              {/* <a className="FacebookAdsCatalog__anchorTag">Learn more</a> */}
            </p>
          </div>
          <div className="FacebookAdsCatalog__container2">
            <div className="FacebookAdsCatalog__containFlex">
              <div className="FacebookAdsCatalog__line"></div>
              <div className="FacebookAdsCatalog__justDiv">
                <h6 className="FacebookAdsCatalog__cardHeading2">
                  Set up requirements
                </h6>
                <p className="FacebookAdsCatalog__setText1">
                  * A Facebook acount with Ads manager enabled
                </p>
                {/* <p className="FacebookAdsCatalog__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="FacebookAdsCatalog__flexDiv1">
          <div className="FacebookAdsCatalog__container3">
            <h6 className="FacebookAdsCatalog__overviewHeading1">Overview</h6>
            <p className="FacebookAdsCatalog__overviewText">
              When you enter your Facebook credentials and hit save, we will
              sync your Facebook Ads objects to any databases you have turned on
              (to turn on a database, navigate to the database tab in the
              navigation pane on the left). These objects will be written into a
              separate schema, corresponding to your project’s name. Facebook
              Ads data will reflect the state of the object at the time of the
              sync: so if a ticket status goes from `unassigned` to `assigned`
              between syncs, on its next sync that tickets status will be
              `assigned`. We will update Facebook Ads data every ~3 hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FacebookAdsCatalog;
