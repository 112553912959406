import Amplify, { Auth } from "aws-amplify";
import {
  COGNITO_REGION,
  COGNITO_USER_POOL_ID,
  COGNITO_CLIENT_ID,
} from "../../environments";

Amplify.configure({
  Auth: {
    region: COGNITO_REGION,
    userPoolId: COGNITO_USER_POOL_ID,
    userPoolWebClientId: COGNITO_CLIENT_ID,
    authenticationFlowType: "USER_SRP_AUTH",
  },
});

export const signUpWithUsernamePassword = (username, password) => {
  return new Promise((resolve, reject) => {
    Auth.signUp({ username, password })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const confirmSignUpWithUsernameCode = (username, code) => {
  return new Promise((resolve, reject) => {
    Auth.confirmSignUp(username, code)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resendSignUpConfirmationCode = (username) => {
  return new Promise((resolve, reject) => {
    Auth.resendSignUp(username)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const signInWithUsernamePassword = (username, password) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, password)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgotPassword = (username) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(username)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const forgotPasswordSubmit = (username, code, password) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(username, code, password)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changePassword = (oldPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        Auth.changePassword(user, oldPassword, newPassword)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const logoutUser = () => {
  return new Promise((resolve, reject) => {
    Auth.signOut()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const refreshAuthorization = () => {
  return new Promise(async (resolve, reject) => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.getRefreshToken,
      async (error, session) => {
        if (error) {
          console.log("Unable to refresh token", error);
          return reject(error);
        } else {
          return resolve(session);
        }
      }
    );
  });
};
