import { message } from "antd";
import axios from "axios";
import { Buffer } from "buffer";
import jwtDecode from "jwt-decode";
import { randomize } from "utils";
import { TWILIO_PASSWORD, TWILIO_USER_NAME } from "environments";
import { updateConversationAttributes } from "api/TwilioChat";

export const username = TWILIO_USER_NAME;
export const password = TWILIO_PASSWORD;

export const token = Buffer.from(`${username}:${password}`, "utf8").toString(
  "base64"
);

export const arr = [];
export const randomizeData = randomize(arr);

export const createTwilioConversation = async (chatName, uniqueName) => {
  let response = null;
  try {
    response = await axios.post(
      `https://conversations.twilio.com/v1/Conversations`,
      new URLSearchParams({
        FriendlyName: chatName,
        UniqueName: uniqueName,
      }),
      {
        headers: {
          Authorization: `Basic ${token}`,
          "Content-type": "application/x-www-form-urlencoded",
        },
      }
    );
  } catch (err) {
    console.log("Create chat error: ", err);
  }

  return response;
};

export const addTwilioChatParticipants = async (
  sid,
  participantsList,
  selectedWorkspaceId
) => {
  let result = false;
  for (let j = 0; j < participantsList.length; j++) {
    let addParticopantResponse = await addChatParticipants(
      sid,
      participantsList[j],
      selectedWorkspaceId
    );
    if (addParticopantResponse !== null) {
      // console.log("addParticopantResponse A", addParticopantResponse);
      let cpSid = addParticopantResponse?.data?.sid;
      if (cpSid !== null && j === participantsList.length - 1) {
        result = true;
      }
    }
  }
  return result;
};

const addChatParticipants = async (sid, resultUser, selectedWorkspaceId) => {
  let response = null;
  try {
    // participantsList?.forEach((resultUser) => {
    response = await axios.post(
      `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
      new URLSearchParams({
        Identity: resultUser.phone_number + "-" + resultUser.id,
        Attributes: JSON.stringify(resultUser),
      }),
      {
        headers: {
          Authorization: `Basic ${token}`,
          "Content-type": "application/x-www-form-urlencoded",
        },
      }
    );
    // });
  } catch (err) {
    console.log("Add chat participant error: ", err);
  }

  return response;
};

export const addTwilioSMSParticipants = async (sid, participantsList) => {
  let result = false;
  for (let j = 0; j < participantsList.length; j++) {
    let addSMSParticipantResponse = await addSMSParticipants(
      sid,
      participantsList[j]
    );
    if (addSMSParticipantResponse !== null) {
      // console.log("addSMSParticipantResponse B", addSMSParticipantResponse);
      let cpSid = addSMSParticipantResponse?.data?.sid;
      if (cpSid !== null && j === participantsList.length - 1) {
        result = true;
      }
    }
  }
  return result;
};

const addSMSParticipants = async (sid, resultUser) => {
  let response = null;
  let twilioPhoneNumber = randomizeData.randomItem();
  try {
    // participantsList?.forEach((resultUser, i) => {
    response = await axios.post(
      `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
      new URLSearchParams({
        "MessagingBinding.Address": resultUser.phone_number,
        "MessagingBinding.ProxyAddress": twilioPhoneNumber,
        Attributes: JSON.stringify(resultUser),
      }),
      {
        headers: {
          Authorization: `Basic ${token}`,
          "Content-type": "application/x-www-form-urlencoded",
        },
      }
    );
  } catch (error) {
    console.log("Add sms participant error: ", error);
    if (error?.response?.data?.code === 50416) {
      // Twilio error code 50416 means A binding for this participant and proxy address already exists in some Conversation
      addSMSParticipantsWithDifferentNumber(sid, resultUser);
    } else if (error?.response?.data?.message) {
      message.error(error.response.data.message);
    } else {
      message.error(error?.message);
    }
  }
  return response;
};

const addSMSParticipantsWithDifferentNumber = async (sid, resultUser) => {
  let twilioPhoneNumber = randomizeData.randomItem();
  try {
    await axios.post(
      `https://conversations.twilio.com/v1/Conversations/${sid}/Participants`,
      new URLSearchParams({
        "MessagingBinding.Address": resultUser.phone_number,
        "MessagingBinding.ProxyAddress": twilioPhoneNumber,
        Attributes: JSON.stringify(resultUser),
      }),
      {
        headers: {
          Authorization: `Basic ${token}`,
          "Content-type": "application/x-www-form-urlencoded",
        },
      }
    );
  } catch (error) {
    if (error?.response?.data?.code === 50416) {
      // Twilio error code 50416 means A binding for this participant and proxy address already exists in some Conversation
      addSMSParticipants(sid, resultUser);
    } else if (error?.response?.data?.message) {
      message.error(error.response.data.message);
    } else {
      message.error(error?.message);
    }
  }
};

export const sendMessageFromSeller = async (
  convSid,
  playbookData,
  defaultMessage
) => {
  const attributesObj = {
    id: playbookData?.seller?.seller_id,
    first_name: playbookData?.seller?.first_name,
    last_name: playbookData?.seller?.last_name,
    phone_number: playbookData?.seller?.phone_number,
    image_uri: playbookData?.seller?.image_uri,
  };

  let response = null;
  try {
    response = await axios.post(
      `https://conversations.twilio.com/v1/Conversations/${convSid}/Messages`,
      new URLSearchParams({
        Author: playbookData?.seller?.phone_number,
        Body: defaultMessage,
        Attributes: JSON.stringify(attributesObj),
      }),
      {
        headers: {
          Authorization: `Basic ${token}`,
          "Content-type": "application/x-www-form-urlencoded",
        },
      }
    );
  } catch (error) {
    console.log("Send first sms error: ", error);
  }
  return response;
};

export const updateTwilioConvAttributes = async (cId, attr) => {
  const payloadForConvAttributes = {
    conversationId: cId,
    attributes: attr,
  };
  updateConversationAttributes(payloadForConvAttributes)
    .then((attrRes) => {
      window.history.replaceState({}, document.title);
    })
    .catch((attrErr) => {});
};
