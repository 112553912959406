import Popover from "antd/lib/popover";
import { Picker as EmojiMart } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import React from "react";
import EmojiIcon from "../../../images/emoji.svg";

const EmojiButton = ({ onSelect }) => {
  const renderEmojiPicker = () => (
    <EmojiMart
      onSelect={(emoji) => onSelect(emoji.native)}
      title="Pick your emoji…"
      emoji="point_up"
      // darkMode={false}
      showPreview={false}
      showSkinTones={false}
      style={{ width: 375, border: 0, margin: -12.5 }}
    />
  );
  return (
    <Popover content={renderEmojiPicker()} placement="topRight">
      <img src={EmojiIcon} />
    </Popover>
  );
};

export default EmojiButton;
