import { Tooltip, Image } from "antd";
import "./index.scss";
import IconTooltip from "../../../images/tooltip.svg";
import { render } from "@testing-library/react";

export const getIdColumn = ({ title, tooltip, dataIndex }, idClicked) => {
  return {
    title: () => {
      return (
        <span className="idHeader">
          {title}
          {/* {tooltip && (
            <Tooltip title={tooltip}>
              <Image
                className="idHeader__tooltip"
                src={IconTooltip}
                preview={false}
              />
            </Tooltip>
          )} */}
        </span>
      );
    },
    dataIndex: dataIndex,
    width: 130,
    render: (id) => {
      return (
        <a className="idValue" onClick={() => idClicked(id)}>
          {id}
        </a>
      );
    },
  };
};

export const getNameColumn = ({ title, tooltip, dataIndex }, nameClicked) => {
  return {
    title: title,
    dataIndex: dataIndex,
    render: (text, record) => {
      return (
        <a className="idValue" onClick={() => nameClicked(record)}>
          {text}
        </a>
      );
    },
  };
};

// Function to truncate text to 25 characters and add ellipsis if needed
const truncateText = (text) => {
  return (text != null && text.length) > 40
    ? text.substring(0, 40) + "..."
    : text;
};
