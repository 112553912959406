import "./index.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MarkDoneEmpty, MarkedGreen} from "images";
import { Card, Collapse, Drawer, Image } from "antd";
import { useDispatch , useSelector } from "react-redux";
import { setFormData, setStepCompletion } from "store/modules/datasets/action";
import CustomSyncRecurrence from "./AddExclusionCustomSyncRecurrence";

const { Panel } = Collapse;

function AddExclusions() {
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false); 
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.stepsReducer);

  const handleInputChange = (subStep, subStepKey, value) => {
    const isStepCompleted = true;
    dispatch(setStepCompletion(3, isStepCompleted));
    dispatch(setFormData(subStep, subStepKey, value));
    dispatch(setStepCompletion(subStep, true));
  };

  const CustomPanelHeader = ({ mainHeading }) => (
    <Card className="buildList__customPanelHeader">
      <div className="buildList__customPanelHeader-header">
        <Image
          src={MarkDoneEmpty}
          preview={false}
          className="buildList__customPanelHeader-image"
        />
        <div>
          <p className="buildList__customPanelHeader-mainHeading">
            {mainHeading}
          </p>
        </div>
      </div>
    </Card>
  );
  const CustomCardContent = ({ mainHeading = "", onClick, number }) => (
    <Card className="buildList__customCardContent" onClick={onClick}>
      <div className="buildList__customCardContent-header">
        <Image
          src={
            steps && steps.steps[number]?.completed
              ? MarkedGreen
              : MarkDoneEmpty
          }
          preview={false}
          className="buildList__customCardContent-image"
        />
        <p className="buildList__customCardContent-mainHeading">
          {mainHeading}
        </p>
      </div>
    </Card>)


  const handleAddExclusionsSelectSourceClicked = () => {
    navigate(`/dashboard/datasets/datasets/add-exclusions/select-source`);
  };
  const handleAddExclusionsPasteQueryClicked = () => {
    navigate(`/dashboard/datasets/datasets/add-exclusions/paste-query`);
  };
  const handleWhenToRunQueryClicked = () => {
    setDrawerVisible(true);
  };

  return (
    <div className="buildList">
      <div className="buildList__mainList">
        <CustomPanelHeader mainHeading="Who should be included in this list?" />
      </div>

      <div className="buildList__subList">
        <CustomCardContent
          mainHeading="Select your Source"
          onClick={handleAddExclusionsSelectSourceClicked}
          number={3.1}
        />
        <CustomCardContent
          mainHeading="Paste the query"
          onClick={handleAddExclusionsPasteQueryClicked}
          number={3.2}
        />
        <CustomCardContent
          mainHeading="When would you like this query to run"
          onClick={handleWhenToRunQueryClicked}
        />
      </div>

      <Drawer
        placement="bottom"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        height="90%"
        className="customdrawer"
      >
        <CustomSyncRecurrence
              onDoneClicked={() => {
                handleInputChange(3.3, "customSyncRecurrence", "done");
                setDrawerVisible(false);
                navigate(-1);
              }}
            />
      </Drawer>
    </div>
  );
}

export default AddExclusions;
