import React from "react";
import "./index.scss";
import { Button, Divider, message, Tooltip } from "antd";
import { Location, ShareableLink } from "images";
import CreatorDetailsMobile from "../CreatorDetailsMobile";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { joinWorkspace } from "api/Workspaces";
import { setUserSession } from "store/modules/auth/action";
import { capitalizeFirstCharacter } from "utils";
import { useNavigate } from "react-router-dom";

function SingleWorkspaceMobile({
  actionButtonText,
  itemData = {},
  setLoader,
  removeItem,
}) {
  const [loading, setLoading] = useState(false);
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const isSellerMode = userSession?.accountRole === "seller";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tooltipText, setTooltipText] = useState("");

  useEffect(() => {
    let remainingTags = "";
    if (itemData?.secondary_tag?.length > TAGS_TO_SH0W) {
      for (var i = TAGS_TO_SH0W; i < itemData?.secondary_tag?.length; i++) {
        if (i === itemData?.secondary_tag?.length - 1) {
          remainingTags = remainingTags + "" + itemData?.secondary_tag?.[i];
        } else {
          remainingTags =
            remainingTags + "" + itemData?.secondary_tag?.[i] + ", ";
        }
      }
      setTooltipText(remainingTags);
    }
  });
  let isEnabled = true;
  if (itemData.isJoined) {
    actionButtonText = "Joined";
    isEnabled = false;
  }
  if (itemData.isOwner) {
    actionButtonText = "Owner";
    isEnabled = false;
  }
  if (isSellerMode) {
    actionButtonText = "Edit";
    isEnabled = false;
  }
  const TAGS_TO_SH0W = 1;
  const joinButtonClicked = () => {
    const payload = {
      workspace_id: itemData.id, // workspace to join
      buyer_profile_id: userSession.buyerProfileId, // current buyer profile id
      seller_profile_id: userSession.sellerProfileId, // current buyer profile id
    };
    setLoader(true);
    callJoinWorkspaceAPI(payload);
  };

  const callJoinWorkspaceAPI = (payload) => {
    joinWorkspace(payload)
      .then((res) => {
        removeItem();
        message.success(res.data.message);
        let updatedUserSession = { ...userSession };
        updatedUserSession.selectedWorkspaceId = parseInt(payload.workspace_id);
        updatedUserSession.workspaceCategoryId = itemData.workspace_category_id;
        updatedUserSession.buyerProfileWorkspaceId =
          res?.data?.data?.buyer_profile_workspace_id;
        updatedUserSession.sellerProfileWorkspaceId =
          res?.data?.data?.seller_profile_workspace_id;
        updatedUserSession.isDefaultWorkspace = false;

        dispatch(setUserSession(updatedUserSession));

        navigate("dataset/datasets");
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoader(false);
        setLoading(false);
      });
  };

  return (
    <div className="singleWorkspaceMobile">
      <div className="singleWorkspaceMobile__playbookAndSellerContainer">
        <div className="singleWorkspaceMobile__playbookAndSellerContainer__left">
          <div className="singleWorkspaceMobile__playbookAndSellerContainer__left__row1">
            <span className="singleWorkspaceMobile__playbookAndSellerContainer__left__row1__title">
              {itemData.name + ""}
            </span>
            {/* <span
              className={` ${
                itemData.primary_tag_color === "Orange"
                  ? "singleWorkspace__playbookAndSellerContainer__left__row1__h1Tag"
                  : itemData.primary_tag_color === "Blue"
                  ? "singleWorkspace__playbookAndSellerContainer__left__row1__h1TagBlue"
                  : itemData.primary_tag_color === "Green"
                  ? "singleWorkspace__playbookAndSellerContainer__left__row1__h1TagGreen"
                  : "singleWorkspace__playbookAndSellerContainer__left__row1__h1TagCyan"
              }`}
            >
              {itemData.primary_tag === null ||
              itemData.primary_tag === undefined
                ? itemData.primary_tag + ""
                : itemData.primary_tag.toUpperCase()}
            </span> */}
          </div>
          <span className="singleWorkspaceMobile__playbookAndSellerContainer__left__shortDescription">
            {"Created: " + itemData.created.split("T")[0] + ""}
          </span>

          <div className="singleWorkspaceMobile__playbookAndSellerContainer__left__locationAndLink">
            <img
              className="singleWorkspaceMobile__playbookAndSellerContainer__left__locationAndLink__image"
              src={Location}
            />
            <span className="singleWorkspaceMobile__playbookAndSellerContainer__left__locationAndLink__text">
              {itemData.location + ""}
            </span>
            <img
              style={{ marginLeft: 8 }}
              className="singleWorkspaceMobile__playbookAndSellerContainer__left__locationAndLink__image"
              src={ShareableLink}
            />
            <span className="singleWorkspaceMobile__playbookAndSellerContainer__left__locationAndLink__text">
              Shareable link
            </span>
          </div>

          <span className="singleWorkspaceMobile__playbookAndSellerContainer__left__replyRate"></span>
        </div>
        <div className="singleWorkspaceMobile__playbookAndSellerContainer__right">
          <CreatorDetailsMobile
            creatorDetails={itemData.userInfo[0]}
            userRole={itemData.user_role}
            marketingStatement={itemData.short_description}
            rating={itemData?.ratings}
          />
        </div>
      </div>

      <div className="singleWorkspaceMobile__descriptionAndReadMoreContainer">
        <div className="singleWorkspaceMobile__descriptionAndReadMoreContainer__left">
          <span className="singleWorkspaceMobile__descriptionAndReadMoreContainer__left__description">
            {itemData.description + ""}
          </span>
        </div>
        {/* <div className="singleWorkspaceMobile__descriptionAndReadMoreContainer__right">
          <Button className="singleWorkspaceMobile__descriptionAndReadMoreContainer__right__readMoreBtn">
            Read More
          </Button>
        </div> */}
      </div>

      <div className="singleWorkspaceMobile__tagsAndActions">
        <div className="singleWorkspaceMobile__tagsAndActions__left">
          {itemData?.secondary_tag?.slice(0, TAGS_TO_SH0W).map((item) => (
            <span className="singleWorkspaceMobile__tagsAndActions__left__eachPill">
              {capitalizeFirstCharacter(item)}
            </span>
          ))}

          {itemData?.secondary_tag?.length > TAGS_TO_SH0W && (
            <Tooltip title={tooltipText}>
              <span className="singleWorkspaceMobile__tagsAndActions__left__eachPill">
                +{itemData?.secondary_tag?.length - TAGS_TO_SH0W}
              </span>
            </Tooltip>
          )}
        </div>

        <div className="singleWorkspaceMobile__tagsAndActions__right">
          <Button
            className={
              isEnabled
                ? "singleWorkspaceMobile__tagsAndActions__right__actions"
                : "singleWorkspaceMobile__tagsAndActions__right__actionsDisabled"
            }
            onClick={joinButtonClicked}
            loading={loading}
          >
            {actionButtonText}
          </Button>
        </div>
      </div>

      <Divider className="singleWorkspaceMobile__divider" />
    </div>
  );
}

export default SingleWorkspaceMobile;
