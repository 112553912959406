import React, { useState, useEffect } from "react";
import { Table, Input, Spin, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { getProfileIdentities } from "api/Explorer";
import { useParams } from "react-router-dom";
import "./index.scss";
import "./index.scss";

const Identity = () => {
  const { revId } = useParams();
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [loading, setLoading] = useState(false); // State for loader
  const [searchResults, setSearchResults] = useState([]); // State for API results
  const [filteredData, setFilteredData] = useState([]); // State for filtered data

  // API call to fetch search results
  const fetchSearchResults = async (query) => {
    setLoading(true); // Show loader when API call starts

    try {
      const res = await getProfileIdentities(revId);
      const results = res?.data?.data?.map((item) => ({
        user: `${item.name || ""}`.trim(),
        universalId: item.people_id || "N/A",
        source: item.source,
        email: item.email,
        phone: item.phone_number,
        address: item.address,
        accurateToDate: item.accurate_to_date, // Example data, update if available
      }));
      setSearchResults(results || []);
    } catch (err) {
      console.error("Error fetching search results:", err.message);
    } finally {
      setLoading(false); // Hide loader when API call completes
    }
  };

  // Use useEffect to fetch search results when searchTerm changes
  useEffect(() => {
    if (searchTerm) {
      fetchSearchResults(searchTerm);
    } else {
      fetchSearchResults(""); // Clear results if searchTerm is empty
    }
  }, [searchTerm]);

  // Filter data based on search term
  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = searchResults.filter((item) => {
      return item?.user.toLowerCase().includes(lowerCaseSearchTerm);
    });
    setFilteredData(filtered);
  }, [searchResults, searchTerm]);

  // Define table columns
  const columns = [
    {
      title: "PERSON ID",
      dataIndex: "universalId",
      key: "universalId",
      width: 90,
    },
    {
      title: "NAME",
      dataIndex: "user",
      key: "user",
      width: 130,
    },
    {
      title: "SOURCE",
      dataIndex: "source",
      key: "source",
      width: 150,
      render: (text) => (
        <Tooltip title={text}>
          <span>
            {text?.length > 20 ? `${text.substring(0, 20)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "PHONE",
      dataIndex: "phone",
      key: "phone",
      width: 150,
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
      key: "address",
      width: 150,
      render: (text) => (
        <Tooltip title={text}>
          <span>
            {text?.length > 20 ? `${text.substring(0, 20)}...` : text}
          </span>
        </Tooltip>
      ),
    },
    // {
    //   title: "LINKEDIN URL",
    //   dataIndex: "linkedin_url",
    //   key: "linkedin_url",
    //   width: 200,
    //   render: (text) => (
    //     <Tooltip
    //       title={
    //         <a href={text} target="_blank" rel="noopener noreferrer">
    //           {text?.length > 200 ? `${text.substring(0, 200)}...` : text}
    //         </a>
    //       }
    //     >
    //       <a href={text} target="_blank" rel="noopener noreferrer">
    //         {text?.length > 30 ? `${text.substring(0, 30)}...` : text}
    //       </a>
    //     </Tooltip>
    //   ),
    // },
    {
      title: "ACCURATE TO DATE",
      dataIndex: "accurateToDate",
      key: "accurateToDate",
      width: 150,
    },
  ];

  return (
    <div className="identity-profile">
      <div className="identity-profile__inputWrapper">
        <Input
          className="identity-profile__input"
          type="search"
          placeholder="Search by name"
          prefix={<SearchOutlined style={{ color: "grey" }} />}
          onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
        />
      </div>
      {/* Show loading spinner while data is being fetched */}
      <Spin spinning={loading} size="medium">
        <Table
          className="identity-profile__table"
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          // tableLayout="fixed" // Fix table layout
        />
      </Spin>
    </div>
  );
};

export default Identity;
