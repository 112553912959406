import { Layout } from "antd";
import SettingsRightNav from "components/SettingsRightNav";
import React, { useState, useEffect } from "react";
import { useCustomEventListener } from "react-custom-events";
import { Outlet, useLocation } from "react-router-dom";
import { isMobile } from "utils";
import "./index.scss";

const { Content } = Layout;

function Settings() {
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [isRightNavItemClicked, setIsRightNavItemClicked] = useState(false);
  const location = useLocation();

  const rightNavItemClicked = (isClicked) => {
    setIsRightNavItemClicked(isClicked);
  };
  
  useCustomEventListener("header_settings_clicked", (data) => {
    setIsRightNavItemClicked(data);
  });

  useEffect(() => {
    if(location?.pathname== '/dashboard/settings'){
      setIsRightNavItemClicked(false);
    }
  }, [location]);

  return (
    <>
      {isMobileView === true ? (
        <div className="settingsPage">
          {isRightNavItemClicked === true ? (
            <Layout>
              <Content className="settingsPage__contentMobile">
                <Outlet />
              </Content>
            </Layout>
          ) : (
            <SettingsRightNav
              onItemClick={rightNavItemClicked}
            ></SettingsRightNav>
          )}
        </div>
      ) : (
        <div className="settingsPage">
          <Layout>
            <Content className="settingsPage__content">
              <Outlet />
            </Content>
          </Layout>
        </div>
      )}
    </>
  );
}

export default Settings;
