import React from "react";
import "./index.scss";
import { Empty, Form, List, Divider, message, Spin } from "antd";
import { SHOULD_SEARCH_ON_PAGE_LOAD } from "constants";
import { DEFAULT_DATE_FORMAT } from "constants";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Searchbar from "components/Datasets/Commons/Searchbar";
import SideFilters from "components/Datasets/Commons/SideFilters";
import FilterFieldsContainer from "components/Datasets/Commons/SideFilters/Fields/FilterFieldsContainer";

import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "components/Datasets/Commons/SideFilters/Fields/FilterDropdown";
import FilterDatePicker from "components/Datasets/Commons/SideFilters/Fields/FilterDatePicker";
import FilterRatingDropdown from "components/Datasets/Commons/SideFilters/Fields/FilterRatingDropdown";
import { getProposalFilterValues, proposalUsingFilters } from "api/Playbooks";
import NewSingleProposal from "./SingleProposal/NewSingleProposal/index";
import MobileSingleProposal from "./SingleProposal/mSingleProposal";
import { isMobile } from "utils";
// import SingleProposal from "./SingleProposal";
import { trackPageCall } from "analytics";
import Flex from "screens/Chat/Flex";

function Proposals() {
  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const [spinning, setSpinning] = useState(true);
  const [searchedFilters, setSearchedFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const { accountId, userId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const userSession = useSelector((state) => state.authReducer).userSession;
  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [form] = Form.useForm();
  const authReducer = useSelector((state) => state.authReducer);
  const externalLinkSegmentParams = authReducer.externalLinkSegmentParams;

  const statusDropdownData = ["Requested"];

  const { buyerProfileWorkspaceId, selectedWorkspaceId} = useSelector(
    (state) => state.authReducer
  ).userSession;


  useEffect(() => {
    setSpinning(true);

    const pageCallData = {
      marketplaceId: userSession.selectedWorkspaceId,
    };
    const mergedJson = {
      ...pageCallData,
      ...externalLinkSegmentParams,
    };
    trackPageCall("Proposals", mergedJson);
    getFilters();
  }, []);

  useEffect(() => {
    if (shouldFetchResults.current) {
      getSearchResults();
    }
  }, [searchedText, searchedFilters]);

  const getFilters = () => {
    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
    };

    getProposalFilterValues(payload)
      .then((res) => {
        const filters = res.data.data;
        setFilterOptions({
          ...filters,
        });
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const [isMobileView, setIsMobileView] = useState(isMobile());
  useEffect(() => {
    function handleResize() {
      setIsMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onApplyFilters = () => {
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    setSearchedFilters(form.getFieldsValue());
  };
  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
  };
  const filterFields = [
    <FilterFieldsContainer
      label="Status"
      onClear={() => {
        onResetFields(["status"]);
      }}
    >
      <FilterDropdown
        name="status"
        placeholder="Status"
        options={statusDropdownData}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Tags"
      onClear={() => {
        onResetFields(["tags"]);
      }}
    >
      <FilterDropdown
        name="tags"
        placeholder="Enter tags here…"
        options={filterOptions.tags}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Created before"
      onClear={() => {
        onResetFields(["createdBefore"]);
      }}
    >
      <FilterDatePicker name="createdBefore" onChange={onApplyFilters} />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Location"
      onClear={() => {
        onResetFields(["location"]);
      }}
    >
      <FilterDropdown
        name="location"
        placeholder="Enter location here…"
        options={filterOptions.location}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Rating"
      onClear={() => {
        onResetFields(["rating"]);
      }}
    >
      <FilterRatingDropdown
        name="rating"
        placeholder="Enter rating here…"
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
  ];

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });

    const payload = {
      // profile_workspace_id: 505,
      profile_workspace_id: buyerProfileWorkspaceId,
      workspace_id:selectedWorkspaceId,
      query: searchedText,
      status: filters["status"],
      location: filters["location"],
      tags: filters["tags"],
      created_before: filters["createdBefore"],
      rating: filters["rating"],
    };

    proposalUsingFilters(payload)
      .then((res) => {
        setSearchResults(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setSpinning(false);
        setLoading(false);
      });
  };

  return (
    <div className="proposals">
      <div className="proposals__searchContainer">
        <Searchbar
          placeholder="Search Proposals"
          filtersVisible={showFilters}
          onFiltersVisibilityChange={setShowFilters}
          onSubmitSearchText={(searchedText) => {
            shouldFetchResults.current = true;
            setIsBeginingSearch(false);
            setSearchedText(searchedText);
          }}
          filtersApplied={
            !isEmpty(
              Object.values(searchedFilters).filter((value) => {
                return !isEmpty(value);
              })
            )
          }
        />
      </div>

      <div className="proposals__filterAndResults">
        <>
          {showFilters && (
            <div className="proposals__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && (
            <div className="proposals__filterAndResults__proposalsList">
              <div className="proposals__filterAndResults__proposalsList__header">
                <p className="proposals__filterAndResults__proposalsList__header__text">
                  Proposals (
                  {searchResults?.length > 0 ? searchResults.length : 0})
                </p>
                <Divider className="proposals__filterAndResults__proposalsList__header__divider" />
              </div>
              {spinning ? (
                <Flex
                  flex={1}
                  justify="center"
                  align="center"
                  style={{
                    width: "100%",
                    height: 500,
                    backgroundColor: "#fff",
                  }}
                  direction="column"
                >
                  <Spin size="default" />
                  <div> Loading...</div>
                </Flex>
              ) : !isEmpty(searchResults) ? (
                <List
                  // grid={{ gutter: 0, column: 3 }}
                  dataSource={searchResults}
                  className="proposals__list"
                  renderItem={(item, index) => {
                    return (
                      <div className="proposals__datas">
                        {isMobileView === true ? (
                          <MobileSingleProposal
                            className="proposals__single"
                            itemData={item}
                          />
                        ) : (
                          <NewSingleProposal
                            className="proposals__single"
                            itemData={item}
                          />
                        )}
                      </div>
                    );
                  }}
                />
              ) : (
                <Empty />
              )}
            </div>
          )}
        </>
      </div>
    </div>
  );
}

export default Proposals;
