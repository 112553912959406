import React, { useState } from "react";
import { Button } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import "./index.scss";

function Google() {
  const [current, setCurrent] = useState("desktop");

  const handleMobileClick = (event) => {
    setCurrent("mobile");
  };
  const handleDesktopClick = (event) => {
    setCurrent("desktop");
  };

  return (
    <>
      <div className={current === "desktop" ? "Google" : "GoogleMobile"}>
        <div className="Google__Grey">
          <div className="Google__Grey__Box">
            <div className="Google__Content">
              <p className="Google__Content__Para-1">Ad</p>
              <p
                className={
                  current === "desktop"
                    ? "Google__Content__Para-2"
                    : "GoogleMobile__Content__Para-2"
                }
              >
                .
              </p>
              <p className="Google__Content__Para-3">
                https://www.fidelityamericanhomes.com/
              </p>
              <p className="Google__Content__Para-4">
                <CaretDownOutlined />
              </p>
            </div>
            <div className="Google__Content-1">
              Fidelity American Homes | Cash Offer For Your Homes | Playbooks
            </div>
            <div className="Google__Content-2">
              We have cash buyers and would love to show you an offer. View your
              offers now, make a decision later and close in 7 days.
            </div>
          </div>
        </div>
      </div>

      <div className="GoogleViewbutton">
        <Button
          className="GoogleViewbutton__DesktopButton"
          onClick={handleDesktopClick}
        >
          Desktop
        </Button>
        <Button
          className="GoogleViewbutton__MobileButton"
          onClick={handleMobileClick}
        >
          Mobile
        </Button>
        <Button
          className={
            current === "desktop" ? "Google__Create" : "GoogleMobile__Create"
          }
        >
          Create
        </Button>
      </div>
    </>
  );
}

export default Google;
