import ChatUI from "screens/Chat/ChatUI";
import "./index.scss";

function ChatRightNav(props) {
  //
  return (
    <div className="chatRightNav">
      <div className="chatRightNav__rightNavChatUIDiv">
        {props.currentConversation !== null && (
          <ChatUI
            rightNavChat={true}
            conversation={props.currentConversation}
            ourAppUsers={props.appUsers}
            playbookDetails={props.proposalDetailsObj}
            chatQuestionOptions={props.chatQuestionOptions}
          ></ChatUI>
        )}
      </div>
    </div>
  );
}

export default ChatRightNav;
