import { Radio, Tooltip, Typography } from "antd";
import CustomAvatar from "commons/CustomAvatar";
import { ProfilePlaceholder } from "images";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserSession } from "store/modules/auth/action";
import { isMobile } from "utils";
import "./index.scss";
function SwitchRole() {
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [showToolTip, setShowToolTip] = useState(false);
  const dispatch = useDispatch();
  const [isSeller, setIsSeller] = useState(
    userSession.accountRole === "seller" ? true : false
  );
  const [isMobileView, setIsMobileView] = useState(isMobile());
  useEffect(() => {
    setUserProfileUrl(userSession?.imageUri ? userSession?.imageUri : null);
  }, []);
  const onChange = (e) => {
    const updatesUserSession = { ...userSession };
    // console.log(e.target.value);
    if (e.target.value === "seller") {
      setIsSeller(true);
      updatesUserSession.accountRole = "seller";
      dispatch(setUserSession(updatesUserSession));
    } else {
      setIsSeller(false);
      updatesUserSession.accountRole = "buyer";
      dispatch(setUserSession(updatesUserSession));
    }
  };
  return (
    <div className="switchRole">
      {isMobileView === true ? (
        <div className="switchRole__headerMobile">
          <div className="switchRole__headerDiv">
            <Typography.Text className="switchRole__headerText">
              Switch Role
            </Typography.Text>
            <CustomAvatar
              className="switchRole__headerImg"
              imageUri={userProfileUrl}
              name={userSession?.firstName}
            />
          </div>
          <div className="switchRole__buyerProfileContainer">
            <Typography.Text className="switchRole__profileText">
              Buyer Profile
            </Typography.Text>
            <Radio
              className="switchRole__radio"
              checked={!isSeller}

              onChange={onChange}
              value={"buyer"}
            ></Radio>
            <div className="switchRole__buyerActiveStatusDiv">
              <Typography.Text
                className={
                  !isSeller
                    ? "switchRole__regularTextBlue"
                    : "switchRole__regularTextGrey"
                }
              >
                {!isSeller ? "Active" : "Inactive"}
              </Typography.Text>
              <Typography.Text className="switchRole__regularTextGrey">
                Default
              </Typography.Text>
            </div>
          </div>
          <div className="switchRole__sellerProfileContainer">
            <Typography.Text className="switchRole__profileText">
              Seller Profile
            </Typography.Text>
            {/* <Tooltip
            placement="top"
            title={showToolTip && "You have to be an authorised seller"}
          > */}
            <Radio
              className="switchRole__radio"
              // disabled={!isSeller}
              value={"seller"}
              checked={isSeller}
              onChange={onChange}
              onMouseEnter={() => {
                setShowToolTip(true);
              }}
              onMouseLeave={() => {
                setShowToolTip(false);
              }}
            ></Radio>
            {/* </Tooltip> */}
            <div className="switchRole__sellerActiveStatusDiv">
              <Typography.Text
                className={
                  isSeller
                    ? "switchRole__regularTextBlue"
                    : "switchRole__regularTextGrey"
                }
              >
                {isSeller ? "Active" : "Inactive"}
              </Typography.Text>
              {/* <Typography.Text className="switchRole__regularTextGrey">
              You are not an authorised seller.{" "}
              <span className="switchRole__regularTextLightBlue">
                Contact Support
              </span>
            </Typography.Text> */}
            </div>
          </div>
          
        </div>
      ) : (
        <div className="switchRole__header">
          <div className="switchRole__headerDiv">
            <Typography.Text className="switchRole__headerText">
              Switch Role
            </Typography.Text>
            <CustomAvatar
              className="switchRole__headerImg"
              imageUri={userProfileUrl}
              name={userSession?.firstName}
            />
          </div>
          <div className="switchRole__buyerProfileContainer">
            <Typography.Text className="switchRole__profileText">
              Buyer Profile
            </Typography.Text>
            <Radio
              className="switchRole__radio"
              checked={!isSeller}
              onChange={onChange}
              value={"buyer"}
            ></Radio>
            <div className="switchRole__buyerActiveStatusDiv">
              <Typography.Text
                className={
                  !isSeller
                    ? "switchRole__regularTextBlue"
                    : "switchRole__regularTextGrey"
                }
              >
                {!isSeller ? "Active" : "Inactive"}
              </Typography.Text>
              <Typography.Text className="switchRole__regularTextGrey">
                Default
              </Typography.Text>
            </div>
          </div>
          <div className="switchRole__sellerProfileContainer">
            <Typography.Text className="switchRole__profileText">
              Seller Profile
            </Typography.Text>
            {/* <Tooltip
            placement="top"
            title={showToolTip && "You have to be an authorised seller"}
          > */}
            <Radio
              className="switchRole__radio"
              // disabled={!isSeller}
              value={"seller"}
              checked={isSeller}
              onChange={onChange}
              onMouseEnter={() => {
                setShowToolTip(true);
              }}
              onMouseLeave={() => {
                setShowToolTip(false);
              }}
            ></Radio>
            {/* </Tooltip> */}
            <div className="switchRole__sellerActiveStatusDiv">
              <Typography.Text
                className={
                  isSeller
                    ? "switchRole__regularTextBlue"
                    : "switchRole__regularTextGrey"
                }
              >
                {isSeller ? "Active" : "Inactive"}
              </Typography.Text>
              {/* <Typography.Text className="switchRole__regularTextGrey">
              You are not an authorised seller.{" "}
              <span className="switchRole__regularTextLightBlue">
                Contact Support
              </span>
            </Typography.Text> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default SwitchRole;