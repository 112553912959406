import { getSelectedWorkspaceProfileId, isMobile } from "utils";
import {
  createDatasetAPI,
  createDatasetList,
  getPreviewSheetLink,
  getUserEntities,
  getUserSources,
  getUserDestinations,
  handleTransformCreation,
} from "api/Datasets";
import {
  setNewSources,
  setNewDestinations,
  setFormData,
} from "store/modules/datasets/action";
import Steps from "./MobileSteps";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSavedAccessToken } from "store/localStorage";
import DesktopSteps from "./DesktopSteps";
import axios from "axios";

const StepsController = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = getSavedAccessToken();
  const apiUrl =
    "https://vnziozspx3.execute-api.us-west-2.amazonaws.com/dev/source";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [entities, setEntities] = useState([]);
  const [sources, setSources] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [buildQueryValue, setBuildQueryValue] = useState(null);
  const [builSourceSelectedValue, setBuildSourceSelected] = useState(null);
  const [exclusiveQueryValue, setExclusiveQueryValue] = useState(null);
  const [exclusiveSourceSelectedValue, setExclusiveSourceSelected] =
    useState(null);
  const [entityValue, setEntitySelected] = useState(null);
  const [sourcesOnBoarded, setSourcesOnBoarded] = useState(null);
  const [destinationsOnBoarded, setDestinationsOnBoarded] = useState(null);
  const [dataTempId, setDataTempId] = useState(null);
  const [dataListId, setDataListId] = useState(null);
  const [destinationId, setDestinationId] = useState(null);
  const [configDestinationName, setConfigDestinationName] = useState(null);
  const [configDestinationId, setConfigDestinationId] = useState(null);
  const [destEntityType, setDestEntityType] = useState(null);

  const userSession = useSelector((state) => state.authReducer).userSession;
  const [transformQueryValue, setTransformQueryValue] = useState(null);
  const [extensionValue, setExtensionValue] = useState(null);
  const [previewLink, setPreviewLink] = useState(null);
  const [entityLoader, setEntityLoader] = useState(false);
  const [sourcesLoader, setSourcesLoader] = useState(false);
  const [destinationsLoader, setDestinationsLoader] = useState(false);
  const [transformSelectedDestination, setTransformSelectedDestination] =
    useState(null);
  const handleBuildQueryValue = (val) => {
    setBuildQueryValue(val);
  };
  const handleSourceSelectedValue = (val) => {
    setBuildSourceSelected(val);
  };
  const handleExclusiveQueryValue = (val) => {
    setExclusiveQueryValue(val);
  };
  const handleExclusiveSelectedValue = (val) => {
    setExclusiveSourceSelected(val);
  };
  const handleEntityValue = (val) => {
    setEntitySelected(val);
  };
  const handleTransformQueryValue = (val) => {
    setTransformQueryValue(val);
    //
  };
  const handleTransformSelectedDestination = (val) => {
    setTransformSelectedDestination(val);
  };
  const handleDestinationValue = (val, extensionVal) => {
    // console.log("val", val);
    setExtensionValue(extensionVal);
    setDestinationId(val.source.id);
    setDestEntityType(val.source.name);
    setConfigDestinationName(val.unique_name);
    setConfigDestinationId(val.client_source_id);
  };

  useEffect(() => {
    setEntityLoader(true);
    const profileWSId = getSelectedWorkspaceProfileId(userSession);
    getUserEntities(profileWSId.toString())
      .then((response) => {
        setEntityLoader(false);
        // console.log("entities api response", response?.data?.data);
        setSourcesOnBoarded(response?.data?.data[0]?.sources_onboarded);
        dispatch(
          setFormData(
            1.1,
            "sourcesOnBoarded",
            response?.data?.data[0]?.sources_onboarded
          )
        );
        setDestinationsOnBoarded(
          response?.data?.data[0]?.destinations_configured
        );
        const entitiesData = response?.data?.data[0];
        if (entitiesData) {
          const filteredEntities = {};

          if (entitiesData.is_company === "1") filteredEntities.Company = "1";
          if (entitiesData.is_event === "1") filteredEntities.Event = "1";
          if (entitiesData.is_people === "1") filteredEntities.People = "1";
          if (entitiesData.is_place === "1") filteredEntities.Place = "1";

          setEntities(filteredEntities);
        }
      })
      .catch((err) => {
        setEntityLoader(false);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    // console.log("sourcesOnBoarded", sourcesOnBoarded);
    if (sourcesOnBoarded != null && entityValue != null) {
      // console.log("apiiii", entityValue);
      setSourcesLoader(true);
      getUserSources(sourcesOnBoarded, entityValue?.toLowerCase())
        .then((response) => {
          setSourcesLoader(false);
          setSources(response?.data?.data);
          dispatch(setNewSources(response?.data?.data));
        })
        .catch((err) => {
          setSourcesLoader(false);
        });
    }
  }, [sourcesOnBoarded, entityValue]);

  useEffect(() => {
    getListOfSources();
  }, []);

  const getListOfSources = () => {
    setDestinationsLoader(true);
    // Construct the query string with the `isDestination` parameter
    const queryParams = new URLSearchParams({ isDestination: true }).toString();
    const urlWithParams = `${apiUrl}?${queryParams}`;

    axios
      .get(urlWithParams, { headers })
      .then((response) => {
        setDestinations(response?.data?.data);
        dispatch(setNewDestinations(response?.data?.data));
        setDestinationsLoader(false);
      })
      .catch((error) => {
        setDestinationsLoader(false);
      });
  };

  useEffect(() => {
    if (buildQueryValue != null) {
      handleCreateBuildList(2);
    }
  }, [buildQueryValue]);

  useEffect(() => {
    if (exclusiveQueryValue) {
      handleCreateBuildList(3);
    }
  }, [exclusiveQueryValue]);

  useEffect(() => {
    if (transformQueryValue != null) {
      handleTransformQuery();
    }
  }, [transformQueryValue]);

  const handleTransformQuery = () => {
    const profileWSId = getSelectedWorkspaceProfileId(userSession);
    const payload = {
      query: transformQueryValue,
      tempId: dataTempId,
      entityTypeInIdentity: transformSelectedDestination,
      eventType: entityValue?.toLowerCase(),
      entityType: transformSelectedDestination,
      profileWorkspaceId: profileWSId.toString(),
    };
    // console.log("create transform api payload", payload);
    handleTransformCreation(payload)
      .then((res) => {
        // console.log("data transform", res.data?.data);
        handlePreviewData(dataTempId, dataListId);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const handleCreateBuildList = (step) => {
    const profileWSId = getSelectedWorkspaceProfileId(userSession);
    const payload = {
      eventType: entityValue?.toLowerCase(),
      inclusionQuery: buildQueryValue,
      profileWorkspaceId: profileWSId.toString(),
      listDescription: "abc",
      entity_type: entityValue,
      inclusion_entity_type_table: builSourceSelectedValue,
      ...(exclusiveQueryValue !== null && {
        exclusionQuery: exclusiveQueryValue,
      }),
      ...(exclusiveQueryValue !== null && {
        exclusion_entity_type_table: exclusiveSourceSelectedValue,
      }),
      ...(dataTempId !== null && step == 3 && { tmpId: dataTempId }),
      ...(dataListId !== null && step == 3 && { existingListId: dataListId }),
    };
    // console.log("create list api payload", payload);
    createDatasetList(payload)
      .then((res) => {
        // console.log("Create datasets list API response", res.data?.data);
        setDataTempId(res.data?.data?.tempId);
        setDataListId(res.data?.data?.listId);
        // handlePreviewData(res.data?.data?.tempId, res.data?.data?.listId);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const handlePreviewData = (tempid, listid) => {
    const payload = {
      tmpId: tempid,
      listId: listid,
    };
    // console.log("Preview data sheet link payload", payload);
    getPreviewSheetLink(payload)
      .then((res) => {
        console.log("Preview sheet link api response", res?.data?.data);
        setPreviewLink(res?.data?.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const handleCreateDataSet = (datasetName, datasetDesc) => {
    // console.log("destinationId", destinationId);
    const profileWSId = getSelectedWorkspaceProfileId(userSession);
    const payload = {
      listId: dataListId,
      query: transformQueryValue,
      sourcesIncluded: sourcesOnBoarded,
      profileWorkspaceId: profileWSId.toString(),
      description: datasetDesc,
      entityTypeInIdentity: transformSelectedDestination,
      tempId: dataTempId,
      name: datasetName,
      user_name: userSession.firstName + " " + userSession.lastName,
      destinations: destinationId,
      connectionName: configDestinationName,
      connectionId: configDestinationId,
      destinationEntityType: destEntityType,
      eventType: entityValue?.toLowerCase(),
    };
    if (payload.destinations === 1) {
      payload.dataExtensionName = extensionValue?.name;
      payload.dataExtensionId = extensionValue?.id;
      payload.dataExtensionKey = extensionValue?.key;
    }

    // console.log("create dataset api payload", payload);

    createDatasetAPI(payload)
      .then((res) => {
        // console.log("create dataset api response", res?.data?.data);
        navigate(`/dashboard/datasets/datasets`);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  return (
    <>
      {isMobileView === true ? (
        <Steps
          sources={sources}
          entities={entities}
          destinations={destinations}
          handleEntityValue={handleEntityValue}
        ></Steps>
      ) : (
        <DesktopSteps
          sources={sources}
          entities={entities}
          handleBuildQueryValue={handleBuildQueryValue}
          handleSourceSelectedValue={handleSourceSelectedValue}
          handleExclusiveQueryValue={handleExclusiveQueryValue}
          handleExclusiveSelectedValue={handleExclusiveSelectedValue}
          handleEntityValue={handleEntityValue}
          handleTransformQueryValue={handleTransformQueryValue}
          handleTransformSelectedDestination={
            handleTransformSelectedDestination
          }
          handleCreateDataSet={handleCreateDataSet}
          handleDestinationValue={handleDestinationValue}
          destinations={destinations}
          entityLoader={entityLoader}
          sourcesLoader={sourcesLoader}
          destinationsLoader={destinationsLoader}
          previewLink={previewLink}
          tempId={dataTempId}
          listId={dataListId}
          entityValue={entityValue?.toLowerCase()}
          profileWorkspaceId={getSelectedWorkspaceProfileId(
            userSession
          ).toString()}
        ></DesktopSteps>
      )}
    </>
  );
};

export default StepsController;
