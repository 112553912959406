import { useState, useEffect } from "react";
import { Input, Button, Image, message, Spin, Select } from "antd";
import "./index.scss";
import { IconChevronLeft } from "images";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";
import Flex from "screens/Chat/Flex";
import { getSavedAccessToken } from "store/localStorage";

function CreateSource() {
  const navigate = useNavigate();

  const userToken = getSavedAccessToken();

  const [loading, setLoading] = useState(false);
  const [sourceTypes, setSourceTypes] = useState([]);
  const { Option } = Select;

  const [postData, setPostData] = useState({
    name: "",
    source_type_id: "",
    source_type_name: "",
  });

  useEffect(() => {
    getSourceTypes();
  }, []);

  const apiUrl =
    "https://vnziozspx3.execute-api.us-west-2.amazonaws.com/dev/source";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  const getSourceTypes = () => {
    setLoading(true);
    axios
      .get(apiUrl + "/type", { headers })
      .then((response) => {
        setLoading(false);
        setSourceTypes(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response.data.message);
      });
  };

  const createSourceAndMoveNext = () => {
    setLoading(true);

    const postDataWithIntegerTypeId = {
      name: postData.name,
      source_type_id: parseInt(postData.source_type_id, 10) || 0,
    };

    axios
      .post(apiUrl, postDataWithIntegerTypeId, { headers })
      .then((response) => {
        setLoading(false);
        const sourceId = response.data.data[0].id;
        const sourceName = postData.source_type_name;
        message.info(response.data.message);
        //navigate(`/dashboard/datasets/sources/apiconnector2/${sourceId}/${sourceName}`);
        navigate(`/dashboard/datasets/sources`);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response.data.message);
      });
  };

  const getSourceTypeOptions = () => {
    return sourceTypes.map((type) => (
      <Option key={type.id} value={type.id}>
        {type.type}
      </Option>
    ));
  };

  if (loading) {
    return (
      <>
        {" "}
        <Flex
          flex={1}
          justify="center"
          align="center"
          style={{
            width: "100%",
            height: 500,
            backgroundColor: "#fff",
          }}
          direction="column"
        >
          <Spin size="default" />
          <div> Loading...</div>
        </Flex>
      </>
    );
  } else {
    return (
      <>
        <div className="ApiConnector">
          <div className="ApiConnector__headerText">
            <span style={{ cursor: "pointer" }}>
              <Image
                src={IconChevronLeft}
                preview={false}
                onClick={() => {
                  window.history.back();
                }}
              />
            </span>
            <span className="ApiConnector__title">Connections</span>
            <span className="ApiConnector__slash">/</span>
            <span className="ApiConnector__title">Catalog</span>
            <span className="ApiConnector__slash">/</span>
            <span className="ApiConnector__mainTitle">Source</span>
          </div>
          <h1 className="ApiConnector__mainHeading">Source Creation</h1>
          <div className="ApiConnector__form">
            <div>
              <h6 className="ApiConnector__heading">Source Name</h6>
              <Input
                className="ApiConnector__input"
                name="Source Name"
                value={postData.name}
                placeholder="Name of Source"
                onChange={(e) =>
                  setPostData({
                    ...postData,
                    name: e.target.value,
                    source_type_name: e.target.value,
                  })
                }
              />
              <p className="ApiConnector__para">
                Name of source you wanted to create
              </p>
            </div>

            <div>
              <h6 className="ApiConnector__heading">Source Type</h6>
              <Select
                className="ApiConnector__dropdown"
                name="Source Type"
                // value={postData.source_type_id}
                placeholder="Choose Source Type"
                onChange={(value) =>
                  setPostData({ ...postData, source_type_id: value })
                }
              >
                {getSourceTypeOptions()}
              </Select>
              <p className="ApiConnector__para">
                Select the type of source you want to create
              </p>
            </div>
          </div>

          <div className="ApiConnector__buttonsDiv">
            <Button
              className="ApiConnector__button"
              type="primary"
              onClick={() => createSourceAndMoveNext()}
            >
              Create Source
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default CreateSource;
