import { message, Form, Button } from "antd";
import {
  getPeopleFilterValues,
  getSources,
  searchPeople,
  entityDataSearch,
} from "api/search";
import DataTable from "components/DataTable";
import { getIdColumn } from "components/DataTable/Columns";
import { DEFAULT_DATE_FORMAT } from "constants";
import { ENTITY_TYPES } from "constants";
import moment from "moment";
import { getSelectedWorkspaceProfileId } from "utils";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FilterFieldsContainer from "../Commons/SideFilters/Fields/FilterFieldsContainer";
import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "../Commons/SideFilters/Fields/FilterDropdown";
import Searchbar from "../Commons/Searchbar";
import "./index.scss";
import SideFilters from "../Commons/SideFilters";
import { SHOULD_SEARCH_ON_PAGE_LOAD } from "constants";
import { isEmpty } from "lodash";
import { truncateText } from "utils";

function PeopleSearch() {
  const peopleColumns = [
    {
      title: "PERSON ID",
      dataIndex: "revcloud_person_id",
    },
    {
      title: "NAME",
      dataIndex: "name",
      // render: (text) => <a className="nameValue">{text}</a>,
    },
    {
      title: "SOURCE",
      dataIndex: "source",
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
    },
    {
      title: "CITY",
      dataIndex: "city",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
    },

    // {
    //   title: "LINKEDIN URL",
    //   dataIndex: "linkedin_url",
    //   render: (text) => <p>{truncateText(text)}</p>,
    // },
    {
      title: "ACCURATE TO DATE",
      dataIndex: "last_activity",
    },
  ];

  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const [searchedFilters, setSearchedFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [resizingResults, setResizingResults] = useState(false);
  const { accountId, userId } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [form] = Form.useForm();
  let cols = peopleColumns;

  useEffect(() => {
    getFilters();
    cols[0] = getIdColumn(peopleColumns[0], idClicked);
  }, []);

  const idClicked = (id) => {
    form.setFieldsValue({ revcloudId: [id] });
    setSearchedFilters({ revcloudId: [id] });
    setShowFilters(true);
  };
  useEffect(() => {
    getSearchResults();
  }, [searchedText]);

  useEffect(() => {
    setResizingResults(true);
    setTimeout(() => {
      setResizingResults(false);
    }, 100);
  }, [showFilters]);

  const getFilters = () => {
    const payload = {
      userId,
      accountId,
      entityTypeId: ENTITY_TYPES.PEOPLE,
    };

    Promise.all([getSources(payload), getPeopleFilterValues(payload)])
      .then((res) => {
        const sources = res[0].data.data;
        const filters = res[1].data.data;
        setFilterOptions({
          sources,
          ...filters,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });
    const payload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page: 1,
        limit: 1000,
      },
    };
    const searchPayload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page: 1,
        limit: 1000,
        searchTerm: searchedText,
        entityTypeId: ENTITY_TYPES.PEOPLE,
      },
    };
    if (searchedText === "") {
      setLoading(true);
      searchPeople(payload)
        .then((res) => {
          setSearchResults(
            res?.data?.data?.data?.map((el) => {
              el.last_activity = moment(el.last_activity)
                .utc()
                .format(DEFAULT_DATE_FORMAT);
              return el;
            })
          );
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (searchedText !== "") {
      setLoading(true);
      entityDataSearch(searchPayload)
        .then((res) => {
          console.log("Searched Text res", res);
          setSearchResults(res?.data?.data?.data);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onApplyFilters = () => {
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    setSearchedFilters(form.getFieldsValue());
  };
  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
  };

  const filterFields = [
    <FilterFieldsContainer
      label="Source"
      onClear={() => {
        onResetFields(["source"]);
      }}
    >
      <FilterDropdown
        name="source"
        placeholder="Enter source name here…"
        options={filterOptions.sources}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    // <FilterFieldsContainer
    //   label="Profile"
    //   onClear={() => {
    //     onResetFields(["linkedInUrl"]);
    //   }}
    // >
    //   <FilterDropdown
    //     name="linkedInUrl"
    //     selectMode="tags"
    //     placeholder="LinkedIn URL"
    //     options={filterOptions.linkedInUrl}
    //     suffixIcon={FILTER_DROPDOWN_SUFFIX.LINK}
    //     onChange={onApplyFilters}
    //   />
    // </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Person Id"
      onClear={() => {
        onResetFields(["revcloudId"]);
      }}
    >
      <FilterDropdown
        name="revcloudId"
        selectMode="tags"
        placeholder="Person Id…"
        options={filterOptions.revcloudId}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.ID}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="General information"
      onClear={() => {
        onResetFields(["address", "email", "phoneNumber"]);
      }}
    >
      <FilterDropdown
        name="email"
        selectMode="tags"
        placeholder="Email"
        options={filterOptions.email}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.EMAIL}
        onChange={onApplyFilters}
      />
      <FilterDropdown
        name="phoneNumber"
        selectMode="tags"
        placeholder="Phone Number"
        options={filterOptions.phoneNumber}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.PHONE}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
  ];
  return (
    <div className="peopleSearch">
      {/* <div className="peopleSearch__saveContainer">
        <Button className="peopleSearch__saveContainer__button">
          Save Search
        </Button>
      </div> */}
      <Searchbar
        placeholder="Search..."
        filtersVisible={showFilters}
        onFiltersVisibilityChange={setShowFilters}
        onSubmitSearchText={(searchedText) => {
          shouldFetchResults.current = true;
          setIsBeginingSearch(false);
          setSearchedText(searchedText);
        }}
        filtersApplied={
          !isEmpty(
            Object.values(searchedFilters).filter((value) => {
              return !isEmpty(value);
            })
          )
        }
      />
      <div className="peopleSearch__filterAndResults">
        <>
          {showFilters && (
            <div className="peopleSearch__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && !resizingResults && (
            <div className="peopleSearch__filterAndResults__results">
              <DataTable
                entityTypeId={ENTITY_TYPES.PEOPLE}
                loading={loading}
                columns={peopleColumns}
                dataSource={searchResults}
                detailsKey="name"
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
}
export default PeopleSearch;
