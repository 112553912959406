import API from "..";

export const getTwilioToken = (payload) => {
  return API.get(`/twilio/token/${payload.userId}`, payload);
};

export const createTwilioConversation = (payload) => {
  return API.post("/twilio/create-conversation/", payload);
};

export const getAllMessages = (payload) => {
  return API.get("/twilio/getAllMessagesByUser", payload);
};

export const sendSMSTo = (payload) => {
  return API.post("/twilio/sendMessageToUser", payload);
};
/**
 * phoneNumber
 * conversationId
 * identity
 * @param {*} payload
 * @returns
 */
export const addUserToConversation = (payload) => {
  return API.post("/twilio/addUserToConversation", payload);
};
/**
 * conversationId
 * psid
 * @param {*} payload
 * @returns
 */
export const removeParticipantFromConversation = (payload) => {
  return API.post("/twilio/removeParticipantFromConversation", payload);
};

export const getPresignedUrlForGroupImage = (payload) => {
  return API.get("/twilio/getPreSignedUrlForGroupImage", payload);
};

export const updateConversationAttributes = (payload) => {
  return API.post("/twilio/updateConversationAttributes", payload);
};
