import React, { useState } from "react";
import { Table, Avatar, Input, Select } from "antd";
import "./index.scss";
import LetterAvatar from "react-letter-avatar";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;

const data = [
  {
    key: "1",
    name: "AstraGen Model",
    status: true,
    modelType: "Random Forest",
    category: "SMS",
    avatar: "", // No avatar, fallback to first letter
  },
  {
    key: "2",
    name: "LogicWave AI",
    status: false,
    modelType: "Boosting Model",
    category: "Email",
    avatar: "", // No avatar, fallback to first letter
  },
  {
    key: "3",
    name: "AlphaMind",
    status: false,
    modelType: "Neural Network",
    category: "AI Phone",
    avatar: "", // No avatar, fallback to first letter
  },
  {
    key: "4",
    name: "NexusAI",
    status: true,
    modelType: "Boosting Model",
    category: "Phone",
    avatar: "", // No avatar, fallback to first letter
  },
  {
    key: "5",
    name: "IntelliStream Model",
    status: false,
    modelType: "Random Forest",
    category: "Direct Mail",
    avatar: "", // No avatar, fallback to first letter
  },
  {
    key: "6",
    name: "VisionaryAI",
    status: true,
    modelType: "Neural Network",
    category: "Email",
    avatar: "", // No avatar, fallback to first letter
  },
];

const columns = [
  {
    title: "NAME",
    dataIndex: "name",
    key: "name",
    render: (text, record) => (
      <div className="model__user">
         <LetterAvatar name={text} size={25} radius={25} />
        <span style={{ marginLeft: "6px" }}>{text}</span>
      </div>
    ),
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <div className="model_statusFlex">
        {text === true && (
          <>
            <div className="model_Status"></div>
            <div>Enabled</div>
          </>
        )}
        {text === false && (
          <>
            <div className="model_InActiveStatus"></div>
            <div>In Progress</div>
          </>
        )}
      </div>
    ),
  },
  {
    title: "MODEL TYPE",
    dataIndex: "modelType",
    key: "modelType",
  },
  {
    title: "CATEGORY",
    dataIndex: "category",
    key: "category",
  },
];

const Model = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const navigate = useNavigate();

  const filteredData = data.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleStatusChange = (value) => {
    setStatusFilter(value);
  };

  const filterByStatus = (data) => {
    switch (statusFilter) {
      case "all":
        return data; // Show all items
  
      case "enabled":
        return data.filter((item) => item.status === true); // Show only enabled items (status: true)
  
      case "in-progress":
        return data.filter((item) => item.status === false); // Show only disabled items (status: false)
  
      case "disabled":
        return data.filter((item) => item.status === "in-progress"); // Show only items with status 'in-progress'
  
      case "no-recent-data":
        return data.filter((item) => item.status === "no-recent-data"); // Show only items with status 'no-recent-data'
  
      default:
        return data; // Default case
    }
  };
  

  const filteredDataByStatus = filterByStatus(filteredData);

  const handleRowClick = (record) => {
    const modelName = record?.name;
    navigate(
      `/dashboard/datasets/model/${modelName}`
    );
  };


  return (
    <div className="model">
      <div className="model__header">
        <Select
          className="model__status-filter"
          defaultValue="all"
          onChange={handleStatusChange}
          style={{ width: 165 }}
        >
            <Option value="all">
              <span className="Status all-icon"></span> All
            </Option>
            <Option value="enabled">
              <span className="Status enabled-icon"></span> Enabled
            </Option>
            <Option value="disabled">
              <span className="Status disabled-icon"></span> Disabled
            </Option>
            <Option value="in-progress">
              <span className="Status in-progress-icon"></span> In Progress
            </Option>
            <Option value="no-recent-data">
              <span className="Status no-recent-data-icon"></span> No Recent
              Data
            </Option>
        </Select>
        <Input
          className="model__header__input"
          type="search"
          placeholder="Search for models by name"
          prefix={<SearchOutlined style={{ color: "grey" }} />}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table
        columns={columns}
        dataSource={filteredDataByStatus}
        pagination={true}
        className="model__content"
        onRow={(record) => {
          return {
            onClick: () => {
              handleRowClick(record);
            },
            onMouseEnter: (e) => {
              e.currentTarget.style.cursor = "pointer";
            },
            onMouseLeave: (e) => {
              e.currentTarget.style.cursor = "default";
            },
          };
        }}

      />
    </div>
  );
};

export default Model;
