import { Avatar, Image, Typography } from "antd";
import { IconEdit, ProfilePlaceholder } from "images";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./index.scss";

function Company() {
  const authReducer = useSelector((state) => state.authReducer);
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const userSession = authReducer.userSession;

  useEffect(() => {
    setUserProfileUrl(
      userSession?.imageUri ? userSession?.imageUri : ProfilePlaceholder
    );
  }, []);

  return (
    <div className="companyPage">
      <div className="companyPage__header">
        <div className="companyPage__headerDiv">
          <Typography.Text className="companyPage__headerText">
            Company Credentials
          </Typography.Text>
          <Avatar className="companyPage_headerImg" src={userProfileUrl} />
        </div>
        <div className="companyPage__headerCredentialsInfoDiv">
          <Typography.Text className="companyPage__normalTextBoldFirst">
            Registered Name
          </Typography.Text>
          <Typography.Text className="companyPage__normalText">
            Starbucks Coffee Company
          </Typography.Text>
          <Typography.Text className="companyPage__normalTextBold">
            Registeration code
          </Typography.Text>
          <Typography.Text className="companyPage__normalText">
            28404998128776
          </Typography.Text>
        </div>

        <div className="companyPage__ctoDiv">
          <Typography.Text className="companyPage__normalTextBold">
            Headquarter location
          </Typography.Text>
          <div className="companyPage__ctoParentDiv">
            <Typography.Text className="companyPage__normalText">
              Seatle, Washington, United States
            </Typography.Text>
            <Image
              className="companyPage__iconEdit"
              preview={false}
              src={IconEdit}
            ></Image>
          </div>
        </div>
      </div>
      <div className="companyPage__headerMiddle">
        <div className="companyPage__headerDiv">
          <Typography.Text className="companyPage__headerText">
            Company Management Membership
          </Typography.Text>
        </div>
        <div className="companyPage__headerCMMInfoDiv">
          <Typography.Text className="companyPage__normalTextBoldFirst">
            CEO
          </Typography.Text>
          <Typography.Text className="companyPage__normalText">
            Jordan West
          </Typography.Text>
          <Typography.Text className="companyPage__normalTextBold">
            CTO
          </Typography.Text>
          <Typography.Text className="companyPage__normalText">
            Nicole Henry
          </Typography.Text>
        </div>

        <div className="companyPage__ctoDiv">
          <Typography.Text className="companyPage__normalTextBold">
            Accounting Director
          </Typography.Text>
          <div className="companyPage__ctoParentDiv">
            <Typography.Text className="companyPage__normalText">
              Ricardo Ruiz
            </Typography.Text>
            <Image
              className="companyPage__iconEdit"
              preview={false}
              src={IconEdit}
            ></Image>
          </div>
        </div>
      </div>
      <div className="companyPage__footer">
        <div className="companyPage__headerDiv">
          <Typography.Text className="companyPage__headerText">
            Financial Resources
          </Typography.Text>
        </div>
        <div className="companyPage__loremDiv">
          <Typography.Text className="companyPage__normalTextBoldFirst">
            Lorem
          </Typography.Text>
          <Typography.Text className="companyPage__normalTextLorem">
            Quis, ut sollicitudin laborum. mollis sit consequat elit, hac ut id
            consectetur risus quis, mi. eu vehicula. in nibh in nulla quis, ut
            sollicitudin laborum. mollis sit consequat elit, hac ut id
            consectetur risus quis, mi. eu vehicula. in nibh in nulla
          </Typography.Text>
        </div>
        <div className="companyPage__managementDiv">
          <Typography.Text className="companyPage__normalTextBold">
            Management Team
          </Typography.Text>
          <Typography.Text className="companyPage__normalTextLorem">
            ajshdj klashdjka klashdjas klhasdj alskdhjashdb mnbasjkdba
            kjahsdkjahs ajsdgkjasgdj klahsdjash jkhasjdk khasdjk ajkshdjkhasd
          </Typography.Text>
          <Typography.Text className="companyPage__normalTextBold">
            Engineering Business
          </Typography.Text>
          <Typography.Text className="companyPage__normalTextLorem">
            ajshdj klashdjka klashdjas klhasdj alskdhjashdb mnbasjkdba
            kjahsdkjahs
          </Typography.Text>
        </div>
        <div className="companyPage__ctoDiv">
          <Typography.Text className="companyPage__normalTextBold">
            Division
          </Typography.Text>
          <div className="companyPage__ctoParentDiv">
            <Typography.Text className="companyPage__normalText">
              293788468
            </Typography.Text>
            <Image
              className="companyPage__iconEdit"
              preview={false}
              src={IconEdit}
            ></Image>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
