import { Divider, Image, Spin } from "antd";
import { IconListHide, IconShowBar } from "images";
import { MenuList } from "components/AppLeftNav/MenuList";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import AppLeftNavMobile from "components/AppLeftnavMobile";
import { useState } from "react";
import { isMobile } from "utils";
import { AppHomeButton } from "./HomeButton";
import "./index.scss";
import Flex from "screens/Chat/Flex";
import { Marketplace } from "./MarketPlace";

const MenuItemIcon = ({ src }) => {
  return <Image className="menu-item-icon" preview={false} src={src} />;
};

export default function AppLeftNav() {
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [visibility, setVisibility] = useState(false);
  const [loadingConversations, setLoadingConversations] = useState(false);

  const Visibilityhandler = () => {
    setVisibility(!visibility);
  };

  return (
    <>
      {isMobileView === true ? (
        <AppLeftNavMobile></AppLeftNavMobile>
      ) : (
        <div className="appLeftNav">
          <AppHomeButton />
          <div className="marketplace-nav-container">
            {visibility && (
              <div className="appLeftNav__marketplace">
                {loadingConversations ? (
                  <Flex
                    flex={1}
                    justify="center"
                    align="center"
                    style={{ width: "100%", height: 500 }}
                    direction="column"
                  >
                    <Spin size="default" />
                    <div> Loading Conversations...</div>
                  </Flex>
                ) : (
                  <Marketplace />
                )}
              </div>
            )}

            <div className="nav-menu">
              <MenuList />
              {/* <div className="Show-Icon">
                <span className={visibility ? "Hide" : "Show"} onClick={() => setVisibility(true)}>
                  <MenuItemIcon src={IconShowBar} />
                </span>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
