import { List, Button } from "antd";
// import SinglePlaybook from "../SinglePlaybook";
import "./index.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PreviewCard from "../PreviewCard";
// import MobileSinglePlaybook from "../mSinglePlaybook";
import { isMobile } from "utils";
import MobilePreviewCard from "../mPreviewCard";

// export const PlaybooksDetailPage = () => {
//   const [isMobileView, setIsMobileView] = useState(isMobile());

//   useEffect(() => {
//     function handleResize() {
//       setIsMobileView(isMobile());
//     }

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return isMobileView === true ? <MobileSinglePlaybook /> : <SinglePlaybook />;
// };

function CategoriesPreview({}) {
  let searchResults = [1, 2, 3];
  let data = searchResults;
  let dataToSet = [];
  dataToSet = data.slice(0, 3);
  const navigate = useNavigate();

  //   const onViewAllClicked = () => {
  //     navigate(`/dashboard/category/playbooks/${catId}`);
  //   };

  //   const [isMobileView, setIsMobileView] = useState(isMobile());
  //   useEffect(() => {
  //     function handleResize() {
  //       setIsMobileView(isMobile());
  //     }

  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []);

  const [current, setCurrent] = useState("desktop");

  const handleMobileClick = (event) => {
    setCurrent("mobile");
  };
  const handleDesktopClick = (event) => {
    setCurrent("desktop");
  };

  return (
    <>
      <div
        className={
          current === "desktop"
            ? "CategoriesPreview"
            : "CategoriesPreviewMobile"
        }
      >
        <div
          className={
            current === "desktop"
              ? "CategoriesPreview__proposalsList"
              : "CategoriesPreviewMobile__proposalsList"
          }
        >
          <List
            dataSource={dataToSet}
            className={
              current === "desktop"
                ? "CategoriesPreview__list"
                : "CategoriesPreviewMobile__list"
            }
            renderItem={(item, index) => {
              return (
                <div
                  className={
                    current === "desktop"
                      ? "CategoriesPreview__datas"
                      : "CategoriesPreviewMobile__datas"
                  }
                >
                  {current === "mobile" ? (
                    <MobilePreviewCard />
                  ) : (
                    <PreviewCard />
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>

      <div className="CategoriesPreviewViewbutton">
        <Button
          className="CategoriesPreviewViewbutton__DesktopButton"
          onClick={handleDesktopClick}
        >
          Desktop
        </Button>
        <Button
          className="CategoriesPreviewViewbutton__MobileButton"
          onClick={handleMobileClick}
        >
          Mobile
        </Button>
        <Button
          className={
            current === "desktop"
              ? "CategoriesPreview__Create"
              : "CategoriesPreviewMobile__Create"
          }
        >
          Create
        </Button>
      </div>
    </>
  );
}

export default CategoriesPreview;

