import { Link, useNavigate } from "react-router-dom";
import { Client as ConversationsClient } from "@twilio/conversations";
import { getTwilioToken } from "api/TwilioChat";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { message } from "antd";
import "./index.scss";
import { setUserSession } from "store/modules/auth/action";

function PeopleNav() {
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const dispatch = useDispatch();
  const [twilioToken, setTwilioToken] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [unReadCount, setUnReadCount] = useState(0);
  const navigate = useNavigate();
  const twilio = useRef(null);

  useEffect(() => {
    const payloadForTwilioToken = {
      userId: userSession.phoneNumber + "-" + userSession.userId,
    };

    getTwilioToken(payloadForTwilioToken)
      .then((res) => {
        // console.log("PeopleNav token B", res?.data?.data?.token);
        setTwilioToken(res?.data?.data?.token);
        userSession.twilioToken = res?.data?.data?.token;
        dispatch(setUserSession(userSession));
      })
      .catch((error) => {
        message.error(error?.response?.data);
      });
  }, []);

  useEffect(() => {
    if (twilioToken) {
      initTwilio();
    }
    return () => {
      twilio?.current?.shutdown();
    };
  }, [twilioToken]);

  useEffect(() => {
    updateUnreadCountInPeople(conversations);
  }, [conversations]);

  const initTwilio = async () => {
    if (twilio.current || !userSession?.userId) return;

    if (!twilioToken) {
      // auth.signOut(); PeopleComponent should be turned into a functional component so we can use Firebase hooks
      // this.props.router.replace("/login");
      return;
    }

    try {
      twilio.current = new ConversationsClient(twilioToken);
    } catch (err) {
      if (err?.message?.includes("Access Token expired")) {
        // firebase.auth().signOut();TODO:ref
      }
      return;
    }
    const client = twilio.current;
    client?.on("connectionStateChanged", (state) => {
      if (state === "connecting") {
      }

      if (state === "connected") {
        // console.log("connectionStatus", connectionStatus);
      }

      if (state === "disconnecting") {
      }

      if (state === "disconnected") {
      }

      if (state === "denied") {
      }
    });
    client?.on("conversationJoined", (thisConversation) => {
      // console.log("PeopleNav conversationJoined", thisConversation);
      if (!conversations.find((c) => c.sid === thisConversation.sid)) {
        setConversations((prevConversations) => [
          ...prevConversations,
          thisConversation,
        ]);
      }
    });

    client?.on("conversationLeft", (thisConversation) => {
      //   console.log("conversationLeft", thisConversation);
      setConversations((prevConversations) =>
        conversations.filter((c) => c.sid !== thisConversation.sid)
      );
    });

    client?.on("conversationUpdated", ({ conversation, updateReasons }) => {
      // Fired when the attributes or the metadata of a conversation have been updated
      // console.log("PeopleNav conversationUpdated", updateReasons);

      if (
        updateReasons.includes("lastReadMessageIndex") ||
        updateReasons.includes("lastMessage")
      ) {
        client?.getSubscribedConversations().then((res) => {
          const lastestConversations = res.items;

          //Find index of specific object using findIndex method.
          const objIndex = lastestConversations?.findIndex(
            (obj) => obj.sid == conversation.sid
          );
          if (objIndex >= 0) {
            lastestConversations[objIndex] = conversation;
            // setConversations(lastestConversations);
            setConversations([...lastestConversations]);
          }
        });
      }
    });

    client?.on("participantUpdated", ({ participant, updateReasons }) => {
      // Fired when the fields of the participant have been updated.
      // console.log("Participant updateReasons", updateReasons);
    });

    // listen to user reachability status updates
    client?.on("userUpdated", ({ user, updateReasons }) => {
      // console.log("PeopleNav userUpdated", updateReasons);
      if (updateReasons.includes("reachabilityOnline")) {
        // user reachability status was updated

        client?.getSubscribedConversations().then((res) => {
          const lastestConversations = res.items;
          setConversations([...lastestConversations]);
          //   updateUnreadCountInPeople(lastestConversations);
        });

        if (user.identity != userSession.userId && user.isOnline) {
          // console.log("==user online");
          // setIsOnline(true);
        } else {
          // setIsOnline(false);
        }
      }

      if (updateReasons.includes("reachabilityNotifiable")) {
        // user notifications status was updated
      }
    });
  };

  const updateUnreadCountInPeople = (lastestConversations) => {
    // console.log("updateUnreadCountInPeople");
    setUnReadCount(0);
    lastestConversations?.forEach((conversation) => {
      conversation
        ?.getUnreadMessagesCount()
        ?.then((count) => {
          // console.log("unreadCount", count);
          setUnReadCount((pre) => pre + count);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  return (
    <Link className="menu-list__link" to={"people"}>
      <div className="PeopleNav">
        <span>Conversations</span>
        <div
          className="PeopleNav__unreadCountContainer"
          style={{
            visibility: unReadCount > 0 ? "visible" : "hidden",
          }}
        >
          <span className="PeopleNav__unreadCount">
            {unReadCount > 9 ? "9+" : unReadCount}
          </span>
        </div>
      </div>
    </Link>
  );
}

export default PeopleNav;
