import { DEEPLINK_IDENTIFIERS } from "../../constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { setExternalUserInfo } from "store/modules/auth/action";

function DeeplinkExternal() {
  const { marketplaceid, categoryid, playbookid, packageid, userid } =
    useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let redirectedFrom = "";
  // console.log(
  //   `MarketplaceId: ${marketplaceid} categoryId: ${categoryid} playbookid: ${playbookid} packageId: ${packageid} userid ${userid}`
  // );
  if (window.location.href.includes("external/requestProposal")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.REQUEST_PROPOSAL;
  } else if (window.location.href.includes("external/playbook")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.PLAYBOOK;
  } else if (window.location.href.includes("external/joinMarketplace")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.JOIN_MARKETPLACE;
  } else if (window.location.href.includes("external/proposalChat")) {
    redirectedFrom = DEEPLINK_IDENTIFIERS.PROPOSAL_CHAT;
  }
  useEffect(() => {
    dispatch(
      setExternalUserInfo({
        marketplaceId: marketplaceid,
        categoryId: categoryid,
        playbookId: playbookid,
        packageId: packageid,
        from: redirectedFrom,
        userId: userid,
      })
    );
    navigate(APP_ROUTES.LANDING.path);
  }, []);

  return <div>Fetching Information...</div>;
}

export default DeeplinkExternal;
