import React, { useState } from "react";
import { Input, Button } from "antd";
import "./index.scss";
const CustomSyncRecurrence = ({ onDoneClicked }) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [endType, setEndType] = useState("");
  const days = ["S", "M", "T", "W", "Th", "F", "Sa"];
  const toggleDay = (day) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };
  return (
    <div className="custom-sync-recurrence">
      <h1 className="custom-sync-recurrence__heading">
        CUSTOM SYNC RECURRENCE
      </h1>
      <div className="custom-sync-recurrence__separator"></div>
      <div className="custom-sync-recurrence__radio-container">
        <label>
          <input type="radio" name="recurrence" value="every" />
          <span className="custom-sync-recurrence__radio-text">
            Repeats every
          </span>
          <input
            type="number"
            className="custom-sync-recurrence__numeric-field"
            min="1"
          />
          <select className="custom-sync-recurrence__dropdown">
            <option>Hours</option>
            <option>Day</option>
            <option>Week</option>
            <option>Month</option>
          </select>
        </label>
        <label>
          <input type="radio" name="recurrence" value="exact" />
          <span className="custom-sync-recurrence__radio-text">
            Or exactly at
          </span>
          <input
            type="number"
            className="custom-sync-recurrence__time-field"
            min="1"
            max="12"
            placeholder="HH"
          />
          <input
            type="number"
            className="custom-sync-recurrence__time-field"
            min="0"
            max="59"
            placeholder="MM"
          />
          <select className="custom-sync-recurrence__time-dropdown">
            <option>AM</option>
            <option>PM</option>
          </select>
        </label>
      </div>
      <h2
        className="custom-sync-recurrence__sub-heading"
        style={{ marginTop: "15px", alignSelf: "start" }}
      >
        Repeats sync on
      </h2>
      <div className="custom-sync-recurrence__days-container">
        {days.map((day, index) => (
          <div
            key={index}
            className={`custom-sync-recurrence__day-box ${
              selectedDays.includes(day)
                ? "custom-sync-recurrence__day-box--selected"
                : ""
            }`}
            onClick={() => toggleDay(day)}
          >
            {day}
          </div>
        ))}
      </div>
      <h2
        className="custom-sync-recurrence__sub-heading"
        style={{ marginTop: "34px", alignSelf: "start" }}
      >
        Ends
      </h2>
      <div className="custom-sync-recurrence__radio-container">
        <label>
          <input
            type="radio"
            name="end"
            value="never"
            onChange={() => setEndType("never")}
          />
          <span className="custom-sync-recurrence__radio-text">Never</span>
        </label>
        <label>
          <input
            type="radio"
            name="end"
            value="on"
            onChange={() => setEndType("on")}
          />
          <span className="custom-sync-recurrence__radio-text">On</span>
          <Input
            type="date"
            className="custom-sync-recurrence__date-field"
            disabled={endType !== "on"}
          />
        </label>
        <label>
          <input
            type="radio"
            name="end"
            value="after"
            onChange={() => setEndType("after")}
          />
          <span className="custom-sync-recurrence__radio-text">After</span>
          <Input
            type="number"
            className="custom-sync-recurrence__occurrences-field"
            min="1"
            disabled={endType !== "after"}
            suffix="occurrences"
          />
        </label>
      </div>
      <div className="custom-sync-recurrence__button-container">
        <Button className="custom-sync-recurrence__button custom-sync-recurrence__button--cancel-button">
          Cancel
        </Button>
        <Button
          type="primary"
          className="custom-sync-recurrence__button custom-sync-recurrence__button--done-button"
          onClick={onDoneClicked}
        >
          Done
        </Button>
      </div>
    </div>
  );
};
export default CustomSyncRecurrence;
