import { divide } from 'lodash'
import './index.scss'
import { IconRevcloud, Facebookads, Loadingbegin } from 'images';
import { a } from 'aws-amplify';
import { useState } from 'react';
import { Radio, Table } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';

const SelectFacebookAccount = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'ID',
        },
        {
            title: 'Name',
            dataIndex: 'Name',
        },
    ];
    const data = [
        {
            key: '1',
            ID: '34234',
            Name: 'Hamza Hassan',
        },
        {
            key: '2',
            ID: '234029340',
            Name: 'John Doe',
        },
    ];
    const [selectionType, setSelectionType] = useState('radio');

    const navigate = useNavigate();
    const { id } = useParams();
    const navigateToFields = () => {
      navigate(`/dashboard/sources/setup/facebookAds/create/screen3`);
    };

    
    return (
        <div>
            <div className='SelectFacebookAccount__component'>
                <h1 className='SelectFacebookAccount__mainHeading'>Select a Facebook Ads account</h1>
                <div className='SelectFacebookAccount__iconsDiv'>
                    <div className='SelectFacebookAccount__icons'>
                        <img src={IconRevcloud} className='SelectFacebookAccount__img1' alt="IconRevcloud" />
                        <img src={Loadingbegin} className='SelectFacebookAccount__img2' alt='Loading...' />
                        <img src={Facebookads} className='SelectFacebookAccount__img3' alt="Facebookads" />
                    </div></div>
                <div className="SelectFacebookAccount__div" >
                    <div >
                        <p className="SelectFacebookAccount__para">We've fetched the Facebook Ads accounts for the loged in user. You'll need to make sure you have the correct permissions for any accounts you want to use.</p>
                    </div>
                    <div className='SelectFacebookAccount__link'>
                        <a href='https://en-gb.facebook.com/business/help/155909647811305'>Read about Facebook Ads permissions</a>
                    </div>
                    <div className='SelectFacebookAccount__tableDiv'>                        
                        <Table
                        rowSelection={{
                            type: selectionType,
                        }}
                        columns={columns}
                        dataSource={data}
                        pagination={false} 
                    />
                    </div>

                    <div className='SelectFacebookAccount__buttonsDiv'>
                        <button onClick={()=>navigateToFields()} className='SelectFacebookAccount__button'>Next</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SelectFacebookAccount
