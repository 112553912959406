import { Button, Divider, Form, Image, Input, Space, Typography } from "antd";
import { UserOnBoardingFooter } from "components/UserOnBoardingFooter";
import { IconContinueWithGoogle } from "images";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "routes";
import AppLogo from "../../components/AppLogo";
import "./index.scss";
import { GoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import { setUserSession } from "store/modules/auth/action";

function WelcomeBack() {
  const dispatch = useDispatch();

  const googleOnSuccess = (res) => {
    const state = {
      email: res.profileObj.email,
      firstName: res.profileObj.givenName ? res.profileObj.givenName : "",
      lastName: res.profileObj.familyName ? res.profileObj.familyName : "",
      password: "999999",
      userId: res.profileObj.googleId,
    };
    // navigate(APP_ROUTES.SIGNUP_ADD_PHONE.path, {
    //   state: {
    //     email: res.profileObj.email,
    //     firstName: res.profileObj.givenName,
    //     lastName: res.profileObj.familyName,
    //     password: "999999",
    //     google_token: res.profileObj.googleId,
    //   },
    // });
    dispatch(setUserSession(state));
  };

  const googleOnFailure = (res) => {
    console.log("Google login failed", res);
  };

  return (
    <>
      <div className="wbPage__container">
        <div className="wbPage__header">
          <div>
            <Typography.Text className="wbPage__textDonthaveAccount">
              Don't have an account?
            </Typography.Text>
            <Link to={APP_ROUTES.SIGNUP.path}>
              <Button type="primary" className="wbPage__button">
                Sign Up
              </Button>
            </Link>
          </div>
        </div>
        <div className="wbPage">
          <Space
            direction="vertical"
            size={14}
            className="wbPage__signinFields"
          >
            <div className="wbPage__headerLogo">
              <AppLogo />
            </div>

            <Typography.Text className="wbPage__headerTitle">
              Welcome Back, Shanton
            </Typography.Text>
            <Form layout="vertical" autoComplete="off">
              <Form.Item
                name="password"
                label="Enter Password"
                required
                rules={[
                  {
                    required: true,
                    message: "Please input your password.",
                  },
                  {
                    min: 6,
                    message: "Min 6 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Password"
                  className="wbPage__inputField"
                  autoComplete={false}
                />
              </Form.Item>
              <Link to={APP_ROUTES.FORGOT_PASSWORD.path}>
                <Button className="wbPage__linkButton" type="link">
                  Forgot Password?
                </Button>
              </Link>
              <Link to={APP_ROUTES.DASHBOARD.path}>
                <Button type="primary" className="wbPage__continueButton">
                  Sign In
                </Button>
              </Link>
              <Link to={APP_ROUTES.SIGNIN.path}>
                <p className="wbPage__textNotYou">
                  Not you?
                  <u className="wbPage__textLightBlueFont12">
                    Sign in with a different account
                  </u>
                </p>
              </Link>

              <Divider>Or</Divider>
              <Form.Item>
                <GoogleLogin
                  clientId="85599887122-2c555u3dpfo6mkar40vsf952q9g7ossj.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <Image
                      src={IconContinueWithGoogle}
                      className="signupPage__googleButton"
                      preview={false}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    ></Image>
                  )}
                  buttonText="Login"
                  onSuccess={googleOnSuccess}
                  onFailure={googleOnFailure}
                  cookiePolicy={"single_host_origin"}
                />
              </Form.Item>
            </Form>
          </Space>
        </div>

        <UserOnBoardingFooter />
      </div>
    </>
  );
}
export default WelcomeBack;
