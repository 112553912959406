import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import AppRoutes from "../../routes";
import { getSavedUserSession } from "../../store/localStorage";
import {
  setIsSocial,
  setUserSession,
  setUserToken,
} from "../../store/modules/auth/action";
import { changeFavivon } from "utils";

function RouteChangeListener() {
  useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return null;
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    changeFavivon();
  }, []);

  useEffect(() => {
    const handleInvalidToken = (event) => {
      if (event.storageArea == localStorage) {
        let token = getSavedUserSession();
        if (token === null) {
          dispatch(setUserSession(null));
          dispatch(setIsSocial(false));
          dispatch(setUserToken(null));
        } else {
          dispatch(setUserSession(token));
        }
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return () => {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  const initializeUserData = () => {
    dispatch(setUserSession(getSavedUserSession()));
  };
  initializeUserData();

  return (
    <>
      <RouteChangeListener />
      <AppRoutes />
    </>
  );
}

export default App;
