import './index.scss'
import { IconRevcloud, Facebookads, Loadingbegin } from 'images';

const YouareConnectedtoFacebookAds = () => {

    return (
        <div>
            <div className='YouareConnectedtoFacebookAds__component'>
                <h1 className='YouareConnectedtoFacebookAds__mainHeading'>Permissions Verified</h1>
                <div className='YouareConnectedtoFacebookAds__iconsDiv'>
                    <div className='YouareConnectedtoFacebookAds__icons'>
                        <img src={IconRevcloud} className='YouareConnectedtoFacebookAds__img1' alt="IconRevcloud" />
                        <img src={Loadingbegin} className='YouareConnectedtoFacebookAds__img2' alt='Loading...' />
                        <img src={Facebookads} className='YouareConnectedtoFacebookAds__img3' alt="Facebookads" />
                    </div></div>
                <div className="YouareConnectedtoFacebookAds__div" >
                    <div >
                        <p className="YouareConnectedtoFacebookAds__para">We were able to read and write the data from Facebook Ads account, Next, configure your warehouse.</p>
                    </div>
                    <div >
                        <p className="YouareConnectedtoFacebookAds__info">What data will be synced?</p>
                    </div>
                    <div >
                    <p className="YouareConnectedtoFacebookAds__para">To see the collection we will sync, visit 
                            <a className='SelectFacebookAccount__link' href='https://developers.facebook.com/docs/app-ads/'> Facebook Ads Documentation</a>
                        </p></div>

                    <div className='YouareConnectedtoFacebookAds__buttonsDiv'>
                        <button className='YouareConnectedtoFacebookAds__button'>Done</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default YouareConnectedtoFacebookAds
