import { Typography } from "antd";
import { useEffect, useState } from "react";
import { getCurrentDomainName } from "utils";
import "./index.scss";

export const UserOnBoardingFooter = () => {
  const [selectedDomain, setSelectedDomain] = useState("palisadeknowledge");

  useEffect(() => {
    setSelectedDomain(getCurrentDomainName);
  }, []);

  const { Link } = Typography;
  return (
    <div className="footer">
      <Link
        href={`https://www.${selectedDomain}.com/contact-us`}
        target="_blank"
        className="footer__footerText"
      >
        Contact us
      </Link>
      <Link
        href={`https://www.${selectedDomain}.com/privacy-policy`}
        target="_blank"
        className="footer__footerText"
      >
        Privacy
      </Link>
      {/* <Link
        href="https://www.google.com"
        target="_blank"
        className="footer__footerText"
      >
        Legal
      </Link>
      <Link
        href="https://www.google.com"
        target="_blank"
        className="footer__footerText"
      >
        Policy updates
      </Link>
      <Link
        href="https://www.google.com"
        target="_blank"
        className="footer__footerText"
      >
        Worldwide
      </Link> */}
    </div>
  );
};
