import { useEffect, useState } from "react";
import FindWorkspaces from "../FindWorkspaces";
import FindWorkspacesMobile from "../FindWorkspacesMobile";
import { isMobile } from "utils";

function Marketplaces() {

  const [isMobileView, setIsMobileView] = useState(isMobile());
  console.log(isMobileView, 'isMobileView')

  useEffect(() => {
    function handleResize() {
      setIsMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>{isMobileView == true ? (<FindWorkspacesMobile/>) : (<FindWorkspaces/>)}</>
  );
}

export default Marketplaces;
