import {
  SET_STEP_COMPLETION,
  SET_DATA,
  SET_SOURCES,
  CLEAR_DATASET_STATE,
  SET_DESTINATIONS,
  SET_ACTIVE_KEY,
} from "./action";

const initialState = {
  activeKey: { activeKey: "1" },
  steps: {
    1: { completed: false, data: {} },
    1.1: { completed: false, data: {} },
    2: { completed: false, data: {} },
    2.1: { completed: false, data: {} },
    2.2: { completed: false, data: {} },
    2.3: { completed: false, data: {} },
    3: { completed: false, data: {} },
    3.1: { completed: false, data: {} },
    3.2: { completed: false, data: {} },
    3.3: { completed: false, data: {} },
    3.4: { completed: true, data: {} },
    4: { completed: false, data: {} },
    5: { completed: false, data: {} },
    5.1: { completed: false, data: {} },
    5.2: { completed: false, data: {} },
    5.3: { completed: false, data: {} },
    6: { completed: false, data: {} },
    7: { completed: false, data: {} },
    7.1: { completed: false, data: {} },
    7.11: { completed: true, data: {} },
    7.2: { completed: true, data: {} },
    7.3: { completed: false, data: {} },
  },
  sources: {},
  destinations: {},
};

const stepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STEP_COMPLETION:
      const { step, isCompleted } = action.payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          [step]: { ...state.steps[step], completed: isCompleted },
        },
      };
    case SET_DATA:
      const { step: stepNumber, subStep, value } = action.payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          [stepNumber]: {
            ...state.steps[stepNumber],
            data: {
              ...state.steps[stepNumber].data,
              [subStep]: value,
            },
          },
        },
      };
    case SET_SOURCES:
      const { newSources } = action.payload;
      return {
        ...state,
        sources: {
          newSources,
        },
      };
    case SET_DESTINATIONS:
      const { newDestinations } = action.payload;
      return {
        ...state,
        destinations: {
          newDestinations,
        },
      };
    case CLEAR_DATASET_STATE:
      return {
        ...initialState,
      };
    case SET_ACTIVE_KEY:
      const { activeKey } = action.payload;
      return {
        ...state,
        activeKey: {
          activeKey,
        },
      };

    default:
      return state;
  }
};

export default stepsReducer;
