import "./index.scss";
import { MoreOutlined } from "@ant-design/icons";
import { PlaybookDummyImage } from "images";
import { Card, Avatar, Row, Col, Image } from "antd";
import { PlaybookRequested } from "images";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Rate, Divider } from "antd";
import { isEmpty } from "lodash";

function PreviewCard({ singlePlaybookDetail }) {
  let data = singlePlaybookDetail;
  const navigate = useNavigate();

  return (
    <>
      <div className="PreviewCard">
        <Card className="PreviewCard__Card" hoverable>
          <Image
            className="PreviewCard__IMG1"
            alt="Image of playbook"
            preview={false}
            src={PlaybookDummyImage}
          />

          <Row
            className="PreviewCard__Row-2"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={19}>
              <div className="PreviewCard__appHomeButton">
                <CustomAvatarSinglePlaybook />
                <div className="PreviewCard__appHomeHeadContainer">
                  <div className="PreviewCard__appHomeMainHead">
                    Kimbell Belmont
                  </div>
                  <div className="PreviewCard__appHomeSubHead">
                    Real Estate Director
                  </div>
                </div>
              </div>
            </Col>
            <Col span={5}>
              <div className="PreviewCard__appHomeButton">
                <MoreOutlined className="PreviewCard__appDotsIcon" />
              </div>
            </Col>
          </Row>
          <h3 className="PreviewCard__H1">Cash Offer For Your Home</h3>
          <p className="PreviewCard__P1">
            We offer to buy your home and we want to give you a price that
            you'll be happy with.
          </p>

          <p className="PreviewCard__proposalDetails__reviewsInfo">
            <Rate disabled defaultValue={1} count={1} />
            <span className="PreviewCard__proposalDetails__reviewsInfo__count">
              23
            </span>
          </p>

          <Divider className="PreviewCard__divider" />

          <div className="PreviewCard__P2">
            {data?.isProposal ? (
              <div className="PreviewCard__P2__Read">
                <img src={PlaybookRequested} /> Requested
              </div>
            ) : (
              <p className="PreviewCard__P2__Low">
                <div className="PreviewCard__P2__Low__Para">AS LOW AS</div> USD
                300
              </p>
            )}
          </div>
        </Card>
      </div>
    </>
  );
}

export default PreviewCard;

function CustomAvatarSinglePlaybook({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar className="PreviewCard__customAvatar__avatar" src={imageUri} />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="PreviewCard__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
