import React, { useState } from "react";
import "./index.scss";
import { Image, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft, MessageMediaIcon } from "images";

function MessageMediaCatalog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/sources/setup/${"messagemedia"}/${id}`);
  };

  const handleBackClick = () => {
    // Navigate back to the previous page
    navigate(-1); // Go back one step
  };

  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };

  return (
    <div className="MessageMediaCatalog">
      <div className="MessageMediaCatalog__Fixed">
        {/* <div className="MessageMediaCatalog__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="MessageMediaCatalog__title"
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}
            onClick={handleConnectionClick}>
            Connections
          </span>
          <span className="MessageMediaCatalog__slash">/</span>
          <span
            className="MessageMediaCatalog__title"
            style={{ cursor: "pointer" }}
            onClick={handleBackClick}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            Catalog
          </span>
          <span className="MessageMediaCatalog__slash">/</span>
          <span className="MessageMediaCatalog__mainTitle">Message Media</span>
        </div> */}
        <div className="MessageMediaCatalog__sourceText">
          <div>
            <Image
              src={MessageMediaIcon}
              preview={false}
              style={{ marginRight: "10px", width: "65px", height: "65px" }}
            />
            <span style={{ marginLeft: "15px" }}>Message Media</span>
          </div>
        </div>
        <div className="MessageMediaCatalog__bar">
          <div>
            <Button   
              onClick={() => navigateToFields()}
              className="MessageMediaCatalog__button"
            >
              Add source
              <ArrowRightOutlined className="MessageMediaCatalog__btnImage" />
            </Button>
          </div>
        </div>

        <div className="MessageMediaCatalog__newSource">
          <Button className="MessageMediaCatalog__newText">Source</Button>
        </div>
        <div className="MessageMediaCatalog__exploreBar">
          <div className="MessageMediaCatalog__exploreText">
            Connect with customers via the channel of their choice – SMS,
            social, and 85+ integrations. All via our easy-to-use messaging
            platform.
          </div>
        </div>
        <div className="MessageMediaCatalog__bottomBar"></div>
      </div>
      <div className="MessageMediaCatalog__mainContainer">
        <div className="MessageMediaCatalog__flexDiv">
          <div className="MessageMediaCatalog__container1">
            <h6 className="MessageMediaCatalog__cardHeading1">
              Compatible destination types
            </h6>
            <div className="MessageMediaCatalog__textFlex">
              <div className="MessageMediaCatalog__cardIcon">
                <MenuOutlined />
              </div>
              <div className="MessageMediaCatalog__cardText">Storage</div>
            </div>
            <p className="MessageMediaCatalog__rawText">
              Destinations for storing raw data.
              {/* <a className="MessageMediaCatalog__anchorTag">Learn more</a> */}
            </p>
          </div>
          <div className="MessageMediaCatalog__container2">
            <div className="MessageMediaCatalog__containFlex">
              <div className="MessageMediaCatalog__line"></div>
              <div className="MessageMediaCatalog__justDiv">
                <h6 className="MessageMediaCatalog__cardHeading2">
                  Set up requirements
                </h6>
                <p className="MessageMediaCatalog__setText1">
                  * An enabled Message Media acount
                </p>
                {/* <p className="MessageMediaCatalog__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="MessageMediaCatalog__flexDiv1">
          <div className="MessageMediaCatalog__container3">
            <h6 className="MessageMediaCatalog__overviewHeading1">Overview</h6>
            <p className="MessageMediaCatalog__overviewText">
              We’re a global messaging provider that helps businesses of all
              sizes cut through the noise – we’re talking 98% open rates and 36%
              click-through rates. That kind of cut through. So it’s no wonder
              we’ve got thousands of customers worldwide and have been in the
              business for over 20 years.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageMediaCatalog;
