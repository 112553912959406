import React, { useState } from "react";
import "./index.scss";
import { Image, Input, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import RandomIcon from "../../../images/Datasets-1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft, IconSalesforce } from "images";

function SalesforceDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/sources/setup/${"salesforce-marketing-cloud"}/${id}`);
  };

  const handleBackClick = () => {
    navigate(-1); // Go back one step
  };

  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };
  
  return (
    <div className="SalesforceDetails">
      <div className="SalesforceDetails__Fixed">
        {/* <div className="SalesforceDetails__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="SalesforceDetails__title"
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}
            onClick={handleConnectionClick}>
            Connections</span>
          <span className="SalesforceDetails__slash">/</span>
          <span className="SalesforceDetails__title" onClick={handleBackClick}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}>
            Catalog
          </span>
          <span className="SalesforceDetails__slash">/</span>
          <span className="SalesforceDetails__mainTitle">
            Salesforce-Marketing-Cloud
          </span>
        </div> */}
        <div className="SalesforceDetails__sourceText">
          <div>
            <Image
              src={IconSalesforce}
              preview={false}
              style={{ marginRight: "10px" }}
            />
            <span style={{ marginLeft: "15px" }}>
              Salesforce Marketing Cloud
            </span>
          </div>
        </div>
        <div className="SalesforceDetails__bar">
          <div>
            <Button
              onClick={() => navigateToFields(id)}
              className="SalesforceDetails__button"
            >
              Add source
              <ArrowRightOutlined className="SalesforceDetails__btnImage" />
            </Button>
          </div>
        </div>

        <div className="SalesforceDetails__newSource">
          <Button className="SalesforceDetails__newText">Source</Button>
        </div>
        <div className="SalesforceDetails__exploreBar">
          <div className="SalesforceDetails__exploreText">
            Salesforce is a leader in on-demand customer relationship management
          </div>
        </div>
        <div className="SalesforceDetails__bottomBar"></div>
      </div>
      <div className="SalesforceDetails__mainContainer">
        <div className="SalesforceDetails__flexDiv">
          <div className="SalesforceDetails__container1">
            <h6 className="SalesforceDetails__cardHeading1">
              Compatible destination types
            </h6>
            <div className="SalesforceDetails__textFlex">
              <div className="SalesforceDetails__cardIcon">
                <MenuOutlined />
              </div>
              <div className="SalesforceDetails__cardText">Storage</div>
            </div>
            <p className="SalesforceDetails__rawText">
              Destinations for storing raw data.
              {/* <a className="SalesforceDetails__anchorTag">Learn more</a> */}
            </p>
          </div>
          <div className="SalesforceDetails__container2">
            <div className="SalesforceDetails__containFlex">
              <div className="SalesforceDetails__line"></div>
              <div className="SalesforceDetails__justDiv">
                <h6 className="SalesforceDetails__cardHeading2">
                  Set up requirements
                </h6>
                <p className="SalesforceDetails__setText1">
                  * Username and password for an account in your salesforce org{" "}
                </p>
                <p className="SalesforceDetails__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="SalesforceDetails__flexDiv1">
          <div className="SalesforceDetails__container3">
            <h6 className="SalesforceDetails__overviewHeading1">Overview</h6>
            <p className="SalesforceDetails__overviewText">
              When you enter your Salesforce credentials and hit save, we will
              sync Salesforce standard objects {"("}and their corresponding
              properties{")"}
              to any databases you have turned on. Salesforce data will reflect
              the state of the object at the time of sync. We will run an update
              ~3 hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesforceDetails;
