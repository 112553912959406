import React, { useState, useRef, useEffect } from "react";
import { Input, Button, Switch, message, Tabs, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import Editor from '@monaco-editor/react';
import { useSelector, useDispatch } from "react-redux";
import { getModelDetails } from "store/modules/connector/action";
import { saveQuery } from "api/Connectors";
import "./index.scss";

function ModelQueryBuilder() {
  const editorRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modelDetails = useSelector(
    (state) => state?.connectorReducer?.modelDetails
  );
  const { modelId, modelName } =
    useParams();
    const [sqlQuery, setSqlQuery] = useState('');
    const [uniqueIdentifier, setUniqueIdentifier] = useState('');

    const onMount = (editor) => {
      editorRef.current = editor;
      // Focus the editor only if the initial content is empty
      if (!sqlQuery.trim()) {
        editor.focus();
      }
    };

    useEffect(() => {
      dispatch(getModelDetails(parseInt(modelId)));
    }, [dispatch, modelId]);

    useEffect(() => {
      if (modelDetails?.primary_key !== undefined) {
        setSqlQuery(modelDetails?.query)
        setUniqueIdentifier(modelDetails.primary_key);
      }
    }, [modelDetails?.primary_key]);

    const handleEditorChange = (value, event) => {
      setSqlQuery(value);
    };
    const handleUniqueIdentifier = (value) => {
      setUniqueIdentifier(value);
    };

    const handleSaveQuery = () => {
      const queryData = {
        "model_name": modelDetails?.model_name,
        "query": sqlQuery,
        "primary_key": uniqueIdentifier,
        "status": modelDetails?.status
      };
      saveQuery(modelId, queryData)
        .then(res => {
          message.success(res?.data?.message)
        })
        .catch(error => {
          message.success(error?.data?.message)
        });
    };

  const items = [
    { key: "1", label: "Overview" },
    { key: "2", label: "Query Builder" },
    { key: "3", label: "Settings" },
  ];

  const onChange = (activeKey) => {
    if (activeKey ==="1") {
      navigate(
        `/dashboard/datasets/model/overview/${modelId}/${modelName}`
      );
   }
   if (activeKey === "3") {
    navigate(
     `/dashboard/model/settings/${modelId}/${modelName}`
   );
 }
  };

  return (
    <div className="ModelQueryBuilder">
      <div className="ModelQueryBuilder__headerText">
        <Tabs defaultActiveKey="2" items={items}
        onChange={onChange} 
        />
      </div>
      <div className="ModelQueryBuilder__container">
              <div>
                <h5 className="ModelQueryBuilder__description">
                Enter the SQL query that will define your Model. Once created, your Model can be used to sync data to your Reverse ETL Destinations.
                </h5>
              </div>
              <div className="ModelQueryBuilder__button">
                <Button type="Primary" onClick={handleSaveQuery}>Save Query</Button><div/>
              </div>
              <div className="ModelQueryBuilder__wrapper">
                <Editor
                  // height="100%"
                  // width="100%"
                  // theme="vs-dark"
                  defaultLanguage="sql"
                  // defaultValue="// Write a SQL query"
                  value={sqlQuery}
                  onChange={handleEditorChange}
                  onMount={onMount}
                />
              </div>

              <div className="uniqueIdentifierContainer">
                <label>Unique Identifier Column * </label>
                <div title="Choose a column to use as the unique identifier for each row. This column will be used to detect new, updated, and deleted rows.">
                  <svg aria-describedby="evergreen-tooltip-89" data-icon="info-sign" viewBox="0 0 16 16" width="12" height="12">
                    <path d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zM7 3h2v2H7V3zm3 10H6v-1h1V7H6V6h3v6h1v1z" fill-rule="evenodd"></path>
                  </svg>
                </div>

                <Input
                  id="uniqueIdentifier"
                  type="text"
                  placeholder="Enter unique identifier column name"
                  value={uniqueIdentifier}
                  onChange={(e) => handleUniqueIdentifier(e.target.value)}
                />
              </div>
            </div>

    </div>
  );
}

export default ModelQueryBuilder;
