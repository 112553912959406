import React, { useState } from "react";
import { Tabs, Collapse } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import "./index.scss";

const { Panel } = Collapse;

function Tasks() {
  const { sourceId, sourceName, connectionName, connectorId, active } =
    useParams();
  const navigate = useNavigate();
  const [activePanel, setActivePanel] = useState(null);

  const items = [
    connectorId == 10
      ? { key: "1", label: "Model" }
      : { key: "1", label: "Overview" },
    { key: "2", label: "Mapping" },
    { key: "3", label: "Tasks" },
    { key: "4", label: "Settings" },
  ];
  const panelData = [
    {
      key: "1",
      mainHeading: "Validation in Progress",
      subHeading: "Complete",
      status: "complete",
    },
    {
      key: "2",
      mainHeading: "AI Schema Mapping",
      subHeading: "Complete",
      status: "complete",
    },
    {
      key: "3",
      mainHeading: "Entities Identified",
      subHeading: "Complete",
      status: "complete",
    },
    {
      key: "4",
      mainHeading: "Data cleaned",
      subHeading: "Complete",
      status: "complete",
    },
    {
      key: "5",
      mainHeading: "Data Unified",
      subHeading: "Complete",
      status: "complete",
    },
    {
      key: "6",
      mainHeading: "Data Viewable",
      subHeading: "In Progress",
      status: "inProgress",
    },
    {
      key: "7",
      mainHeading: "Identities Generated",
      subHeading: "1 Task",
      status: "pending",
    },
  ];

  const onChange = (activeKey) => {
    const pathPrefix =
      sourceName === "Revcloud API Events"
        ? "/dashboard/datasets/apiconnector/overview"
        : "/dashboard/datasets/connector/overview";
    navigate(
      `${pathPrefix}/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
    );
    if (activeKey === "2") {
      navigate(
        `/dashboard/datasets/sources/mapping/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "3") {
      navigate(
        `/dashboard/datasets/sources/tasks/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "4") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };

  const handlePanelChange = (panelKey) => {
    setActivePanel(panelKey === activePanel ? null : panelKey);
  };

  const CustomPanelHeader = ({
    number,
    mainHeading,
    subHeading,
    panelKey,
    status,
  }) => {
    const isActive = activePanel === panelKey;
    let backgroundColor,
      numberBackgroundColor,
      mainHeadingColor,
      subHeadingColor;

    if (status === "complete") {
      backgroundColor = "#51b150";
      numberBackgroundColor = "#3F933B";
      mainHeadingColor = "#ffffff";
      subHeadingColor = "#ffffff";
    } else if (status === "inProgress") {
      backgroundColor = "#394399";
      numberBackgroundColor = "#3A6793";
      mainHeadingColor = "#ffffff";
      subHeadingColor = "#ffffff";
    } else if (status === "pending") {
      backgroundColor = "#ffffff";
      numberBackgroundColor = "#3872CD";
      mainHeadingColor = "#394399";
      subHeadingColor = "#394399";
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: backgroundColor, // Change color when active
          padding: "8px",
          borderRadius: "8px",
          boxShadow:
            activePanel === panelKey ? "none" : "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            fontWeight: "bold",
            backgroundColor: numberBackgroundColor,
            color: "#FFFFFF",
            marginRight: "8px",
          }}
        >
          {number}
        </div>
        <div>
          <div
            style={{
              fontSize: "12px",
              color: mainHeadingColor,
              fontWeight: "bold",
            }}
          >
            {mainHeading}
          </div>
          <div
            style={{
              fontSize: "10px",
              color: subHeadingColor,
            }}
          >
            {subHeading}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="ConnectorSettings">
      <div className="ConnectorSettings__headerText">
        <Tabs defaultActiveKey="3" items={items} onChange={onChange} />
      </div>

      <div className="tasks">
        <Collapse
          accordion
          expandIconPosition="end"
          onChange={handlePanelChange}
          activeKey={activePanel}
          className="tasks"
        >
          {panelData.map((panel) => (
            <Panel
              header={
                <CustomPanelHeader
                  number={panel.key}
                  mainHeading={panel.mainHeading}
                  subHeading={panel.subHeading}
                  panelKey={panel.key}
                  status={panel.status}
                />
              }
              key={panel.key}
              collapsible="disabled"
            />
          ))}
        </Collapse>
      </div>
    </div>
  );
}

export default Tasks;
