import React from "react";
import "./index.scss";
import {
  Image,
  Input,
  Button,
  message,
  Spin,
  Form,
  Select,
  Modal,
  Table,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Flex from "screens/Chat/Flex";
import { useState, useEffect } from "react";
import { IconChevronLeft } from "images";
import {
  getFlowsByUserId,
  getActiveConnections,
  getSaleforceAppObjects,
  createFlow,
  activateFlow,
} from "api/Connectors";

const { Option } = Select;

function AvailableFlows() {
  const [searchInput, setSearchInput] = useState("");
  const [spinning, setSpinning] = useState(true);
  const [flows, setFlows] = useState([]);
  const [activeFlows, setActiveFlows] = useState([]);
  const [appObjects, setAppObjects] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [connectorProfileId, setConnectorProfileId] = useState(null);
  const [flowName, setFlowName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const tableContainerStyle = {
    height: "calc(100vh - 300px)", // Adjust the height as needed
    overflow: "auto",
  };

  const columns = [
    // {
    //     title: "Id",
    //     dataIndex: ["id"],
    //     key: "id",
    //     // render: (text, record, index) => index + 1,
    // },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "name",
    },
    {
      title: "Status",
      dataIndex: ["status"],
      key: "active",
      render: (text) => (
        <div className="statusFlex">
          <div className={text == "Draft" ? "InActiveStatus" : "Status"}></div>
          <div>{text == "Draft" ? "Non-Active" : "Active"}</div>
        </div>
      ),
    },

    {
      title: "Event Type",
      dataIndex: ["event_type"],
      key: "type",
    },
    {
      title: "Action",
      dataIndex: "status",
      render: (text, record) => (
        <Button
          className={
            text == "Draft"
              ? "AvailableFlows__inactive-button"
              : "AvailableFlows__active-button"
          }
          onClick={() => handleActivateFlow(record)}
          disabled={text !== "Draft" ? true : false}
        >
          {text == "Draft" ? "ACTIVATE" : "ACTIVATED"}
        </Button>
      ),
    },
  ];

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelectEventType = (value) => {
    setEventType(value);
  };

  const handleSelectConnectorProfileId = (value) => {
    setConnectorProfileId(value);
  };

  const handleCreateFlow = () => {
    setIsModalVisible(true);
  };

  const handleFlowName = (e) => {
    setFlowName(e.target.value);
  };

  const fetchFlowsByUserId = async () => {
    setSpinning(true);
    getFlowsByUserId()
      .then((res) => {
        setFlows(res?.data?.data);
      })
      .catch((error) => {})
      .finally(() => {
        setSpinning(false);
      });
  };

  const fetchActiveConnections = async () => {
    getActiveConnections()
      .then((res) => {
        setActiveFlows(res?.data?.data);
        setConnectorProfileId(res?.data?.data[0]?.id);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const fetchSaleforceAppObjects = async () => {
    getSaleforceAppObjects()
      .then((res) => {
        setAppObjects(res?.data?.data);
        setEventType([res?.data?.data[0]]);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleActivateFlow = (record) => {
    setSpinning(true);
    activateFlow(record?.id)
      .then((res) => {
        message.success(res?.data?.message);
        fetchFlowsByUserId();
      })
      .catch((error) => {})
      .finally(() => {
        setSpinning(false);
      });
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    if (inputValue === "") {
      setFilteredData(flows);
    } else {
      const filtered = filteredData.filter((datas) =>
        datas.name.toLowerCase().includes(inputValue)
      );
      setFilteredData(filtered);
    }
  };

  const onCreateFlowSubmitCalled = () => {
    const payload = {
      flowName: flowName,
      connectorProfileId: connectorProfileId,
      appObjectNames: eventType,
    };
    setIsModalVisible(false);
    setSpinning(true);
    createFlow(payload)
      .then((res) => {
        message.success(res?.data?.message);
        fetchFlowsByUserId();
      })
      .catch((error) => {})
      .finally(() => {
        setSpinning(false);
      });
  };

  useEffect(() => {
    fetchFlowsByUserId();
    fetchActiveConnections();
    fetchSaleforceAppObjects();
  }, []);

  return (
    <>
      <div className="AvailableFlows">
        <div className="AvailableFlows__headerText">
          <span style={{ cursor: "pointer" }}>
            <Image
              src={IconChevronLeft}
              preview={false}
              onClick={() => {
                window.history.back();
              }}
            />
          </span>
          <span className="AvailableFlows__title">Available Flows</span>
        </div>

        <div className="AvailableFlows__bar">
          <Input
            className="AvailableFlows__input"
            type="search"
            placeholder="Search for flows by name"
            onChange={handleSearch}
            value={searchInput}
            prefix={<SearchOutlined style={{ color: "grey" }} />}
          />
          <div>
            <Button
              onClick={() => handleCreateFlow()}
              className="AvailableFlows__button"
            >
              Create Flow
            </Button>
          </div>
        </div>

        <div className="AvailableFlows__tableDiv" style={tableContainerStyle}>
          {spinning ? (
            <Flex
              flex={1}
              justify="center"
              align="center"
              style={{
                width: "100%",
                height: 100,
                backgroundColor: "#fff",
              }}
              direction="column"
            >
              <Spin size="default" />
              <div> Loading...</div>
            </Flex>
          ) : (
            <Table
              columns={columns}
              dataSource={searchInput ? filteredData : flows}
              pagination={{
                pageSize: 10, // Number of items per page
              }}
            />
          )}
        </div>
      </div>

      <Modal
        title="Create Flow"
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={onCreateFlowSubmitCalled}
        width={500}
        centered
        className="rounded-modal"
        okText="Submit"
        okButtonProps={{
          style: { borderRadius: "8px", backgroundColor: "#1C74D4" },
        }}
        cancelButtonProps={{ style: { borderRadius: "8px" } }}
      >
        <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Form.Item
            label="Flow Name"
            name="flowName"
            rules={[{ required: true, message: "Please enter the flow name!" }]}
          >
            <Input
              onChange={handleFlowName}
              placeholder="Enter the flow name"
            />
          </Form.Item>

          <Form.Item
            label="Event Type"
            name="dropdown1"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Select
              mode="multiple" // Set mode to 'multiple' for multiple selections
              onChange={handleSelectEventType}
              defaultValue={
                appObjects?.length > 0 ? [appObjects[0]] : undefined
              } // Default value as an array
            >
              {appObjects?.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Choose Connection"
            name="dropdown2"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Select
              onChange={handleSelectConnectorProfileId}
              defaultValue={
                activeFlows?.length > 0 ? activeFlows[0]?.name : undefined
              }
            >
              {activeFlows?.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default AvailableFlows;