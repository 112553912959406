import React from "react";
import { Table } from "antd";
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from "recharts";
import "./index.scss";

function AIModelOverview() {
  const graphData = [
    { date: "01/09/24", accuracy: 55 },
    { date: "02/09/24", accuracy: 46 },
    { date: "03/09/24", accuracy: 78 },
    { date: "04/09/24", accuracy: 22 },
    { date: "05/09/24", accuracy: 48 },
    { date: "06/09/24", accuracy: 39 },
    { date: "07/09/24", accuracy: 71 },
    { date: "08/09/24", accuracy: 80 },
    { date: "09/09/24", accuracy: 70 },
  ];
  const dataOne = [
    {
      key: "1",
      date: "01/09/24",
      accuracyAtDeployment: "55%",
      currentAccuracy: "70%",
      driftPerformance: "15%",
    },
  ];

  const dataOneColumns = [
    {
      title: "LAST MODEL TRAINING DATE",
      dataIndex: "date",
      key: "date",
      render: (date) => (
        <div>{date}</div>
      ),
    },
    {
      title: "ACCURACY AT DEPLOYMENT",
      dataIndex: "accuracyAtDeployment",
      key: "accuracyAtDeployment",
      render: (percentage) => (
        <div>{percentage}</div>
      ),
    },
    {
      title: "CURRENT ACCURACY",
      dataIndex: "currentAccuracy",
      key: "currentAccuracy",
      render: (percentage) => (
        <div>{percentage}</div>
      ),
    },
    {
      title: "DRIFT IN PERFORMANCE",
      dataIndex: "driftPerformance",
      key: "driftPerformance",
      render: (percentage) => (
        <div>{percentage}</div>
      ),
    },
  ];

  const dataTwo = [
    {
      key: "1",
      status: "AI life cycle confidence",
    },
  ];

  const dataTwoColumns = [
    {
      title: "DEPLOYMENT STATUS",
      dataIndex: "status",  // Fixed the typo here
      key: "status",        // Fixed the typo here
      render: (status) => (
        <div>{status}</div>
      ),
    }
  ];

  const dataThree = [
    {
      key: "1",
      graph: (
        <LineChart
          width={1050}  // Increased width
          height={270}  // Decreased height
          data={graphData}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis dataKey="date" />
          <YAxis domain={[0, 100]} /> {/* Adding YAxis for accuracy */}
          <Tooltip />
          <CartesianGrid
            stroke="#A9A9A9"        // Dark grey color
            strokeDasharray="1 1"  // Dotted grid lines
          />
          <Line type="monotone" dataKey="accuracy" stroke="#3872CD" /> {/* Change the line color */}
        </LineChart>
      ),
    },
  ];

  const dataThreeColumns = [
    {
      title: "ACCURACY OVER TIME",
      dataIndex: "graph",
      key: "graph",
      render: (graph) => <div>{graph}</div>,
    },
  ];

  return (
    <div className="AI_Model">
      <Table
        className="AI_Model__table AI_Model__table--summary"
        columns={dataOneColumns} // Fixed variable name
        dataSource={dataOne}
        pagination={false}
      />
      <Table
        className="AI_Model__table AI_Model__table--summary"
        columns={dataTwoColumns} // Fixed variable name
        dataSource={dataTwo}
        pagination={false}
      />
      <Table
        className="AI_Model__table"
        columns={dataThreeColumns} // Fixed variable name
        dataSource={dataThree}
        pagination={false}
      />
    </div>
  );
}

export default AIModelOverview;
