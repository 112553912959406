import { Drawer, Layout } from "antd";
import "antd/dist/antd.css";
import AppLeftNav from "components/AppLeftNav";
import BottomNav from "components/BottomNav";
import HeaderDesktop from "components/Header";
import HeaderBar from "components/HeaderBar";
import SettingsRightNav from "components/SettingsRightNav";
import { useEffect, useState } from "react";
import { useCustomEventListener } from "react-custom-events";
import { Outlet, useLocation } from "react-router-dom";
import { isMobile } from "utils";
import "./index.scss";
import NewHeader from "components/NewHeader";

const { Sider, Content, Header, Footer } = Layout;

function DashBoard() {
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [hideHeaderFooterForMobileChat, setHideHeaderFooterForMobileChat] =
    useState(false);
  const location = useLocation();
  const shouldShowSettingsNav =
    location.pathname.includes("dashboard/settings");
  const shouldShowProfileScreen =
    location.pathname.includes("dashboard/people");
  const shouldShowProposalDetailsScreen = location.pathname.includes(
    "dashboard/proposalDetails"
  );

  useCustomEventListener("hideHeader_footer_forMobileChat", (data) => {
    setHideHeaderFooterForMobileChat(data);
  });

  useEffect(() => {
    function handleResize() {
      setIsMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="dashBoardPage">
      {isMobileView === true ? (
        <div className="dashBoardPage__containerMobileView">
          {!hideHeaderFooterForMobileChat && <HeaderBar></HeaderBar>}

          <Content className="dashBoardPage__contentMobile  hide-scroll-bar">
            <Outlet />
          </Content>

          {!hideHeaderFooterForMobileChat && (
            <Footer>
              <BottomNav></BottomNav>
            </Footer>
          )}
        </div>
      ) : (
        <div className="dashBoardPage__containerDesktopView">
          <Sider
            style={{
              background: "#100f5c",
              height: "calc(100vh)",
            }}
            trigger={null}
            collapsible
            width="233px"
          >
            <AppLeftNav />
          </Sider>
          <Layout>
            {!shouldShowProfileScreen && !shouldShowProposalDetailsScreen && (
              <NewHeader />
            )}
            <Content
              className={
                shouldShowProfileScreen || shouldShowProposalDetailsScreen
                  ? "dashBoardPage__contentPeople hide-scroll-bar"
                  : "dashBoardPage__content hide-scroll-bar"
              }
            >
              <Outlet />
            </Content>
          </Layout>
          {shouldShowSettingsNav && (
            <div className="dashBoardPage__settingsRightNav">
              <SettingsRightNav />
            </div>
          )}
          {!shouldShowSettingsNav &&
            !shouldShowProfileScreen &&
            !shouldShowProposalDetailsScreen && (
              <div className="dashBoardPage__rightNav"></div>
            )}
        </div>
      )}
    </div>
  );
}

export default DashBoard;
