import "./index.scss";
import { trackEventCall, trackPageCall } from "analytics";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Avatar,
  Button,
  // Carousel,
  Image,
  Divider,
  Empty,
  message,
  Rate,
  Spin,
  Form,
  Input,
  Select,
} from "antd";
import {
  getPlaybookCategories,
  getPlaybookDetailsById,
  playbookActions,
} from "api/Playbooks";
import Carousel from "react-bootstrap/Carousel";
import { IconCameraAddPhoto } from "images";
import MediaUpload from "components/MediaUpload";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import Flex from "screens/Chat/Flex";
import { setExternalUserInfo } from "store/modules/auth/action";
import { useCustomEventListener } from "react-custom-events";

import { createAPlaybook } from "api/Playbooks";
import { generateRandomIntegerInRange } from "utils";
import { ANALYTICS_EVENT_NAMES } from "../../../constants";
import { isMobile } from "utils";
import PackageDetails from "../PlaybookDetails/PackageDetails";
import AddPackage from "../CreatePlaybook/AddPackage";
// import PreviewPlaybookAds from "./PreviewPlaybookAds";
function CreateB2bPlaybook() {
  const contentStyle = {
    height: "520px",
    width: "600px",
    color: "#fff",
    textAlign: "center",
    background: "#C0C0C0",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };
  const { TextArea } = Input;

  const { playbookId } = useParams();
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const [pbDetails, setPBDetails] = useState({});
  const [spinning, setSpinning] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [imagesList, setImagesList] = useState([]);
  const [packageDetails, setPackageDetails] = useState({});
  const [creatingConversation, setCreatingConversation] = useState(false);
  const categoryId = useRef("");
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [servicesSelectionData, setServicesSelection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [categoriesList, setCategoriesList] = useState([]);
  const onCategoryChange = (index) => {
    categoryId.current = categoriesList[index - 1].id;
  };
  var groupChatImage = "";
  const navigate = useNavigate();

  const [image1Url, setImage1Url] = useState("");
  const [image2Url, setImage2Url] = useState("");
  const [image3Url, setImage3Url] = useState("");
  const [image4Url, setImage4Url] = useState("");
  const [playbookIconUrl, setPlaybookIconUrl] = useState("");
  const [selectedPrimaryColor, setSelectedPrimaryColor] = useState(0);

  const externalUserInfo = authReducer.externalUserInfo;
  const dispatch = useDispatch();
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [userFullName, setUserFullName] = useState(
    `${userSession.firstName} ${userSession.lastName}`
  );
  const colorsList = [
    { key: "0", name: "Green", hexCode: "#98D650" },
    { key: "1", name: "Orange", hexCode: " #F0994F" },
    { key: "2", name: "Blue", hexCode: "#0F0F5C" },
    { key: "2", name: "Cyan", hexCode: "#6AA3F8" },
  ];

  const PreviewCreateClicked = () => {
    // e.preventDefault();
    // console.log("This is PreviewPlaybookAds")
    navigate(`/dashboard/PreviewPlaybookAds`);
  };

  // const CancelPreviewCreateClicked = (finalPayload) => {
  //   // e.preventDefault();
  //   console.log("This is Final Payload", finalPayload);
  //   // navigate(`/dashboard/PreviewPlaybookAds`);
  // };

  useCustomEventListener("profile-img-changed", (data) => {
    setUserProfileUrl(data);
  });
  useCustomEventListener("first-name-changed", (data) => {
    setUserFullName(data);
  });

  useEffect(() => {
    const list = [];
    for (var i = 0; i < 7; i++) {
      list.push({
        key: `${i}`,
        service_name: "",
        b_isSelected: false,
        s_isSelected: false,
        p_isSelected: false,
      });
    }
    setServicesSelection(list);
  }, []);

  useEffect(() => {
    setUserProfileUrl(userSession?.imageUri);
  }, []);
  useEffect(() => {
    getPlaybookCategoriesAPI();
  }, []);

  const getPlaybookCategoriesAPI = () => {
    const payload = { workspace_category_id: userSession.workspaceCategoryId };
    getPlaybookCategories(payload)
      .then((res) => {
        const categories = res.data.data.response;
        setCategoriesList(categories);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {});
  };

  useEffect(() => {
    setSpinning(true);

    const payload = {
      playbook_id: 186,
      profile_workspace_id: 10,
    };

    getPlaybookDetailsById(payload)
      .then((res) => {
        setPBDetails(res?.data?.data[0]);
        if (res?.data?.data[0]?.image1_url) {
          let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image1_url);
          setImagesList(localImageList);
        }

        if (res?.data?.data[0]?.image2_url) {
          let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image2_url);
          setImagesList(localImageList);
        }
        if (res?.data?.data[0]?.image3_url) {
          let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image3_url);
          setImagesList(localImageList);
        }
        if (res?.data?.data[0]?.image4_url) {
          let localImageList = imagesList;
          localImageList.push(res?.data?.data[0]?.image4_url);
          setImagesList(localImageList);
        }

        if (externalUserInfo != null) {
          dispatch(setExternalUserInfo(null));
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setSpinning(false);
      });
  }, []);

  const playbookDetails = pbDetails;
  const onPackagePriceChanged = (from) => (e) => {
    if (from === "basic") {
      packageDetails.basic_price = e;
    } else if (from === "standard") {
      packageDetails.standard_price = e;
    } else if (from === "premium") {
      packageDetails.premium_price = e;
    }

    setPackageDetails(packageDetails);
  };
  const onPackageDescriptionChanged = (from) => (e) => {
    if (from === "basic") {
      packageDetails.basic_description = e.target.value;
    } else if (from === "standard") {
      packageDetails.standard_description = e.target.value;
    } else if (from === "premium") {
      packageDetails.premium_description = e.target.value;
    }
    setPackageDetails(packageDetails);
  };

  const onServiceNameChanged = (index) => (e) => {
    servicesSelectionData[index].service_name = e.target.value;
    const updatedServicesSelectionData = [...servicesSelectionData];
    setServicesSelection(updatedServicesSelectionData);
  };
  const changeServiceSelection = (setSelection, plan, index) => {
    if (plan === "basic") {
      servicesSelectionData[index].b_isSelected = setSelection;
    } else if (plan === "standard") {
      servicesSelectionData[index].s_isSelected = setSelection;
    } else if (plan === "premium") {
      servicesSelectionData[index].p_isSelected = setSelection;
    }
    const updatedServicesSelectionData = [...servicesSelectionData];
    setServicesSelection(updatedServicesSelectionData);
  };

  const setDynamicColor = (color) => {
    const root = document.documentElement;
    root.style.setProperty("--dynamicColor", `${color}`);
  };
  const handlePrimaryColorChange = (index) => {
    setSelectedPrimaryColor(index);
    setDynamicColor(colorsList[index].hexCode);
  };
  const [index, setIndex] = useState(0);
  
  const onImageUpload = (imageNumber, imageUrl) => {
    setIsEnabled(true);
    if (index == 0) {
      setImage1Url(imageUrl);
    }
    if (index == 1) {
      setImage2Url(imageUrl);
    }
    if (index == 2) {
      setImage3Url(imageUrl);
    }
    if (index == 3) {
      setImage4Url(imageUrl);
    }
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleCreatePlaybook = (values) => {
    // console.log("values:", values);
    if (!image1Url || !image2Url || !image3Url) {
      message.error("Kindly upload all images for playbook");
    } else {
      if (playbookIconUrl !== "") {
        setLoading(false);
        message.error("Kindly upload icon for playbook");
      } else {
        setLoading(true);
        generateCreatePlaybookPayload(
          values,
          packageDetails,
          servicesSelectionData
        );
      }
    }
  };

  const generateCreatePlaybookPayload = (
    values,
    packageDetails,
    servicesSelectionData
  ) => {
    const payload = {
      user_id: userSession.userId,
      profile_workspace_id: userSession.sellerProfileWorkspaceId,
      workspace_id: userSession.selectedWorkspaceId,

      title: values.title,
      short_description: values.short_description,
      primary_tag: values.primary_tag,
      primary_tag_color: colorsList[selectedPrimaryColor].name,
      reply_rate: generateRandomIntegerInRange(70, 99),
      description: values.addDescription,
      location: values.location,
      playbook_category_id: values.category ? values.category : 0,
      language: values.language,
      tags: values.additional_tags,
      highlight_1: values.highlight1,
      highlight_2: values.highlight2,
      highlight_3: values.highlight3,
      highlight_4: values.highlight4,

      active: true,
      playbook_category_name: "",
      packages: [
        {
          name: "basic",
          price: packageDetails.basic_price,
          description: packageDetails.basic_description,
          options: [
            {
              index: 0,
              name: servicesSelectionData[0].service_name,
              is_selected: servicesSelectionData[0].b_isSelected,
            },
            {
              index: 1,
              name: servicesSelectionData[1].service_name,
              is_selected: servicesSelectionData[1].b_isSelected,
            },
            {
              index: 2,
              name: servicesSelectionData[2].service_name,
              is_selected: servicesSelectionData[2].b_isSelected,
            },
            {
              index: 3,
              name: servicesSelectionData[3].service_name,
              is_selected: servicesSelectionData[3].b_isSelected,
            },
            {
              index: 4,
              name: servicesSelectionData[4].service_name,
              is_selected: servicesSelectionData[4].b_isSelected,
            },
            {
              index: 5,
              name: servicesSelectionData[5].service_name,
              is_selected: servicesSelectionData[5].b_isSelected,
            },
            {
              index: 6,
              name: servicesSelectionData[6].service_name,
              is_selected: servicesSelectionData[6].b_isSelected,
            },
          ],
        },

        {
          name: "standard",
          price: packageDetails.standard_price,
          description: packageDetails.standard_description,
          options: [
            {
              index: 0,
              name: servicesSelectionData[0].service_name,
              is_selected: servicesSelectionData[0].s_isSelected,
            },
            {
              index: 1,
              name: servicesSelectionData[1].service_name,
              is_selected: servicesSelectionData[1].s_isSelected,
            },
            {
              index: 2,
              name: servicesSelectionData[2].service_name,
              is_selected: servicesSelectionData[2].s_isSelected,
            },
            {
              index: 3,
              name: servicesSelectionData[3].service_name,
              is_selected: servicesSelectionData[3].s_isSelected,
            },
            {
              index: 4,
              name: servicesSelectionData[4].service_name,
              is_selected: servicesSelectionData[4].s_isSelected,
            },
            {
              index: 5,
              name: servicesSelectionData[5].service_name,
              is_selected: servicesSelectionData[5].s_isSelected,
            },
            {
              index: 6,
              name: servicesSelectionData[6].service_name,
              is_selected: servicesSelectionData[6].s_isSelected,
            },
          ],
        },

        {
          name: "premium",
          price: packageDetails.premium_price,
          description: packageDetails.premium_description,
          options: [
            {
              index: 0,
              name: servicesSelectionData[0].service_name,
              is_selected: servicesSelectionData[0].p_isSelected,
            },
            {
              index: 1,
              name: servicesSelectionData[1].service_name,
              is_selected: servicesSelectionData[1].p_isSelected,
            },
            {
              index: 2,
              name: servicesSelectionData[2].service_name,
              is_selected: servicesSelectionData[2].p_isSelected,
            },
            {
              index: 3,
              name: servicesSelectionData[3].service_name,
              is_selected: servicesSelectionData[3].p_isSelected,
            },
            {
              index: 4,
              name: servicesSelectionData[4].service_name,
              is_selected: servicesSelectionData[4].p_isSelected,
            },
            {
              index: 5,
              name: servicesSelectionData[5].service_name,
              is_selected: servicesSelectionData[5].p_isSelected,
            },
            {
              index: 6,
              name: servicesSelectionData[6].service_name,
              is_selected: servicesSelectionData[6].p_isSelected,
            },
          ],
        },
      ],
      video_url: "",
      image1_url: image1Url,
      image2_url: image2Url,
      image3_url: image3Url,
      image4_url: image4Url,
      image_uri: playbookIconUrl,
    };
    checkPayload(payload);
  };
  const category = [
    { id: 10, name: "Home Sale with No Commitment" },
    { id: 5, name: "Real Estate Data" },
    { id: 31, name: "Home Valuation" },
    {id:13, name:"Easy Home Sale for Cash"},
    {id:3, name:"Find the Top Real Estate Agent"}
  ];

  const checkPayload = (finalPayload) => {
    if (
      finalPayload.playbook_category_name === "" &&
      finalPayload.playbook_category_id < 1
    ) {
      console.log("final Payload before error message", finalPayload);
      setLoading(false);
      message.error("Please provide a Playbook Category");
    } else {
      console.log("final Payload before API call", finalPayload);
      callCreatePlaybookAPI(finalPayload);
    }
  };

  const callCreatePlaybookAPI = (payload) => {
    createAPlaybook(payload)
      .then((res) => {
        // console.log(res.data)
        message.success(res.data.message);
        navigate("/dashboard/myPlaybooks");
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleCreatePlaybook}
      >
        <div className="createplaybookDetails">
          {/* <div className="createplaybookDetails__rightAlign">
            <Image
              className="createplaybookDetails__cameraAddPhoto"
              src={IconCameraAddPhoto}
              preview={false}
            />
          </div> */}
          <div>
            <div className="createplaybookDetails__centerAlign createplaybookDetails__image">
              <CustomAvatar name={userFullName} imageUri={userProfileUrl} />
            </div>

            <span className="createplaybookDetails__centerAlign createplaybookDetails__playbookTitle">
              <label
                className="createplaybookDetails__playbookTitle__label"
                for="fname"
              >
                Playbook Title
              </label>
              <Form.Item
                name="title"
                // className="createWorkspace__addGeneralDetails__titleRoleContainer__title"
                required
                rules={[{ required: true, message: "Please provide title." }]}
              >
                <Input
                  maxLength={27}
                  showCount
                  placeholder="Please enter a suitable title for playbook"
                />
              </Form.Item>
            </span>
            <span className="createplaybookDetails__centerAlign createplaybookDetails__welcomeText ">
              Hi, <span style={{ fontStyle: "italic" }}> NAME_OF_BUYER</span>
              <br />
              My name is
              {" " + userFullName + " "}
              <br />
              Let me tell you about my playbook.
            </span>

            <div className="createplaybookDetails__centerAlign">
              <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                  <MediaUpload
                    style={{ width: "100%" }}
                    className="createPlaybook__uploadPromotionalMedia__rightSide__image"
                    onImageUploaded={onImageUpload}
                    createButtonFunctionality={() => setIsEnabled(false)}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <MediaUpload
                    style={{ width: "100%" }}
                    className="createPlaybook__uploadPromotionalMedia__rightSide__image"
                    onImageUploaded={onImageUpload}
                    createButtonFunctionality={() => setIsEnabled(false)}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <MediaUpload
                    style={{ width: "100%" }}
                    className="createPlaybook__uploadPromotionalMedia__rightSide__image"
                    onImageUploaded={onImageUpload}
                    createButtonFunctionality={() => setIsEnabled(false)}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <MediaUpload
                    style={{ width: "100%" }}
                    className="createPlaybook__uploadPromotionalMedia__rightSide__image"
                    onImageUploaded={onImageUpload}
                    createButtonFunctionality={() => setIsEnabled(false)}
                  />
                </Carousel.Item>
              </Carousel>
            </div>

            <p className="createplaybookDetails__aboutHighlight">Highlights</p>

            <div>
              <div
                className="createplaybookDetails__aboutHighlight__high"
                style={{ flexDirection: "row", height: "62px" }}
              >
                <span className="createplaybookDetails__highLights__text">
                  <Form.Item
                    name="highlight1"
                    required
                    rules={[
                      { required: true, message: "Please add Highlight 1" },
                    ]}
                  >
                    <Input
                      maxLength={45}
                      showCount
                      placeholder="Add a highlight"
                    />
                  </Form.Item>
                </span>
              </div>

              <div
                className="createplaybookDetails__aboutHighlight__high"
                style={{ flexDirection: "row", height: "62px" }}
              >
                <span className="createplaybookDetails__highLights__text">
                  <Form.Item
                    name="highlight2"
                    required
                    rules={[
                      { required: true, message: "Please add Highlight 2" },
                    ]}
                  >
                    <Input
                      maxLength={45}
                      showCount
                      placeholder="Add a highlight"
                    />
                  </Form.Item>
                </span>
              </div>

              <div
                className="createplaybookDetails__aboutHighlight__high"
                style={{ flexDirection: "row", height: "62px" }}
              >
                <span className="createplaybookDetails__highLights__text">
                  <Form.Item
                    name="highlight3"
                    required
                    rules={[
                      { required: true, message: "Please add Highlight 3" },
                    ]}
                  >
                    <Input
                      maxLength={45}
                      showCount
                      placeholder="Add a highlight"
                    />
                  </Form.Item>
                </span>
              </div>

              <div
                className="createplaybookDetails__aboutHighlight__high"
                style={{ flexDirection: "row" }}
              >
                <span className="createplaybookDetails__highLights__text">
                  <Form.Item
                    name="highlight4"
                    required
                    rules={[
                      { required: true, message: "Please add Highlight 4" },
                    ]}
                  >
                    <Input
                      maxLength={45}
                      showCount
                      placeholder="Add a highlight"
                    />
                  </Form.Item>
                </span>
              </div>
            </div>

            <Form.Item
              label="Short Description"
              name="short_description"
              className="createplaybookDetails__shortDescription"
              required
              rules={[
                {
                  required: true,
                  message: "Please provide short description.",
                },
              ]}
            >
              <Input
                maxLength={80}
                showCount
                className="createplaybookDetails__shortDescription__shortInput"
                placeholder="Please enter short description"
              />
            </Form.Item>

            <Form.Item
              label="About"
              name="addDescription"
              className="createplaybookDetails__aboutHeading"
              required
              rules={[
                {
                  required: true,
                  message: "Add description of your playbook here",
                },
              ]}
            >
              <TextArea
                style={{ "min-height": 125, padding: 8 }}
                placeholder="Add description of your playbook here"
                rows={4}
              />
            </Form.Item>

            <p className="createplaybookDetails__reviewsInfo">
              <Rate disabled defaultValue={1} count={1} />{" "}
              {playbookDetails?.rating_avg !== null
                ? playbookDetails?.rating_avg
                : 0}
              {"  "}
              <span className="createplaybookDetails__reviewsInfo__count">
                (
                {playbookDetails?.rating_avg !== null
                  ? parseInt(parseInt(playbookDetails?.rating_avg) * 1.7)
                  : 0}
                K)
              </span>{" "}
            </p>

            <Divider className="createplaybookDetails__divider" />

            <div className="createplaybookDetails__languageCategory">
              <p className="createplaybookDetails__languageCategory__value">
                <Form.Item
                  label="Primary Tag"
                  name="primary_tag"
                  className="createplaybookDetails__languageCategory__value__P-tag"
                  required
                  rules={[
                    { required: true, message: "Please add primary tag" },
                  ]}
                >
                  <Input maxLength={15} showCount placeholder="Primary Tag" />
                </Form.Item>
              </p>

              <p className="createplaybookDetails__languageCategory__value">
                <Form.Item
                  label="Primary Tag color"
                  name="primary_tag_color"
                  className="createplaybookDetails__languageCategory__value__tagColor"
                  required
                >
                  <Select
                    showSearch
                    showArrow
                    defaultValue={colorsList[0]}
                    placeholder="Color"
                    className="primaryTag"
                    optionFilterProp="children"
                    onChange={handlePrimaryColorChange}
                    maxTagCount={1}
                  >
                    {colorsList.map((option, index) => {
                      return (
                        <Select.Option key={index} value={option.id}>
                          <div className="createWorkspace__addGeneralDetails__tagsContainer__colorSelection__row">
                            <span
                              className="createWorkspace__addGeneralDetails__tagsContainer__colorSelection__row__text"
                              style={{
                                color: option.hexCode,
                              }}
                            >
                              {option.name}
                            </span>
                            <div
                              className="createWorkspace__addGeneralDetails__tagsContainer__colorSelection__row__color"
                              style={{
                                backgroundColor: option.hexCode,
                              }}
                            ></div>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </p>
            </div>

            <div className="createplaybookDetails__languageCategory">
              <p className="createplaybookDetails__languageCategory__value">
                <Form.Item
                  name="language"
                  className="createplaybookDetails__languageCategory__value__languageDefault"
                  label="Language"
                  required
                  rules={[{ required: true, message: "Please add Language" }]}
                >
                  <Input placeholder="English - Default" />
                </Form.Item>
              </p>

              <p className="createplaybookDetails__languageCategory__value">
                <Form.Item
                  name="category"
                  className="createplaybookDetails__languageCategory__value__categoryDropdown"
                  label="Category"
                  required
                >
                  <Select
                    placeholder="Category"
                    style={{ width: "100%" }}
                    maxTagCount={1}
                    tokenSeparators={[","]}
                    onChange={onCategoryChange}
                  >
                    {category.map((category, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={category?.id || category}
                        >
                          {category?.name || category}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </p>
            </div>

            <div className="createplaybookDetails__languageCategory">
              <p className="createplaybookDetails__languageCategory__value">
                <Form.Item
                  name="additional_tags"
                  label=" Additional Tags"
                  className="createplaybookDetails__description__Addtags"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please add tags to playbook",
                    },
                  ]}
                >
                  <Select
                    mode="tags"
                    placeholder="Additional Tags"
                    style={{ width: "100%" }}
                    maxTagCount={3}
                    tokenSeparators={[","]}
                  />
                </Form.Item>
              </p>

              <p className="createplaybookDetails__languageCategory__value">
                <Form.Item
                  label="City"
                  name="location"
                  className="createplaybookDetails__description__Addtags"
                  required
                  rules={[{ required: true, message: "Please add Location" }]}
                >
                  <Input placeholder="City" />
                </Form.Item>
              </p>
            </div>

            <Spin spinning={creatingConversation}>
              <div className="createplaybookDetails__comparePackage">
                <AddPackage
                  onPackagePriceChanged={onPackagePriceChanged}
                  onPackageDescriptionChanged={onPackageDescriptionChanged}
                  servicesSelectionData={servicesSelectionData}
                  onServiceNameChanged={onServiceNameChanged}
                  changeServiceSelection={changeServiceSelection}
                  className="createplaybookDetails__comparePackage__Package"
                />
              </div>
            </Spin>
          </div>

          <div className="createplaybookDetails__Buttons">
            <div>
              <Button className="createplaybookDetails__saveSourceButton-1">
                Discard
              </Button>
            </div>
            <div>
              <Button
                className="createplaybookDetails__saveSourceButton-2"
                onClick={() => PreviewCreateClicked()}
              >
                Preview & Create
              </Button>
            </div>
          </div>

          <div className="createplaybookDetails__Or">
            <div className="createplaybookDetails__Or__Pispace-1">
              _____________
            </div>
            <div className="createplaybookDetails__Or__Pi">Or</div>
            <div className="createplaybookDetails__Or__Pispace-2">
              _____________
            </div>
          </div>

          <div className="createplaybookDetails__centerAlign createplaybookDetails__Buttons-2">
            <button
              className="createplaybookDetails__Buttons-2__Skip"
              htmlType="submit"
              type="primary"
            >
              Skip Preview & Create
            </button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default CreateB2bPlaybook;

function CustomAvatar({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="createplaybookDetails__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="createplaybookDetails__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}

// <div className="createplaybookDetails__centerAlign">
// <Carousel style={{ width: "100%" }}>
//   {imagesList.map((imageUrl, index) => {
//     return (
//       <div>
//       <MediaUpload style={{ width: "100%" }}
//       className="createPlaybook__uploadPromotionalMedia__rightSide__image"
//       onImageUploaded={onImageUpload}
//       createButtonFunctionality={() => setIsEnabled(false)}
//     />
//       </div>
//     );
//   })}
// </Carousel>
// </div>
