import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, message, Progress } from "antd";
import { useState } from "react";
import ImgCrop from "antd-img-crop";
import { getPresignedUrlForImage } from "api/Playbooks";
import "../MediaUpload/index.scss"
// import AspectRatio from '@mui/joy/AspectRatio';
import {
  getSelectedWorkspaceProfileId,
  uploadFileUsingPreSignedURL,
} from "utils";

function MediaUpload({ onImageUploaded, createButtonFunctionality }) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);

  const userSession = useSelector((state) => state.authReducer).userSession;
  const onProgress = () => {};

  const onImageSelected = (options) => {
    const { file } = options;

    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width > 519 && img.height > 519) {
        proceedWithImageUpload(options);
      } else {
        let newFileList = fileList;
        setFileList(newFileList.pop());
        alert("Image size must be greater than 520 x 520");
      }
    };
  };

  const proceedWithImageUpload = (options) => {
    const { onSuccess, onError, file } = options;

    const payloadForGetPresignedUrl = {
      profileworkspaceId: getSelectedWorkspaceProfileId(userSession),
      accountId: userSession.accountId,
      userId: userSession.userId,
      fileName: file.name,
    };

    let uploadedImagePath = null;

    // getPreSignedUrlForWorkspaceImage()
    getPresignedUrlForImage(payloadForGetPresignedUrl)
      .then((res) => {
        createButtonFunctionality();
        const uploadUrl = res.data.data.signedUrl;
        const bucket = res.data.data.bucket;
        const key = res.data.data.key;
        uploadedImagePath =
          "https://" +
          bucket +
          ".s3.us-west-2.amazonaws.com" +
          "/" +
          key +
          `?abc=${Math.random()}`;

        uploadFileUsingPreSignedURL(uploadUrl, file, (progress) => {
          onProgress({
            percent: Math.ceil(progress),
          });
        })
          .then((uploadRes) => {
            onImageUploaded(fileList.length, uploadedImagePath);
            message.success("Image uploaded successfully");
            onSuccess("Ok");
          })
          .catch((uploadErr) => {
            console.log(uploadErr);
            onError({ uploadErr });
          })
          .finally(() => {});
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => {});
  };
  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <>
      <Upload
        listType="picture-card"
        defaultFileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        customRequest={onImageSelected}
        className="Upload"
        // style={{height: undefined, width: '100%', aspectRatio: 3 / 3}}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}
export default MediaUpload;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
