export const UPDATE_FILE_STATUS = "UPDATE_FILE_STATUS";
export const SET_CURRENT_PROCESSSING_FILE = "SET_CURRENT_PROCESSSING_FILE";
export const SET_CURRENT_GPT_DETAILS = "SET_CURRENT_GPT_DETAILS";
export const SET_NEW_FILE_UPLOADED = "SET_NEW_FILE_UPLOADED";
export const SET_FILE_UPLOADING_POPUP_OPENED =
  "SET_FILE_UPLOADING_POPUP_OPENED";
export const SET_CURRENT_SOURCE_INFO = "SET_CURRENT_SOURCE_INFO";
export const CLEAR_FILE_ONBOARDING_STATE = "CLEAR_FILE_ONBOARDING_STATE";

export const updateFileStatus = (payload) => {
  return { type: UPDATE_FILE_STATUS, payload: payload };
};

export const setCurrentProcessingGPTDetails = (payload) => {
  return { type: SET_CURRENT_GPT_DETAILS, payload: payload };
};

export const setCurrentProcessingFile = (payload) => {
  return { type: SET_CURRENT_PROCESSSING_FILE, payload: payload };
};
export const setCurrentSourceInfo = (payload) => {
  return { type: SET_CURRENT_SOURCE_INFO, payload: payload };
};
export const setNewFileUploaded = (payload) => {
  return { type: SET_NEW_FILE_UPLOADED, payload: payload };
};
export const setFileUploadingPopupOpened = (payload) => {
  return { type: SET_FILE_UPLOADING_POPUP_OPENED, payload: payload };
};
export const clearFileOnboardingState = (payload) => {
  return { type: CLEAR_FILE_ONBOARDING_STATE, payload: payload };
};
