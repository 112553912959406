import { SettingOutlined } from "@ant-design/icons";
import { Drawer, Image, Typography } from "antd";
import CustomAvatar from "commons/CustomAvatar";
import AppRightNavMobile from "components/AppRightNavMobile";
import { IconChevronLeft } from "images";
import { useState, useEffect } from "react";
import { emitCustomEvent, useCustomEventListener } from "react-custom-events";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import "./index.scss";

function HeaderBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [showBackArrow, setShowBackArrow] = useState(false);
  const { playbookCategoryId } = useParams();
  const { playbookId } = useParams();
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const isB2B = userSession?.selectedWorkspaceId == 496;

  useEffect(() => {
    if (getHeaderText()[0] === "Steps") {
      setShowBackArrow(true);
    } else {
      setShowBackArrow(false);
    }
  }, [location]);

  const onBackClicked = () => {
    navigate(-1);
  };
  const getHeaderText = () => {
    switch (location.pathname || userSession?.selectedWorkspaceId) {
      case `${APP_ROUTES.DASHBOARD.path}/${APP_ROUTES.UPLOADS.path}`:
        return "Uploads";
      case `${APP_ROUTES.DASHBOARD.path}/proposals`:
        if (isB2B) {
          return "Proposals";
        } else {
          return "Inquiries";
        }
      case `${APP_ROUTES.DASHBOARD.path}/playbooks`:
        if (isB2B) {
          return "Playbooks";
        } else {
          return "Listings";
        }
      case `${APP_ROUTES.DASHBOARD.path}/playbooks`:
        return "Playbooks";
      case `${APP_ROUTES.DASHBOARD.path}/playbooks/${playbookCategoryId}`:
        return "Playbooks";
      case `${APP_ROUTES.DASHBOARD.path}/playbookCategories`:
        return "Playbook Categories";
      case `${APP_ROUTES.DASHBOARD.path}/myPlaybooks`:
        return "My Playbooks";
      case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets`:
      case `${APP_ROUTES.DASHBOARD.path}/datasets/companies`:
      case `${APP_ROUTES.DASHBOARD.path}/datasets/people`:
      case `${APP_ROUTES.DASHBOARD.path}/datasets/places`:
        return "Datasets";
      case `${APP_ROUTES.DASHBOARD.path}/settings/`:
      case `${APP_ROUTES.DASHBOARD.path}/settings`:
        return "Profile";
      case `${APP_ROUTES.DASHBOARD.path}/settings/yourCompany`:
        return "Company";
      case `${APP_ROUTES.DASHBOARD.path}/settings/userManagement`:
        return "User Management";
      case `${APP_ROUTES.DASHBOARD.path}/settings/switchRole`:
        return "Switch Role";
      case `${APP_ROUTES.DASHBOARD.path}/settings/portfolio`:
        return "Workspace Family";
      case `${APP_ROUTES.DASHBOARD.path}/settings/notificationSettings`:
        return "Notification Settings";
      case `${APP_ROUTES.DASHBOARD.path}/settings/changePassword`:
        return "Change Password";
      case `${APP_ROUTES.DASHBOARD.path}/createMarketplace`:
        return ["Create Marketplace"];
      case `${APP_ROUTES.DASHBOARD.path}/findMarketplaces`:
        return ["Marketplaces"];
      case `${APP_ROUTES.DASHBOARD.path}/createPlaybook`:
        return ["Create Playbook"];
      case `${APP_ROUTES.DASHBOARD.path}/playbookDetails/${playbookId}`:
        return ["Playbook Details"];
      case `${APP_ROUTES.DASHBOARD.path}/proposalDetails/${playbookId}`:
        return ["Proposal Details"];
      case `${APP_ROUTES.DASHBOARD.path}/people`:
        return ["Conversations"];
      case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets`:
        return ["Datasets"];
      case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets/steps`:
        return ["Steps"];
      case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets/build-list`:
        return ["Build List"];
      case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets/transform-data`:
        return ["Trasnform Data"];
      case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets/add-exclusions`:
        return ["Add Exclusions"];
      case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets/preview-your-list`:
        return ["Preview List"];
      case `${APP_ROUTES.DASHBOARD.path}/datasets/datasets/select-your-destinations`:
        return ["Select Destination"];
      default:
        return "Dashboard";
    }
  };

  const settingsIconClicked = () => {
    emitCustomEvent("header_settings_clicked", false);
    navigate(`/dashboard/settings`);
  };

  useCustomEventListener("right_nav_close_clicked", (data) => {
    setVisible(data);
  });

  return (
    <div className="headerBar">
      <Drawer
        placement="right"
        width={"0px"}
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        open={visible}
      >
        <AppRightNavMobile></AppRightNavMobile>
      </Drawer>
      <div className="headerBar__headerElementsContainer">
        {showBackArrow && (
          <Image
            rootClassName="headerBar__backArrowMobileNew"
            preview={false}
            src={IconChevronLeft}
            onClick={onBackClicked}
          />
        )}
        <div
          onClick={() => {
            // setVisible(true);
          }}
        >
          <CustomAvatar
            name={userSession.selectedWorkspaceName}
            imageUri={userSession.selectedWorkspaceImagUrl}
          />
        </div>

        <Typography.Text className="headerBar__headerNameText">
          {userSession.selectedWorkspaceName + " > " + getHeaderText()}
        </Typography.Text>
        {/* <Link to={"settings"}> */}
        <SettingOutlined
          className="headerBar__appSettingIcon"
          onClick={settingsIconClicked}
        />
        {/* </Link> */}
      </div>
    </div>
  );
}

export default HeaderBar;
