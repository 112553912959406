import { Button, Image, message, Switch, Typography } from "antd";
import {
  getUserNotificationSettings,
  updateUserNotificationSettings,
} from "api/userManagement";
import classnames from "classnames";
import { IconNotificationBell } from "images";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "utils";
import "./index.scss";

function NotificationSettings() {
  const [loading, setLoading] = useState(false);
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const [listData, setListData] = useState([]);
  const [isMobileView, setIsMobileView] = useState(isMobile());

  useEffect(() => {
    const payloadForNotifSettings = {
      userId: userSession.userId,
    };
    getUserNotificationSettings(payloadForNotifSettings)
      .then((res) => {
        let resultList = [];
        let resList = res?.data?.data?.response;
        if (resList?.length > 0) {
          resList.forEach((item) => {
            if (item.name === "email_notifications") {
              item.name = "Email Notifications";
            } else if (item.name === "receive_monthly_newsletter") {
              item.name = "Receive Monthly Newsletter";
            } else if (item.name === "notified_of_new_features") {
              item.name = "Be Notified of New Features";
            } else if (item.name === "playbooks_we_recommend") {
              item.name = "Playbooks We Recommend";
            } else if (item.name === "become_seller") {
              item.name = "Become a Seller and Earn Income";
            }
            resultList.push(item);
          });
          setListData(resultList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSwitchChange = (id, checked) => {
    setListData((pre) => {
      return pre.map((setting) => {
        if (setting.id === id) {
          setting.active = checked;
        }
        return setting;
      });
    });
  };

  const handleOnSave = () => {
    setLoading(true);
    const settings = listData.map((setting) => {
      return { [String(setting.id)]: setting.active };
    });

    const payloadForUpdateSettings = {
      userId: userSession.userId,
      settings: settings,
    };
    updateUserNotificationSettings(payloadForUpdateSettings)
      .then((res) => {
        message.success(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="notifSettings">
      {isMobileView === true ? (
        <div className="notifSettings__headerMobile">
          <div className="notifSettings__titleDiv">
            <Typography.Text className="notifSettings__headerText">
              Updates
            </Typography.Text>
            <Image
              className="notifSettings__iconBell"
              src={IconNotificationBell}
              preview={false}
            ></Image>
          </div>
          <div>
            {listData.length > 0
              ? listData?.map((item, i) => {
                  const isOddRow = i % 2;
                  return (
                    <div
                      className={classnames(
                        "notifSettings__notifItemContainer",
                        isOddRow && "notifSettings__notifItemContainerWhite"
                      )}
                    >
                      <Typography.Text className="notifSettings__regularTextBlue">
                        {item.name}
                      </Typography.Text>
                      <Switch
                        id={item.id}
                        defaultChecked={item.active}
                        className="notifSettings__switch"
                        size="small"
                        onChange={(checked) => {
                          onSwitchChange(item.id, checked);
                        }}
                      ></Switch>
                    </div>
                  );
                })
              : null}
          </div>

          <div className="notifSettings__buttonsDivMobile">
            <Button
              className="notifSettings__cancelButton"
              type="primary"
              hidden={true}
              // onClick={handleCancelChanges}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              className="notifSettings__saveChangesButtonMobile"
              type="primary"
              onClick={handleOnSave}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div className="notifSettings__header">
          <div className="notifSettings__titleDiv">
            <Typography.Text className="notifSettings__headerText">
              Updates
            </Typography.Text>
            <Image
              className="notifSettings__iconBell"
              src={IconNotificationBell}
              preview={false}
            ></Image>
          </div>
          <div>
            {listData.length > 0
              ? listData?.map((item, i) => {
                  const isOddRow = i % 2;
                  return (
                    <div
                      className={classnames(
                        "notifSettings__notifItemContainer",
                        isOddRow && "notifSettings__notifItemContainerWhite"
                      )}
                    >
                      <Typography.Text className="notifSettings__regularTextBlue">
                        {item.name}
                      </Typography.Text>
                      <Switch
                        id={item.id}
                        defaultChecked={item.active}
                        className="notifSettings__switch"
                        size="small"
                        onChange={(checked) => {
                          onSwitchChange(item.id, checked);
                        }}
                      ></Switch>
                    </div>
                  );
                })
              : null}
          </div>

          <div className="notifSettings__buttonsDiv">
            <Button
              className="notifSettings__cancelButton"
              type="primary"
              hidden={true}
              // onClick={handleCancelChanges}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              className="notifSettings__saveChangesButton"
              type="primary"
              onClick={handleOnSave}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationSettings;
