import { CloseOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Divider, Image, List, message, Tooltip, Typography } from "antd";
import { getTwilioToken } from "api/TwilioChat";
import { getUserWorkspaceByProfileIds, joinWorkspace } from "api/Workspaces";
import CustomAvatar from "commons/CustomAvatar";
import { IconAddNumberActivated } from "images";
import { useEffect, useState } from "react";
import { emitCustomEvent } from "react-custom-events";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setExternalUserInfo, setUserSession } from "store/modules/auth/action";
import { isMobile } from "utils";
import "./index.scss";

export const Marketplace = () => {
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const [myWorkplaces, setMyWorkplaces] = useState([]);
  const [visibility, setVisibility] = useState(false);

  const OWNER = "owner";
  const FOUNDER = "founder";

  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const domainAndUserInfo = authReducer.domainAndUserInfo;
  const externalUserInfo = authReducer.externalUserInfo;
  const externalInviteInfo = authReducer.externalInviteInfo;

  const dispatch = useDispatch();

  useEffect(() => {
    callGetUserWorkspaceByProfileIdsAPI();
  }, []);

  useEffect(() => {
    callUpdateUserWorkspaceByProfileIdsAPI();
  }, [userSession]);

  const callJoinWorkspaceAPI = (marketplaceId) => {
    if (marketplaceId !== undefined) {
      const payload = {
        workspace_id: marketplaceId, // workspace to join
        buyer_profile_id: userSession.buyerProfileId, // current buyer profile id
        seller_profile_id: userSession.sellerProfileId, // current seller profile id
      };
      joinWorkspace(payload)
        .then((res) => {
          let updatedUserSession = { ...userSession };
          updatedUserSession.selectedWorkspaceId = parseInt(marketplaceId);

          updatedUserSession.buyerProfileWorkspaceId =
            res?.data?.data?.buyer_profile_workspace_id;
          updatedUserSession.sellerProfileWorkspaceId =
            res?.data?.data?.seller_profile_workspace_id;
          updatedUserSession.isDefaultWorkspace = false;

          updatedUserSession.workspaceCategoryId =
            res?.data?.data?.workspace_info?.workspace_category_id;
          userSession.selectedWorkspaceName =
            res?.data?.data?.workspace_info?.name;

          dispatch(setUserSession(updatedUserSession));
        })
        .catch((err) => {});
    }
  };

  const callGetUserWorkspaceByProfileIdsAPI = () => {
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let externalWorkspaceId = domainAndUserInfo?.marketplaceId;
        if (externalInviteInfo != null) {
          externalWorkspaceId = externalInviteInfo.marketplaceId;
        } else if (externalUserInfo != null) {
          externalWorkspaceId = externalUserInfo.marketplaceId;
        }

        let marketplacesList = res?.data?.data;
        setMyWorkplaces(marketplacesList);

        if (marketplacesList != null && marketplacesList.length > 0) {
          let defaultMarketplaceId = domainAndUserInfo?.marketplaceId;

          if (parseInt(userSession.selectedWorkspaceId) > 0) {
            defaultMarketplaceId = userSession.selectedWorkspaceId;
          }
          let isMarketPlacePresent = false;
          for (let i = 0; i < marketplacesList.length; i++) {
            let el = marketplacesList[i];
            if (
              el.workspace_id != null &&
              el.workspace_id === parseInt(externalWorkspaceId)
            ) {
              userSession.isDataMenuAvialable =
                el?.key === "B2B" ? true : false;
              userSession.selectedWorkspaceImagUrl = el.image_uri;
              userSession.selectedWorkspaceName = el.name;
              userSession.workspaceCategoryId = el.workspace_category_id;
              userSession.selectedWorkspaceId = el.workspace_id;
              isMarketPlacePresent = true;
              if (el?.workspace_profiles.length > 1) {
                el?.workspace_profiles.forEach((workspaceProfile) => {
                  // added an extra check as we need to show edit left nav option to owner
                  if (
                    workspaceProfile.workspace_role === OWNER ||
                    workspaceProfile.workspace_role === FOUNDER
                  ) {
                    userSession.isMarketPlaceOwner = true;
                  } else {
                    userSession.isMarketPlaceOwner = false;
                  }
                  if (workspaceProfile.account_role === "buyer") {
                    userSession.buyerProfileWorkspaceId =
                      workspaceProfile.profile_workspace_id;
                  } else {
                    userSession.sellerProfileWorkspaceId =
                      workspaceProfile.profile_workspace_id;
                  }
                });
              }
              break;
            } else {
              isMarketPlacePresent = false;
            }
          }
          dispatch(setUserSession(userSession));

          if (
            !isMarketPlacePresent &&
            externalWorkspaceId !== 1 &&
            (externalUserInfo?.playbookId === null ||
              externalUserInfo?.playbookId === undefined)
          ) {
            callJoinWorkspaceAPI(externalWorkspaceId);
            if (externalUserInfo != null) {
              dispatch(setExternalUserInfo(null));
            }
          }
        } else {
          if (
            externalWorkspaceId > 0 &&
            (externalUserInfo?.playbookId === null ||
              externalUserInfo?.playbookId === undefined)
          ) {
            callJoinWorkspaceAPI(externalWorkspaceId);

            dispatch(setExternalUserInfo(null));
          }
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const callUpdateUserWorkspaceByProfileIdsAPI = () => {
    // payload
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
    };

    getUserWorkspaceByProfileIds(payload)
      .then((res) => {
        let marketplacesList = res?.data?.data;
        setMyWorkplaces(marketplacesList);
      })
      .catch((err) => {})
      .finally(() => {});
  };
  const switchWorkspaceClicked = (
    item,
    workspaceId,
    workspaceCategoryId,
    arrayOfWorkspaceProfiles
  ) => {
    userSession.isDataMenuAvialable = item.key === "B2B" ? true : false;
    userSession.selectedWorkspaceImagUrl = item.image_uri;
    userSession.selectedWorkspaceName = item.name;
    userSession.selectedWorkspaceId = workspaceId;
    emitCustomEvent("selected-workspace-id-changed");
    userSession.workspaceCategoryId = workspaceCategoryId;

    if (arrayOfWorkspaceProfiles.length >= 1) {
      arrayOfWorkspaceProfiles.forEach((workspaceProfile) => {
        // added an extra check as we need to show edit left nav option to owner

        if (
          workspaceProfile.workspace_role === OWNER ||
          workspaceProfile.workspace_role === FOUNDER
        ) {
          userSession.isMarketPlaceOwner = true;
        } else {
          userSession.isMarketPlaceOwner = false;
        }
        if (workspaceProfile.account_role === "buyer") {
          userSession.buyerProfileWorkspaceId =
            workspaceProfile.profile_workspace_id;
        } else {
          userSession.sellerProfileWorkspaceId =
            workspaceProfile.profile_workspace_id;
        }
      });

      // check for profile switching
      userSession.isDefaultWorkspace = true;
    } else {
      // check for profile switching
      userSession.isDefaultWorkspace = false;
      if (arrayOfWorkspaceProfiles[0].account_role === "buyer") {
        userSession.buyerProfileWorkspaceId =
          arrayOfWorkspaceProfiles[0].profile_workspace_id;
        // userSession.accountRole = "buyer";
      } else {
        userSession.sellerProfileWorkspaceId =
          arrayOfWorkspaceProfiles[0].profile_workspace_id;
        // userSession.accountRole = "seller";
      }
    }

    dispatch(setUserSession(userSession));
  };

  const rightNavCloseClicked = () => {
    emitCustomEvent("right_nav_close_clicked", false);
  };

  const handleCreateMarketplace = () => {
    if (isMobileView) {
      // Show a message for mobile view when clicking on the link
      message.info("Seller mode required for this functionality");
      rightNavCloseClicked();
    }
  };

  return (
    <div className="marketPlace">
      {isMobileView === true
        ? myWorkplaces?.length > 0 && (
            <div className="marketPlace__marketPlaceRightNavContainer">
              <div className="marketPlace__rightNavHeader">
                <CloseOutlined
                  className="marketPlace__appCloseLeftNavIcon"
                  onClick={rightNavCloseClicked}
                />
                <Typography.Text className="marketPlace__marketplaceText">
                  Switch Marketplace
                </Typography.Text>
              </div>
              <Divider className="marketPlace__dividerBorder" />
              <List
                dataSource={myWorkplaces}
                className="marketPlace__listOfWorkplaces hide-scroll-bar"
                itemLayout="vertical"
                rowKey={"key"}
                renderItem={(item) => {
                  return (
                    // <Tooltip title={item.name} placement="right">
                    <Link
                      className="menu-list__link"
                      to={
                        userSession.accountRole === "buyer"
                          ? "playbooks"
                          : "myPlaybooks"
                      }
                    >
                      <div
                        className="marketPlace__marketplaceNameIconContainer"
                        onClick={() => {
                          switchWorkspaceClicked(
                            item,
                            item.workspace_id,
                            item.workspace_category_id,
                            item.workspace_profiles
                          );
                          setTimeout(() => {
                            rightNavCloseClicked();
                          }, 50);
                        }}
                      >
                        <Typography.Text className="marketPlace__marketplaceNameText">
                          {item.name}
                        </Typography.Text>
                        <div
                          className={` ${
                            parseInt(item.workspace_id) ===
                            parseInt(userSession.selectedWorkspaceId)
                              ? "marketPlace__workspaceIconSelected"
                              : "marketPlace__workspaceIcon"
                          }`}
                        >
                          <CustomAvatar
                            name={item.name}
                            imageUri={item.image_uri}
                          />
                        </div>
                      </div>
                    </Link>
                    // </Tooltip>
                  );
                }}
              />
              <Link
                className="menu-list__link"
                to={isMobileView ? "#" : "/dashboard/createMarketplace"}
                onClick={handleCreateMarketplace}
              >
                <div className="marketPlace__rightNavFooter">
                  <Typography.Text className="marketPlace__addMarketplaceText">
                    Add Marketplace
                  </Typography.Text>
                  <Image src={IconAddNumberActivated} preview={false}></Image>
                </div>
              </Link>
            </div>
          )
        : myWorkplaces?.length > 0 && (
            <List
              dataSource={myWorkplaces}
              className="marketPlace__listOfWorkplaces hide-scroll-bar"
              itemLayout="vertical"
              rowKey={"key"}
              renderItem={(item) => {
                return (
                  <Tooltip title={item.name} placement="right">
                    <Link
                      className="menu-list__link"
                      to={
                        userSession.accountRole === "buyer"
                          ? "playbooks"
                          : "myPlaybooks"
                      }
                    >
                      <div
                        className={` ${
                          parseInt(item.workspace_id) ===
                          parseInt(userSession.selectedWorkspaceId)
                            ? "marketPlace__workspaceIconSelected"
                            : "marketPlace__workspaceIcon"
                        }`}
                        onClick={() => {
                          switchWorkspaceClicked(
                            item,
                            item.workspace_id,
                            item.workspace_category_id,
                            item.workspace_profiles
                          );
                        }}
                      >
                        <CustomAvatar
                          name={item.name}
                          imageUri={item.image_uri}
                        />
                      </div>
                    </Link>
                  </Tooltip>
                );
              }}
            />
          )}
    </div>
  );
};
