import { InfoCircleOutlined } from "@ant-design/icons";
import { trackEventCall, trackPageCall } from "analytics";
import { Button, Form, Image, Input, message, Space, Typography } from "antd";
import { UserOnBoardingFooter } from "components/UserOnBoardingFooter";
import { ANALYTICS_EVENT_NAMES } from "../../constants";
import { Close } from "images";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CheckPassword, getCurrentDomainName } from "utils";
import AppLogo from "../../components/AppLogo";
import { APP_ROUTES } from "../../routes";
import "./index.scss";

function SignupInfo() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [domain, setDomain] = useState(getCurrentDomainName());
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDomain, setSelectedDomain] = useState("palisadeknowledge");

  useEffect(() => {
    setSelectedDomain(getCurrentDomainName);
  }, []);

  useEffect(() => {
    trackPageCall("OnBoarding User Info");
  }, []);

  const handleSignupInfo = (values) => {
    setShowErrorMessage(false);
    const { firstname, lastname, password } = values;
    const eventData = {
      firstname: firstname,
      lastname: lastname,
      email: state.email,
    };
    if (CheckPassword(password)) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.ONBOARDING_NAME_EMAIL_ENTERED,
        eventData
      );
      navigate(APP_ROUTES.SIGNUP_ADD_PHONE.path, {
        state: {
          email: state.email,
          firstName: firstname,
          lastName: lastname,
          password: password,
        },
      });
    } else {
      setShowErrorMessage(true);
      setErrorMessage(
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      );
    }
  };
  const onErrorCloseClick = () => {
    setShowErrorMessage(false);
  };
  return (
    <>
      <div className="signupInfoPage__container">
        <div className="signupInfoPage__header">
          <div>
            <Typography.Text className="signupInfoPage__textAlreadyhaveAccount">
              Already have an account?
            </Typography.Text>
            <Link to={APP_ROUTES.SIGNIN.path}>
              <Button type="primary" className="signupInfoPage__button">
                Sign In
              </Button>
            </Link>
          </div>
        </div>

        <div className="signupInfoPage">
          <Space
            direction="vertical"
            size={0}
            className="signupInfoPage__signinFields"
          >
            <div className="signupInfoPage__headerLogo">
              <AppLogo className="signupInfoPage__logo" />
            </div>
            {/* {domain == 'alyson' ? (<Typography.Text className="signupInfoPage__headerTitle">
              Hi, I’m Alyson
              <br />
              <Typography.Text className="signupInfoPage__authHeaderTitle">
                Ready to tackle some projects together?
                <br /> Let’s get started
              </Typography.Text>
            </Typography.Text>) : (<Typography.Text className="signupInfoPage__headerTitle">
              The World's First Marketplace For
              <br />
              Big Data & AI Projects
            </Typography.Text>)} */}
             <Typography.Text className="signupInfoPage__headerTitle">
              Create Your Account
            </Typography.Text>
            {showErrorMessage && (
              <div className="signinPage__errorMessageContainer">
                <Image
                  className="signinPage__errorContainerCrossImage"
                  src={Close}
                  onClick={onErrorCloseClick}
                  preview={false}
                ></Image>
                <Typography.Text
                  className="signinPage__textErrorMessage"
                  type="danger"
                >
                  {errorMessage}
                </Typography.Text>
              </div>
            )}
            <Form
              layout="vertical"
              onFinish={handleSignupInfo}
              autoComplete="off"
            >
              <div className="signupInfoPage__namesContainer">
                <Form.Item
                  name="firstname"
                  label={<span className="custom-label">First Name</span>}
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter your firstname.",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    className="signupInfoPage__inputField"
                    autoComplete="new-password"
                    placeholder="First Name"
                    type={"text"}
                  />
                </Form.Item>
                <Space size={6}>
                  <span></span>
                  <span></span>
                </Space>
                <Form.Item
                  name="lastname"
                  label={<span className="custom-label">Last Name</span>}
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter your lastname.",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    className="signupInfoPage__inputField"
                    autoComplete="new-password"
                    placeholder="Last Name"
                    type={"text"}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="password"
                label={<span className="custom-label">Enter Password</span>}
                required
                tooltip={{
                  title:
                    "Use 8 or more characters with a mix of letters, numbers & symbols",
                  icon: <InfoCircleOutlined />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Please enter your password.",
                  },
                  {
                    min: 8,
                    message: "Min 8 characters required.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Password"
                  className="signupInfoPage__inputField"
                  autoComplete="new-password"
                  type={"password"}
                />
              </Form.Item>
              <p className="signupInfoPage__termsConditionDesc">
                {" "}
                By signing up, you agree to the{" "}
                <a
                  className="signupInfoPage__termsConditionDescChangeColor"
                  href={`https://www.${selectedDomain}.com/terms-of-use`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>{" "}
                and
                <br></br>
                <a
                  className="signupInfoPage__termsConditionDescChangeColor"
                  href={`https://www.${selectedDomain}.com/privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Privacy Policy
                </a>
                , including{" "}
                <a
                  className="signupInfoPage__termsConditionDescChangeColor"
                  href="https://www.google.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Cookie use
                </a>
                .
              </p>
              <Button
                type="primary"
                htmlType="submit"
                className="signupInfoPage__continueButton"
              >
                Create My Account
              </Button>
            </Form>
          </Space>
        </div>

        <UserOnBoardingFooter />
      </div>
    </>
  );
}
export default SignupInfo;
