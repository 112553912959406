import API from "..";

export const getProfiles = (userId, profileWorkspaceId) => {
  return API.get(`/dataset/profileExplorerSection?userId=${userId}&profileWorkspaceId=${profileWorkspaceId}`);
};

export const getProfileEmails = (revId) => {
    return API.get(`/dataset/profileExplorerEmailSection?revPersonId=${revId}`);
};

export const getProfileNumbers = (revId) => {
    return API.get(`/dataset/profilePhoneSection?revPersonId=${revId}`);
};

export const getProfileIPs = (revId) => {
    return API.get(`/dataset/profileIPAddressSection?revPersonId=${revId}`);
};

export const getProfileAgents = (revId) => {
    return API.get(`/dataset/profileUserAgentSection?revPersonId=${revId}`);
};

export const getProfileLocations = (revId) => {
    return API.get(`/dataset/profileExplorerLocationSection?revPersonId=${revId}`);
};

export const getProfileIdentities = (revId) => {
    return API.get(`/dataset/profileExplorerIdentitySection?revPersonId=${revId}`);
};