import React, { useState } from "react";
import "./index.scss";
import { Image, Input, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import RandomIcon from "../../../images/Datasets-1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft, IconRevcloud, Zendesk } from "images";

function LeadsAPIDestinationCatalog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/destination/setup/${"leadsapi"}/${id}`);
  };

  const handleBackClick = () => {
    // Navigate back to the previous page
    navigate(-1); // Go back one step
  };

  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };

  return (
    <div className="LeadsAPICatalog">
      <div className="LeadsAPICatalog__Fixed">
        {/* <div className="LeadsAPICatalog__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="LeadsAPICatalog__title"
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}
            onClick={handleConnectionClick}>
            Connections</span>
          <span className="LeadsAPICatalog__slash">/</span>
          <span className="LeadsAPICatalog__title" onClick={handleBackClick}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}>
            Catalog
          </span>
          <span className="LeadsAPICatalog__slash">/</span>
          <span className="LeadsAPICatalog__mainTitle">Leads API</span>
        </div> */}
        <div className="LeadsAPICatalog__sourceText">
          <div>
            <Image
              src={IconRevcloud}
              preview={false}
              style={{ marginRight: "10px" }}
            />
            <span style={{ marginLeft: "15px" }}>Leads API</span>
          </div>
        </div>
        <div className="LeadsAPICatalog__bar">
          <div>
            <Button
              onClick={() => navigateToFields()}
              className="LeadsAPICatalog__button"
            >
              Add Destination
              <ArrowRightOutlined className="LeadsAPICatalog__btnImage" />
            </Button>
          </div>
        </div>
        <div className="SalesforceDestinationDetails__newSource">
          <Button className="SalesforceDestinationDetails__newText">
            Destination
          </Button>
        </div>
        <div className="LeadsAPICatalog__exploreBar">
          <div className="LeadsAPICatalog__exploreText">
            Leads API brings businesses and customers closer together
          </div>
        </div>
        <div className="LeadsAPICatalog__bottomBar"></div>
      </div>
      <div className="LeadsAPICatalog__mainContainer">
        <div className="LeadsAPICatalog__flexDiv">
          <div className="LeadsAPICatalog__container1">
            <h6 className="LeadsAPICatalog__cardHeading1">
              Compatible destination types
            </h6>
            <div className="LeadsAPICatalog__textFlex">
              <div className="LeadsAPICatalog__cardIcon">
                <MenuOutlined />
              </div>
              <div className="LeadsAPICatalog__cardText">Storage</div>
            </div>
            <p className="LeadsAPICatalog__rawText">
              Destinations for storing raw data.
              {/*<a className="LeadsAPICatalog__anchorTag">Learn more</a>*/}
            </p>
          </div>
          <div className="LeadsAPICatalog__container2">
            <div className="LeadsAPICatalog__containFlex">
              <div className="LeadsAPICatalog__line"></div>
              <div className="LeadsAPICatalog__justDiv">
                <h6 className="LeadsAPICatalog__cardHeading2">
                  Set up requirements
                </h6>
                <p className="LeadsAPICatalog__setText1">
                  * A LeadsAPI account with administrator privileges
                </p>
                {/* <p className="LeadsAPICatalog__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="LeadsAPICatalog__flexDiv1">
          <div className="LeadsAPICatalog__container3">
            <h6 className="LeadsAPICatalog__overviewHeading1">Overview</h6>
            <p className="LeadsAPICatalog__overviewText">
              When you enter your LeadsAPI credentials and hit save, we will
              sync standard LeadsAPI objects (and their corresponding
              properties) to any databases you have turned on (to turn on a
              database, navigate to the database tab in the navigation pane on
              the left). These objects will be written into a separate schema,
              corresponding to your project’s name. LeadsAPI data will reflect
              the state of the object at the time of the sync: so if a ticket
              status goes from `unassigned` to `assigned` between syncs, on its
              next sync that tickets status will be `assigned`. We will update
              LeadsAPI data every ~3 hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadsAPIDestinationCatalog;
