import { Divider, List } from "antd";
import SinglePlaybook from "../SinglePlaybook";
import "./index.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrivSinglePlaybook from "../PrivHomesPlaybooks/SinglePlaybook";
import { useNavigate } from "react-router-dom";
import MobileSinglePlaybook from "../mSinglePlaybook";
import { isMobile } from "utils";

// export const PlaybooksDetailPage = () => {
//   const [isMobileView, setIsMobileView] = useState(isMobile());

//   useEffect(() => {
//     function handleResize() {
//       setIsMobileView(isMobile());
//     }

//     window.addEventListener("resize", handleResize);
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

// return isMobileView === true ? (
//   <MobileSinglePlaybook />
// ) : (
//   <SinglePlaybook />
// );
// };

function SingleCategoryPlaybooks({ searchResults, catName, catId, isMobileView1 }) {
  let data = searchResults;
  let dataToSet = [];
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  if (data.length > 3) {
    dataToSet = data.slice(0, 3);
  } else {
    dataToSet = data;
  }
  const navigate = useNavigate();

  const onViewAllClicked = () => {
    navigate(`/dashboard/category/playbooks/${catId}`, { state: { flag: isMobileView1 } });
  };

  const [isMobileView, setIsMobileView] = useState(isMobile());

  useEffect(() => {
    function handleResize() {
      setIsMobileView(isMobile());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="singleCategoryPlaybooks">
      <div className="singleCategoryPlaybooks__proposalsList">
        <div className="singleCategoryPlaybooks__header__header">
          <p className="singleCategoryPlaybooks__header__text">
            {catName} ({data?.length > 0 ? data.length : 0})
            <p
              className="singleCategoryPlaybooks__textViewAll"
              onClick={() => onViewAllClicked()}
            >
              View All
            </p>
          </p>

          <Divider className="singleCategoryPlaybooks__header__divider" />
        </div>

        <List
          dataSource={dataToSet}
          className="singleCategoryPlaybooks__list"
          renderItem={(item, index) => {
            return (
              <div className="singleCategoryPlaybooks__datas">
                {isMobileView1 === true ? (
                  <MobileSinglePlaybook
                    className="singleCategoryPlaybooks__single"
                    singlePlaybookDetail={item}
                  />
                ) : (
                  userSession.selectedWorkspaceName === "Palisade Knowledge" ? (
                    <PrivSinglePlaybook
                      className="singleCategoryPlaybooks__single"
                      singlePlaybookDetail={item}
                    />
                  ) : (
                    <SinglePlaybook
                      className="singleCategoryPlaybooks__single"
                      singlePlaybookDetail={item}
                    />
                  )
                )}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}

export default SingleCategoryPlaybooks;
