import React, { useState } from "react";
import "./index.scss";
import { Image, Input, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import RandomIcon from "../../../images/Datasets-1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronLeft, Zendesk } from "images";

function ZendeskCatalog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/sources/setup/${"zendesk"}/${id}`);
  };

  const handleBackClick = () => {
    // Navigate back to the previous page
    navigate(-1); // Go back one step
  };

  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };

  return (
    <div className="ZendeskCatalog">
      <div className="ZendeskCatalog__Fixed">
        {/* <div className="ZendeskCatalog__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="ZendeskCatalog__title"
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}
            onClick={handleConnectionClick}>
            Connections
          </span>
          <span className="ZendeskCatalog__slash">/</span>
          <span
            className="ZendeskCatalog__title"
            style={{ cursor: "pointer" }}
            onClick={handleBackClick}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
          >
            Catalog
          </span>
          <span className="ZendeskCatalog__slash">/</span>
          <span className="ZendeskCatalog__mainTitle">Zendesk</span>
        </div> */}
        <div className="ZendeskCatalog__sourceText">
          <div>
            <Image
              src={Zendesk}
              preview={false}
              style={{ marginRight: "10px" }}
            />
            <span style={{ marginLeft: "15px" }}>Zendesk</span>
          </div>
        </div>
        <div className="ZendeskCatalog__bar">
          <div>
            <Button
              onClick={() => navigateToFields()}
              className="ZendeskCatalog__button"
            >
              Add source
              <ArrowRightOutlined className="ZendeskCatalog__btnImage" />
            </Button>
          </div>
        </div>

        <div className="ZendeskCatalog__newSource">
          <Button className="ZendeskCatalog__newText">Source</Button>
        </div>
        <div className="ZendeskCatalog__exploreBar">
          <div className="ZendeskCatalog__exploreText">
            Zendesk brings businesses and customers closer together
          </div>
        </div>
        <div className="ZendeskCatalog__bottomBar"></div>
      </div>
      <div className="ZendeskCatalog__mainContainer">
        <div className="ZendeskCatalog__flexDiv">
          <div className="ZendeskCatalog__container1">
            <h6 className="ZendeskCatalog__cardHeading1">
              Compatible destination types
            </h6>
            <div className="ZendeskCatalog__textFlex">
              <div className="ZendeskCatalog__cardIcon">
                <MenuOutlined />
              </div>
              <div className="ZendeskCatalog__cardText">Storage</div>
            </div>
            <p className="ZendeskCatalog__rawText">
              Destinations for storing raw data.
              {/* <a className="ZendeskCatalog__anchorTag">Learn more</a> */}
            </p>
          </div>
          <div className="ZendeskCatalog__container2">
            <div className="ZendeskCatalog__containFlex">
              <div className="ZendeskCatalog__line"></div>
              <div className="ZendeskCatalog__justDiv">
                <h6 className="ZendeskCatalog__cardHeading2">
                  Set up requirements
                </h6>
                <p className="ZendeskCatalog__setText1">
                  * A Zendesk account with administrator privileges
                </p>
                {/* <p className="ZendeskCatalog__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="ZendeskCatalog__flexDiv1">
          <div className="ZendeskCatalog__container3">
            <h6 className="ZendeskCatalog__overviewHeading1">Overview</h6>
            <p className="ZendeskCatalog__overviewText">
              When you enter your Zendesk credentials and hit save, we will sync
              standard Zendesk objects (and their corresponding properties) to
              any databases you have turned on (to turn on a database, navigate
              to the database tab in the navigation pane on the left). These
              objects will be written into a separate schema, corresponding to
              your project’s name. Zendesk data will reflect the state of the
              object at the time of the sync: so if a ticket status goes from
              `unassigned` to `assigned` between syncs, on its next sync that
              tickets status will be `assigned`. We will update Zendesk data
              every ~3 hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZendeskCatalog;
