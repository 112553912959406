import React from "react";
import { useState, useEffect } from "react";
import { Input, Image, Button, Divider, List, message, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import SingleWorkspace from "./SingleWorkspace";
import { Link } from "react-router-dom";
import "./index.scss";
import { isEmpty } from "lodash";
import classNames from "classnames";
import FileUploadHoverIcon from "../../../images/file-upload-hover.svg";
import FileUploadIdleIcon from "../../../images/file-upload-idle.svg";
import { getAllApplicationWorkspaces, getAllMyAllWorkspaces} from "api/Workspaces";
import { useSelector } from "react-redux";

function FindWorkspaces() {
  const [workspacesList, setWorkspaces] = useState([]);
  const [finalWorkspaces, setFinalWorkspaces] = useState(workspacesList);
  const [finalWorkspacesForSeller, setFinalWorkspacesForSeller] = useState([]);

  const [showCreateWorkspaceTooltip, setShowCreateWorkspaceTooltip] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [searchedText, setSearchedText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const shouldHighlightField = !isEmpty(searchedText) || isFocused;

  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const isSellerMode = userSession?.accountRole === 'seller';

  useEffect(() => {
    setLoading(true);
    callGetAllApplicationWorkspacesAPI();
  }, []);

  useEffect(() => {
    setLoading(true);
    callGetMyAllWorkspaces();
  }, []);

  const callGetAllApplicationWorkspacesAPI = () => {
    const payload = {
      profileids:
        userSession.buyerProfileId + "," + userSession.sellerProfileId,
      user_id: userSession.userId,
    };

    getAllApplicationWorkspaces(payload)
      .then((res) => {
        setWorkspaces(res.data.data);
        setFinalWorkspaces(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callGetMyAllWorkspaces = () => {
    getAllMyAllWorkspaces()
      .then((res) => {
        // setWorkspaces(res?.data?.data);
        setFinalWorkspacesForSeller(res?.data?.data);
      })
      .catch((err) => {
        // message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = workspacesList.filter((singleWorkspace) => {
        return singleWorkspace.name
          .toLowerCase()
          .includes(keyword.toLowerCase());
      });
      setFinalWorkspaces(results);
    } else {
      setFinalWorkspaces(workspacesList);
    }

    setSearchedText(keyword);
  };
  

  return (
    <div className="findWorkspaces hide-scroll-bar">
      <div className="findWorkspaces__searchContainer">
        <div
          className={classNames("searchbar__field", {
            "searchbar__field--focused": shouldHighlightField,
          })}
        >
          <Input
            placeholder="Search marketplaces"
            value={searchedText}
            onChange={filter}
            onPressEnter={filter}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
            addonBefore={
              <SearchOutlined className="searchbar__field__searchIcon" />
            }
            addonAfter={
              <div className="searchbar__field__addonAfter">
                <Button
                  type="text"
                  className="searchbar__field__addonAfter__closeBtn"
                  onClick={() => {
                    setSearchedText("");
                    setFinalWorkspaces(workspacesList);
                  }}
                />
              </div>
            }
          />
        </div>

        {userSession?.accountRole != "buyer" && (
          <Link className="menu-list__link" to={"/dashboard/createMarketplace"}>
          <Tooltip title="Create Marketplace">
            <div
              className="findWorkspaces__searchField__addIcon"
              onMouseOver={() => {
                setShowCreateWorkspaceTooltip(true);
              }}
              onMouseOut={() => {
                setShowCreateWorkspaceTooltip(false);
              }}
            >
              <Image
                src={
                  showCreateWorkspaceTooltip
                    ? FileUploadHoverIcon
                    : FileUploadIdleIcon
                }
                preview={false}
              />
            </div>
          </Tooltip>
        </Link>)}
      </div>
     
      <div className="findWorkspaces__workspacesList ">
        <div className="findWorkspaces__workspacesList__list hide-scroll-bar">
          <div className="findWorkspaces__workspacesList__list__heading__row">
            <span className="findWorkspaces__workspacesList__list__heading__row__text">
              {isSellerMode ? "My Marketplaces" : "Find Marketplaces"} (
                {
                  isSellerMode
                    ? finalWorkspacesForSeller?.length > 0
                    ? finalWorkspacesForSeller?.length
                    : 0
                    : finalWorkspaces?.length > 0
                    ? finalWorkspaces?.length
                    : 0
                }
              )
            </span>
          </div>
          <Divider className="playbookFilters__divider" />
          <List
            dataSource={isSellerMode ? [...finalWorkspacesForSeller].reverse() : [...finalWorkspaces].reverse()}
            itemLayout="vertical"
            loading={loading}
            rowKey={"key"}
            renderItem={(item, index) => {
              return (
                <SingleWorkspace
                  actionButtonText={"Join"}
                  itemData={item}
                  setLoader={(isLoading) => {
                    setLoading(isLoading);
                  }}
                  removeItem={() => {
                    // setFinalWorkspaces(finalWorkspaces.splice(index + 1));
                    callGetAllApplicationWorkspacesAPI();
                  }}
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FindWorkspaces;
