import API from "..";

export const getSaleforceRequiredFileds = (sourceId) => {
  return API.get(`/connector/required-fields/${sourceId}`);
};

export const createProfile = (payload) => {
  return API.post(
    "/connector/create-profile?connectorType=SalesforceMarketingCloud",
    payload
  );
};
export const createFacebookAdProfile = (payload) => {
  return API.post("/connector/create-profile", payload);
};

export const getAPIVersionData = (sourceName) => {
  return API.get(
    `/connector/supported-profile-version?connectorLabel=${sourceName}`
  );
};
export const getFlowsByUserId = () => {
  return API.get("/connector/flows");
};

export const getActiveConnections = () => {
  return API.get("/connector/active-connections");
};

export const getSaleforceAppObjects = () => {
  return API.get("/connector/salesforce-app-objects");
};

export const createFlow = (payload) => {
  return API.post("/connector/create-flow", payload);
};

export const activateFlow = (id) => {
  return API.post(`/connector/activate-flow/${id}`);
};

export const deleteSource = (id, sourceType, flag) => {
  return API.delete(
    `/connector/delete?connectionId=${id}&sourceType=${sourceType}&destination=${flag}`
  );
};

export const editSource = (payload) => {
  return API.patch("/connector/update-name", payload);
};

export const getListOfFlows = (id) => {
  return API.get(`/connector/events?connectionId=${id}`);
};

export const getListOfCatalogs = () => {
  return API.get("/connector/list");
};

export const createModel = (payload) => {
  return API.post("/connector/create-model", payload);
};

export const getModelsList = (id) => {
  return API.get(`/connector/model/${id}`);
};

export const deleteModel = (id) => {
  return API.delete(`/connector/model/${id}`);
};

export const saveQuery = (id, payload) => {
  return API.put(`/connector/model/${id}`, payload);
};

export const previewQuery = (payload) => {
  return API.post("/connector/postgres/preview", payload);
};

export const getListOfDestinationsCatalogs = (payload) => {
  return API.get("/connector/list", payload);
};

export const getDestinationRequiredFileds = (sourceId, payload) => {
  return API.get(`/connector/required-fields/${sourceId}`, payload);
};

export const createDestination = (payload) => {
  return API.post("/dataset/destination", payload);
};

export const getCreatedSourcesList = (payload) => {
  return API.get("connector/active-sources", payload);
};

// export const createDestination = (payload) => {
//   return API.post(
//     "/dataset/destination",
//     payload
//   );
// };
