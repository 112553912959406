import { Form, message, Slider, List, Divider, Spin } from "antd";
import {
  buyerPlaybooksUsingFilters,
  getBuyerPlaybookFilterValues,
  getCategoryDetailsByCategoryId,
} from "api/Playbooks";
import SinglePlaybook from "../SinglePlaybook";
// import PrivSinglePlaybook from "../PrivHomesPlaybooks/SinglePlaybook";
import MobileSinglePlaybook from "../mSinglePlaybook";
import Searchbar from "components/Datasets/Commons/Searchbar";
import SideFilters from "components/Datasets/Commons/SideFilters";
import FilterDatePicker from "components/Datasets/Commons/SideFilters/Fields/FilterDatePicker";
import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "components/Datasets/Commons/SideFilters/Fields/FilterDropdown";
import FilterFieldsContainer from "components/Datasets/Commons/SideFilters/Fields/FilterFieldsContainer";
import FilterRatingDropdown from "components/Datasets/Commons/SideFilters/Fields/FilterRatingDropdown";
import FilterSingleSelectionDropdown from "components/Datasets/Commons/SideFilters/Fields/FilterSingleSelectionDropdown";
import {
  DEFAULT_DATE_FORMAT,
  PLAYBOOK_ACTIONS,
  SHOULD_SEARCH_ON_PAGE_LOAD,
} from "constants";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import "./index.scss";
import { useParams } from "react-router-dom";
import SingleCategoryPlaybooks from "../SingleCategoryPlaybooks";
import { isMobile } from "utils";
import Flex from "screens/Chat/Flex";
import PrivSinglePlaybook from "../PrivHomesPlaybooks/SinglePlaybook";

function CategoryPlaybooks() {
  const [showFilters, setShowFilters] = useState(false);
  const [spinning, setSpinning] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchedFilters, setSearchedFilters] = useState({});
  const [categoryName, setCategoryName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [form] = Form.useForm();
  let data = searchResults;

  // getting category id from URL

  const { playbookCategoryId } = useParams();
  const location = useLocation();
  const mobileCheck = location.state?.flag;

  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const { selectedWorkspaceId, buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;

  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [showCreatePlaybookTooltip, setShowCreatePlaybookTooltip] =
    useState(false);
    const [isMobileView, setIsMobileView] = useState(isMobile());
    useEffect(() => {
      function handleResize() {
        setIsMobileView(isMobile());
      }
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

  useEffect(() => {
    getFilters();
    getCategoryName();
  }, []);

  useEffect(() => {
    if (shouldFetchResults.current) {
      getSearchResults();
    }
  }, [searchedText, searchedFilters]);

  const getCategoryName = () => {
    const payload = {
      playbook_category_id: playbookCategoryId,
    };

    getCategoryDetailsByCategoryId(payload)
      .then((res) => {
        const categoryDetails = res.data.data[0];
        setCategoryName(categoryDetails.name);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const getFilters = () => {
    const payload = {
      playbook_category_id: playbookCategoryId,
      profile_workspace_id: buyerProfileWorkspaceId,
      workspace_id: selectedWorkspaceId,
      profile_type: "buyer",
    };

    getBuyerPlaybookFilterValues(payload)
      .then((res) => {
        const filters = res.data.data;
        setFilterOptions({
          ...filters,
        });
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const listDropdownData = [
    PLAYBOOK_ACTIONS.REVIEWING,
    PLAYBOOK_ACTIONS.HIGH_PRIORITY,
    PLAYBOOK_ACTIONS.LOW_PRIORITY,
    PLAYBOOK_ACTIONS.MEDIUM_PRIORITY,
    PLAYBOOK_ACTIONS.NOT_INTRESTED,
  ];

  const onApplyFilters = () => {
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    setSearchedFilters(form.getFieldsValue());
  };

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });

    let updatedBudgetLimits = filters["budget"]?.split(",");
    let min = 0;
    let max = 999999999;

    if (updatedBudgetLimits?.length > 1) {
      min = parseInt(updatedBudgetLimits[0]);
      max = parseInt(updatedBudgetLimits[1]);
    }

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      workspace_id: selectedWorkspaceId,
      playbook_category_id: playbookCategoryId,
      profile_type: "buyer",
      query: searchedText,
      min_budget: min,
      max_budget: max,
      tags: filters["tags"],
      owners: filters["owners"],
      created_before: filters["createdBefore"],
      rating: filters["rating"],
      action: filters["list"],
    };

    setLoading(true);
    setSpinning(true);
    buyerPlaybooksUsingFilters(payload)
      .then((res) => {
        setSearchResults(res.data.data);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        setSpinning(false);
      });
  };

  const tipFormatter = (value) => `$${value}`;

  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
  };
  const filterFields = [
    <FilterFieldsContainer
      label="Budget"
      onClear={() => {
        onResetFields(["budget"]);
      }}
    >
      <Form.Item name="budget">
        <Slider
          range={{ draggableTrack: true }}
          min={0}
          max={parseInt(filterOptions.maxBudget)}
          defaultValue={[0, parseInt(filterOptions.maxBudget)]}
          tipFormatter={tipFormatter}
          onAfterChange={onApplyFilters}
        />
      </Form.Item>
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Owners"
      onClear={() => {
        onResetFields(["owners"]);
      }}
    >
      <FilterDropdown
        name="owners"
        placeholder="Enter Owners here…"
        options={filterOptions.sellers}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Tags"
      onClear={() => {
        onResetFields(["tags"]);
      }}
    >
      <FilterDropdown
        name="tags"
        placeholder="Enter tags here…"
        options={filterOptions.tags}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="List"
      onClear={() => {
        onResetFields(["list"]);
      }}
    >
      <FilterSingleSelectionDropdown
        name="list"
        placeholder="Enter list here…"
        options={listDropdownData}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Created before"
      onClear={() => {
        onResetFields(["createdBefore"]);
      }}
    >
      <FilterDatePicker name="createdBefore" onChange={onApplyFilters} />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Rating"
      onClear={() => {
        onResetFields(["rating"]);
      }}
    >
      <FilterRatingDropdown
        name="rating"
        placeholder="Enter rating here…"
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
  ];

  return (
    <div className="newPlaybooks">
      <div className="newPlaybooks__searchContainer">
        <Searchbar
          placeholder="Search playbooks"
          filtersVisible={showFilters}
          onFiltersVisibilityChange={setShowFilters}
          onSubmitSearchText={(searchedText) => {
            shouldFetchResults.current = true;
            setIsBeginingSearch(false);
            setSearchedText(searchedText);
          }}
          filtersApplied={
            !isEmpty(
              Object.values(searchedFilters).filter((value) => {
                return !isEmpty(value);
              })
            )
          }
        />
      </div>

      <div className="newPlaybooks__filterAndResults">
      <div className="newPlaybooks__filterAndResults__container">
          {showFilters && (
            <div className="newPlaybooks__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && (
            <>
            {spinning ? (<Flex
                  flex={1}
                  justify="center"
                  align="center"
                  style={{
                    width: "100%",
                    height: isMobileView === true ? "100%" : 100,
                    backgroundColor: "#fff",
                  }}
                  direction="column"
                >
                  <Spin size="default" />
                  <div> Loading...</div>
                </Flex>):(
              <div>
              <div className="categoryPlaybooks__header__header">
                <p className="categoryPlaybooks__header__text">
                  {categoryName} ({data?.length > 0 ? data.length : 0})
                </p>
                <Divider className="categoryPlaybooks__header__divider" />
              </div>
              <List
                dataSource={data}
                className="categoryPlaybooks__list"
                renderItem={(item, index) => {
                  return (
                    <div className="categoryPlaybooks__datas">
                    {isMobileView === true || mobileCheck === true ? (
                      <MobileSinglePlaybook
                        className="singleCategoryPlaybooks__single"
                        singlePlaybookDetail={item}
                      />
                    ) : (
                      userSession.selectedWorkspaceName === "Palisade Knowledge" ? (
                        <PrivSinglePlaybook
                          className="singleCategoryPlaybooks__single"
                          singlePlaybookDetail={item}
                        />
                      ) : (
                        <SinglePlaybook
                          className="singleCategoryPlaybooks__single"
                          singlePlaybookDetail={item}
                        />
                      )
                    )}
                    </div>
                  );
                }}
              />
            </div>
            )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryPlaybooks;
