import React, { useState, useEffect } from "react";
import { Input, Button, message, Spin, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import Flex from "screens/Chat/Flex";
import { getSavedAccessToken } from "store/localStorage";
import "./index.scss";

const LoadingSpinner = () => (
  <Flex
    flex={1}
    justify="center"
    align="center"
    style={{
      width: "100%",
      height: 350,
      backgroundColor: "#fff",
    }}
    direction="column"
  >
    <Spin size="default" />
    <div>Loading...</div>
  </Flex>
);

const ApiDetailsForm = ({
  sourceId,
  sourceName,
  apiKey,
  copyText,
  revokeAPIKeyCalled,
  generateNewKeyAPI,
}) => (
  <div>
    <h1 className="ApiConnectorDetails__mainHeading">API Key</h1>
    <div className="ApiConnectorDetails__form">
      {/* Your form fields here */}
      <div>
        <h6 className="ApiConnectorDetails__heading">Source Id</h6>
        <Input
          className="ApiConnectorDetails__input"
          name="Source Id"
          placeholder="dsfkjasKJSHKJHS1213"
          value={sourceId}
          disabled
        />
        <p className="ApiConnectorDetails__para">
          You can differentiate by the source id.
        </p>
      </div>

      <div>
        <h6 className="ApiConnectorDetails__heading">Source Name</h6>
        <Input
          className="ApiConnectorDetails__input"
          name="Source Name"
          placeholder="Name of Source"
          value={sourceName ? sourceName : "?"}
          disabled
        />
        <p className="ApiConnectorDetails__para">
          You can differentiate by the source name.
        </p>
      </div>

      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <h6 className="ApiConnectorDetails__heading">Write Key</h6>
            <Input
              type="text"
              id="textInput"
              readOnly
              className="ApiConnectorDetails__input2"
              name="Source Id"
              value={apiKey}
              placeholder="SRDlkhGHB433VVc2"
            />
            <p className="ApiConnectorDetails__para">
              Use this key to send data to this source from our partners,
              plugins, libraries, or REST API.
            </p>
          </div>

          <Button
            className="ApiConnectorDetails__copyButton"
            type="primary"
            icon={<CopyOutlined />}
            onClick={copyText}
          >
            Copy
          </Button>
          <Button
            className="ApiConnectorDetails__revokeButton"
            type="primary"
            icon={<CloseOutlined />}
            onClick={revokeAPIKeyCalled}
          >
            Revoke
          </Button>
        </div>
      </div>
    </div>

    <div className="ApiConnectorDetails__buttonsDiv">
      <Button
        className="ApiConnectorDetails__button"
        type="primary"
        onClick={generateNewKeyAPI}
      >
        Generate New Key
      </Button>
    </div>
  </div>
);

const ApiConnectorDetails = () => {
  const userToken = getSavedAccessToken();
  const navigate = useNavigate();
  const { sourceId, sourceName, connectionName, connectorId, active } =
    useParams();
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);

  const apiUrl =
    "https://vnziozspx3.execute-api.us-west-2.amazonaws.com/dev/key";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    getAPIKey();
  }, [sourceId, sourceName]);

  const getAPIKey = async () => {
    try {
      setLoading(true);
      const response = await axios.get(apiUrl, { headers });
      setApiKey(response.data.data[0].lastFourCharacters);
    } catch (error) {
      message.error(error.response?.data?.message || "Error fetching API key");
    } finally {
      setLoading(false);
    }
  };

  const onChange = (activeKey) => {
    if (activeKey === "2") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };

  const copyText = () => {
    var inputField = document.getElementById("textInput");
    if (inputField && inputField.value) {
      // Check if input field exists and has a value
      inputField.select();
      inputField.setSelectionRange(0, 99999);
      document.execCommand("copy");
      message.success("Successfully copied to clipboard!");
    } else {
      message.warning("There is nothing to copy!"); // Notify the user if there's nothing to copy
    }
  };

  const revokeAPIKeyCalled = async () => {
    try {
      setLoading(true);
      await axios.delete(apiUrl, { headers });
      setApiKey("");
      message.success("API Key revoked successfully");
    } catch (error) {
      message.error(error.response?.data?.message || "Error revoking API key");
    } finally {
      setLoading(false);
    }
  };

  const generateNewKeyAPI = async () => {
    try {
      setLoading(true);
      // Placeholder logic for generating a new key (replace with your actual logic)
      const response = await axios.post(
        apiUrl,
        {
          /* your data for generating a new key */
        },
        { headers }
      );
      message.success(response.data.message);
      setApiKey(response.data.data[0].apiKey);
    } catch (error) {
      message.error(
        error.response?.data?.message || "Error generating new API key"
      );
    } finally {
      setLoading(false);
    }
  };

  const items = [
    {
      key: "1",
      label: "Overview",
    },
    {
      key: "2",
      label: "Settings",
    },
  ];

  return (
    <div className="ApiConnectorDetails">
      <div className="ApiConnectorDetails__headerText">
        <span>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </span>
      </div>

      {loading ? (
        <LoadingSpinner />
      ) : (
        <ApiDetailsForm
          {...{
            sourceId,
            sourceName,
            apiKey,
            copyText,
            revokeAPIKeyCalled,
            generateNewKeyAPI,
          }}
        />
      )}
    </div>
  );
};

export default ApiConnectorDetails;
