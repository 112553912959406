import { Collapse, Card, Button, Input, Spin } from "antd";
import "./index.scss";
import { LoadingOutlined } from '@ant-design/icons';
import { useState, useRef, useEffect } from "react";

function DesktopPasteQuery({ handleTestQuery, handleQueryValue, loader }) {
  const [queryValue, setQueryValue] = useState(null);// Trigger this effect whenever queryValue changes

  const handleInputChange = (e) => {
    setQueryValue(e.target.value); // Update local state
    handleQueryValue(e.target.value); // Call the external function
  };

  return (
    <Card className="desktopPasteQuery__customCardContent">
      <div className="desktopPasteQuery__customCardContent-header">
        <p
          style={{
            flex: 1,
            fontFamily: "Lato",
            fontWeight: "bold",
            fontSize: "11pt",
            textAlign: "left",
            margin: 0,
            color: "#000000",
          }}
        >
          {"...have a SQL Query"}
        </p>
      </div>
      <Input.TextArea
        className="desktopPasteQuery__textInput"
        placeholder="Write your query here"
        value={queryValue}
        onChange={handleInputChange} // Use the handler for onChange
      />
     <Button
        className={`desktopPasteQuery__saveButton ${!queryValue ? 'disabled' : ''}`}
        onClick={handleTestQuery}
        disabled={!queryValue} // Disable the button if queryValue is empty
      >
        {loader ?   <Spin
    indicator={
      <LoadingOutlined
        style={{color: "white", marginBottom:"2px" }}
        spin
      />
    }
  /> : "Save"}
      </Button>
    </Card>


  );
}

export default DesktopPasteQuery;