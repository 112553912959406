import { trackEventCall } from "analytics";
import { Button, Image, message, Typography } from "antd";
import { getUserWorkspaceByProfileIds } from "api/Workspaces";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { IconScheduleMeeting } from "images";
import { useEffect, useState } from "react";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { useSelector } from "react-redux";
import { isMobile } from "utils";
import "./index.scss";
function ScheduleMeeting({ sellerInfoObject }) {
  const [isPopWidgetOpen, setIsPopWidgetOpen] = useState(false);
  const [userWorkCalendlyAccount, setUserWorkCalendlyAccount] = useState(
    sellerInfoObject?.seller?.work_calendly_url !== null
      ? sellerInfoObject?.seller?.work_calendly_url
      : "https://calendly.com/billraney1"
  );
  const [isMobileView, setIsMobileView] = useState(isMobile());
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  // console.log("sellerInfoObject", sellerInfoObject);
  const scheduleCallClicked = () => {
    setIsPopWidgetOpen(true);
    const segmentEventData = {
      marketplaceId: userSession.selectedWorkspaceId,
      playbookname: sellerInfoObject?.name,
      sellername:
        sellerInfoObject?.seller?.first_name +
        " " +
        sellerInfoObject?.seller?.last_name,
      selleremail: sellerInfoObject?.seller?.email,
      playbook_id: sellerInfoObject?.id,
      buyername: userSession.firstName,
      buyerPhone: userSession.phoneNumber,
      buyerUserId: userSession.userId,
      // revenue: parseInt(pkg?.price),
      // currency: "USD",
      // planType: pkg?.plan_type,
      // planId: pkg?.id,
    };
    // console.log("segmentEventData", segmentEventData);
    trackEventCall(
      ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_CLICKED,
      segmentEventData
    );
  };

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload),
  });

  return (
    <div className="scheduleMeeting">
      <div
        className={
          isMobileView === true
            ? "scheduleMeeting__scheduleMeetingDiv"
            : "scheduleMeeting__scheduleMeetingDivDesktop"
        }
        onClick={scheduleCallClicked}
      >
        {/* <Image
          rootClassName={
            isMobileView === true
              ? "scheduleMeeting__iconScheduleMeetingNew"
              : "scheduleMeeting__iconScheduleMeeting"
          }
          preview={false}
          src={IconScheduleMeeting}
        ></Image> */}

        {/* {!isMobileView && ( */}
        <Typography.Text className="scheduleMeeting__scheduleMeetingText">
          Schedule a meeting
        </Typography.Text>
        {/* )} */}
        {/* <Button
          className="scheduleCall__scheduleButton"
          onClick={scheduleCallClicked}
        >
          Schedule a Meeting
        </Button> */}
      </div>

      <PopupModal
        url={
          sellerInfoObject?.seller?.work_calendly_url !== null
            ? sellerInfoObject?.seller?.work_calendly_url
            : "https://calendly.com/billraney1"
        }
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#00a2ff"
        open={isPopWidgetOpen}
        onModalClose={() => setIsPopWidgetOpen(false)}
      />
    </div>
  );
}

export default ScheduleMeeting;
