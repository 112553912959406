import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import "./index.scss";
import { SectionBarIcon } from "images";
import { Image, Spin, message } from "antd";
import { editWorkspaceNav, getUserWorkspaceByProfileIds } from "api/Workspaces";
import { useSelector, useDispatch } from "react-redux";
import { isMobile } from "utils";
import MobileV3EditNavBar from "./MobileV3EditNavbar";
import Flex from "screens/Chat/Flex";
import { setLeftNavOptions } from "store/modules/auth/action";


const V3EditNavBar = () => {

    const [data, setData] = useState();
    const [isMobileView, setIsMobileView] = useState(isMobile());

    const authReducer = useSelector((state) => state.authReducer);
    const dispatch = useDispatch();
    const userSession = authReducer.userSession;
    let myWorkspaceId = 0;

    const [loading, setLoading] = useState(true);
    const [apiCallLoading, setApiCallLoading] = useState(false);

    useEffect(() => {
        callGetUserWorkspaceByProfileIdsAPI();
    }, []);

    const callGetUserWorkspaceByProfileIdsAPI = async () => {
        setLoading(true);
        const payload = {
            profileids:
                userSession.buyerProfileId + "," + userSession.sellerProfileId,
        };

        await getUserWorkspaceByProfileIds(payload)
            .then((res) => {
                setLoading(true);
                let marketplacesList = res?.data?.data;
                myWorkspaceId = userSession.selectedWorkspaceId;
                const foundWorkspace = marketplacesList.find(workspace => workspace.workspace_id === myWorkspaceId);

                if (foundWorkspace) {
                    let keyValueData = {};
                    keyValueData["left_nav_options"] = foundWorkspace.left_nav_options;
                    setData(keyValueData);
                }
            })
            .catch((err) => {

            })
            .finally(() => {
                setLoading(false);
            });
    };


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const sourceList = data.left_nav_options.find(
            (item) => item.key === result.source.droppableId
        );
        const destinationList = data.left_nav_options.find(
            (item) => item.key === result.destination.droppableId
        );

        if (result.source.droppableId === result.destination.droppableId) {
            if (result.source.droppableId === "submenus") {
                const movedItem = sourceList.submenus.find(
                    (item, index) => index === result.source.index
                );

                sourceList.submenus.splice(result.source.index, 1);
                sourceList.submenus.splice(result.destination.index, 0, movedItem);
            } else {
                const movedItem = data.left_nav_options.find(
                    (item, index) => index === result.source.index
                );

                data.left_nav_options.splice(result.source.index, 1);
                data.left_nav_options.splice(result.destination.index, 0, movedItem);
            }
        } else {
            if (result.source.droppableId === "submenus") return;

            const movedItem = sourceList.submenus.find(
                (item, index) => index === result.source.index
            );

            sourceList.submenus.splice(result.source.index, 1);
            destinationList.submenus.splice(result.destination.index, 0, movedItem);
        }

        setData({ ...data });
    };

    const handleMainNameChange = (e, item) => {
        item.name = e.target.value;
        setData({ ...data });
    };


    const handleMainVisibiltyChange = (value, item) => {
        item.isVisible = value;
        setData({ ...data });
    };

    const handleSubMenuNameChange = (e, mainItem, submenu) => {
        submenu.name = e.target.value;
        setData({ ...data });
    };
    const handleSubMenuVisibiltyChange = (value, submenu) => {
        submenu.isVisible = value;
        setData({ ...data });
    };

    const handleSave = async () => {

        setLoading(true);
        let apiPayload = {
            "workspace_id": userSession.selectedWorkspaceId,
            "left_nav_options": data.left_nav_options,
        }


        await editWorkspaceNav(apiPayload)
            .then(response => {
                message.success("Data updated successfully");
                dispatch(setLeftNavOptions(response?.data?.data))
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    };

    if (loading) {
        return <> <Flex
            flex={1}
            justify="center"
            align="center"
            style={{
                width: "100%",
                height: 500,
                backgroundColor: "#fff",
            }}
            direction="column"
        >
            <Spin size="default" />
            <div> Loading...</div>
        </Flex></>
    } else {
        return (
            <>
                {isMobileView === true ? (<MobileV3EditNavBar />) : (
                    <>
                        <div className="EditNavbar">
                            <p className="mainHeading">
                                Reorder or change the way your menu appears
                            </p>
                            <div className="titleDiv">
                                <div className="headerOptions">
                                    <div className="iconText">
                                        <span className="span1">Current navigation</span>
                                        <span>
                                            {" "}
                                            {"("}In order of appearance{")"}
                                        </span>
                                    </div>
                                    <div className="iconBellEdit">Edit Label</div>
                                    <div className="iconBellEye">Visibility</div>
                                </div>
                            </div>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="left_nav_options" type="list">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {data.left_nav_options.map((option, index) => (
                                                <Draggable
                                                    key={option.key}
                                                    draggableId={option.key}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className="divFlex">
                                                                <div className="Width">
                                                                    <div className="labelFlex">
                                                                        <span className="SectionIcon">
                                                                            <Image
                                                                                src={SectionBarIcon}
                                                                                preview={false}
                                                                            />
                                                                        </span>
                                                                        <div className="mainText">{option.text}</div>
                                                                    </div>
                                                                    <div className="divBorder"></div>
                                                                </div>
                                                                <input
                                                                    className="inputSpace"
                                                                    type="text"
                                                                    value={option.name}
                                                                    onChange={(e) => handleMainNameChange(e, option)}
                                                                />
                                                                {option.isVisible ? (
                                                                    <EyeOutlined
                                                                        onClick={() => handleMainVisibiltyChange(!option.isVisible, option)}
                                                                        className="eyeIcon"
                                                                    />
                                                                ) : (
                                                                    <EyeInvisibleOutlined
                                                                        onClick={() => handleMainVisibiltyChange(!option.isVisible, option)}
                                                                        className="eyeIcon"
                                                                    />
                                                                )}
                                                            </div>
                                                            <Droppable droppableId="submenus" type="list">
                                                                {(provided) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {option.submenus.map((submenu, subIndex) => (
                                                                            <Draggable
                                                                                key={submenu.key}
                                                                                draggableId={submenu.key}
                                                                                index={subIndex}
                                                                            >
                                                                                {(provided) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                    >
                                                                                        <div className="divFlex2">
                                                                                            <div className="Width">
                                                                                                <div className="labelFlex2">
                                                                                                    <div className="subText">
                                                                                                        {submenu.text}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="divBorder"></div>
                                                                                            </div>
                                                                                            <input
                                                                                                className="inputSpace"
                                                                                                type="text"
                                                                                                value={submenu.name}
                                                                                                onChange={(e) =>
                                                                                                    handleSubMenuNameChange(
                                                                                                        e,
                                                                                                        option,
                                                                                                        submenu
                                                                                                    )
                                                                                                }
                                                                                            />
                                                                                            {submenu.isVisible ? (
                                                                                                <EyeOutlined
                                                                                                    onClick={() => handleSubMenuVisibiltyChange(!submenu.isVisible, submenu)}
                                                                                                    className="eyeIcon"
                                                                                                />
                                                                                            ) : (
                                                                                                <EyeInvisibleOutlined
                                                                                                    onClick={() => handleSubMenuVisibiltyChange(!submenu.isVisible, submenu)}
                                                                                                    className="eyeIcon"
                                                                                                />
                                                                                            )}                                                                                </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className="buttonsDiv">
                            <button
                                loading={loading}
                                type="primary"
                                className="saveChangesButton" onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </>
                )}

            </>
        );
    }
};

export default V3EditNavBar;
