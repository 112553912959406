import "./index.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPreviewSheetLink } from "api/Datasets";
import { MarkDoneEmpty, MarkedGreen } from "images";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Image, Card, Typography, Progress } from "antd";
import {
  setActiveKey,
  setFormData,
  setStepCompletion,
} from "store/modules/datasets/action";

function PreviewYourList() {
  const { Panel } = Collapse;
  const { Link } = Typography;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [previewLink, setPreviewLink] = useState(null);
  const steps = useSelector((state) => state.stepsReducer);

  useEffect(() => {
    const tempId = steps && steps.steps[3.4].data.list.tempId; // value in response from create list API
    const listId = steps && steps.steps[3.4].data.list.listId; // value in response from create list API

    const payload = {
      tmpId: tempId,
      listId: listId,
    };
    getPreviewSheetLink(payload)
      .then((res) => {
        console.log("Preview sheet link api response", res?.data?.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  useEffect(() => {
    if (previewLink === null) {
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 92 ? prev + 8 : prev + 1));
      }, 1200);
      const tempId = steps && steps.steps[3.4].data.list.tempId;
      const listId = steps && steps.steps[3.4].data.list.listId;
      const payload = {
        tmpId: tempId,
        listId: listId,
      };

      getPreviewSheetLink(payload)
        .then((res) => {
          if (res?.data?.data) {
            clearInterval(interval);
            setProgress(100);
            setPreviewLink(res?.data?.data);
            handleInputChange(6, "PreviewYourList", "done");
          }
        })
        .catch((err) => {
          console.error("Error fetching data:", err);
          clearInterval(interval);
        });

      return () => clearInterval(interval);
    }
  }, [previewLink]);

  const handleInputChange = (subStep, subStepKey, value) => {
    const isStepCompleted = true;
    dispatch(setStepCompletion(6, isStepCompleted));
    dispatch(setFormData(subStep, subStepKey, value));
    dispatch(setStepCompletion(subStep, true));
    dispatch(setActiveKey("7"));
  };

  const CustomPanelHeader = ({ mainHeading, subHeading, number }) => (
    <Card className="PreviewYourList__customPanelHeader">
      <div className="PreviewYourList__customPanelHeader-header">
        <Image
          src={
            steps && steps.steps[number]?.completed
              ? MarkedGreen
              : MarkDoneEmpty
          }
          preview={false}
          className="PreviewYourList__customPanelHeader-image"
        />
        <div className="PreviewYourList__customPanelHeader-headingContainer">
          <div className="PreviewYourList__customPanelHeader-mainHeading">
            {mainHeading}
          </div>

          <div className="PreviewYourList__customPanelHeader-subHeading">
            {subHeading}
          </div>
        </div>
      </div>
    </Card>
  );

  const CustomCardContent = ({ mainHeading = "", onClick, number }) => (
    <Card className="PreviewYourList__customCardContent" onClick={onClick}>
      <div className="PreviewYourList__customCardContent-header">
        <Image
          src={
            steps && steps.steps[number]?.completed
              ? MarkedGreen
              : MarkDoneEmpty
          }
          preview={false}
          className="PreviewYourList__customPanelHeader-image"
        />
        <p className="PreviewYourList__customCardContent-mainHeading">
          {mainHeading}
        </p>
      </div>
      <div className="PreviewYourList__customCardContent-header">
        {previewLink ? (
          <Link
            className="PreviewYourList__customCardContent-subHeading"
            href={previewLink}
            target="_blank"
            onClick={() => {
              handleInputChange(6, "PreviewYourList", "done");
              navigate(-1);
            }}
          >
            {previewLink}
          </Link>
        ) : (
          <div className="no-data-message">
            <p>
              Your link is being prepared. It will be ready shortly. Please
              wait...
            </p>
            <Progress
              percent={progress}
              status={progress < 100 ? "active" : "normal"}
            />
          </div>
        )}
      </div>
    </Card>
  );

  return (
    <div className="PreviewYourList">
      <Collapse defaultActiveKey={["1"]} collapsible="disabled">
        <Panel
          header={
            <CustomPanelHeader
              mainHeading="View your list in a Google sheet"
              subHeading="Preview and approve list"
            />
          }
          key="1"
        >
          <CustomCardContent mainHeading="Link to Google sheets" />
        </Panel>
      </Collapse>
    </div>
  );
}

export default PreviewYourList;
