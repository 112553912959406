import { message, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COGNITO_CLIENT_ID, COGNITO_DOMAIN } from "../../environments";
import { APP_ROUTES } from "../../routes";
import { getSocialSigninRedirectURI } from "../../utils";

function SocialSignin() {
  const [pageMessage, setPageMessage] = useState("Authenticating...");
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code") || "";
    axios
      .post(
        `${COGNITO_DOMAIN}oauth2/token`,
        new URLSearchParams(
          {
            grant_type: "authorization_code",
            client_id: COGNITO_CLIENT_ID,
            redirect_uri: getSocialSigninRedirectURI(),
            code,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
      )
      .then((res) => {
        navigate(APP_ROUTES.SIGNUP_INFO.path);
      })
      .catch((err) => {
        setPageMessage(err.message);
        message.error(err.message);
      });
  }, []);

  return <Typography.Title>{pageMessage}</Typography.Title>;
}
export default SocialSignin;
