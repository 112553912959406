import {
  SET_USER_SESSION,
  SET_USER_TOKEN,
  SET_ACCESS_TOKEN,
  IS_SOCIAL_KEY,
  DOMAIN_AND_USER_INFO_KEY,
  EXTERNAL_USER_INFO_KEY,
  EXTERNAL_INVITE_INFO_KEY,
  EXTERNAL_LINK_SEGMENT_PARAMS_KEY,
  CLEAR_AUTH_STATE,
} from "./action";
import { takeEvery, call } from "redux-saga/effects";
import {
  saveUserSession,
  saveUserToken,
  saveAccessToken,
  saveSocialKey,
  saveDomainAndUserInfo,
  saveExternalUserInfo,
  saveExternalInviteInfo,
  saveExternalLinkSegmentParams,
} from "../../localStorage";

export function* authSaga() {
  yield takeEvery(SET_USER_SESSION, handleSetUserSession);
  yield takeEvery(SET_USER_TOKEN, handleSetUserToken);
  yield takeEvery(SET_ACCESS_TOKEN, handleSetAccessToken);
  yield takeEvery(IS_SOCIAL_KEY, handleSetIsSocial);
  yield takeEvery(DOMAIN_AND_USER_INFO_KEY, handleSetIsDomainAndUser);
  yield takeEvery(EXTERNAL_USER_INFO_KEY, handleSetIsExternalUser);
  yield takeEvery(EXTERNAL_INVITE_INFO_KEY, handleSetIsExternalInvite);
  yield takeEvery(
    EXTERNAL_LINK_SEGMENT_PARAMS_KEY,
    handleSetIsExternalLinkSegmentParam
  );
  yield takeEvery(CLEAR_AUTH_STATE, handleClearAuthState);
}
function* handleSetUserSession(action) {
  yield call(saveUserSession, action.payload);
}

function* handleSetUserToken(action) {
  yield call(saveUserToken, action.payload);
}

function* handleSetAccessToken(action) {
  yield call(saveAccessToken, action.payload);
}

function* handleSetIsSocial(action) {
  yield call(saveSocialKey, action.payload);
}
function* handleSetIsDomainAndUser(action) {
  yield call(saveDomainAndUserInfo, action.payload);
}

function* handleSetIsExternalUser(action) {
  yield call(saveExternalUserInfo, action.payload);
}
function* handleSetIsExternalInvite(action) {
  yield call(saveExternalInviteInfo, action.payload);
}
function* handleSetIsExternalLinkSegmentParam(action) {
  yield call(saveExternalLinkSegmentParams, action.payload);
}

function* handleClearAuthState(action) {
  yield call(saveUserToken, action.payload);
  yield call(saveAccessToken, action.payload);
  yield call(saveUserSession, action.payload);
  yield call(saveSocialKey, action.payload);
  yield call(saveDomainAndUserInfo, action.payload);
  yield call(saveExternalUserInfo, action.payload);
  yield call(saveExternalInviteInfo, action.payload);
  yield call(saveExternalLinkSegmentParams, action.payload);
}
