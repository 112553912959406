import {
  CLEAR_FILE_ONBOARDING_STATE,
  SET_CURRENT_GPT_DETAILS,
  SET_CURRENT_PROCESSSING_FILE,
  SET_CURRENT_SOURCE_INFO,
  SET_FILE_UPLOADING_POPUP_OPENED,
  SET_NEW_FILE_UPLOADED,
  UPDATE_FILE_STATUS,
} from "./action";

const initialState = {
  files: {},
  currentProcessingFile: {},
  currentSourceInfo: {},
  newFileUploaded: null,
  fileUploadingPopupOpened: true,
};

const fileOnboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILE_STATUS:
      const { uid } = action.payload;
      return {
        ...state,
        files: {
          ...state.files,
          [uid]: action.payload,
        },
      };
    case SET_CURRENT_GPT_DETAILS:
      return {
        ...state,
        currentGPTDetails: action.payload,
      };
    case SET_CURRENT_PROCESSSING_FILE:
      return {
        ...state,
        currentProcessingFile: action.payload,
      };
    case SET_CURRENT_SOURCE_INFO:
      return {
        ...state,
        currentSourceInfo: action.payload,
      };
    case SET_NEW_FILE_UPLOADED:
      return {
        ...state,
        newFileUploaded: action.payload,
      };
    case SET_FILE_UPLOADING_POPUP_OPENED:
      return {
        ...state,
        fileUploadingPopupOpened: action.payload,
      };
    case CLEAR_FILE_ONBOARDING_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default fileOnboardReducer;
