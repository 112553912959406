import React, { useState } from "react";
import { Table, Select, Switch, Tabs, Button } from "antd";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";

const { Option } = Select;

const FieldMapper = () => {
  const { sourceId, sourceName, connectionName, connectorId, active } =
    useParams();
  const navigate = useNavigate();
  const [activePanel, setActivePanel] = useState(null);

  const items = [
    connectorId == 10
      ? { key: "1", label: "Model" }
      : { key: "1", label: "Overview" },
    { key: "2", label: "Mapping" },
    { key: "3", label: "Tasks" },
    { key: "4", label: "Settings" },
  ];

  const onChange = (activeKey) => {
    const pathPrefix =
      sourceName === "Revcloud API Events"
        ? "/dashboard/datasets/apiconnector/overview"
        : "/dashboard/datasets/connector/overview";
    navigate(
      `${pathPrefix}/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
    );
    if (activeKey === "2") {
      navigate(
        `/dashboard/datasets/sources/mapping/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "3") {
      navigate(
        `/dashboard/datasets/sources/tasks/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "4") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };
  const columns = [
    {
      title: "Source Field",
      dataIndex: "sourceField",
      key: "sourceField",
      className: "field-mapper__source-field",
    },
    {
      title: "Matched Fields",
      dataIndex: "matchedField",
      key: "matchedField",
      render: (_, record) => (
        <Select
          defaultValue={record.matchedField}
          className="field-mapper__select"
        >
          <Option value="Email">Email</Option>
          <Option value="Phone">Phone</Option>
          <Option value="Name">Name</Option>
          <Option value="Address">Address</Option>
          <Option value="Campaign ID">Campaign ID</Option>
        </Select>
      ),
      className: "field-mapper__matched-fields",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, record) => (
        <div className="field-mapper__description">
          <FileTextOutlined className="field-mapper__description-icon" />
          <div className="field-mapper__description-text">
            <p>{record.description}</p>
            <small>{record.example}</small>
          </div>
        </div>
      ),
      className: "field-mapper__description-column",
    },
    {
      title: "Identity Fields",
      dataIndex: "identityField",
      key: "identityField",
      render: (_, record) => (
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked={record.identityField}
          className="field-mapper__switch"
        />
      ),
      className: "field-mapper__identity-fields",
    },
  ];

  const dataSource = [
    {
      key: "1",
      sourceField: "Email Address",
      matchedField: "Email",
      description: "Unique email address",
      example: "e.g. jondoe@gmail.com",
      identityField: true,
    },
    {
      key: "2",
      sourceField: "Phone Number",
      matchedField: "Phone",
      description: "Unique phone number",
      example: "e.g. +123 456 789",
      identityField: false,
    },
    {
      key: "3",
      sourceField: "Name",
      matchedField: "Name",
      description: "Name of the user",
      example: "e.g. Jon Doe",
      identityField: true,
    },
    {
      key: "4",
      sourceField: "Full Address",
      matchedField: "Address",
      description: "Location of the user",
      example: "e.g. 3543 Guido St, LA",
      identityField: true,
    },
    {
      key: "5",
      sourceField: "Campaign ID",
      matchedField: "Campaign ID",
      description: "The unique ID of the campaign",
      example: "e.g. #128465",
      identityField: true,
    },
  ];

  return (
    <div className="ConnectorSettings">
      <div className="ConnectorSettings__headerText">
        <Tabs defaultActiveKey="2" items={items} onChange={onChange} />
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        className="field-mapper__table"
      />
    </div>
  );
};

export default FieldMapper;
