import React, { useRef, useState, useCallback } from "react";
import { Button, message, Steps, Image, Input, Table } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import Editor from '@monaco-editor/react';
import { DBIcon } from "images";
import { createModel, previewQuery } from "api/Connectors";

const ModelCreation = () => {
  const { Step } = Steps;
  const editorRef = useRef();
  const { sourceId, sourceName, connectionName, connectorId, active } =
    useParams();
  const navigate = useNavigate();
  const [uniqueIdentifier, setUniqueIdentifier] = useState('');
  const [modelData, setModelData] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [modelName, setModelName] = useState('');
  const [sqlQuery, setSqlQuery] = useState('');

  const handleClick = () => {
    setIsSelected(!isSelected); // Toggle selection state on click
  };
  const onMount = (editor) => {
    editorRef.current = editor;
    // Focus the editor only if the initial content is empty
    if (!sqlQuery.trim()) {
      editor.focus();
    }
  };
  const handleEditorChange = (value, event) => {
    setSqlQuery(value);
  };

  const handleModelNameChange = (name) => {
    setModelName(name);
  };

  const createModelApi = async () => {
    const payload = {
      "model_name": modelName,
      "query": sqlQuery,
      "connection_id": sourceId,
      "primary_key": uniqueIdentifier
    };
    try {
      const response = await createModel(payload); // Assuming createModel() is an async function that creates the model
      navigate(
        `/dashboard/datasets/connector/overview/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
      message.success('Model created successfully!');
    } catch (error) {
      console.error('Error creating model:', error.message);
      message.error('Failed to create model. Please try again.');
    }
  };

  const previewQueryApi = () => {
    const payload = {
      "query": sqlQuery,
      "connection_id": sourceId,
      "primary_key": uniqueIdentifier
    };

    previewQuery(payload)
      .then(response => {
        setModelData(response?.data?.data)
      })
      .catch(error => {
      });
  };
  const queryValidation = () => {
    const payload = {
      "query": sqlQuery,
      "connection_id": sourceId,
      "primary_key": uniqueIdentifier
    };

    previewQuery(payload)
      .then(response => {
        setModelData(response?.data?.data)
        next();
      })
      .catch(error => {
      });
  };
  const columns = modelData?.length > 0 ? Object.keys(modelData[0])?.map(key => ({
    title: key,
    dataIndex: key,
    key: key,
  })) : [];
  const steps = [
    {
      title: 'Choose Modeling Method',
    },
    {
      title: 'Define Model',
    },
    {
      title: 'Create',
    },
  ];
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '400px',
    textAlign: 'center',
    marginTop: 40,
  };


  return (
    <>
      <div className="FixedModel">
        <div>
        <Steps current={current} items={items} className="custom-steps" />
          <div style={contentStyle}>{steps[current].content}</div>
          {/* Conditional rendering based on the value of `current` */}
          {current === 0 && (
            <div> 
              <h1 className="topHeading">Choose Modeling Method</h1>
              <div onClick={handleClick} className={`modelingMethodContainer ${isSelected ? 'selected' : ''}`}>
                <div className="iconContainer">
                  <Image src={DBIcon} preview={false} />
                </div>
                <div className="methodDescription">
                  <h5>SQL Editor</h5>
                  <p>Create your model using our online SQL editor.</p>
                </div>
              </div>
            </div>
          )}
          {current === 1 && (
            <div className="queryEditorContainer">
              <div>
                <h2 className="queryEditorTitle">Define Model</h2>
                <h5 className="queryEditorDescription">
                  Enter the SQL query that will define your model. Once created, your model can be used to map data to your Reverse ETL destinations.
                  <br />
                  e.g.
                  <code style={{ backgroundColor: '#f8f9fa', padding: '0.2em 0.4em', borderRadius: '0.25em' }}>
                    SELECT * FROM salesforce_flex_unify.accounts;
                  </code>
                </h5>
              </div>
              <div className="editorWrapper">
                <Editor
                  // height="100%"
                  // width="100%"
                  // theme="vs-dark"
                  defaultLanguage="sql"
                  // defaultValue="// Write a SQL query"
                  value={sqlQuery}
                  onChange={handleEditorChange}
                  onMount={onMount}
                />
              </div>

              <div className="uniqueIdentifierContainer">
                <label>Unique Identifier Column * </label>
                <div title="Choose a column to use as the unique identifier for each row. This column will be used to detect new, updated, and deleted rows.">
                  <svg aria-describedby="evergreen-tooltip-89" data-icon="info-sign" viewBox="0 0 16 16" width="12" height="12">
                    <path d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zM7 3h2v2H7V3zm3 10H6v-1h1V7H6V6h3v6h1v1z" fill-rule="evenodd"></path>
                  </svg>
                </div>

                <Input
                  className="uniqueIdentifierContainer__inputDiv"
                  type="text"
                  placeholder="Enter unique identifier column name"
                  value={uniqueIdentifier}
                  onChange={(e) => setUniqueIdentifier(e.target.value)}
                />
                <div className="uniqueIdentifierContainer__buttonDiv">
                  <Button type="primary"
                    onClick={previewQueryApi}
                    disabled={
                      !sqlQuery.trim() || !uniqueIdentifier
                    }
                    icon={<EyeOutlined />}
                  >
                    Preview
                  </Button>
                </div>

              </div>
              {modelData?.length > 0 && (
                <div className="uniqueIdentifierContainer__tableDiv">
                  <Table dataSource={modelData} columns={columns} />
                </div>
              )}
            </div>
          )}
          {current === 2 && (
            <div className="queryEditorContainer">
              <div>
                <h2 className="modelTitle">Create</h2>
                <h5 className="modelHeading">
                  Model Name * </h5>
                <h6 className="modelParagraph">
                  Pick a name to help you identify this model in knowledge graph. You can change this at anytime under Settings. </h6>
              </div>
              <div className="modelInput">
                <Input
                  type="text"
                  id="modelName"
                  placeholder="Enter the name of the model"
                  value={modelName}
                  onChange={(e) => handleModelNameChange(e.target.value)}
                />
              </div>
            </div>
          )}
          <div
            style={{
              marginTop: 24,
            }}
          >
            <div className="buttonContainer">
              {current > 0 && (
                <Button
                  style={{
                    margin: '0 20px',
                  }}
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    if (current === 0 || current === 2) {
                      // If current is 0 or 2, directly move to the next step
                      next();
                    } else if (current === 1) {
                      // If current is 1, execute previewQuery
                      queryValidation();
                    }
                  }}
                  disabled={
                    current === 0 ? !isSelected : current === 1 ? !sqlQuery.trim() || !uniqueIdentifier : false
                  }
                >
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    createModelApi();
                  }}
                  disabled={!modelName.trim()}
                >
                  Create Model
                </Button>
              )}
            </div>


          </div>
        </div>
      </div>


    </>
  );
}

export default ModelCreation;