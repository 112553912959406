import { message, Form, Button } from "antd";
import { getPlacesFilterValues, getSources, searchPlaces, entityDataSearch } from "api/search";
import DataTable from "components/DataTable";
import { getSelectedWorkspaceProfileId } from "utils";
import { getIdColumn } from "components/DataTable/Columns";
import { DEFAULT_DATE_FORMAT } from "constants";
import { ENTITY_TYPES } from "constants";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import FilterFieldsContainer from "../Commons/SideFilters/Fields/FilterFieldsContainer";
import FilterDropdown, {
  FILTER_DROPDOWN_SUFFIX,
} from "../Commons/SideFilters/Fields/FilterDropdown";
import Searchbar from "../Commons/Searchbar";
import "./index.scss";
import SideFilters from "../Commons/SideFilters";
import { SHOULD_SEARCH_ON_PAGE_LOAD } from "constants";
import { isEmpty } from "lodash";

function PlacesSearch() {
  const placesColumns = [
    {
      title: "PLACE ID",
      dataIndex: "revcloud_place_id",
      tooltip:
        "The Place ID represents an identification generated for all the records within an entity type (i.e Place) of the application. The Palisade system recognizes matching records from multiple sources or within the same source and assigns the same ID for such records.",
    },
    {
      title: "SOURCE",
      dataIndex: "source",
    },
    {
      title: "ADDRESS",
      dataIndex: "address",
      // render: (text) => <a className="nameValue">{text}</a>,
    },
    {
      title: "STATE",
      dataIndex: "state",
    },
    {
      title: "CITY",
      dataIndex: "city",
    },
    {
      title: "ZIP",
      dataIndex: "zip",
    },
    {
      title: "ACCURATE TO DATE",
      dataIndex: "accurate_to_date",
    },
  ];
  const [isBeginingSearch, setIsBeginingSearch] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? false : true
  );
  const [searchedText, setSearchedText] = useState(
    SHOULD_SEARCH_ON_PAGE_LOAD ? "" : null
  );
  const [searchedFilters, setSearchedFilters] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [resizingResults, setResizingResults] = useState(false);
  const userSession = useSelector((state) => state.authReducer).userSession;
  const { accountId, userId  } = useSelector(
    (state) => state.authReducer
  ).userSession;
  const shouldFetchResults = useRef(SHOULD_SEARCH_ON_PAGE_LOAD ? true : false);
  const [form] = Form.useForm();
  let cols = placesColumns;

  useEffect(() => {
    getFilters();
    cols[0] = getIdColumn(placesColumns[0], idClicked);
  }, []);

  const idClicked = (id) => {
    form.setFieldsValue({ revcloudId: [id] });
    setSearchedFilters({ revcloudId: [id] });
    setShowFilters(true);
  };

  useEffect(() => {
    if (shouldFetchResults.current) {
      getSearchResults();
    }
  }, [searchedText, searchedFilters]);

  useEffect(() => {
    setResizingResults(true);
    setTimeout(() => {
      setResizingResults(false);
    }, 100);
  }, [showFilters]);

  const getFilters = () => {
    const payload = {
      userId,
      accountId,
      entityTypeId: ENTITY_TYPES.PLACES,
    };

    Promise.all([getSources(payload), getPlacesFilterValues(payload)])
      .then((res) => {
        const sources = res[0].data.data;
        const filters = res[1].data.data;
        setFilterOptions({
          sources,
          ...filters,
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const getSearchResults = () => {
    const filters = {};
    Object.keys(searchedFilters).forEach((filter) => {
      let filterValue = searchedFilters[filter];
      if (moment.isMoment(filterValue)) {
        filterValue = [moment(filterValue).format(DEFAULT_DATE_FORMAT)];
      } else if (["string", "number"].includes(typeof filterValue)) {
        filterValue = [filterValue];
      } else {
        filterValue = filterValue || [];
      }
      filters[filter] = filterValue.join(",");
    });
    const payload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page:1,
        limit:1000
      },
    };
    const searchPayload = {
      body: {
        ...filters,
      },
      params: {
        userId,
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        page:1,
        limit:1000,
        searchTerm: searchedText,
        entityTypeId: ENTITY_TYPES.PLACES,

      },
    };
    if (searchedText===""){
      setLoading(true);
      searchPlaces(payload)
        .then((res) => {
          setSearchResults(
            res?.data?.data?.data?.map((el) => {
              el.accurate_to_date = moment(el.accurate_to_date)
                .utc()
                .format(DEFAULT_DATE_FORMAT);
              return el;
            })
          );
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if(searchedText!==""){
      setLoading(true);
      entityDataSearch(searchPayload)
      .then((res) => {
        console.log("Searched Text res", res);
        setSearchResults(res?.data?.data?.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  };
  const onApplyFilters = () => {
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    setSearchedFilters(form.getFieldsValue());
  };
  const onResetFields = (fields) => {
    form.resetFields(fields);
    shouldFetchResults.current = true;
    setIsBeginingSearch(false);
    const fieldValues = form.getFieldsValue();
    fields.forEach((field) => {
      fieldValues[field] = "";
    });
    setSearchedFilters(fieldValues);
  };

  const filterFields = [
    <FilterFieldsContainer
      label="Source"
      onClear={() => {
        onResetFields(["source"]);
      }}
    >
      <FilterDropdown
        name="source"
        placeholder="Enter source name here…"
        options={filterOptions.sources}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.DOWN_ARROW}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
    <FilterFieldsContainer
      label="Place ID"
      onClear={() => {
        onResetFields(["revcloudId"]);
      }}
    >
      <FilterDropdown
        name="revcloudId"
        selectMode="tags"
        placeholder="Place ID…"
        options={filterOptions.revcloudId}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.ID}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,

    <FilterFieldsContainer
      label="Address"
      onClear={() => {
        onResetFields(["state", "city", "zip"]);
      }}
    >
      <FilterDropdown
        name="state"
        selectMode="tags"
        placeholder="State"
        options={filterOptions.state}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.STATE}
        onChange={onApplyFilters}
      />
      <FilterDropdown
        name="city"
        selectMode="tags"
        placeholder="City"
        options={filterOptions.city}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.CITY}
        onChange={onApplyFilters}
      />
      <FilterDropdown
        name="zip"
        selectMode="tags"
        placeholder="Zip"
        options={filterOptions.zip}
        suffixIcon={FILTER_DROPDOWN_SUFFIX.ZIP}
        onChange={onApplyFilters}
      />
    </FilterFieldsContainer>,
  ];

  return (
    <div className="placesSearch">
      {/* <div className="placesSearch__saveContainer">
        <Button className="placesSearch__saveContainer__button">
          Save Search
        </Button>
      </div> */}
      <Searchbar
        placeholder="Search..."
        filtersVisible={showFilters}
        onFiltersVisibilityChange={setShowFilters}
        onSubmitSearchText={(searchedText) => {
          shouldFetchResults.current = true;
          setIsBeginingSearch(false);
          setSearchedText(searchedText);
        }}
        filtersApplied={
          !isEmpty(
            Object.values(searchedFilters).filter((value) => {
              return !isEmpty(value);
            })
          )
        }
      />
      <div className="placesSearch__filterAndResults">
        <>
          {showFilters && (
            <div className="placesSearch__filterAndResults__filters">
              <Form form={form}>
                <SideFilters
                  filterFields={filterFields}
                  onClose={() => {
                    setShowFilters(false);
                  }}
                  onReset={() => {
                    form.resetFields();
                    shouldFetchResults.current = true;
                    setIsBeginingSearch(false);
                    setSearchedFilters({});
                  }}
                />
              </Form>
            </div>
          )}
          {!isBeginingSearch && !resizingResults && (
            <div className="placesSearch__filterAndResults__results">
              <DataTable
                entityTypeId={ENTITY_TYPES.PLACES}
                loading={loading}
                columns={placesColumns}
                dataSource={searchResults}
                detailsKey="address"
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
}
export default PlacesSearch;
