import {
  Button,
  Checkbox,
  Form,
  Image,
  List,
  message,
  Select,
  Spin,
} from "antd";
import { createSource, getMappingForAdditonalFields } from "api/FileOnboard";
import {
  ENTITY_NAMES,
  ENTITY_TYPE_COLUMNS,
  FON_FIELD_IDENTIFIER,
  ENTITY_TYPES,
  ENTITY_EVENT_ID,
} from "../../../../../../../../constants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentProcessingFile,
  setCurrentSourceInfo,
} from "store/modules/fileOnboard/action";
import "./index.scss";
import IconDownArrow from "../../../../../../../../images/filter-chevron-dropdown.svg";

const { Option } = Select;
const USER_DEFINED_FIELD = "User Defined Field";

/*
- File onboarding flow component used to render Additional Required Fields step
- onContinue will be invoked when all of the requried fields are filled
- Error handling and redux dispatch is hanlded within the scope of this component
- It will alo create the new source based on the Selected Entity Type
*/
function AdditionalRequiredFields({ onContinue }) {
  const [error, setError] = useState("");
  const currentSourceInfo = useSelector((state) => state.fileOnboardReducer).currentSourceInfo;
  const currentProcessingFile = useSelector((state) => state.fileOnboardReducer).currentProcessingFile;
  const dispatch = useDispatch();
  const { accountId, userId } = useSelector((state) => state.authReducer).userSession;
  const [loading, setLoading] = useState(false);
  const [_, setRefresh] = useState(0);
  const [form] = Form.useForm();
  const [loadingMapping, setLoadingMapping] = useState(true);
  const [additionalFieldsMapping, setAdditionalFieldsMapping] = useState({});

  const columnsMappedForSystemFields = Object.values(
    currentSourceInfo.systemFields
  );
  const additionalColumns = currentProcessingFile.schema.columns.filter(
    (column) => {
      return !columnsMappedForSystemFields.includes(column);
    }
  );

  useEffect(() => {
    const payloadColumns = additionalColumns.map((payloadColumn) => {
      return payloadColumn.replace(/'/g, "''");
    });
    const payload = {
      columns: `'${payloadColumns.join("','")}'`,
    };
    setLoadingMapping(true);
    getMappingForAdditonalFields(payload)
      .then((res) => {
        const dictionaryArray = res.data.data.response;
        const dictionary = {};
        dictionaryArray.forEach((item) => {
          dictionary[item.key] = item.value;
        });
        setAdditionalFieldsMapping(dictionary);
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setLoadingMapping(false);
      });
  }, []);

  const createSelectedEntityTypeSource = (values) => {
    const requiredFileds = [];
    const nonOptionalFields = [];
    const systemRequiredColumns =
      ENTITY_TYPE_COLUMNS[currentSourceInfo.entity_type];
    systemRequiredColumns.forEach((item) => {
      const isRequiredField = currentSourceInfo.systemFields[item.column_name]
        ? true
        : false;
      requiredFileds.push({
        [item.column_name]: isRequiredField,
        field_identifier: FON_FIELD_IDENTIFIER.SYSTEM,
      });
      if (isRequiredField) {
        nonOptionalFields.push(item.column_name);
      }
    });
    const updatedSystemFields = { ...currentSourceInfo.systemFields };
    additionalColumns.forEach((item) => {
      const isRequiredField = values[item] ? true : false;
      requiredFileds.push({
        [item]: isRequiredField,
        field_identifier: FON_FIELD_IDENTIFIER.SOURCE,
      });
      if (isRequiredField) {
        nonOptionalFields.push(values[`${item}_mapping`]);
        updatedSystemFields[values[`${item}_mapping`]] = item;
      }
    });

    const rawTableName = String(currentSourceInfo.source_name)
      .concat(` ${ENTITY_NAMES[currentSourceInfo.entity_type]}`)
      .toLowerCase()
      .replace(/ /g, "_");

    const payload = {
      entity_event_id:ENTITY_EVENT_ID.EMAIL,
      entity_type_id: currentSourceInfo.entity_type,
      source_name: currentSourceInfo.source_name,
      raw_table: rawTableName,
      user_id: userId,
      account_id: accountId,
      active: true,
      schema: {
        columns: nonOptionalFields,
      },
      required_fields: requiredFileds,
      file_path: [currentProcessingFile?.s3_url],
    };
    if (currentSourceInfo.entity_type === ENTITY_TYPES.EVENTS) {
      payload.entity_event_id = ENTITY_EVENT_ID.EMAIL;
      payload.event_action_id = currentSourceInfo.systemFields.event_action_id;
      payload.campaign_id = currentSourceInfo.systemFields.campaign_id;
    }
    dispatch(
      setCurrentSourceInfo({ ...currentSourceInfo, additionalFields: values })
    );
    setLoading(true);
    createSource(payload)
      .then((res) => {
        dispatch(
          setCurrentProcessingFile({
            ...currentProcessingFile,
            matchedSource: res?.data?.data.response[0],
            matchedFields: {
              ...currentSourceInfo.systemFields,
              ...updatedSystemFields,
            },
          })
        );
        onContinue();
        createPlaceTypeSourceIfNeeded(values);
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const createPlaceTypeSourceIfNeeded = (values) => {
    if (currentSourceInfo.entity_type !== ENTITY_TYPES.PEOPLE) {
      return; //Do no proceed further
    }
    const { address, city, state, zip } = currentSourceInfo.systemFields;
    const areFilledAllAddressFields =
      !isEmpty(address) && !isEmpty(city) && !isEmpty(state) && !isEmpty(zip);
    if (!areFilledAllAddressFields) {
      return; //Do no proceed further
    }
    const entityType = ENTITY_TYPES.PLACES;
    const requiredFileds = [];
    const nonOptionalFields = [];
    const systemRequiredColumns = ENTITY_TYPE_COLUMNS[entityType];
    systemRequiredColumns.forEach((item) => {
      const isRequiredField = !isEmpty(
        currentSourceInfo.systemFields[item.column_name]
      );
      requiredFileds.push({
        [item.column_name]: isRequiredField,
        field_identifier: FON_FIELD_IDENTIFIER.SYSTEM,
      });
      if (isRequiredField) {
        nonOptionalFields.push(item.column_name);
      }
    });
    additionalColumns.forEach((item) => {
      const isRequiredField = values[item] ? true : false;
      requiredFileds.push({
        [item]: isRequiredField,
        field_identifier: FON_FIELD_IDENTIFIER.SOURCE,
      });
      if (isRequiredField) {
        nonOptionalFields.push(item);
      }
    });

    const rawTableName = String(currentSourceInfo.source_name)
      .concat(` ${ENTITY_NAMES[entityType]}`)
      .toLowerCase()
      .replace(/ /g, "_");

    const payload = {
      entity_event_id:ENTITY_EVENT_ID.EMAIL,
      entity_type_id: entityType,
      source_name: currentSourceInfo.source_name,
      raw_table: rawTableName,
      user_id: userId,
      account_id: accountId,
      active: true,
      schema: {
        columns: nonOptionalFields,
      },
      required_fields: requiredFileds,
      file_path: [currentProcessingFile?.s3_url],
    };
    createSource(payload)
    .then(() => { })
    .catch(() => {});
  };

  const onFinish = (values) => {
    createSelectedEntityTypeSource(values);
  };

  const getInitialValues = () => {
    const initialValues = currentSourceInfo?.additionalFields || {};
    additionalColumns.forEach((column) => {
      if (!isEmpty(additionalFieldsMapping[column])) {
        initialValues[`${column}_mapping`] = additionalFieldsMapping[column][0];
      } else {
        initialValues[`${column}_mapping`] = USER_DEFINED_FIELD;
      }
    });
    return initialValues;
  };

  if (loadingMapping) {
    return <Spin spinning className="additionalRequiredFields__loading"></Spin>;
  }

  return (
    <div className="additionalRequiredFields">
      <p className="additionalRequiredFields__heading">
        Fields that You Require (Optional)
      </p>
      <p className="additionalRequiredFields__subHeading">
        Please match your fields to our system fields or tell us to ignore them
        if you do not want these fields to be contributed and updated by the
        knowledge graph. For example, a CRM lead ID is a “user field” that is
        unique to your data and should not be updated whereas “Age”, “Job Title”
        or “email” could be updated and should be identified as a system field.
      </p>
      <div className="additionalRequiredFields__row additionalRequiredFields__row--header">
        <p className="additionalRequiredFields__row__left">Your Fields</p>
        <p className="additionalRequiredFields__row__center">
          Make Required Field
        </p>
        <div className="additionalRequiredFields__row__right">
          <span className="additionalRequiredFields__row__right__label">
            Data Dictionary Fields
          </span>
        </div>
      </div>

      <Form
        onFinish={onFinish}
        onFieldsChange={(fields) => {
          fields.forEach((field) => {
            const checkboxName = field.name[0].replace("_mapping", "");
            if (field.value === USER_DEFINED_FIELD) {
              form.resetFields([checkboxName]);
            }
          });
          setRefresh(Math.random());
          setError("");
        }}
        form={form}
        initialValues={getInitialValues()}
      >
        <List
          className="additionalRequiredFields__list"
          dataSource={additionalColumns}
          itemLayout="vertical"
          locale={{
            emptyText:
              "No additional fields found in your file. You can continue.",
          }}
          renderItem={(item, index) => {
            return (
              <div key={index} className="additionalRequiredFields__row">
                <p className="additionalRequiredFields__row__left">{item}</p>
                <p className="additionalRequiredFields__row__center">
                  <Form.Item name={`${item}`} valuePropName="checked">
                    <Checkbox
                      disabled={
                        form.getFieldValue([`${item}_mapping`]) ===
                        USER_DEFINED_FIELD
                      }
                    />
                  </Form.Item>
                </p>

                <div className="additionalRequiredFields__row__right">
                  <Form.Item name={`${item}_mapping`}>
                    <Select
                      placeholder="Select column"
                      showSearch
                      filterOption="children"
                      defaultActiveFirstOption
                      suffixIcon={<Image src={IconDownArrow} preview={false} />}
                    >
                      <Option
                        value={USER_DEFINED_FIELD}
                        key={USER_DEFINED_FIELD}
                      >
                        {USER_DEFINED_FIELD}
                      </Option>
                      {(additionalFieldsMapping[item] || []).map((option) => {
                        return (
                          <Option value={option} key={option}>
                            {option}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            );
          }}
        />

        <div className="additionalRequiredFields__footer">
          <span className="additionalRequiredFields__footer__error">
            {error}
          </span>

          <Button
            loading={loading}
            htmlType="submit"
            className="additionalRequiredFields__footer__continueButton"
          >
            Continue
          </Button>
        </div>
      </Form>
    </div>
  );
}
export default AdditionalRequiredFields;
