import React, { useState } from "react";
import { trackEventCall, trackPageCall } from "analytics";
import { Avatar, Button, Carousel, Divider, Rate, Image } from "antd";
import { isEmpty } from "lodash";
import { PlaybookDummyImage } from "images";
import "./index.scss";

function PlaybookDetailsPreview() {
  const [current, setCurrent] = useState("desktop");

  const handleMobileClick = (event) => {
    setCurrent("mobile");
  };
  const handleDesktopClick = (event) => {
    setCurrent("desktop");
  };

  return (
    <>
      <div
        className={
          current === "desktop"
            ? "PlaybookDetailsPreview"
            : "PlaybookDetailsPreviewMobile"
        }
      >
        <div>
          <div className="PlaybookDetailsPreview__centerAlign PlaybookDetailsPreview__image">
            <CustomAvatar />
          </div>

          <span className="PlaybookDetailsPreview__centerAlign PlaybookDetailsPreview__playbookTitle">
            Cash Offer For Your Home
          </span>
          <span className="PlaybookDetailsPreview__centerAlign PlaybookDetailsPreview__playbookDes">
            We offer to buy your home and we want to give you a price that
            you'll be happy with.
          </span>
          <span className="PlaybookDetailsPreview__centerAlign PlaybookDetailsPreview__welcomeText ">
            Hi, Kristy!
            <br />
            My name is Kimbell.
            <br />
            Let me tell you about my playbook.
          </span>

          <div className="PlaybookDetailsPreview__centerAlign">
            <Button className="PlaybookDetailsPreview__positiveBtn">
              Continue
            </Button>
          </div>

          <div>
            <Carousel
              className="PlaybookDetailsPreview__Carousel PlaybookDetailsPreview__centerAlign"
              style={{ width: "100%" }}
              autoplay
            >
              <Image
                className="PlaybookDetailsPreview__IMG1"
                alt="Image of playbook"
                preview={false}
                src={PlaybookDummyImage}
              />
            </Carousel>
          </div>
          <p className="PlaybookDetailsPreview__aboutHeading">Highlights</p>

          <div style={{ flexDirection: "row" }}>
            <span style={{ fontSize: 18 }}>{"\u2022"}</span>
            <span className="PlaybookDetailsPreview__highLights__text">
              Find mortgages you can refi.
            </span>
          </div>

          <div style={{ flexDirection: "row" }}>
            <span style={{ fontSize: 18 }}>{"\u2022"}</span>
            <span className="PlaybookDetailsPreview__highLights__text">
              Analyze markets and lender risk.
            </span>
          </div>

          <div style={{ flexDirection: "row" }}>
            <span style={{ fontSize: 18 }}>{"\u2022"}</span>
            <span className="PlaybookDetailsPreview__highLights__text">
              Build your own risk model.
            </span>
          </div>

          <div style={{ flexDirection: "row" }}>
            <span style={{ fontSize: 18 }}>{"\u2022"}</span>
            <span className="PlaybookDetailsPreview__highLights__text">
              Find seller financed mortgages.
            </span>
          </div>

          <p className="PlaybookDetailsPreview__aboutHeading">About</p>
          <p className="PlaybookDetailsPreview__description">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from 45 BC,
            making it over 2000 years old. Richard McClintock, a Latin professor
            at Hampden-Sydney College in Virginia, looked up one of the more
            obscure Latin words, consectetur, from a Lorem Ipsum passage, and
            going through the cites of the word in classical literature,
            discovered the undoubtable source. Lorem Ipsum comes from sections
            1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes
            of Good and Evil) by Cicero, written in 45 BC. This book is a
            treatise on the theory of ethics, very popular during the
            Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
            amet..", comes from a line in section 1.10.32.
          </p>

          <p className="PlaybookDetailsPreview__reviewsInfo">
            <Rate disabled defaultValue={1} count={1} />{" "}
            <span className="PlaybookDetailsPreview__reviewsInfo__count">
              23
            </span>
          </p>

          <Divider className="PlaybookDetailsPreview__divider" />

          <div className="PlaybookDetailsPreview__languageCategory">
            <p className="PlaybookDetailsPreview__languageCategory__heading">
              Language
            </p>
            <p className="PlaybookDetailsPreview__languageCategory__heading">
              Category
            </p>
            <p className="PlaybookDetailsPreview__languageCategory__heading">
              Location
            </p>
          </div>

          <div className="PlaybookDetailsPreview__languageCategory">
            <p className="PlaybookDetailsPreview__languageCategory__value">
              English
            </p>
            <p className="PlaybookDetailsPreview__languageCategory__value">
              Real Estate
            </p>
            <p className="PlaybookDetailsPreview__languageCategory__value">
              New York
            </p>
          </div>

          <p className="PlaybookDetailsPreview__tagsHeading">Tags</p>
          <div className="PlaybookDetailsPreview__tagsdescription">
            <span className="PlaybookDetailsPreview__tagsdescription__tagsAndActions__left__eachPill">
              Tag-1
            </span>
            <span className="PlaybookDetailsPreview__tagsdescription__tagsAndActions__left__eachPill">
              Tag-2
            </span>
            <span className="PlaybookDetailsPreview__tagsdescription__tagsAndActions__left__eachPill">
              Tag-3
            </span>
            <span className="PlaybookDetailsPreview__tagsdescription__tagsAndActions__left__eachPill">
              Tag-4
            </span>
          </div>
        </div>
        <Button className="PlaybookDetailsPreview__Button PlaybookDetailsPreview__centerAlign">
          Requested Playbook
        </Button>
      </div>

      <div className="PlaybookDetailsPreviewViewbutton">
        <Button
          className="PlaybookDetailsPreviewViewbutton__DesktopButton"
          onClick={handleDesktopClick}
        >
          Desktop
        </Button>
        <Button
          className="PlaybookDetailsPreviewViewbutton__MobileButton"
          onClick={handleMobileClick}
        >
          Mobile
        </Button>
        <Button
          className={
            current === "desktop"
              ? "PlaybookDetailsPreview__Create"
              : "PlaybookDetailsPreviewMobile__Create"
          }
        >
          Create
        </Button>
      </div>
    </>
  );
}

export default PlaybookDetailsPreview;

function CustomAvatar({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="PlaybookDetailsPreview__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="PlaybookDetailsPreview__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
