import React, { useEffect, useState } from "react";
import { Alert, Table, Tag, Tabs, Spin, Button, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import Flex from "screens/Chat/Flex";
import { useSelector, useDispatch } from "react-redux";
import { getModelsList } from "api/Connectors";
import "./index.scss";
import { getModelDetails } from "store/modules/connector/action";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism'; // You can choose different styles

function ModelOverview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modelDetails = useSelector(
    (state) => state?.connectorReducer?.modelDetails
  );
  const loading = useSelector((state) => state?.connectorReducer?.loading);
  const [query, setQuery] = useState("");
  const { modelId, modelName } =
    useParams();
  const tableContainerStyle = {
    height: "calc(100vh - 230px)",
    overflow: "auto",
  };



  const columns = [
    {
      title: "NAME",
      dataIndex: "event_type",
      key: "name",
    },
    {
      title: "ACTION",
      dataIndex: "event_type",
      key: "name",
    },

    {
      title: "MAPPING",
      dataIndex: "last_execution_records",
      key: "synced",
    },
    {
      title: "LATEST SYNC",
      dataIndex: "last_execution_records",
      key: "synced",
    },
  ];


  const items = [
    { key: "1", label: "Overview" },
    { key: "2", label: "Query Builder" },
    { key: "3", label: "Settings" },
  ];

  const onChange = (activeKey) => {
    if (activeKey === "3") {
      navigate(
        `/dashboard/model/settings/${modelId}/${modelName}`
      );
    }
    if (activeKey === "2") {
      navigate(
        `/dashboard/model/querybuilder/${modelId}/${modelName}`
      );
    }
  };

  useEffect(() => {
    dispatch(getModelDetails(parseInt(modelId)));
  }, [dispatch, modelId]);

  return (
    <div className="ModelOverview">
      <div className="ModelOverview__headerText">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      {loading ? (
        <Flex
          flex={1}
          justify="center"
          align="center"
          style={{
            width: "100%",
            height: 400,
            backgroundColor: "#fff",
          }}
          direction="column"
        >
          <Spin size="default" />
          <div> Loading...</div>
        </Flex>
      ) : (
        <div>
          <div className="ModelOverview__query">Query
            <div className="ModelOverview__box"><p>
            <SyntaxHighlighter language="sql" style={materialLight} readOnly={true}>
          {modelDetails?.query}
        </SyntaxHighlighter>
            </p>
            </div>

          </div>
          <div className="ModelOverview__query">Mappings</div>
          <div
            className="ModelOverview__tableDiv"
            style={tableContainerStyle}
          >
            <div className="ModelOverview__parentButton">
              <Button className="ModelOverview__button">Add Mapping</Button>
            </div>
            <Table
              columns={columns}
              pagination={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ModelOverview;
