import "./index.scss";
import { MoreOutlined, CopyOutlined } from "@ant-design/icons";

import { Card, message, Image, Avatar } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Rate, Divider } from "antd";
import { trackEventCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { playbookActions } from "api/Playbooks";
import { PlaybookDummyImage } from "images";


function NewSingleProposal({
  itemData = {},
  removeItem,
  setListLoader,
  minPrice,
}) {
  const navigate = useNavigate();
  const userSession = useSelector((state) => state.authReducer).userSession;
  const isB2B = userSession?.selectedWorkspaceName == "Palisade Knowledge"
  const TAGS_TO_SH0W = 3;

  const [tooltipText, setTooltipText] = useState("");

  const handleVisibleChange = (newVisible) => {
    setActionVisibilty(newVisible);
  };
  const [actionVisibilty, setActionVisibilty] = useState(false);

  const onReadMoreClicked = (playbookId) => {
    navigate(`/dashboard/proposalDetails/${playbookId}`);
  };

  const createProposalChat = (playbookDetails) => {
    navigate(`/dashboard/proposalDetails/${playbookDetails?.id}`);
  };

  useEffect(() => {
    let remainingTags = "";
    if (itemData?.secondary_tag?.length > TAGS_TO_SH0W) {
      for (var i = TAGS_TO_SH0W; i < itemData?.secondary_tag?.length; i++) {
        if (i === itemData?.secondary_tag?.length - 1) {
          remainingTags = remainingTags + "" + itemData?.secondary_tag?.[i];
        } else {
          remainingTags =
            remainingTags + "" + itemData?.secondary_tag?.[i] + ",";
        }
      }
      setTooltipText(remainingTags);
    }
  });

  const callActionsAPI = (actionText) => {
    setActionVisibilty(false);
    setListLoader(true);

    const payload = {
      playbook_id: itemData.id,
      action: actionText,
      price: itemData.min_price,
      selected_package_id: "",
    };
    const segmentEventData = {
      playbookname: itemData?.name,
      sellername:
        itemData?.seller?.first_name + " " + itemData?.seller?.last_name,
      selleremail: itemData?.seller?.email,
      playbook_id: itemData?.id,
      price: itemData?.min_price,
      marketplaceId: itemData?.workspace_id,
    };
    if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED,
        segmentEventData
      );
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
        segmentEventData
      );
    }

    playbookActions(payload)
      .then((res) => {
        removeItem();
        if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
            segmentEventData
          );
          trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, segmentEventData);
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
            segmentEventData
          );
          createProposalChat(itemData);
        }
        message.success(res?.data?.message);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setListLoader(false);
      });
  };

  const actionDropDownContent = (
    <div className="popOverContainer">
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.REQUEST_PROPOSAL);
        }}
      >
        {PLAYBOOK_ACTIONS.REQUEST_PROPOSAL}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.REVIEWING);
        }}
      >
        {PLAYBOOK_ACTIONS.REVIEWING}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.HIGH_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.HIGH_PRIORITY}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.MEDIUM_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.MEDIUM_PRIORITY}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.LOW_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.LOW_PRIORITY}
      </p>

      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.NOT_INTRESTED);
        }}
      >
        {PLAYBOOK_ACTIONS.NOT_INTRESTED}
      </p>
    </div>
  );

  return (
    <div className="newSingleProposal">
      <Card hoverable>
        <Image
          onClick={() => onReadMoreClicked(itemData.id)}
          className="newSingleProposal__IMG1"
          alt="Image of playbook"
          preview={false}
          src={
            itemData?.thumbnail === null 
              ? (itemData?.image1_url && itemData?.image1_url !== '') 
                ? itemData?.image1_url
                : PlaybookDummyImage
              : itemData?.thumbnail
          }
        />
        <div className="newSingleProposal__appHomeButton">
          <CustomAvatarSinglePlaybook
            imageUri={itemData?.seller?.image_uri}
            name={itemData?.seller?.first_name}
          />
          <div className="newSingleProposal__appHomeHeadContainer">
            <div
              className="newSingleProposal__appHomeMainHead"
              onClick={() => onReadMoreClicked(itemData.id)}
            >
              {itemData?.seller?.first_name + " " + itemData?.seller?.last_name}
            </div>
            <div
              onClick={() => onReadMoreClicked(itemData.id)}
              className="newSingleProposal__appHomeSubHead"
            >
              {itemData?.seller?.work_position}
            </div>
          </div>
        </div>
        <h3
          onClick={() => onReadMoreClicked(itemData.id)}
          className="newSingleProposal__H1"
        >
          {itemData?.name}
        </h3>

      
        <div title={itemData?.short_description}>
         <p className="newSingleProposal__P1">{itemData?.short_description}</p>
        </div>

        {isB2B ? (<p className="newSingleProposal__proposalDetails__reviewsInfo">
          {/* <Rate disabled defaultValue={1} count={1} /> */}
          {"  "}
          {/* {itemData?.rating_avg !== null ? parseInt(itemData?.rating_avg)+'.0' : 0} */}
          {"  "}
          <span className="newSingleProposal__proposalDetails__reviewsInfo__count">
            {/* (
            {itemData?.rating_avg !== null
              ? parseInt(parseInt(itemData?.rating_avg) * 1.7)
              : 0}
            K) */}
          </span>{" "}
        </p>) : (  <p className="newSingleProposal__proposalDetails__reviewsInfo">
          <Rate disabled defaultValue={1} count={1} />
          {"  "}
          {itemData?.rating_avg !== null ? parseInt(itemData?.rating_avg)+'.0' : 0}
          {"  "}
          <span className="newSingleProposal__proposalDetails__reviewsInfo__count">
            (
            {itemData?.rating_avg !== null
              ? parseInt(parseInt(itemData?.rating_avg) * 1.7)
              : 0}
            K)
          </span>{" "}
        </p>)}
      
        <Divider className="newSingleProposal__divider" />

        <div className="newSingleProposal__P2">
          {itemData?.isProposal ? (
            <p className="newSingleProposal__P2__Read">
              <CopyOutlined className="newSingleProposal__P2__Icon" />
              Requested Playbook
            </p>
          ) : (
            <div
            className="newSingleProposal__P2__Low"
            onClick={() => onReadMoreClicked(itemData.id)}
          ><p className="newSingleProposal__P2__Low">Continue</p></div>
            
          )}
        </div>
      </Card>
    </div>
  );
}

export default NewSingleProposal;

function CustomAvatarSinglePlaybook({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="newSingleProposal__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="newSingleProposal__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
