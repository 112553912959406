import { Button, Form, Input, message, Modal } from "antd";
import { checkIfPhoneExists } from "api/userManagement";
import axios from "axios";
import { Buffer } from "buffer";
import {
  TWILIO_PASSWORD,
  TWILIO_SMS_SID,
  TWILIO_USER_NAME,
} from "environments";
import { useState } from "react";
import { emitCustomEvent } from "react-custom-events";
import PhoneInput from "react-phone-input-2";
import "./index.scss";

function VerifyPhonePopup(props) {
  const [loading, setLoading] = useState(false);
  const [phoneCodeSent, setPhoneCodeSent] = useState(false);

  const sendVerificationCode = (values) => {
    const { phonenumber, confirmationCode } = values;
    let prefix = "+";
    const phoneNum = prefix.concat(phonenumber);
    if (confirmationCode === null || confirmationCode === undefined) {
      if (phoneNum === props.currentPhoneNumber) {
        message.error(
          "This phone number is already registered, please try with some other number."
        );
      } else {
        sendCodeToPhone(phoneNum);
      }
    } else {
      verifyPhoneCode(phoneNum, confirmationCode);
    }
  };

  const sendCodeToPhone = (phoneNumber) => {
    const username = TWILIO_USER_NAME;
    const password = TWILIO_PASSWORD;
    const sms_sid = TWILIO_SMS_SID;

    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    axios
      .post(
        `https://verify.twilio.com/v2/Services/${sms_sid}/Verifications`,
        new URLSearchParams({
          To: phoneNumber,
          Channel: "sms",
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setPhoneCodeSent(true);
        message.success("A verification code is sent to your phone number.");
      })
      .catch((err) => {
        setPhoneCodeSent(false);
      });
  };

  const verifyPhoneCode = (phoneNumber, cCode) => {
    setLoading(true);
    const username = TWILIO_USER_NAME;
    const password = TWILIO_PASSWORD;
    const sms_sid = TWILIO_SMS_SID;
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    axios
      .post(
        `https://verify.twilio.com/v2/Services/${sms_sid}/VerificationCheck`,
        new URLSearchParams({
          To: phoneNumber,
          Code: cCode,
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (res.data.valid) {
          emitCustomEvent("user-phone-updated", phoneNumber);
          props.onCancel();
        } else {
          setLoading(false);
          message.error("Please enter a valid code.");
        }
      })
      .catch((err) => {
        message.error(
          "The code you have entered is either invalid or expired."
        );
        setLoading(false);
      });
  };

  const handleCancelChanges = () => {
    props.onCancel();
  };

  return (
    <Modal
      visible={props.visible}
      title=""
      onCancel={props.onCancel}
      footer={null}
      bodyStyle={{ padding: 0 }}
      centered
      width={400}
      height={400}
      wrapClassName="verifyPhone"
    >
      <div className="verifyPhone__mainContainer">
        <div className="verifyPhone__header">
          {/* <img src={ChevronLeftIcon} style={{ marginRight: 16 }} /> */}
          <span className="verifyPhone__headerTitle">Verify Phone Number</span>
        </div>
        <Form
          layout="vertical"
          onFinish={sendVerificationCode}
          className="verifyPhone__formDiv"
        >
          <Form.Item
            label="New Phone Number"
            name="phonenumber"
            required
            rules={[
              {
                required: true,
                message: "Please input your phone number.",
              },
            ]}
            hasFeedback
          >
            <PhoneInput
              className="verifyPhone__inputField"
              country={"us"}
              prefix="+ "
            />
          </Form.Item>
          <Button
            className="verifyPhone__linkButton"
            type="link"
            htmlType="submit"
          >
            {phoneCodeSent ? "ReSend Code" : "Send Code"}
          </Button>

          {phoneCodeSent && (
            <div className="verifyPhone__codeInputDiv">
              <Form.Item
                name="confirmationCode"
                label="Enter the code sent to your phone"
                rules={[
                  {
                    required: false,
                    message: "Please input confirmation code.",
                  },
                ]}
              >
                <Input
                  disabled={!phoneCodeSent}
                  placeholder="Confirmation code"
                  className="verifyPhone__codeInputField"
                ></Input>
              </Form.Item>
            </div>
          )}
          {phoneCodeSent && (
            <div className="verifyPhone__buttonsContainer">
              <Button
                className="verifyPhone__cancelButton"
                type="primary"
                onClick={handleCancelChanges}
              >
                Cancel
              </Button>
              <Button
                disabled={!phoneCodeSent}
                className="verifyPhone__saveChangesButton"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Save
              </Button>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
}
export default VerifyPhonePopup;
