export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID;
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_ORIGIN = process.env.REACT_APP_APP_ORIGIN;
export const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const TWILIO_USER_NAME = process.env.REACT_APP_TWILIO_USER_NAME;
export const TWILIO_PASSWORD = process.env.REACT_APP_TWILIO_PASSWORD;
export const TWILIO_SMS_SID = process.env.REACT_APP_TWILIO_SMS_SID;
