import { divide } from 'lodash'
import './index.scss'
import { IconRevcloud, Loadingbegin, Facebookads } from 'images';
import { useNavigate, useParams } from 'react-router-dom';

const SQLSchemaName = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const navigateToFields = () => {
      navigate(`/dashboard/sources/setup/facebookAds/create/screen4`);
    };
    return (
        <div>
            <div className='SQLSchemaName__component'>
                <h1 className='SQLSchemaName__mainHeading'>SQL Schema Name</h1>
                <div className='SelectFacebookAccount__iconsDiv'>
                    <div className='SelectFacebookAccount__icons'>
                        <img src={IconRevcloud} className='SelectFacebookAccount__img1' alt="IconRevcloud" />
                        <img src={Loadingbegin} className='SelectFacebookAccount__img2' alt='Loading...' />
                        <img src={Facebookads} className='SelectFacebookAccount__img3' alt="Facebookads" />
                    </div></div>
                <div className="SQLSchemaName__div" >
                    <div >
                        <h6 className="SQLSchemaName__heading">Schema Name *</h6>
                        <input
                            className="SQLSchemaName__input"
                            placeholder="Enter Schema Name"
                        />
                        <p className="SQLSchemaName__para">This will be the schema name used in any connected warehouses.</p>
                    </div>
                    <div >
                        <h6 className="SQLSchemaName__heading"></h6>
                        <input
                            className="SQLSchemaName__input"
                            placeholder="Add Query"
                        />
                        <div className='SQLSchemaName__buttonsDiv'>
                            <button onClick={()=>navigateToFields()} className='SQLSchemaName__button'>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SQLSchemaName
