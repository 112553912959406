// action.js
export const SET_LOADING = "SET_LOADING";
export const GET_CONNECTOR_OVERVIEW_LIST = "GET_CONNECTOR_OVERVIEW_LIST";
export const SET_CONNECTOR_OVERVIEW_LIST = "SET_CONNECTOR_OVERVIEW_LIST";
export const GET_REQUIRED_FIELDS = "GET_REQUIRED_FIELDS";
export const SET_REQUIRED_FIELDS = "SET_REQUIRED_FIELDS";
export const GET_CATALOGS_LIST = "GET_CATALOGS_LIST";
export const SET_CATALOGS_LIST = "SET_CATALOGS_LIST";
export const GET_MODEL_DETAILS = "GET_MODEL_DETAILS";
export const SET_MODEL_DETAILS = "SET_MODEL_DETAILS";

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const getConnectorOverviewList = (data) => ({
  type: GET_CONNECTOR_OVERVIEW_LIST,
  payload: data,
});

export const setConnectorOverviewList = (data) => ({
  type: SET_CONNECTOR_OVERVIEW_LIST,
  payload: data,
});

export const setRequiredFields = (data) => ({
  type: SET_REQUIRED_FIELDS,
  payload: data,
});

export const getRequiredFields = (data) => ({
  type: GET_REQUIRED_FIELDS,
  payload: data,
});

export const setCatalogsList = (data) => ({
  type: SET_CATALOGS_LIST,
  payload: data,
});

export const getCatalogsList = (data) => ({
  type: GET_CATALOGS_LIST,
  payload: data,
});

export const setModelDetails = (data) => ({
  type: SET_MODEL_DETAILS,
  payload: data,
});

export const getModelDetails = (data) => ({
  type: GET_MODEL_DETAILS,
  payload: data,
});
