import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { setExternalInviteInfo } from "store/modules/auth/action";

function DeeplinkInvite() {
  const { marketplaceid, email, role } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(
  //   `DeeplinkInvite MarketplaceId: ${marketplaceid} email: ${email} role ${role}`
  // );

  useEffect(() => {
    dispatch(
      setExternalInviteInfo({
        marketplaceId: marketplaceid,
        userEmail: email,
        userRole: role,
      })
    );
    navigate(APP_ROUTES.LANDING.path);
  }, []);

  return <div>Fetching Information...</div>;
}

export default DeeplinkInvite;
