import "./index.scss";
import { MoreOutlined, CopyOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { Image, message, Popover, Avatar } from "antd";
import { PlaybookRequested } from "images";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Rate, Divider } from "antd";
import { trackEventCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

import { playbookActions } from "api/Playbooks";

function MyMobileNewSinglePlaybook({ itemData = {} }) {
  //   let data = singlePlaybookDetail;
  const navigate = useNavigate();

  // import from sellerDetails
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  //

  const { buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  const handleVisibleChange = (newVisible) => {
    setActionVisibilty(newVisible);
  };
  const [actionVisibilty, setActionVisibilty] = useState(false);

  const onReadMoreClicked = (playbookId) => {
    navigate(`/dashboard/playbookDetails/${playbookId}`);
  };
  const createProposalChat = (playbookDetails) => {
    navigate(`/dashboard/proposalDetails/${playbookDetails?.id}`);
  };

  const callActionsAPI = (actionText) => {
    setActionVisibilty(false);

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      playbook_id: itemData.id,
      action: actionText,
      price: itemData.min_price,
      selected_package_id: 0,
    };
    const segmentEventData = {
      playbookname: itemData?.name,
      sellername:
        itemData?.seller?.first_name + " " + itemData?.seller?.last_name,
      selleremail: itemData?.seller?.email,
      playbook_id: itemData?.id,
      price: itemData?.min_price,
      marketplaceId: itemData?.workspace_id,
    };
    if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED,
        segmentEventData
      );
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
        segmentEventData
      );
    }
    console.log(payload);

    playbookActions(payload)
      .then((res) => {
        if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
            segmentEventData
          );
          trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, segmentEventData);
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
            segmentEventData
          );
          createProposalChat(itemData);
        }
        message.success(res?.data?.message);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {});
  };

  return (
    <div className="mymobilenewsinglePlaybook">
      <div className="mymobilenewsinglePlaybook__Row" hoverable>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="mymobilenewsinglePlaybook__Col" span={9}>
            <Image
              onClick={() => onReadMoreClicked(itemData.id)}
              className="mymobilenewsinglePlaybook__IMG1"
              alt="Image of playbook"
              src={itemData?.image1_url}
            />
          </Col>
          <Col className="mymobilenewsinglePlaybook__Col" span={15}>
            <div>
              <h3
                onClick={() => onReadMoreClicked(itemData.id)}
                className="mymobilenewsinglePlaybook__H1"
              >
                {itemData?.name}
              </h3>
              <p className="mymobilenewsinglePlaybook__P1">
                {itemData?.short_description}
              </p>
            </div>
            <div>
              <p className="mymobilenewsinglePlaybook__proposalDetails__reviewsInfo">
                <Rate disabled defaultValue={1} count={1} />
                {"  "}
                {itemData?.rating_avg !== null ? itemData?.rating_avg : 0}
                {"  "}
                <span className="mymobilenewsinglePlaybook__proposalDetails__reviewsInfo__count">
                  (
                  {itemData?.rating_avg !== null
                    ? parseInt(parseInt(itemData?.rating_avg) * 1.7)
                    : 0}
                  K)
                </span>{" "}
              </p>
            </div>
          </Col>
        </Row>

        <Row
          className="mymobilenewsinglePlaybook__Row-2"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={14}>
            <div className="mymobilenewsinglePlaybook__appHomeButton">
              <CustomAvatarSinglePlaybook
                imageUri={itemData?.seller?.image_uri}
                name={itemData?.seller?.first_name}
              />
              <div className="mymobilenewsinglePlaybook__appHomeHeadContainer">
                <div
                  className="mymobilenewsinglePlaybook__appHomeMainHead"
                  onClick={() => onReadMoreClicked(itemData.id)}
                >
                  {itemData?.seller?.first_name +
                    " " +
                    itemData?.seller?.last_name}
                </div>
                <div
                  onClick={() => onReadMoreClicked(itemData.id)}
                  className="mymobilenewsinglePlaybook__appHomeSubHead"
                >
                  Front-end Developer asdsd asdsadas adasdsa asdasd
                </div>
              </div>
            </div>
          </Col>

          <Col span={10}>
            <div className="mymobilenewsinglePlaybook__P2">
              {itemData?.isProposal ? (
                <p className="mymobilenewsinglePlaybook__P2__Read">
                  <img src={PlaybookRequested} style={{ marginRight: "4px" }} />{" "}
                  Requested
                </p>
              ) : (
                <p className="mymobilenewsinglePlaybook__P2__Low">
                  <div className="mymobilenewsinglePlaybook__P2__Low__As">
                    AS LOW AS
                  </div>

                  <div className="mymobilenewsinglePlaybook__P2__Low__Price">
                    USD {parseInt(itemData?.min_price)}
                  </div>
                </p>
              )}
            </div>
          </Col>
        </Row>

        <Divider className="mymobilenewsinglePlaybook__divider" />
      </div>
    </div>
  );
}

export default MyMobileNewSinglePlaybook;

function CustomAvatarSinglePlaybook({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="mymobilenewsinglePlaybook__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="mymobilenewsinglePlaybook__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
