import { all } from "redux-saga/effects";
import { authSaga } from "./modules/auth/saga";
import { connectorSaga } from "./modules/connector/saga"; // Adjust this import path to where your new saga is

function* rootSaga() {
  yield all([
    authSaga(),
    connectorSaga(), // Add your new saga here
  ]);
}

export default rootSaga;
