import React, { useState } from "react";
import {
  Form,
  Select,
  Table,
  Alert,
  Tabs,
  Input,
  Switch,
  Button,
  Row,
  Col,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import "./index.scss";

const { Option } = Select;

const CSVMappings = () => {
  const { sourceId, sourceName, connectionName, connectorId, active } =
    useParams();
  const navigate = useNavigate();
  const items = [
    connectorId == 10
      ? { key: "1", label: "Model" }
      : { key: "1", label: "Overview" },
    { key: "2", label: "Mapping" },
    { key: "3", label: "Tasks" },
    { key: "4", label: "Settings" },
  ];
  const onUpdate = () => {
    // Update logic here
    console.log("Update button clicked");
  };

  const onChange = (activeKey) => {
    const pathPrefix =
      sourceName === "Revcloud API Events"
        ? "/dashboard/datasets/apiconnector/overview"
        : "/dashboard/datasets/connector/overview";
    navigate(
      `${pathPrefix}/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
    );
    if (activeKey === "2") {
      navigate(
        `/dashboard/datasets/sources/mapping/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "3") {
      navigate(
        `/dashboard/datasets/sources/tasks/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
    if (activeKey === "4") {
      navigate(
        `/dashboard/datasets/settings/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
      );
    }
  };

  const dataSource = [
    {
      key: "1",
      sourceField: "Email Address",
      matchedField: (
        <Select defaultValue="Email" style={{ width: "100%" }}>
          <Option value="Email">Email</Option>
          <Option value="None">None</Option>
        </Select>
      ),
      description: <Input value="e.g. jondoe@gmail.com" disabled />,
      identityField: <Switch defaultChecked />,
    },
    {
      key: "2",
      sourceField: "Phone Number",
      matchedField: (
        <Select defaultValue="Phone" style={{ width: "100%" }}>
          <Option value="Phone">Phone</Option>
          <Option value="None">None</Option>
        </Select>
      ),
      description: <Input value="e.g. +123 456 789" disabled />,
      identityField: <Switch />,
    },
    {
      key: "3",
      sourceField: "Name",
      matchedField: (
        <Select defaultValue="Name" style={{ width: "100%" }}>
          <Option value="Name">Name</Option>
          <Option value="None">None</Option>
        </Select>
      ),
      description: <Input value="e.g. Jon Doe" disabled />,
      identityField: <Switch defaultChecked />,
    },
    {
      key: "4",
      sourceField: "Full Address",
      matchedField: (
        <Select defaultValue="Address" style={{ width: "100%" }}>
          <Option value="Address">Address</Option>
          <Option value="None">None</Option>
        </Select>
      ),
      description: <Input value="e.g. 3543 Guido St, LA" disabled />,
      identityField: <Switch defaultChecked />,
    },
    {
      key: "5",
      sourceField: "Campaign ID",
      matchedField: (
        <Select defaultValue="Campaign ID" style={{ width: "100%" }}>
          <Option value="Campaign ID">Campaign ID</Option>
          <Option value="None">None</Option>
        </Select>
      ),
      description: <Input value="e.g. #128465" disabled />,
      identityField: <Switch defaultChecked />,
    },
  ];

  const columns = [
    {
      title: "Source Field",
      dataIndex: "sourceField",
      key: "sourceField",
    },
    {
      title: "Matched Fields",
      dataIndex: "matchedField",
      key: "matchedField",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Identity Fields",
      dataIndex: "identityField",
      key: "identityField",
    },
  ];

  return (
    <div className="identity-fields-container">
      <div className="ConnectorSettings__headerText">
        <Tabs defaultActiveKey="2" items={items} onChange={onChange} />
      </div>

      <div className="warning">
        <p>No Identity Fields Matched</p>
        <span>We did not find any fields that match our identity graph.</span>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        className="identity-table"
      />
      <div className="update-button">
        <Button type="primary" onClick={onUpdate}>
          Update
        </Button>
      </div>
    </div>
  );
};

export default CSVMappings;
