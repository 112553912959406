import React, { useEffect, useState } from "react";
import { Image, Table, Spin } from "antd";
import "./index.scss";
import { useParams } from "react-router-dom";
import {
  IconCSVFile,
  IconCSVPeople,
  IconCSVPlaces,
  IconCSVcompanies,
} from "images";
import { getEntitiesFromAthena, getUploadedCSVOverview } from "api/FileOnboard";
import { getSelectedWorkspaceProfileId } from "utils";
import { useSelector } from "react-redux";

function CsvOverview({ csvRefresh, onStatusChange }) {
  const { sourceId } = useParams();
  const [connectionDetails, setConnectionDetails] = useState({});
  const [csvFiles, setCsvFiles] = useState([]);
  const [csvDuplicateFiles, setCsvDuplicateFiles] = useState([]);
  const [csvSummary, setCsvSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [athenaLoading, setAthenaLoading] = useState(true);
  const [error, setError] = useState(false);
  const userSession = useSelector((state) => state.authReducer).userSession;

  const [userFullName, setUserFullName] = useState(
    `${userSession.firstName} ${userSession.lastName}`
  );

  useEffect(() => {
    const payload = { sourceId: sourceId };
    getUploadedCSVOverview(payload)
      .then((res) => {
        console.log("getUploadedCSVOverview: ", res);
        const csvFiles = res?.data?.data?.csvFiles || [];

        // Separate files based on "Duplicate" status
        const duplicateFiles = csvFiles.filter(
          (file) => file.status === "Duplicate"
        );
        const nonDuplicateFiles = csvFiles.filter(
          (file) => file.status !== "Duplicate"
        );

        const failedFiles = csvFiles.filter(
          (file) => file.status === "Onboarding Failed"
        );

        if (failedFiles.length > 0) {
          onStatusChange(true, failedFiles);
        } else {
          onStatusChange(false);
        }

        setConnectionDetails(res?.data?.data?.connections || {});
        setCsvFiles(nonDuplicateFiles);
        setCsvDuplicateFiles(duplicateFiles);
        setCsvSummary(res?.data?.data?.entityCountResult || []);
        setError(false);
      })
      .catch((err) => {
        setError(true);
        onStatusChange(true, []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [sourceId, csvRefresh]);

  useEffect(() => {
    if (connectionDetails.display_name) {
      setAthenaLoading(true);
      const payload = {
        profileWorkspaceId: getSelectedWorkspaceProfileId(userSession),
        displayName: connectionDetails.display_name,
      };
      getEntitiesFromAthena(payload)
        .then((res) => {
          console.log("entites from athena", res.data.data);
          setCsvSummary(res?.data?.data || []);
        })
        .catch((err) => {})
        .finally(() => {
          setAthenaLoading(false);
        });
    }
  }, [connectionDetails.display_name, csvRefresh]);

  const createUploadColumns = (title, showStatus = true) => {
    const columns = [
      {
        title: title,
        dataIndex: "key",
        key: "key",
        render: (text) => (
          <div className="upload-summary__icon-row">
            <Image
              src={IconCSVFile}
              className="upload-summary__icon"
              preview={false}
            />
            <span>{text}</span>
          </div>
        ),
      },
    ];

    if (showStatus) {
      columns.push({
        title: "STATUS",
        dataIndex: "status",
        key: "status",
        render: (text) => (
          <span>
            {text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
          </span>
        ),
      });
    }

    return columns;
  };

  const identityColumns = [
    {
      title: "TOTAL IDENTITIES GENERATED",
      dataIndex: "entity",
      key: "entity",
      render: (text, record) => {
        let icon;
        switch (text) {
          case "People":
            icon = (
              <Image
                src={IconCSVPeople}
                className="upload-summary__icon"
                preview={false}
              />
            );
            break;
          case "Place":
            icon = (
              <Image
                src={IconCSVPlaces}
                className="upload-summary__icon"
                preview={false}
              />
            );
            break;
          case "Company":
            icon = (
              <Image
                src={IconCSVcompanies}
                className="upload-summary__icon"
                preview={false}
              />
            );
            break;
          default:
            icon = null;
        }

        return (
          <div className="upload-summary__icon-row">
            {icon}
            {athenaLoading ? (
              <Spin size="small" />
            ) : (
              <span>
                {text} ({record.count})
              </span>
            )}
          </div>
        );
      },
    },
  ];

  // Convert connectionDetails object to an array of one item
  const connectionDetailsArray = Object.keys(connectionDetails).length
    ? [connectionDetails]
    : [];

  const connectionColumns = [
    {
      title: "SOURCE NAME",
      dataIndex: "display_name",
      key: "display_name",
      render: (text) => <span>{text || "N/A"}</span>,
    },
    {
      title: "ROWS",
      dataIndex: "row_count",
      key: "row_count",
      render: (text) => <span>{text || "N/A"}</span>,
    },
    {
      title: "AGE OF DATA",
      dataIndex: "accurate_to_dt",
      key: "accurate_to_dt",
      render: (date) => (
        <span>
          {new Date(date).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })}
        </span>
      ),
    },
    {
      title: "AGE DETERMINED BY",
      dataIndex: "extracted_from",
      key: "extracted_from",
      render: (text) => (
        <span> There's a timestamp in column "{text || "N/A"}"</span>
      ),
    },
  ];

  // Show spinner while loading
  if (loading) {
    return (
      <div className="upload-summary__loading">
        <Spin size="medium" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="upload-summary__loading">
        <h2>No Data Found against this source...</h2>
      </div>
    );
  }

  return (
    <div className="upload-summary">
      {connectionDetailsArray.length > 0 && (
        <Table
          className="upload-summary__table upload-summary__table--connections"
          columns={connectionColumns}
          dataSource={connectionDetailsArray}
          pagination={false}
        />
      )}

      {csvFiles.length > 0 && (
        <Table
          className="upload-summary__table"
          columns={createUploadColumns("UPLOADS")}
          dataSource={csvFiles}
          pagination={false}
        />
      )}

      {csvDuplicateFiles.length > 0 && (
        <Table
          className="upload-summary__table"
          columns={createUploadColumns("DUPLICATE FILES", false)}
          dataSource={csvDuplicateFiles}
          pagination={false}
        />
      )}

      {athenaLoading ? (
        <div className="upload-summary__loading-container">
          <Spin size="mediun" tip="Fetching identities data..." />
        </div>
      ) : (
        <Table
          className="upload-summary__table upload-summary__table--identities"
          columns={identityColumns}
          dataSource={csvSummary}
          pagination={false}
          scroll={{ y: 400 }}
        />
      )}
    </div>
  );
}

export default CsvOverview;
