import { Button, Image } from "antd";
import React from "react";
import "./index.scss";
import FilterCrossIcon from "../../../../images/filter-cross.svg";

function SideFilters({ filterFields = null, onClose, onReset }) {
  return (
    <div className="sideFilters">
      <div className="sideFilters__heading">
        <span className="sideFilters__heading__label">Customize Results</span>
        <Image
          wrapperClassName="sideFilters__heading__cross"
          src={FilterCrossIcon}
          preview={false}
          onClick={onClose}
        />
      </div>
      <div className="sideFilters__scrollableContent hide-scroll-bar">
        {filterFields}
      </div>
      <div className="sideFilters__footer">
        <Button onClick={onReset} className="sideFilters__footer__resetBtn">
          Reset Filters
        </Button>
      </div>
    </div>
  );
}
export default SideFilters;
