import React from "react";
import { Tabs, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DestinationSettings from "../DestinationSettings";
import "./index.scss";

function DestinationOverview() {
  const loading = useSelector((state) => state?.connectorReducer?.loading);
  // const { sourceId, sourceName, connectionName, connectorId, active } =
  //   useParams();

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Settings",
      children: <DestinationSettings />, // Corrected: Use the component, not a string
    },
  ];

  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <div className="DestinationOverview__headerText">
          <Tabs defaultActiveKey="2" items={items} onChange={onChange} />
        </div>
      )}
    </div>
  );
}

export default DestinationOverview;
