import React from "react";

function Flex({
  justify = "flex-start",
  align = "flex-start",
  allCenter = false,
  direction = "row",
  flex = "initial",
  style,
  children,
  ...props
}) {
  return (
    <div
      style={{
        display: "flex",
        flex,
        alignItems: allCenter ? "center" : align,
        justifyContent: allCenter ? "center" : justify,
        flexDirection: direction,
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
}

export default Flex;
