import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row, Button } from "antd";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Avatar, Image } from "antd";
import { useCustomEventListener } from "react-custom-events";
import {
  EllipsisOutlined,
  CheckCircleOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { PlaybookDummyImage } from "images";
import { width } from "@mui/system";

function Facebook(itemData = {}) {
  const userSession = useSelector((state) => state.authReducer).userSession;
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [userFullName, setUserFullName] = useState(
    `${userSession.firstName} ${userSession.lastName}`
  );
  const [current, setCurrent] = useState("desktop");

  useCustomEventListener("profile-img-changed", (data) => {
    setUserProfileUrl(data);
  });
  useCustomEventListener("first-name-changed", (data) => {
    setUserFullName(data);
  });

  useEffect(() => {
    setUserProfileUrl(userSession?.imageUri);
  }, []);

  const handleMobileClick = (event) => {
    setCurrent("mobile");
  };
  const handleDesktopClick = (event) => {
    setCurrent("desktop");
  };

  return (
    <>
      <div className={current === "desktop" ? "Facebook" : "FacebookMobile"}>
        <div className="Facebook__appHomeButton">
          <Row
            className="Facebook__Row-1"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={4}>
              <div>
                <CustomAvatar name={userFullName} imageUri={userProfileUrl} />
              </div>
            </Col>
            <Col span={17}>
              <div className="Facebook__appHomeHeadContainer">
                <div className="Facebook__appHomeMainHead">
                  Fidelity American Homes{" "}
                  <CheckCircleOutlined className="Facebook__Tick" />
                </div>
                <div className="Facebook__appHomeSubHead">
                  Sponsored. <GlobalOutlined className="Facebook__Dot" />
                </div>
              </div>
            </Col>

            <Col span={3}>
              <div
                className={
                  current === "desktop"
                    ? "Facebook__appDotsIcon"
                    : "FacebookMobile__appDotsIcon"
                }
              >
                <EllipsisOutlined />
              </div>
            </Col>
          </Row>
        </div>
        <div className="Facebook__Description">
          <p>
            We offer to buy your home and we want to give you a price that
            you'll be happy with.
          </p>
        </div>

        <Image
          className="Facebook__IMG1"
          alt="Image of playbook"
          preview={false}
          src={PlaybookDummyImage}
        />

        <div className="Facebook__Grey">
          <Row
            className="Facebook__Row-2"
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col span={16}>
              <div className="Facebook__Grey__FirstContent">
                FidelityAmericanHomes.com
              </div>
              <div className="Facebook__Grey__SecondContent">
                Cash Offer For Your Home
              </div>
            </Col>

            <Col span={8}>
              <div className="Facebook__Grey__Learn">
                <Button>Learn More</Button>
              </div>
            </Col>
          </Row>
          <div className="Facebook__Grey__Des">
            <p>
              We have cash buyers. View your offers, make a decision later and
              close in 7 days
            </p>
          </div>
        </div>
      </div>

      <div className="FacebookViewbutton">
        <Button
          className="FacebookViewbutton__DesktopButton"
          onClick={handleDesktopClick}
        >
          Desktop
        </Button>
        <Button
          className="FacebookViewbutton__MobileButton"
          onClick={handleMobileClick}
        >
          Mobile
        </Button>
        <Button
          className={
            current === "desktop"
              ? "Facebook__Create"
              : "FacebookMobile__Create"
          }
        >
          Create
        </Button>
      </div>
    </>
  );
}

export default Facebook;

function CustomAvatar({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1C`BF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar className="Facebook__customAvatar__avatar" src={imageUri} />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="Facebook__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
