import {
  getSavedUserToken,
  getSavedAccessToken
} from "../../store/localStorage";
import { message } from "antd";

export const requestInterceptor = async (config) => {
  const { idToken = "", accessToken = "" } = getSavedUserToken() || {};
  const jwtAccessToken = getSavedAccessToken() || "";
 
  // config.headers.common.Authorization = idToken;
  // config.headers.common.AccessToken = accessToken;
  config.headers.common.idToken = idToken;
  config.headers.common['x-auth-token'] = jwtAccessToken;
  // config = await getConfigWithLatestTokens(config, idToken, accessToken);
  return config;
};
export const responseInterceptor = (response) => {
  return response;
};
export const errorInterceptor = async (error) => {
  if (error.response.status == 403) {
    message.error(error?.response?.data?.message);
    setTimeout(() => {
      window.location.pathname = "/signin";
      localStorage.clear();
    }, 500);
  } else {
    message.error(error?.response?.data?.message);
  }
  return Promise.reject(error?.message);
};