import React, { useState } from "react";
import { useEffect } from "react";
import "./index.scss";
import { Button, Form } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useCustomEventListener } from "react-custom-events";
import { Avatar, Image } from "antd";
import { PlaybookDummyImage } from "images";

function ThankYouPage() {
  const userSession = useSelector((state) => state.authReducer).userSession;
  const [userProfileUrl, setUserProfileUrl] = useState(null);
  const [userFullName, setUserFullName] = useState(
    `${userSession.firstName} ${userSession.lastName}`
  );
  const [current, setCurrent] = useState("desktop");

  useCustomEventListener("profile-img-changed", (data) => {
    setUserProfileUrl(data);
  });
  useCustomEventListener("first-name-changed", (data) => {
    setUserFullName(data);
  });

  useEffect(() => {
    setUserProfileUrl(userSession?.imageUri);
  }, []);

  const handleMobileClick = (event) => {
    setCurrent("mobile");
  };
  const handleDesktopClick = (event) => {
    setCurrent("desktop");
  };

  return (
    <>
      <div
        className={
          current === "desktop" ? "ThankYouPage" : "ThankYouPageMobile"
        }
      >
        <div className="ThankYouPage__Border">
          <div className="ThankYouPage__Image"></div>
          <div
            className={
              current === "desktop"
                ? "ThankYouPage__Logo"
                : "ThankYouPageMobile__Logo"
            }
          >
            <Image
              className="ThhankYouPage__IMG1"
              alt="Image of playbook"
              preview={false}
              src={PlaybookDummyImage}
            />
          </div>
          <div
            className={
              current === "desktop"
                ? "ThankYouPage__Paragraphs"
                : "ThankYouPageMobile__Paragraphs"
            }
          >
            <p className="ThankYouPage__Paragraphs__Para-1">
              Cash Offer for Your Home
            </p>
            <p className="ThankYouPage__Paragraphs__Para-2">
              Offer We have cash buyers. View Your Offer, make a decision later
              and close in 7 days.
            </p>
            <Form
              className={
                current === "desktop"
                  ? "ThankYouPage__Form"
                  : "ThankYouPageMobile__Form"
              }
            >
              <input
                className={
                  current === "desktop"
                    ? "ThankYouPage__Form__Input"
                    : "ThankYouPageMobile__Form__Input"
                }
                placeholder="Enter Property Address"
              />
              <button
                className={
                  current === "desktop"
                    ? "ThankYouPage__Form__Switch"
                    : "ThankYouPageMobile__Form__Switch"
                }
              >
                Get my estimated offer
              </button>
            </Form>
          </div>
          <div
            className={
              current === "desktop"
                ? "ThankYouPage__LowerContent"
                : "ThankYouPageMobile__LowerContent"
            }
          >
            <p
              className={
                current === "desktop"
                  ? "ThankYouPage__LowerContent__Head"
                  : "ThankYouPageMobile__LowerContent__Head"
              }
            >
              Why sellers love Fidelity American Homes
            </p>
            <Button
              className={
                current === "desktop"
                  ? "ThankYouPage__LowerContent__Button"
                  : "ThankYouPageMobile__LowerContent__Button"
              }
            >
              {" "}
              <MessageOutlined /> Text Me My Offer
            </Button>
          </div>
        </div>
      </div>

      <div className="ThankYouPageViewbutton">
        <Button
          className="ThankYouPageViewbutton__DesktopButton"
          onClick={handleDesktopClick}
        >
          Desktop
        </Button>
        <Button
          className="ThankYouPageViewbutton__MobileButton"
          onClick={handleMobileClick}
        >
          Mobile
        </Button>
        <Button
          className={
            current === "desktop"
              ? "ThankYouPage__Create"
              : "ThankYouPageMobile__Create"
          }
        >
          Create
        </Button>
      </div>
    </>
  );
}

export default ThankYouPage;

function CustomAvatar({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar className="ThankYouPage__customAvatar__avatar" src={imageUri} />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="ThankYouPage__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
