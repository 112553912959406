import { message, Button, Form, Input, Space, Typography, Image } from "antd";
import { UserOnBoardingFooter } from "components/UserOnBoardingFooter";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import AppLogo from "../../components/AppLogo";
import "./index.scss";
import { createUser, signin } from "api/userManagement";
import { useEffect, useState } from "react";
import { confirmSignUpWithUsernameCode } from "api/Auth";
import { useDispatch } from "react-redux";
import { setIsSocial, setUserSession } from "store/modules/auth/action";
import axios from "axios";
import { Buffer } from "buffer";
import { parseUserResponse } from "utils";
import { signInWithUsernamePassword } from "../../api/Auth";
import { setUserToken, setAccessToken } from "../../store/modules/auth/action";
import {
  TWILIO_PASSWORD,
  TWILIO_SMS_SID,
  TWILIO_USER_NAME,
} from "environments";
import { Close } from "images";
import { trackEventCall, trackIdentifyCall, trackPageCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "../../constants";

function SignupPhoneCode() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const username = TWILIO_USER_NAME;
  const password = TWILIO_PASSWORD;
  const sms_sid = TWILIO_SMS_SID;
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    trackPageCall("OnBoarding Enter PhoneCode");
  }, []);

  const verifyPhoneCode = (values) => {
    setShowErrorMessage(false);
    setLoading(true);
    const { confirmationCode } = values;

    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    axios
      .post(
        `https://verify.twilio.com/v2/Services/${sms_sid}/VerificationCheck`,
        new URLSearchParams({
          To: state.phoneNumber,
          Code: confirmationCode,
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        if (res.data.valid) {
          // trackEventCall(ANALYTICS_EVENT_NAMES.ONBOARDING_PHONE_VERIFIED);
          handleSignupWithPhone();
        } else {
          setLoading(false);
          setErrorMessage("Please enter a valid code.");
          setShowErrorMessage(true);
        }
      })
      .catch((err) => {
        setShowErrorMessage(true);
        setErrorMessage(
          "The code you have entered is either invalid or expired."
        );
        setLoading(false);
      });
  };

  const reSendVerificationCode = () => {
    setShowErrorMessage(false);
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );
    axios
      .post(
        `https://verify.twilio.com/v2/Services/${sms_sid}/Verifications`,
        new URLSearchParams({
          To: state.phoneNumber,
          Channel: "sms",
        }),
        {
          headers: {
            Authorization: `Basic ${token}`,
            "Content-type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        message.success("SMS code sent again");
      })
      .catch((err) => {
        showErrorMessage(err.message);
        setShowErrorMessage(true);
      });
  };

  const verifyPhone = (values) => {
    setLoading(true);
    const { confirmationCode } = values;
    const response = confirmSignUpWithUsernameCode(
      state.phoneNumber,
      confirmationCode
    );
    response
      .then((res) => {
        handleSignupWithPhone();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSignupWithPhone = () => {
    setShowErrorMessage(false);
    const response = createUser(state);
    response
      .then((res) => {
        var payloadForSigninUrl = null;
        if (state.isSocialSignin) {
          payloadForSigninUrl = {
            idtoken: state.tokenId,
            isSocial: state.isSocial,
          };
          signInSocialUser(payloadForSigninUrl);
        } else {
          payloadForSigninUrl = {
            email: state.email,
            password: state.password,
            isSocial: false,
          };
          signInUser(payloadForSigninUrl);
        }
        state.userId = res.data.data.user_id;
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setErrorMessage(err.response.data.message);
        } else {
          setErrorMessage(err.message);
        }
        setShowErrorMessage(true);
        setLoading(false);
      });
  };

  const signInSocialUser = (payloadForSigninUrl) => {
    const socialState = {
      isSocialSignin: true,
    };
    dispatch(setIsSocial(socialState));
    const state = {
      idToken: payloadForSigninUrl.idtoken,
    };
    dispatch(setUserToken(state));

    signin(payloadForSigninUrl)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setAccessToken(response?.data?.data?.response?.token));
          dispatch(setUserToken(response?.data?.data?.response?.token));
          trackEventCall(ANALYTICS_EVENT_NAMES.ONBOARDING_PROFILE_CREATED);
          const state = parseUserResponse(response, true);
          dispatch(setUserSession(state));
        } else {
          setErrorMessage(response.data.message);
          setShowErrorMessage(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(error.message);
        }
        setShowErrorMessage(true);
        setLoading(false);
      });
  };

  const signInUser = (payloadForSigninUrl) => {
    signInWithUsernamePassword(
      payloadForSigninUrl.email,
      payloadForSigninUrl.password
    )
      .then((res) => {
        const state = {
          idToken: res.signInUserSession.idToken.jwtToken,
        };
        const stateSocial = {
          isSocialSignin: true,
        };
        dispatch(setIsSocial(stateSocial));
        dispatch(setUserToken(state));
        signin(payloadForSigninUrl)
          .then((response) => {
            if (response.status === 200) {
              dispatch(setAccessToken(response?.data?.data?.response?.token));
              dispatch(setUserToken(response?.data?.data?.response?.token));
              trackEventCall(ANALYTICS_EVENT_NAMES.ONBOARDING_PROFILE_CREATED);
              const state = parseUserResponse(response, false);
              dispatch(setUserSession(state));

              const segmentIdentityData = {
                email: state?.email,
                firstname: state?.firstName,
                lastname: state?.lastName,
                phonenumber: state?.phoneNumber,
              };

              trackIdentifyCall(state?.userId, segmentIdentityData);
              // window.OpenReplay.setUserID(state?.email);
            } else {
              setErrorMessage(response.data.message);
              setShowErrorMessage(true);
              setLoading(false);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              setErrorMessage(error.response.data.message);
            } else {
              setErrorMessage(error.message);
            }
            setShowErrorMessage(true);
            setLoading(false);
          });
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setShowErrorMessage(true);
        setLoading(false);
      });
  };

  const onErrorCloseClick = () => {
    setShowErrorMessage(false);
  };
  return (
    <>
      <div className="signupPhoneCode__container">
        <div className="signupPhoneCode__header">
          <div>
            <Typography.Text className="signupPhoneCode__textDonthaveAccount">
              Already have an account?
            </Typography.Text>
            <Link to={APP_ROUTES.SIGNIN.path}>
              <Button type="primary" className="signupPhoneCode__button">
                Sign In
              </Button>
            </Link>
          </div>
        </div>
        <div className="signupPhoneCode">
          <Space
            direction="vertical"
            size={0}
            className="signupPhoneCode__signinFields"
          >
            <div className="signupPhoneCode__headerLogo">
              <AppLogo className="signupPhoneCode__logo" />
            </div>
            <Typography.Text className="signupPhoneCode__headerTitle">
              Verify Your Phone
            </Typography.Text>
            {showErrorMessage && (
              <div className="signinPage__errorMessageContainer">
                <Image
                  className="signinPage__errorContainerCrossImage"
                  src={Close}
                  onClick={onErrorCloseClick}
                  preview={false}
                ></Image>
                <Typography.Text
                  className="signinPage__textErrorMessage"
                  type="danger"
                >
                  {errorMessage}
                </Typography.Text>
              </div>
            )}
            <Typography.Text className="signupPhoneCode__textNormal">
              Two-step verification
            </Typography.Text>
            <Form layout="vertical" onFinish={verifyPhoneCode}>
              <Form.Item
                name="confirmationCode"
                label={<span className="custom-label">Enter the code sent to your phone</span>}
                required
                rules={[
                  {
                    required: true,
                    message: "Please input confirmation code.",
                  },
                ]}
              >
                <Input
                  disabled={loading}
                  placeholder="Confirmation code"
                  className="signupPhoneCode__inputField"
                ></Input>
              </Form.Item>
              <div className="signupPhoneCode__sendAgainDiv">
                <Typography.Text className="signupPhoneCode__textCodeNotReveived">
                  {" "}
                  Didn't receive code?{" "}
                </Typography.Text>
                <Typography.Text
                  onClick={reSendVerificationCode}
                  underline
                  className="signupPhoneCode__textSendAgain"
                >
                  Send Again
                </Typography.Text>
              </div>

              <Button
                loading={loading}
                htmlType="submit"
                type="primary"
                className="signupPhoneCode__continueButton"
              >
                Continue
              </Button>
            </Form>
          </Space>
        </div>

        <UserOnBoardingFooter />
      </div>
    </>
  );
}
export default SignupPhoneCode;
