import { Button, Image, Select, Typography } from "antd";
import AppLogo from "components/AppLogo";
import {
  IconBuying,
  IconBuyingAndSelling,
  IconSelling,
  IconQuizLogo,
  IconTick,
} from "images";
import { useEffect, useState } from "react";
import "./index.scss";

function QuizFlow() {
  const [quizList, setQuizList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState([[]]);

  useEffect(() => {
    // Call quiz fetching api here
    const questions = [
      {
        id: 0,
        qTitle: "Answer a few quick questions to see how we can help you.",
        qtype: "single_selection",
        options: [
          {
            id: 0,
            image: IconBuying,
            text: "I'm Buying",
          },
          {
            id: 1,
            image: IconSelling,
            text: "I'm Selling",
          },
          {
            id: 2,
            image: IconBuyingAndSelling,
            text: "I’m Buying & Selling",
          },
        ],
      },
      {
        id: 1,
        qTitle: "When was the property built?",
        qSubHeading: "Choose one of the following",
        qtype: "single_selection",
        options: [
          {
            id: 0,
            image: null,
            text: "1960s",
          },
          {
            id: 1,
            image: null,
            text: "1970s",
          },
          {
            id: 2,
            image: null,
            text: "1980s",
          },
          {
            id: 3,
            image: null,
            text: "1990s",
          },
          {
            id: 4,
            image: null,
            text: "2000s",
          },
          {
            id: 5,
            image: null,
            text: "2010 or later",
          },
        ],
      },
      {
        id: 2,
        qTitle: "How many beds and baths are in this property?",
        qtype: "dropdown",
        dropDowns: [
          {
            defaultValue: "Beds",
            options: [
              { value: "any", label: "Any" },
              { value: "+1", label: "+1" },
              { value: "+2", label: "+2" },
              { value: "+3", label: "+3" },
              { value: "+4", label: "+4" },
              { value: "+5", label: "+5" },
            ],
          },
          {
            defaultValue: "Baths",
            options: [
              { value: "any", label: "Any" },
              { value: "+1", label: "+1" },
              { value: "+2", label: "+2" },
              { value: "+3", label: "+3" },
              { value: "+4", label: "+4" },
              { value: "+5", label: "+5" },
            ],
          },
        ],
      },
      {
        id: 3,
        qTitle: "How much is your home worth?",
        qSubHeading:
          "Please tell us your estimated amount you are planning to receive.",
        qtype: "single_selection",
        options: [
          {
            id: 0,
            image: null,
            text: "$110K or less",
          },
          {
            id: 1,
            image: null,
            text: "$110K - $150K",
          },
          {
            id: 2,
            image: null,
            text: "$150K - $190K",
          },
          {
            id: 3,
            image: null,
            text: "$190K - $250K",
          },
          {
            id: 4,
            image: null,
            text: "$250K - $300K",
          },
          {
            id: 5,
            image: null,
            text: "300K or more",
          },
        ],
      },
    ];

    setQuizList(questions);
    setCurrentQuestion(questions[0]);
  }, []);

  const renderRows = () => {
    let results = currentQuestion?.options;
    let finalArr = [],
      columns = [];

    results.forEach((result, i) => {
      // prepare the array
      columns.push(
        <div key={i} className="quizFlow__quizflowOptionsDiv">
          {result?.image && (
            <Image
              className="quizFlow__quizOptionImage"
              preview={false}
              src={result.image}
            ></Image>
          )}
          {result?.text && <Typography.Text>{result.text}</Typography.Text>}
        </div>
      );

      // after three items add a new row
      if ((i + 1) % 3 === 0) {
        finalArr.push(
          <div className="quizFlow__quizflowOptionsDiv">{columns}</div>
        );
        columns = [];
      }
    });
    return finalArr;
  };

  const renderSingleSelectionQuestion = () => {
    currentQuestion?.options?.map((options, index) => {
      console.log("options", options);
      return (
        <div className="quizFlow__quizflowOptionsDiv">
          {options?.image && (
            <Image
              className="quizFlow__quizOptionImage"
              preview={false}
              src={options.image}
            ></Image>
          )}
          {options?.text && <Typography.Text>{options.text}</Typography.Text>}
          {(index + 1) % 3 === 0 && <br></br>}
        </div>
      );
    });
  };

  const renderDropDownQuestion = () => {
    currentQuestion?.dropDowns?.map((dropdowns, index) => {
      return (
        <div>
          <Select></Select>
          {(index + 1) % 2 === 0 && <br></br>}
        </div>
      );
    });
  };

  const nextButtonClick = () => {
    if (currentQuestionIndex + 1 === quizList.length) {
      // Last question,
    } else {
      setCurrentQuestion(quizList[currentQuestionIndex + 1]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      const currentArray = [];
      const newArray = [...selectedItems];
      newArray[currentQuestionIndex + 1] = currentArray;
      setSelectedItems(newArray);
    }
  };

  const backButtonClick = () => {
    if (currentQuestionIndex === 0) {
      // First question,
    } else {
      setCurrentQuestion(quizList[currentQuestionIndex - 1]);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const selectedQuestionsWithOptions = (questionNo, optionsNo) => {
    let currentArray = selectedItems[questionNo];
    if (currentArray.includes(optionsNo)) {
      currentArray = arrayRemove(currentArray, optionsNo);
    } else {
      currentArray.push(optionsNo);
    }
    const newArray = [...selectedItems];
    newArray[questionNo] = currentArray;
    setSelectedItems(newArray);
  };

  const arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele != value;
    });
  };

  const checkIfSelectedOptionIsSaved = (questionNo, optionsNo) => {
    const currentArray = selectedItems[questionNo];
    if (currentArray.includes(optionsNo)) {
      return true;
    }

    return false;
  };

  return (
    <>
      <div className="quizFlow">
        <div className="quizFlow__headerLogo">
          <Image
            className="quizFlow__quizLogo"
            src={IconQuizLogo}
            preview={false}
          ></Image>
        </div>
        <div className="quizFlow__quizTitles">
          <Typography.Text className="quizFlow__quizTitleText">
            {currentQuestion.qTitle}
          </Typography.Text>
          {currentQuestion.qSubHeading !== null ? (
            <Typography.Text className="quizFlow__quizSubHeadingText">
              {currentQuestion.qSubHeading}
            </Typography.Text>
          ) : null}
        </div>
        <div className="quizFlow__quizOptionsParentDiv">
          {currentQuestion.qtype === "single_selection" && (
            <div className="quizFlow__quizflowOptionsParentDiv">
              {/* {renderRows()} */}
              {currentQuestion?.options?.map((options, index) => {
                const isSelected = checkIfSelectedOptionIsSaved(
                  currentQuestion.id,
                  options.id
                );
                return (
                  <div className="quizFlow__container" key={index}>
                    <div
                      className={
                        isSelected
                          ? "quizFlow__selectedQuizflowOptionsDiv"
                          : "quizFlow__quizflowOptionsDiv"
                      }
                      key={index}
                      onClick={() => {
                        selectedQuestionsWithOptions(
                          currentQuestion.id,
                          options.id
                        );
                      }}
                    >
                      {options?.image && (
                        <Image
                          className="quizFlow__quizOptionImage"
                          preview={false}
                          src={options.image}
                        ></Image>
                      )}
                      {options?.text && (
                        <Typography.Text
                          className={
                            isSelected
                              ? "quizFlow__selectedQuizOptionsText"
                              : "quizFlow__quizOptionsText"
                          }
                        >
                          {options.text}
                        </Typography.Text>
                      )}
                      {/* {(index + 1) % 3 === 0 && <br></br>} */}
                    </div>
                    {isSelected ? (
                      <img
                        style={{ position: "absolute", top: 0, right: 0 }}
                        src={IconTick}
                        preview={false}
                      ></img>
                    ) : null}
                  </div>
                );
              })}
            </div>
          )}
          {currentQuestion.qtype === "dropdown" && (
            <div className="quizFlow__quizflowDropdownOptionsParentDiv">
              {currentQuestion?.dropDowns?.map((dropdowns, index) => {
                return (
                  <div className="quizFlow__dropDownParentDiv" key={index}>
                    <Select
                      className="quizFlow__dropDownDiv"
                      defaultValue={dropdowns.defaultValue}
                      options={dropdowns.options}
                    ></Select>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="quizFlow__quizButtonsDiv">
          <Button
            className="quizFlow__backButton"
            type="primary"
            onClick={backButtonClick}
          >
            Back
          </Button>
          <Button
            className="quizFlow__submitButton"
            type="primary"
            onClick={nextButtonClick}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
}

export default QuizFlow;
