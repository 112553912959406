import "./index.scss";
import {
  Button,
  Select,
  Divider,
  message,
  Input,
  Image,
  Form,
  Spin,
  Avatar,
  Upload,
} from "antd";
import AddPackage from "./AddPackage";
import MediaUpload from "components/MediaUpload";
import { useEffect, useState, useRef } from "react";
import { createAPlaybook, getPlaybookCategories } from "api/Playbooks";
import { generateRandomIntegerInRange } from "utils";
import { FileImageOutlined } from "@ant-design/icons";
import { IconCameraAddPhoto } from "images";
import { uploadFileUsingPreSignedURL } from "utils";
import { getPreSignedUrlForWorkspaceImage } from "api/Workspaces";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";

const { TextArea } = Input;

function CreatePlaybook() {
  const [loading, setLoading] = useState(false);
  const [preSignedInfo, setPreSignedInfo] = useState({});

  const [categoriesList, setCategoriesList] = useState([]);

  const [image1Url, setImage1Url] = useState("");
  const [image2Url, setImage2Url] = useState("");
  const [image3Url, setImage3Url] = useState("");

  const [isEnabled, setIsEnabled] = useState(true);

  const [packageDetails, setPackageDetails] = useState({});
  const [servicesSelectionData, setServicesSelection] = useState([]);

  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const categoryId = useRef("");
  const [selectedPrimaryColor, setSelectedPrimaryColor] = useState(0);
  const [playbookIconUrl, setPlaybookIconUrl] = useState("");

  const navigate = useNavigate();

  const onProgress = () => {};

  const colorsList = [
    { key: "0", name: "Green", hexCode: "#98D650" },
    { key: "1", name: "Orange", hexCode: " #F0994F" },
    { key: "2", name: "Blue", hexCode: "#0F0F5C" },
    { key: "2", name: "Cyan", hexCode: "#6AA3F8" },
  ];

  useEffect(() => {
    setDynamicColor(colorsList[0].hexCode);
  }, []);

  const setDynamicColor = (color) => {
    const root = document.documentElement;
    root.style.setProperty("--dynamicColor", `${color}`);
  };
  const handlePrimaryColorChange = (index) => {
    setSelectedPrimaryColor(index);
    setDynamicColor(colorsList[index].hexCode);
  };

  useEffect(() => {
    const packageDetail = {
      basic_price: "",
      basic_description: "",
      standard_price: "",
      standard_description: "",
      premium_price: "",
      premium_description: "",
    };

    setPackageDetails(packageDetail);
  }, {});

  const onCategoryChange = (index) => {
    categoryId.current = categoriesList[index - 1].id;
  };
  const onPackagePriceChanged = (from) => (e) => {
    if (from === "basic") {
      packageDetails.basic_price = e;
    } else if (from === "standard") {
      packageDetails.standard_price = e;
    } else if (from === "premium") {
      packageDetails.premium_price = e;
    }

    setPackageDetails(packageDetails);
  };

  const onImageUpload = (imageNumber, imageUrl) => {
    setIsEnabled(true);
    if (imageNumber === 1) {
      setImage1Url(imageUrl);
    }
    if (imageNumber === 2) {
      setImage2Url(imageUrl);
    }
    if (imageNumber === 3) {
      setImage3Url(imageUrl);
    }
  };
  const onPackageDescriptionChanged = (from) => (e) => {
    if (from === "basic") {
      packageDetails.basic_description = e.target.value;
    } else if (from === "standard") {
      packageDetails.standard_description = e.target.value;
    } else if (from === "premium") {
      packageDetails.premium_description = e.target.value;
    }
    setPackageDetails(packageDetails);
  };

  useEffect(() => {
    const list = [];
    for (var i = 0; i < 7; i++) {
      list.push({
        key: `${i}`,
        service_name: "",
        b_isSelected: false,
        s_isSelected: false,
        p_isSelected: false,
      });
    }
    setServicesSelection(list);
  }, []);

  const changeServiceSelection = (setSelection, plan, index) => {
    if (plan === "basic") {
      servicesSelectionData[index].b_isSelected = setSelection;
    } else if (plan === "standard") {
      servicesSelectionData[index].s_isSelected = setSelection;
    } else if (plan === "premium") {
      servicesSelectionData[index].p_isSelected = setSelection;
    }
    const updatedServicesSelectionData = [...servicesSelectionData];
    setServicesSelection(updatedServicesSelectionData);
  };

  const onServiceNameChanged = (index) => (e) => {
    servicesSelectionData[index].service_name = e.target.value;
    const updatedServicesSelectionData = [...servicesSelectionData];
    setServicesSelection(updatedServicesSelectionData);
  };
  useEffect(() => {
    getPlaybookCategoriesAPI();
  }, []);

  const getPlaybookCategoriesAPI = () => {
    const payload = { workspace_category_id: userSession.workspaceCategoryId };
    getPlaybookCategories(payload)
      .then((res) => {
        const categories = res.data.data.response;
        setCategoriesList(categories);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreatePlaybook = (values) => {

    if (image1Url === "" && image2Url === "" && image3Url === "") {
      message.error("Kindly upload atleast one image for playbook");
    } else {
      if (playbookIconUrl === "") {
        setLoading(false);
        message.error("Kindly upload icon for playbook");
      } else {
        setLoading(true);
        generateCreatePlaybookPayload(
          values,
          packageDetails,
          servicesSelectionData
        );
      }
    }
  };

  const generateCreatePlaybookPayload = (
    values,
    packageDetails,
    servicesSelectionData
  ) => {
    const payload = {
      user_id: userSession.userId,
      profile_workspace_id: userSession.sellerProfileWorkspaceId,
      workspace_id: userSession.selectedWorkspaceId,

      title: values.title,
      short_description: values.short_description,
      primary_tag: values.primary_tag,
      primary_tag_color: colorsList[selectedPrimaryColor].name,
      reply_rate: generateRandomIntegerInRange(70, 99),
      description: values.addDescription,
      location: values.location,
      playbook_category_id: values.category ? values.category : 0,
      language: values.language,
      tags: values.additional_tags,
      highlight_1: values.highlight1,
      highlight_2: values.highlight2,
      highlight_3: values.highlight3,
      highlight_4: values.highlight4,

      active: true,
      playbook_category_name: values.add_category ? values.add_category : "",
      packages: [
        {
          name: "basic",
          price: packageDetails.basic_price,
          description: packageDetails.basic_description,
          options: [
            {
              index: 0,
              name: servicesSelectionData[0].service_name,
              is_selected: servicesSelectionData[0].b_isSelected,
            },
            {
              index: 1,
              name: servicesSelectionData[1].service_name,
              is_selected: servicesSelectionData[1].b_isSelected,
            },
            {
              index: 2,
              name: servicesSelectionData[2].service_name,
              is_selected: servicesSelectionData[2].b_isSelected,
            },
            {
              index: 3,
              name: servicesSelectionData[3].service_name,
              is_selected: servicesSelectionData[3].b_isSelected,
            },
            {
              index: 4,
              name: servicesSelectionData[4].service_name,
              is_selected: servicesSelectionData[4].b_isSelected,
            },
            {
              index: 5,
              name: servicesSelectionData[5].service_name,
              is_selected: servicesSelectionData[5].b_isSelected,
            },
            {
              index: 6,
              name: servicesSelectionData[6].service_name,
              is_selected: servicesSelectionData[6].b_isSelected,
            },
          ],
        },

        {
          name: "standard",
          price: packageDetails.standard_price,
          description: packageDetails.standard_description,
          options: [
            {
              index: 0,
              name: servicesSelectionData[0].service_name,
              is_selected: servicesSelectionData[0].s_isSelected,
            },
            {
              index: 1,
              name: servicesSelectionData[1].service_name,
              is_selected: servicesSelectionData[1].s_isSelected,
            },
            {
              index: 2,
              name: servicesSelectionData[2].service_name,
              is_selected: servicesSelectionData[2].s_isSelected,
            },
            {
              index: 3,
              name: servicesSelectionData[3].service_name,
              is_selected: servicesSelectionData[3].s_isSelected,
            },
            {
              index: 4,
              name: servicesSelectionData[4].service_name,
              is_selected: servicesSelectionData[4].s_isSelected,
            },
            {
              index: 5,
              name: servicesSelectionData[5].service_name,
              is_selected: servicesSelectionData[5].s_isSelected,
            },
            {
              index: 6,
              name: servicesSelectionData[6].service_name,
              is_selected: servicesSelectionData[6].s_isSelected,
            },
          ],
        },

        {
          name: "premium",
          price: packageDetails.premium_price,
          description: packageDetails.premium_description,
          options: [
            {
              index: 0,
              name: servicesSelectionData[0].service_name,
              is_selected: servicesSelectionData[0].p_isSelected,
            },
            {
              index: 1,
              name: servicesSelectionData[1].service_name,
              is_selected: servicesSelectionData[1].p_isSelected,
            },
            {
              index: 2,
              name: servicesSelectionData[2].service_name,
              is_selected: servicesSelectionData[2].p_isSelected,
            },
            {
              index: 3,
              name: servicesSelectionData[3].service_name,
              is_selected: servicesSelectionData[3].p_isSelected,
            },
            {
              index: 4,
              name: servicesSelectionData[4].service_name,
              is_selected: servicesSelectionData[4].p_isSelected,
            },
            {
              index: 5,
              name: servicesSelectionData[5].service_name,
              is_selected: servicesSelectionData[5].p_isSelected,
            },
            {
              index: 6,
              name: servicesSelectionData[6].service_name,
              is_selected: servicesSelectionData[6].p_isSelected,
            },
          ],
        },
      ],
      video_url: "",
      image1_url: image1Url,
      image2_url: image2Url,
      image3_url: image3Url,
      image_uri: playbookIconUrl,
    };

    checkPayload(payload);
  };

  const checkPayload = (finalPayload) => {
    if (
      finalPayload.playbook_category_name === "" &&
      finalPayload.playbook_category_id < 1
    ) {
      // console.log("final Payload before error message", finalPayload);
      setLoading(false);
      message.error("Please provide a Playbook Category");
    } else {
      // console.log("final Payload before API call", finalPayload);
      callCreatePlaybookAPI(finalPayload);
    }
  };

  const callCreatePlaybookAPI = (payload) => {
    createAPlaybook(payload)
      .then((res) => {
        message.success(res.data.message);
        navigate("/dashboard/myPlaybooks");
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (index) => {
    categoryId.current = categoriesList[index - 1].id;
  };

  const onChange = ({ file }) => {
    // console.log("api call");
    setLoading(true);
    let uploadedImagePath = null;

    getPreSignedUrlForWorkspaceImage()
      .then((res) => {
        setPreSignedInfo(res.data.data);
        const uploadUrl = res.data.data.url;
        const bucket = res.data.data.bucket;
        const key = res.data.data.key;
        uploadedImagePath =
          "https://" +
          bucket +
          ".s3.us-west-2.amazonaws.com" +
          "/" +
          key +
          `?abc=${Math.random()}`;

        uploadFileUsingPreSignedURL(uploadUrl, file, (progress) => {
          onProgress({
            percent: Math.ceil(progress),
          });
        })
          .then((uploadRes) => {
            setPlaybookIconUrl(uploadedImagePath);
            message.success("Image uploaded successfully");
          })
          .catch((uploadErr) => {
            // console.log(uploadErr);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => {});
  };
  return (
    <div className="createPlaybook">
      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleCreatePlaybook}
      >
        <div className="createPlaybook__mainContainer">
          <div className="createPlaybook__addGeneralDetails">
            <div className="createPlaybook__addGeneralDetails__heading">
              <div className="createWorkspace__addGeneralDetails__heading">
                <div className="createWorkspace__headingAndImageContainer">
                  <div className="createWorkspace__headingAndImageContainer__headingContainer">
                    <span> Add General Details</span>
                    <br />
                    <span className="createWorkspace__addGeneralDetails__subHeading">
                      Get your skills speak out loud to impress our buyers
                    </span>
                  </div>

                  <div className="createWorkspace__headingAndImageContainer__imageContainer">
                    <div className="createWorkspace__headingAndImageContainer__imageContainer__image">
                      <Spin spinning={loading}>
                        <ImgCrop>
                          <Upload
                            accept="image/*"
                            showUploadList={false}
                            customRequest={onChange}
                          >
                            <div className="createWorkspace__headingAndImageContainer__imageContainer__image__headerImgDiv">
                              <Avatar
                                className="createWorkspace__headingAndImageContainer__imageContainer__image__headerImg"
                                src={playbookIconUrl}
                                size={48}
                                icon={<FileImageOutlined />}
                              />
                              <Image
                                className="createWorkspace__headingAndImageContainer__imageContainer__image__cameraIcon"
                                src={IconCameraAddPhoto}
                                preview={false}
                              />
                            </div>
                          </Upload>
                        </ImgCrop>
                      </Spin>
                    </div>
                  </div>
                </div>
                <br />

                <div className="createWorkspace__addGeneralDetails__titleRoleContainer">
                  <Form.Item
                    label="Title (max length 60 characters)"
                    name="title"
                    className="createWorkspace__addGeneralDetails__titleRoleContainer__title"
                    required
                    rules={[
                      { required: true, message: "Please provide title." },
                    ]}
                  >
                    <Input
                      maxLength={60}
                      showCount
                      placeholder="Please enter a suitable title for playbook"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Role"
                    name="role"
                    className="createWorkspace__addGeneralDetails__titleRoleContainer__role"
                    required
                    rules={[
                      { required: true, message: "Please provide role." },
                    ]}
                  >
                    <Input placeholder="CEO - CFO" />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Short Description"
                  name="short_description"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please provide short description.",
                    },
                  ]}
                >
                  <Input
                    maxLength={80}
                    showCount
                    placeholder="Please enter short description"
                  />
                </Form.Item>
                <Divider className="createWorkspace__addGeneralDetails__divider" />

                <div className="createWorkspace__addGeneralDetails__tagsContainer">
                  <Form.Item
                    label="Primay Tag"
                    name="primary_tag"
                    required
                    rules={[
                      { required: true, message: "Please add primary tag" },
                    ]}
                  >
                    <Input
                      className="primaryInput"
                      maxLength={15}
                      showCount
                      placeholder="Primary Tag"
                    />
                  </Form.Item>

                  <div className="createWorkspace__addGeneralDetails__tagsContainer__colorSelection">
                    <Form.Item
                      label="Primary Tag color"
                      name="primary_tag_color"
                      required
                    >
                      <Select
                        showSearch
                        showArrow
                        defaultValue={colorsList[0]}
                        placeholder="Color"
                        className="primaryTag"
                        optionFilterProp="children"
                        onChange={handlePrimaryColorChange}
                        maxTagCount={1}
                      >
                        {colorsList.map((option, index) => {
                          return (
                            <Select.Option key={index} value={option.id}>
                              <div className="createWorkspace__addGeneralDetails__tagsContainer__colorSelection__row">
                                <span
                                  className="createWorkspace__addGeneralDetails__tagsContainer__colorSelection__row__text"
                                  style={{
                                    color: option.hexCode,
                                  }}
                                >
                                  {option.name}
                                </span>
                                <div
                                  className="createWorkspace__addGeneralDetails__tagsContainer__colorSelection__row__color"
                                  style={{
                                    backgroundColor: option.hexCode,
                                  }}
                                ></div>
                              </div>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <Form.Item
                    name="additional_tags"
                    label=" Additional Tags"
                    className="createWorkspace__addGeneralDetails__tagsContainer__custom"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please add tags to playbook",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      placeholder="Additional Tags"
                      style={{ width: "100%" }}
                      maxTagCount={3}
                      tokenSeparators={[","]}
                    />
                  </Form.Item>
                </div>

                <Divider className="createWorkspace__addGeneralDetails__divider" />

                <div className="createWorkspace__addGeneralDetails__categoryLanguageContainer">
                  <div className="createWorkspace__addGeneralDetails__categoryLanguageContainer__category">
                    <Form.Item
                      name="category"
                      className="categoryDropdown"
                      label="Category"
                      required
                    >
                      <Select
                        placeholder="Category"
                        style={{ width: "100%" }}
                        maxTagCount={1}
                        tokenSeparators={[","]}
                        onChange={onCategoryChange}
                      >
                        {categoriesList.map((category, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={category?.id || category}
                            >
                              {category?.name || category}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="createWorkspace__addGeneralDetails__categoryLanguageContainer__location">
                    <Form.Item
                      name="add_category"
                      className="dropdown"
                      label="Add Category"
                      required
                      rules={[
                        { required: false, message: "Please add Location" },
                      ]}
                    >
                      <Input placeholder="Add Category" />
                    </Form.Item>
                  </div>

                  <div className="createWorkspace__addGeneralDetails__categoryLanguageContainer__location">
                    <Form.Item
                      name="location"
                      className="dropdown"
                      label="City"
                      required
                      rules={[
                        { required: true, message: "Please add Location" },
                      ]}
                    >
                      <Input placeholder="City" />
                    </Form.Item>
                  </div>

                  <div className="createWorkspace__addGeneralDetails__categoryLanguageContainer__language">
                    <Form.Item
                      name="language"
                      className="dropdown createWorkspace__addGeneralDetails__categoryLanguageContainer__language"
                      label="Language"
                      required
                      rules={[
                        { required: true, message: "Please add Language" },
                      ]}
                    >
                      <Input placeholder="English - Default" />
                    </Form.Item>
                  </div>
                </div>

                <Divider className="createWorkspace__addGeneralDetails__divider" />
              </div>
            </div>
          </div>
          <div className="createPlaybook__addHighlights">
            <p className="addPackage__heading">Add Highlights</p>

            <div className="createPlaybook__addHighlights__singleRow">
              <Form.Item
                name="highlight1"
                className="createPlaybook__addHighlights__singleRow__leftSide"
                required
                rules={[{ required: true, message: "Please add Highlight 1" }]}
              >
                <Input maxLength={45} showCount placeholder="Add a highlight" />
              </Form.Item>

              <Form.Item
                name="highlight2"
                className="createPlaybook__addHighlights__singleRow__rightSide"
                required
                rules={[{ required: true, message: "Please add Highlight 2" }]}
              >
                <Input maxLength={45} showCount placeholder="Add a highlight" />
              </Form.Item>
            </div>

            <div className="createPlaybook__addHighlights__singleRow">
              <Form.Item
                name="highlight3"
                className="createPlaybook__addHighlights__singleRow__leftSide"
                required
                rules={[{ required: true, message: "Please add Highlight 3" }]}
              >
                 <Input maxLength={45} showCount placeholder="Add a highlight" />
              </Form.Item>

              <Form.Item
                name="highlight4"
                className="createPlaybook__addHighlights__singleRow__rightSide"
                required
                rules={[{ required: true, message: "Please add Highlight 4" }]}
              >
                <Input maxLength={45} showCount placeholder="Add a highlight" />
              </Form.Item>
            </div>
          </div>
          <div className="createPlaybook__addHighlights">
            <Form.Item
              label="Add Description"
              name="addDescription"
              required
              rules={[
                {
                  required: true,
                  message: "Add description of your playbook here",
                },
              ]}
            >
              <TextArea
                style={{ "min-height": 125, padding: 8 }}
                placeholder="Add description of your playbook here"
                rows={4}
              />
            </Form.Item>
          </div>

          <div>
            <br />

            <div className="createPlaybook__addPackageContainer">
              <AddPackage
                onPackagePriceChanged={onPackagePriceChanged}
                onPackageDescriptionChanged={onPackageDescriptionChanged}
                servicesSelectionData={servicesSelectionData}
                onServiceNameChanged={onServiceNameChanged}
                changeServiceSelection={changeServiceSelection}
              />
            </div>

            <div className="createPlaybook__uploadPromotionalMediaContainer">
              <div className="createPlaybook__uploadPromotionalMedia">
                <div className="createPlaybook__uploadPromotionalMedia__leftSide">
                  <span className="createPlaybook__uploadPromotionalMedia__leftSide__heading">
                    Upload promotional images
                  </span>
                  <br />
                  <span className="createPlaybook__uploadPromotionalMedia__leftSide__subHeading">
                    You can share up to 3 images (jpg, png - max size 2
                    mb/image)
                  </span>
                </div>

                <MediaUpload
                  className="createPlaybook__uploadPromotionalMedia__rightSide__image"
                  onImageUploaded={onImageUpload}
                  createButtonFunctionality={() => setIsEnabled(false)}
                />
              </div>
            </div>
          </div>
        </div>

        <Button
          htmlType="submit"
          type="primary"
          className="createPlaybook__saveSourceButton"
          loading={loading}
          disabled={!isEnabled}
        >
          Create
        </Button>
      </Form>
    </div>
  );
}

export default CreatePlaybook;
