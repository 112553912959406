import API from "..";

export const createUser = (payload) => {
  return API.post("/userManagement/createUser", payload);
};

export const signin = (payload) => {
  return API.post("userManagement/signin", payload);
};

export const changePassword = (payload) => {
  return API.post("userManagement/changeUserPassword", payload);
};

export const forgotPassword = (payload) => {
  return API.post("userManagement/forgotPassword", payload);
};

export const resetPassword = (payload) => {
  return API.post("userManagement/resetPassword", payload);
};

export const getWorkspaceUsersList = (payload) => {
  return API.get("userManagement/getUsersList", payload);
};

export const getProfilePresignedUri = (payload) => {
  return API.get("userManagement/getPreSignedUrlForUserImage", payload);
};

export const checkIfEmailExists = (payload) => {
  return API.get("userManagement/checkEmailStatus", payload);
};
export const checkIfPhoneExists = (payload) => {
  return API.get("userManagement/getPhoneNumberStatus", payload);
};

export const updateUserProfile = (payload) => {
  return API.post("userManagement/updateUserProfile", payload);
};

export const updateWorkProfile = (payload) => {
  return API.post("userManagement/updateWorkProfile", payload);
};

export const getUserNotificationSettings = (payload) => {
  return API.get("userManagement/getUserNotificationSettings", payload);
};

export const updateUserNotificationSettings = (payload) => {
  return API.post("userManagement/updateUserNotificationSettings", payload);
};

export const inviteUserToWorkspace = (payload) => {
  return API.post("userManagement/inviteUserToWorkspace", payload);
};

export const getUserInvitesListById = (payload) => {
  return API.get("userManagement/getUserInvitesListById", payload);
};

export const updateUserInvite = (payload) => {
  return API.post("userManagement/updateUserInvite", payload);
};

export const deleteUserAccount = (payload) => {
  return API.delete("userManagement/deleteUser", payload);
};

export const getUserInfoByMarketPlace = (payload) => {
  return API.get("userManagement/getUserInfoByMarketplace", payload);
};
