import React from "react";
import "./index.scss";
import {
  Button,
  Select,
  Divider,
  Input,
  Form,
  message,
  Spin,
  Image,
  Upload,
  Avatar,
  Modal
} from "antd";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  editWorkspace,
  getWorkspaceCategories,
  getPreSignedUrlForWorkspaceImage,
  getMarketplaceDetailsById,
} from "api/Workspaces";
import { useNavigate, useParams } from "react-router";
import { FileImageOutlined } from "@ant-design/icons";
import { setUserSession } from "store/modules/auth/action";
import ImgCrop from "antd-img-crop";
import { IconCameraAddPhoto } from "images";
import { uploadFileUsingPreSignedURL } from "utils";
import { trackEventCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "../../../constants";

const { Option } = Select;
const { TextArea } = Input;

export default function EditMarketplace() {
  const { marketplaceId } = useParams();
  const [loading, setLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const categoryId = useRef("");
  const navigate = useNavigate();
  const onProgress = () => {};
  const [marketplaceIconUrl, setMarketplaceIconUrl] = useState("");
  const [marketplaceDetails, setMarketplaceDetails] = useState("");
  const selectedCategoryId = marketplaceDetails?.workspace_category_id;
  const selectedCategory = categoriesList.find(category => category.id == selectedCategoryId);


  const colorsList = [
    { key: "0", name: "Green", hexCode: "#98D650" },
    { key: "1", name: "Orange", hexCode: " #F0994F" },
    { key: "2", name: "Blue", hexCode: "#0F0F5C" },
    { key: "2", name: "Cyan", hexCode: "#6AA3F8" },
  ];

  const [selectedPrimaryColor, setSelectedPrimaryColor] = useState('');
  console.log(selectedPrimaryColor, "selectedPrimaryColor")
  const [preSignedInfo, setPreSignedInfo] = useState({});

 
  const handlePrimaryColorChange = (index) => {
    setSelectedPrimaryColor(index);
  };
  

  useEffect(() => {
    getMarketplaceDetailsById(marketplaceId)
      .then((res) => {
        setLoading(false)
        setMarketplaceDetails(res?.data?.data[0])
      })
      .catch((err) => {

      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    getWorkspaceCategories()
      .then((res) => {
        setLoading(false);
        const catList = res.data.data.response;
        setCategoriesList(catList);
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {});
  }, []);

  const children = [];
  for (let i = 10; i < 10; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  const handleEditWorkspace = (values) => {
    // setLoading(true);

    const payload = {
      key: marketplaceDetails?.key,
      workspace_name: values.name,
      short_description: values.short_description,
      description: values.description,
      price_description: values.price_description,
      primary_tag: values.primary_tag,
      secondary_tag: values.additional_tags,
      primary_tag_color: values.primary_tag_color,
      location: values.location,
      language: values.language,
      user_role: values.role,
      account_id: userSession.accountId,
      user_id: userSession.userId,
      user_name: userSession.firstName + " " + userSession.lastName,
      email: userSession.email,
      profile_id: userSession.sellerProfileId,
      workspace_category_id: categoryId?.current !== "" ? categoryId?.current : marketplaceDetails?.workspace_category_id,
      image_uri: marketplaceIconUrl !== "" ? marketplaceIconUrl : marketplaceDetails?.image_uri,
    };

    callEditWorkspaceAPI(payload);
  };

  const onChange = ({ file }) => {
    setLoading(true);
    let uploadedImagePath = null;

    getPreSignedUrlForWorkspaceImage()
      .then((res) => {
        setPreSignedInfo(res.data.data);
        const uploadUrl = res.data.data.url;
        const bucket = res.data.data.bucket;
        const key = res.data.data.key;
        uploadedImagePath =
          "https://" +
          bucket +
          ".s3.us-west-2.amazonaws.com" +
          "/" +
          key +
          `?abc=${Math.random()}`;

        uploadFileUsingPreSignedURL(uploadUrl, file, (progress) => {
          onProgress({
            percent: Math.ceil(progress),
          });
        })
          .then((uploadRes) => {
            setMarketplaceIconUrl(uploadedImagePath);
            message.success("Image uploaded successfully");
          })
          .catch((uploadErr) => {
            console.log(uploadErr);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => {});
  };
  const onCategoryChange = (index) => {
    categoryId.current = categoriesList[index - 1].id;
  };
  const callEditWorkspaceAPI = (payload) => {
    editWorkspace(payload, marketplaceId)
      .then((res) => {
        message.success("Marketplace updated successfully");
        navigate("/dashboard/findMarketplaces");
      })
      .catch((err) => {
        message.error(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleDiscard = () => {
    Modal.confirm({
      title: 'Discard Changes',
      content: 'Are you sure you want to discard changes?',
      onOk() {
        setMarketplaceIconUrl(marketplaceDetails?.image_uri);
        navigate(`/dashboard/findMarketplaces`);
      },
      onCancel() {
        // User canceled, do nothing
      },
    });
  };

  return (
    <div className="editMarketplace hide-scroll-bar">
      <Spin spinning={loading}>
      <Form
        layout="vertical"
        autoComplete="off"
        fields={[
          { name: "name", value: marketplaceDetails?.name },
          { name: "role", value: marketplaceDetails?.user_role},
          { name: "short_description", value: marketplaceDetails?.short_description },
          { name: "primary_tag", value: marketplaceDetails?.primary_tag },
          { name: "primary_tag_color", value: selectedPrimaryColor !== '' ? selectedPrimaryColor : marketplaceDetails?.primary_tag_color },
          { name: "additional_tags", value: marketplaceDetails?.secondary_tag},
          { name: "category", value: selectedCategory && selectedCategory?.name},
          { name: "location", value: marketplaceDetails?.location },
          { name: "language", value: marketplaceDetails?.language},
          { name: "price_description", value: marketplaceDetails?.price_description},
          { name: "description", value: marketplaceDetails?.description },
        ]}
        form={form}
        onFinish={handleEditWorkspace}
      >
        <div className="editMarketplace__mainContainer">
          <div className="editMarketplace__addGeneralDetails">
            <div className="editMarketplace__addGeneralDetails__heading">
              <div className="editMarketplace__headingAndImageContainer">
                <div className="editMarketplace__headingAndImageContainer__headingContainer">
                  <span> Edit a Marketplace</span>
                  <br />
                  <span className="editMarketplace__addGeneralDetails__subHeading">
                    Get your skills speak out loud to impress our buyers
                  </span>
                </div>

                <div className="editMarketplace__headingAndImageContainer__imageContainer">
                  <div className="editMarketplace__headingAndImageContainer__imageContainer__image">
                    <Spin spinning={loading}>
                      <ImgCrop>
                        <Upload
                          accept="image/*"
                          showUploadList={false}
                          customRequest={onChange}
                        >
                          <div className="editMarketplace__headingAndImageContainer__imageContainer__image__headerImgDiv">
                            <Avatar
                              className="editMarketplace__headingAndImageContainer__imageContainer__image__headerImg"
                              src={marketplaceIconUrl !== "" ? marketplaceIconUrl : marketplaceDetails?.image_uri}
                              size={48}
                              icon={<FileImageOutlined />}
                            />
                            <Image
                              className="editMarketplace__headingAndImageContainer__imageContainer__image__cameraIcon"
                              src={IconCameraAddPhoto}
                              preview={false}
                            />
                          </div>
                        </Upload>
                      </ImgCrop>
                    </Spin>
                  </div>
                </div>
              </div>
              <br />

              <div className="editMarketplace__addGeneralDetails__titleRoleContainer">
                <Form.Item
                  label="Title (max length 30 characters)"
                  name="name"
                  className="editMarketplace__addGeneralDetails__titleRoleContainer__title"
                  required
                  rules={[{ required: true, message: "Please provide title." }]}
                >
                  <Input
                    maxLength={30}
                    showCount
                    placeholder="Please enter a suitable title for marketplace"
                  />
                </Form.Item>

                <Form.Item
                  label="Role"
                  name="role"
                  className="editMarketplace__addGeneralDetails__titleRoleContainer__role"
                  required
                  rules={[{ required: true, message: "Please provide role." }]}
                >
                  <Input placeholder="CEO - CFO" />
                </Form.Item>
              </div>
              <Form.Item
                label="Short Description"
                name="short_description"
                required
                rules={[
                  {
                    required: true,
                    message: "Please provide short description.",
                  },
                ]}
              >
                <Input placeholder="Please enter short description" />
              </Form.Item>
              <Divider className="editMarketplace__addGeneralDetails__divider" />

              <div className="editMarketplace__addGeneralDetails__tagsContainer">
                <Form.Item
                  label="Primay Tag"
                  name="primary_tag"
                  required
                  rules={[
                    { required: true, message: "Please add primary tag" },
                  ]}
                >
                  <Input
                    className="primaryInput"
                    maxLength={15}
                    showCount
                    placeholder="Primary Tag"
                  />
                </Form.Item>

                <div className="editMarketplace__addGeneralDetails__tagsContainer__colorSelection">
                  <Form.Item
                    label="Primary Tag color"
                    name="primary_tag_color"
                    required
                  >
                    <Select
                      showSearch
                      showArrow
                      //defaultValue={colorsList[0]}
                      placeholder="Color"
                      className="primaryTag"
                      optionFilterProp="children"
                      onSelect={handlePrimaryColorChange}
                      maxTagCount={1}
                    >
                      {colorsList.map((option, index) => {
                        return (
                          <Select.Option key={index} value={option.name} label={option.name}>
                            <div className="editMarketplace__addGeneralDetails__tagsContainer__colorSelection__row">
                              <span
                                className="editMarketplace__addGeneralDetails__tagsContainer__colorSelection__row__text"
                                style={{
                                  color: option.hexCode,
                                }}
                              >
                                {option.name}
                              </span>
                              <div
                                className="editMarketplace__addGeneralDetails__tagsContainer__colorSelection__row__color"
                                style={{
                                  backgroundColor: option.hexCode,
                                }}
                              ></div>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>

                <Form.Item
                  name="additional_tags"
                  label=" Additional Tags"
                  className="editMarketplace__addGeneralDetails__tagsContainer__custom"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please add tags to marketplace",
                    },
                  ]}
                >
                  <Select
                    mode="tags"
                    placeholder="Additional Tags"
                    style={{ width: "100%" }}
                    maxTagCount={3}
                    tokenSeparators={[","]}
                  />
                </Form.Item>
              </div>

              <Divider className="editMarketplace__addGeneralDetails__divider" />

              <div className="editMarketplace__addGeneralDetails__categoryLanguageContainer">
                <div className="editMarketplace__addGeneralDetails__categoryLanguageContainer__category">
                  <Form.Item
                    name="category"
                    className="categoryDropdown"
                    label="Category"
                    required
                    rules={[{ required: true, message: "Please add category" }]}
                  >
                    <Select
                      placeholder="Category"
                      style={{ width: "100%" }}
                      maxTagCount={1}
                      tokenSeparators={[","]}
                      onChange={onCategoryChange}
                    >
                      {categoriesList.map((category, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={category?.id}
                          >
                            {category?.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>

                <div className="editMarketplace__addGeneralDetails__categoryLanguageContainer__location">
                  <Form.Item
                    name="location"
                    className="dropdown"
                    label="City"
                    required
                    rules={[{ required: true, message: "Please add Location" }]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </div>

                <div className="editMarketplace__addGeneralDetails__categoryLanguageContainer__language">
                  <Form.Item
                    name="language"
                    className="dropdown editMarketplace__addGeneralDetails__categoryLanguageContainer__language"
                    label="Language"
                    required
                    rules={[{ required: true, message: "Please add Location" }]}
                  >
                    <Input placeholder="English - Default" />
                  </Form.Item>
                </div>
              </div>

              <Divider className="editMarketplace__addGeneralDetails__divider" />
            </div>
          </div>

          <div className="editMarketplace__addDescriptionContainer">
            <Form.Item
              label="Add marketing statement"
              name="price_description"
              required
              rules={[
                {
                  required: true,
                  message: "Add marketing statement for your marketplace here",
                },
              ]}
            >
              <TextArea
                style={{ "min-height": 60, padding: 8, height: 60 }}
                placeholder="Enter marketing statement for your buyers"
                rows={3}
                showCount
                maxLength={150}
              />
            </Form.Item>
          </div>
          <div className="editMarketplace__addDescriptionContainer">
            <Form.Item
              label="Add Description"
              name="description"
              required
              rules={[
                {
                  required: true,
                  message: "Add description of your marketplace here",
                },
              ]}
            >
              <TextArea
                style={{ "min-height": 125, padding: 8 }}
                placeholder="Add description of your marketplace here"
                rows={4}
              />
            </Form.Item>
          </div>
        </div>
        <div className="editMarketplace__button">
        <Button
          htmlType="submit"
          type="primary"
          loading={loading}
          className="editMarketplace__button__saveSourceButton"
        >
          Save
        </Button> 
        <Button className="editMarketplace__button__discardSourceButton" onClick={handleDiscard}>
          Discard
        </Button>  
        </div>  
      </Form>
      </Spin>
    </div>
  );
}
