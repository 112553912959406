import "./index.scss";
import { IconRevcloud, Facebookads, Loadingbegin } from "images";
import { useNavigate, useParams } from "react-router-dom";

const PermissionsVerified = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/datasets/sources`);
  };
  return (
    <div>
      <div className="PermissionsVerified__component">
        <h1 className="PermissionsVerified__mainHeading">
          Permissions Verified
        </h1>
        <div className="PermissionsVerified__iconsDiv">
          <div className="PermissionsVerified__icons">
            <img
              src={IconRevcloud}
              className="PermissionsVerified__img1"
              alt="IconRevcloud"
            />
            <img
              src={Loadingbegin}
              className="PermissionsVerified__img2"
              alt="Loading..."
            />
            <img
              src={Facebookads}
              className="PermissionsVerified__img3"
              alt="Facebookads"
            />
          </div>
        </div>
        <div className="PermissionsVerified__div">
          <div>
            <p className="PermissionsVerified__para">
              We were able to read and write the data from Facebook Ads account,
              Next, configure your warehouse.
            </p>
          </div>
          <div className="PermissionsVerified__buttonsDiv">
            <button
              onClick={() => navigateToFields()}
              className="PermissionsVerified__button"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionsVerified;
