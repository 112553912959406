import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";
import "./index.scss";

dayjs.extend(utc);

export function isSameDay(currentMessageCreatedAt, diffMessageCreatedAt) {
  if (!diffMessageCreatedAt) {
    return false;
  }

  const currentCreatedAt = dayjs(currentMessageCreatedAt).local();
  const diffCreatedAt = dayjs(diffMessageCreatedAt).local();

  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) {
    return false;
  }

  return currentCreatedAt.isSame(diffCreatedAt, "day");
}

const Day = ({ currentMessageCreatedAt, prevMessageCreatedAt }) => {
  if (
    currentMessageCreatedAt &&
    !isSameDay(currentMessageCreatedAt, prevMessageCreatedAt)
  ) {
    return (
      <div className="dayContainer">
        <span className="dayContainer__dayText">
          {dayjs(currentMessageCreatedAt)
            .local()
            .format("DD MMMM")
            .toUpperCase()}
        </span>
      </div>
    );
  }
  return null;
};

export default Day;
