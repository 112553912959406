import "./index.scss";
import { MoreOutlined, CopyOutlined } from "@ant-design/icons";
import MySellerDetails from "../MySinglePlaybook/MySellerDetails";
import { Card, message, Image, Avatar } from "antd";
import { PlaybookRequested } from "images";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Rate, Divider } from "antd";
import { trackEventCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { PlaybookDummyImage } from "images";

import { playbookActions } from "api/Playbooks";

function MyNewSinglePlaybook({ itemData = {} }) {
  //   let data = singlePlaybookDetail;
  const navigate = useNavigate();

  // import from sellerDetails
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  //

  const { buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  const handleVisibleChange = (newVisible) => {
    setActionVisibilty(newVisible);
  };
  const [actionVisibilty, setActionVisibilty] = useState(false);

  const onReadMoreClicked = (playbookId) => {
    navigate(`/dashboard/playbookDetails/${playbookId}`);
  };
  const createProposalChat = (playbookDetails) => {
    navigate(`/dashboard/proposalDetails/${playbookDetails?.id}`);
  };

  const callActionsAPI = (actionText) => {
    setActionVisibilty(false);

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      playbook_id: itemData.id,
      action: actionText,
      price: itemData.min_price,
      selected_package_id: 0,
    };
    const segmentEventData = {
      playbookname: itemData?.name,
      sellername:
        itemData?.seller?.first_name + " " + itemData?.seller?.last_name,
      selleremail: itemData?.seller?.email,
      playbook_id: itemData?.id,
      price: itemData?.min_price,
      marketplaceId: itemData?.workspace_id,
    };
    if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED,
        segmentEventData
      );
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
        segmentEventData
      );
    }
    console.log(payload);

    playbookActions(payload)
      .then((res) => {
        if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
            segmentEventData
          );
          trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, segmentEventData);
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
            segmentEventData
          );
          createProposalChat(itemData);
        }
        message.success(res?.itemData?.message);
      })
      .catch((err) => {
        message.error(err?.response?.itemData?.message);
      })
      .finally(() => {});
  };

  return (
    <div className="mynewsinglePlaybook">
      <Card hoverable>
        <Image
          onClick={() => onReadMoreClicked(itemData.id)}
          className="mynewsinglePlaybook__IMG1"
          alt="Image of playbook"
          preview={false}
          src={ itemData?.image1_url !== "" ? itemData?.image1_url : PlaybookDummyImage}
          />
        <div className="mynewsinglePlaybook__appHomeButton">
          <CustomAvatarSinglePlaybook
            imageUri={userSession?.imageUri}
            name={userSession?.firstName}
          />
          <div className="mynewsinglePlaybook__appHomeHeadContainer">
            <div
              className="mynewsinglePlaybook__appHomeMainHead"
              onClick={() => onReadMoreClicked(itemData.id)}
            >
              {userSession?.firstName + " " + userSession?.lastName}
            </div>
            <div
              onClick={() => onReadMoreClicked(itemData.id)}
              className="mynewsinglePlaybook__appHomeSubHead"
            >
              {/* Front-end Developer */}
            </div>
          </div>
        </div>
        <h3
          onClick={() => onReadMoreClicked(itemData.id)}
          className="mynewsinglePlaybook__H1"
        >
          {itemData?.name}
        </h3>
        <p className="mynewsinglePlaybook__P1">{itemData?.short_description}</p>

        <p className="mynewsinglePlaybook__proposalDetails__reviewsInfo">
          <Rate disabled defaultValue={1} count={1} />
          {"  "}
          {itemData?.rating_avg !== null ? itemData?.rating_avg : 0}
          {"  "}
          <span className="mynewsinglePlaybook__proposalDetails__reviewsInfo__count">
            (
            {itemData?.rating_avg !== null
              ? parseInt(parseInt(itemData?.rating_avg) * 1.7)
              : 0}
            K)
          </span>{" "}
        </p>

        <Divider className="mynewsinglePlaybook__divider" />

        <div className="mynewsinglePlaybook__P2">
          {/* {itemData?.isProposal ? (
            <p className="mynewsinglePlaybook__P2__Read">
              <img src={PlaybookRequested} /> Playbook
            </p>
          ) : ( */}
            <p onClick={() => onReadMoreClicked(itemData.id)} className="mynewsinglePlaybook__P2__Low">
              {/* AS LOW AS USD{parseInt(itemData?.min_price)} / month */}
              Learn More
            </p>
          {/* )} */}
        </div>
      </Card>
    </div>
  );
}

export default MyNewSinglePlaybook;

function CustomAvatarSinglePlaybook({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="mynewsinglePlaybook__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="mynewsinglePlaybook__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
