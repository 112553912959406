import { FileTextOutlined } from "@ant-design/icons";
import { Button, Image, List, Progress, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setFileUploadingPopupOpened } from "store/modules/fileOnboard/action";
import { secondsToDhms } from "utils";
import ChevronDownIcon from "../../images/chevron-down.svg";
import ChevronUpIcon from "../../images/chevron-up.svg";
import "./index.scss";

function InprogressUploads() {
  // Based on uploadError I need to show the internet connectivity error message.
  const inprogressFiles = useSelector(
    (state) => state.fileOnboardReducer
  ).files;
  const fileUploadingPopupOpened = useSelector(
    (state) => state.fileOnboardReducer
  ).fileUploadingPopupOpened;
  const dispatch = useDispatch();
  const inprogressFilesDatasource = Object.values(inprogressFiles);
  if (isEmpty(inprogressFilesDatasource)) {
    return null;
  }
  const filesCountUnderUpload = inprogressFilesDatasource.reduce(
    (count, item) => {
      return count + (item.uploadSuccess || item.uploadError ? 0 : 1);
    },
    0
  );

  const filesCountWithSuccess = inprogressFilesDatasource.reduce(
    (count, item) => {
      return count + (item.uploadSuccess ? 1 : 0);
    },
    0
  );
  const filesCountWithError = inprogressFilesDatasource.reduce(
    (count, item) => {
      return count + (item.uploadError ? 1 : 0);
    },
    0
  );
  const totalTimeRemaing = secondsToDhms(
    inprogressFilesDatasource.reduce((count, item) => {
      return count + item.timeRemaining;
    }, 0)
  );

  const uploadTitle = filesCountUnderUpload
    ? `Uploading ${filesCountUnderUpload} item${
        filesCountUnderUpload > 1 ? "s" : ""
      }`
    : `${filesCountWithSuccess} upload${
        filesCountWithSuccess > 1 ? "s" : ""
      } complete`;
  return (
    <div className="inprogressUploads">
      <div className="inprogressUploads__header">
        <p
          className="inprogressUploads__header__title"
          onClick={() => {
            dispatch(setFileUploadingPopupOpened(true));
          }}
        >
          {filesCountWithError > 0
            ? "Upload failed due to internet issue"
            : uploadTitle}
        </p>
        <Button
          className="inprogressUploads__header__arrowIcon"
          type="text"
          icon={
            fileUploadingPopupOpened ? (
              <Image src={ChevronDownIcon} preview={false} />
            ) : (
              <Image src={ChevronUpIcon} preview={false} />
            )
          }
          onClick={() => {
            dispatch(setFileUploadingPopupOpened(!fileUploadingPopupOpened));
          }}
        />
      </div>
      {fileUploadingPopupOpened && (
        <>
          {totalTimeRemaing && (
            <div className="inprogressUploads__timeRemaining">
              {totalTimeRemaing} left...
            </div>
          )}
          <List
            dataSource={inprogressFilesDatasource.reverse()}
            itemLayout="vertical"
            rowKey={"uid"}
            className="inprogressUploads__list"
            renderItem={(item) => {
              return (
                <div className="inprogressUploads__list__row">
                  <FileTextOutlined className="inprogressUploads__list__row__fileIcon" />
                  <p className="inprogressUploads__list__row__fileName">
                    <Tooltip title={item.name}>{item.name}</Tooltip>
                  </p>
                  <Progress
                    type="circle"
                    percent={parseInt(item.progress * 100)}
                    width={24}
                    status={item.uploadError ? "exception" : null}
                    strokeColor={parseInt(item.progress) ? null : "#0F0F60"}
                  />
                </div>
              );
            }}
          />
        </>
      )}
    </div>
  );
}
export default InprogressUploads;
