import "./index.scss";
import { MoreOutlined, CopyOutlined } from "@ant-design/icons";

import { Card, message, Popover, Avatar, Row, Col, Image } from "antd";
import { PlaybookRequested } from "images";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Rate, Divider } from "antd";
import { trackEventCall } from "analytics";
import { ANALYTICS_EVENT_NAMES } from "constants";
import { PLAYBOOK_ACTIONS } from "constants";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { PlaybookDummyImage } from "images";

import { playbookActions } from "api/Playbooks";
import { clearConfigCache } from "prettier";

function PrivSinglePlaybook({ singlePlaybookDetail = {} }) {
  const itemData = singlePlaybookDetail;
  const userSession = useSelector((state) => state.authReducer).userSession;
  const navigate = useNavigate();


  const [actionVisibilty, setActionVisibilty] = useState(false);

  const onReadMoreClicked = (playbookId) => {
    navigate(`/dashboard/playbookDetails/${playbookId}`);
  };

  const { buyerProfileWorkspaceId } = useSelector(
    (state) => state.authReducer
  ).userSession;

  const createProposalChat = (playbookDetails) => {
    navigate(`/dashboard/proposalDetails/${playbookDetails?.id}`);
  };

  const handleVisibleChange = (newVisible) => {
    setActionVisibilty(newVisible);
  };

  const callActionsAPI = (actionText) => {
    setActionVisibilty(false);

    const payload = {
      profile_workspace_id: buyerProfileWorkspaceId,
      playbook_id: itemData.id,
      action: actionText,
      price: itemData.min_price,
      selected_package_id: 0,
    };
    const segmentEventData = {
      playbookname: itemData?.name,
      sellername: itemData?.seller?.first_name + " " + itemData?.seller?.last_name,
      selleremail: itemData?.seller?.email,
      playbook_id: itemData?.id,
      price: itemData?.min_price,
      marketplaceId: itemData?.workspace_id,
    };
    if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUESTED,
        segmentEventData
      );
      trackEventCall(
        ANALYTICS_EVENT_NAMES.PROPOSAL_REQUEST_QUALIFIED,
        segmentEventData
      );
    }
    console.log(payload);

    playbookActions(payload)
      .then((res) => {
        if (actionText === PLAYBOOK_ACTIONS.REQUEST_PROPOSAL) {
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_CREATED,
            segmentEventData
          );
          trackEventCall(ANALYTICS_EVENT_NAMES.PROPOSAL_SENT, segmentEventData);
          trackEventCall(
            ANALYTICS_EVENT_NAMES.PROPOSAL_REVIEWED,
            segmentEventData
          );
          createProposalChat(itemData);
        }
        message.success(res?.data?.message);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {});
  };

  const actionDropDownContent = (
    <div className="popOverContainer">
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.REQUEST_PROPOSAL);
        }}
        style={{
          display:
          itemData?.user_id == userSession?.userId
              ? "none"
              : "visible",
        }}
      >
        {PLAYBOOK_ACTIONS.REQUEST_PROPOSAL}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.REVIEWING);
        }}
      >
        {PLAYBOOK_ACTIONS.REVIEWING}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.HIGH_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.HIGH_PRIORITY}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.MEDIUM_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.MEDIUM_PRIORITY}
      </p>
      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.LOW_PRIORITY);
        }}
      >
        {PLAYBOOK_ACTIONS.LOW_PRIORITY}
      </p>

      <p
        onClick={() => {
          callActionsAPI(PLAYBOOK_ACTIONS.NOT_INTRESTED);
        }}
      >
        {PLAYBOOK_ACTIONS.NOT_INTRESTED}
      </p>
    </div>
  );

  return (
    <div className="PrivSinglePlaybook">
      <Card
        className="PrivSinglePlaybook__Card"
        hoverable
      >
        <Image
          className="PrivSinglePlaybook__IMG1"
          alt="Image of playbook"
          preview={false}
          src={
            itemData?.thumbnail === null 
              ? (itemData?.image1_url && itemData?.image1_url !== '') 
                ? itemData?.image1_url
                : PlaybookDummyImage
              : itemData?.thumbnail
          }
          onClick={() => onReadMoreClicked(itemData.id)}
        />

        <Row
          className="PrivSinglePlaybook__Row-2"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col span={19}>
            <div className="PrivSinglePlaybook__appHomeButton">
              <CustomAvatarSinglePlaybook
                imageUri={itemData?.seller?.image_uri}
                // This lines shows the first letter of a name
                name={itemData?.seller?.first_name}
              />
              <div className="PrivSinglePlaybook__appHomeHeadContainer">
                <div className="PrivSinglePlaybook__appHomeMainHead">
                  {itemData?.seller?.first_name +
                    " " +
                    itemData?.seller?.last_name}
                </div>
                <div className="PrivSinglePlaybook__appHomeSubHead">
                  {itemData?.seller?.work_position}
                </div>
              </div>
            </div>
          </Col>

          <Col span={5}>
            <Popover
              placement="bottom"
              overlayClassName="actionsPopover"
              content={actionDropDownContent}
              trigger="click"
              visible={actionVisibilty}
              onVisibleChange={handleVisibleChange}
              className="MobileSinglePlaybook__P2__Low__Price__appdot"
            >
              <MoreOutlined className="PrivSinglePlaybook__appDotsIcon" />
            </Popover>
          </Col>
        </Row>
        <h3 className="PrivSinglePlaybook__H1">{itemData?.location}</h3>
        <div className="PrivSinglePlaybook__flex" onClick={() => onReadMoreClicked(itemData.id)}>
          <span className="PrivSinglePlaybook__P3">
            {itemData?.secondary_tag[0]}
          </span>
          <span className="PrivSinglePlaybook__P4">
            {itemData?.secondary_tag[1]}
          </span>
          {/* <span className="PrivSinglePlaybook__P5">
            {itemData?.secondary_tag[2]}
          </span> */}
        </div>
        
        <div onClick={() => onReadMoreClicked(itemData.id)}
        title={itemData?.description}>
        <p className="PrivSinglePlaybook__P1">{itemData?.description}</p>
        </div>
        <Divider className="PrivSinglePlaybook__divider" />

        <div className="PrivSinglePlaybook__P2">
        {itemData?.isProposal ? (
            <div>
              <img src={PlaybookRequested} /> Requested
            </div>
          ) : (
            <p className="PrivSinglePlaybook__P2__Low"
            onClick={() => onReadMoreClicked(itemData.id)}>
          
            {/* STARTING FROM<span className="price"> ${parseInt(itemData?.min_price)}</span> */}
            Learn More
            </p>
   
          )}
        </div>
      </Card>
    </div>
  );
}

export default PrivSinglePlaybook;

function CustomAvatarSinglePlaybook({ name, imageUri }) {
  const arrayOfColors = {
    A: "#AB0CFF",
    B: "#3283FF",
    C: "#85660C",
    D: "#782AB7",
    E: "#565656",
    F: "#EA1E29",
    G: "#3DB24B",
    H: "#F8E2A1",
    I: "#E2E2E2",
    J: "#1CBF4E",
    K: "#C5441C",
    L: "#DC9FFB",
    M: "#D90ED7",
    N: "#325A9B",
    O: "#FFAF16",
    P: "#F9A19F",
    Q: "#91AD1C",
    R: "#1C8356",
    S: "#48C1A7",
    T: "#2ED9FF",
    U: "#B10CA1",
    V: "#C174A7",
    W: "#EA3FBA",
    X: "#AE0065",
    Y: "#E8D325",
    Z: "#F80086",
  };

  let finalAvatar = null;
  if (
    imageUri !== null &&
    imageUri !== undefined &&
    imageUri !== "" &&
    !isEmpty(imageUri)
  ) {
    finalAvatar = (
      <Avatar
        className="PrivSinglePlaybook__customAvatar__avatar"
        src={imageUri}
      />
    );
  } else {
    finalAvatar = (
      <Avatar
        className="PrivSinglePlaybook__customAvatar__avatar"
        style={{
          backgroundColor: arrayOfColors[name?.charAt(0).toUpperCase() + ""],
          paddingTop: 8,
        }}
      >
        {name?.charAt(0).toUpperCase() + ""}
      </Avatar>
    );
  }

  return <div className="customAvatar">{finalAvatar}</div>;
}
