import { Empty, Table } from "antd";
import "./index.scss";
import RecordDetailsPopup from "./RecordDetailsPopup";
import { useState } from "react";
import { getNameColumn } from "components/DataTable/Columns";
import { useNavigate } from "react-router-dom";

function DataTable({
  entityTypeId,
  loading,
  columns = [],
  dataSource = [],
  // detailsKey = "",
}) {
  
  const navigate = useNavigate();
  const [detailsRecord, setDetailsRecord] = useState(null);
  let nameClicked = (record) => {
    setDetailsRecord(record);
  };
  // if (detailsKey) {
  //   columns[2] = getNameColumn(columns[2], nameClicked);
  // }

  const handleRowClick = (record) => {
    const personName = record?.name;
    const revId = record?.revcloud_person_id;
    navigate(`/dashboard/datasets/explorer/${personName}/${revId}`);
  };

  return (
    <>
      <Table
        className="dataTable__content"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        sticky
        loading={loading}
        locale={{
          emptyText: (
            <Empty
              description={
                loading ? "Loading" : "No Data Found"
              }
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        onRow={(record) => ({
          onClick: () => {
            if (entityTypeId === 2) {
              handleRowClick(record); // Trigger navigation
            }
          },
          onMouseEnter: (e) => {
            if (entityTypeId === 2) {
              e.currentTarget.style.cursor = "pointer";
            }
          },
          onMouseLeave: (e) => {
            e.currentTarget.style.cursor = "default";
          },
        })}
        // onRow={(record) => {
        //   return {
        //     onClick: () => {
        //       setDetailsRecord(record);
        //     },
        //   };
        // }}
      ></Table>
      {/* {detailsRecord && (
        <RecordDetailsPopup
          entityTypeId={entityTypeId}
          title={detailsRecord[detailsKey]}
          record={detailsRecord}
          onClose={() => {
            setDetailsRecord(null);
          }}
        />
      )} */}
    </>
  );
}

export default DataTable;
